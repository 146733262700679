import { useState, useEffect } from "react";
import { Col, Image, NavDropdown, Row } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DGButton from "../../Buttons/DGButton";
import AcademicPaperCard from "../../Cards/AcademicPaperCard";
import BlogCard from "../../Cards/BlogCard";
import PodcastCard from "../../Cards/PodcastCard";
import VideoCard from "../../Cards/VideoCard";

import { ReactComponent as CaretDown } from "../../../public/icons/Caret-Down.svg";
import MegaMenuArrow from "../../../public/images/MegaMenu-Arrow.svg";
import UploadFile from "../../../public/icons/Upload-File.svg";
import DGModal from "../../Modals/DGModal";

import blogsList from "../../../data/blogs.json";

const ExploreDropdown = (props) => {
  const tabs = [
    {
      key: "blog",
      href: "/blog",
      title: "Blog",
      subTitle:
        "Yaratıcı bünyelere ufuk açıcı bilgiler, araştırmalar ve analizler",
      showCategories: true,
      newestTitle: "blog yazıları",
      rightButtonTitle: "Tüm Blog Yazıları",
      rightButtonHref: "/blog",
    },
    {
      key: "podcast",
      href: "/podcast",
      title: "Podcast",
      subTitle: "Çok iş yapan ve çok konuşan yaratıcıların özlerine yolculuk",
      showCategories: false,
      newestTitle: "podcast yayınları",
      rightButtonTitle: "Tüm Podcast Yayınları",
      rightButtonHref: "/podcast",
    },
    {
      key: "videos",
      href: "/egitim-videolari",
      title: "Eğitim Videoları",
      subTitle: "Beş dakikada her şeyi öğrenmek isteyenler için videolar",
      showCategories: true,
      newestTitle: "eğitim videoları",
      rightButtonTitle: "Tüm Eğitim Videoları",
      rightButtonHref: "/egitim-videolari",
    },
    {
      key: "pages",
      href: "/akademik-yazilar",
      title: "Akademik Yazılar",
      subTitle:
        "Lorem ipsum her şeyi öğrenmek isteyenler için akademik yazılar",
      showCategories: true,
      newestTitle: "akademik yazılar",
      rightButtonTitle: "Tüm Akademik Yazılar",
      rightButtonHref: "/akademik-yazilar",
      leftButtonTitle: "Akademik Yazı Yükle",
      leftButtonHref: "/",
      leftButtonIcon: UploadFile,
    },
  ];

  const podcasts = null; //props.data?.podcasts;
  const blogs = blogsList.slice(0, 4);
  const tutorials = null; //props.data?.tutorials;

  const location = useLocation();
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [selectedData, setSelectedData] = useState(blogs);
  const [show, setShow] = useState(false);

  function hideDropdown() {
    setShow(false);
    document.getElementsByClassName("dropdown-explore")[0]?.click();
  }

  useEffect(() => {
    if (selectedTab.key === "pages") {
      // setSelectedData(pages);
    } else if (selectedTab.key === "podcast") {
      setSelectedData(podcasts);
    } else if (selectedTab.key === "videos") {
      setSelectedData(tutorials);
    } else {
      setSelectedData(blogs);
    }
  }, [selectedTab]);

  useEffect(() => {
    setShow(false);
  }, [location.pathname]);

  useEffect(() => {
    const html = document.querySelector("html");
    if (html) {
      html.style.overflow = show ? "hidden" : "auto";
    }
  }, [show]);

  return (
    <NavDropdown
      title={
        <div
          style={{
            display: "flex",
            gap: "4px",
            alignItems: "center",
          }}
        >
          Keşfet
          <CaretDown width={12} height={12} style={{ marginTop: -4 }} />
        </div>
      }
      className="f-h6 p-0 dropdown-explore"
      active={
        location.pathname.includes("/podcast") ||
        location.pathname.includes("/blog") ||
        location.pathname.includes("/egitim-videolari") ||
        location.pathname.includes("/akademik-yazilar")
      }
      show={show}
      onMouseEnter={() => setShow(true)}
    >
      <Row
        style={{
          position: "absolute",
          top: "-2px",
          left: "0",
          right: "0",
          height: "calc(100vh - 96px)",
        }}
      >
        <Col
          xs="auto"
          style={{
            height: "calc(100vh - 96px)",
            gap: "45px",
            flexWrap: "nowrap",
            background: "white",
            padding: "0px 0px 0px 45px",
            width: "max-content",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <LeftContainer
            tabs={tabs}
            setSelectedTab={setSelectedTab}
            selectedTab={selectedTab}
          />
          <RightContainer
            selectedTab={selectedTab}
            hideDropdown={hideDropdown}
            navigate={navigate}
            selectedData={selectedData}
          />
        </Col>

        <Overlay hideDropdown={hideDropdown} />
      </Row>
    </NavDropdown>
  );
};

const Overlay = (props) => {
  return (
    <Col
      style={{
        height: "calc(100vh - 96px)",
        backgroundColor: "rgba(18, 14, 22, 0.85)",
      }}
      onMouseEnter={() => props.hideDropdown()}
      xs
    />
  );
};

const CategoriesView = (props) => {
  const selectedTab = props.selectedTab;

  return selectedTab.showCategories ? (
    <div>
      <div
        style={{
          height: "1px",
          background: "var(--neutral200)",
          width: "100%",
          margin: "32px 0px",
        }}
      />

      <div style={{ width: "250px" }}>
        <div className="f-h6">
          Popüler {selectedTab.newestTitle} kategorileri
        </div>
        <Row style={{ gap: "20px", marginTop: "16px" }}>
          <Link
            to={`${selectedTab.href}?category=Tutorials & İpuçları`}
            className="f-label-l"
          >
            Tutorials & İpuçları
          </Link>
          <Link
            to={`${selectedTab.href}?category=Tutorials & İpuçları`}
            className="f-label-l"
          >
            Tutorials & İpuçları
          </Link>
          <Link
            to={`${selectedTab.href}?category=Tutorials & İpuçları`}
            className="f-label-l"
          >
            Tutorials & İpuçları
          </Link>
          <Link
            to={`${selectedTab.href}?category=Tutorials & İpuçları`}
            className="f-label-l"
          >
            Tutorials & İpuçları
          </Link>
        </Row>
      </div>
    </div>
  ) : null;
};

const MegaMenuItem = (props) => {
  return (
    <NavDropdown.Item
      style={{ whiteSpace: "normal", display: "flex" }}
      {...props}
    >
      <Col xs>
        <div className="f-h6">{props.title}</div>
        <div className="f-label" style={{ marginTop: "5px" }}>
          {props.desc}
        </div>
      </Col>
      <Col
        xs="auto"
        style={{ visibility: props.active ? "visible" : "hidden" }}
      >
        <Image src={MegaMenuArrow} />
      </Col>
    </NavDropdown.Item>
  );
};

const LeftContainer = (props) => {
  return (
    <Row
      style={{
        gap: "30px",
        flexDirection: "column",
        width: "290px",
        padding: "45px 0",
      }}
    >
      <Row
        className="f-h5"
        style={{
          borderBottom: "1px solid #E3DFED",
          paddingBottom: "13px",
        }}
      >
        Keşfet
      </Row>

      {props.tabs.map((x, i) => (
        <MegaMenuItem
          href={x.href}
          onMouseOver={() => props.setSelectedTab(x)}
          active={props.selectedTab.key === x.key}
          title={x.title}
          desc={x.subTitle}
          key={i}
        />
      ))}
    </Row>
  );
};

const RightContainer = (props) => {
  return (
    <div
      style={{
        height: "100%",
        background: "var(--neutral50)",
        display: "flex",
        flexDirection: "column",
        maxWidth: "572px",
      }}
    >
      <div
        style={{
          flexGrow: 1,
          overflow: "scroll",
          padding: "45px 32px 20px",
        }}
      >
        <div className="f-h6" style={{ marginBottom: 16 }}>
          En yeni {props.selectedTab.newestTitle}
        </div>

        <div
          style={{
            display: "grid",
            gridTemplateColumns:
              props.selectedTab.key === "pages"
                ? "auto"
                : "repeat(2, minmax(0, 1fr))",
            gap: 16,
            textWrap: "wrap",
            whiteSpace: "normal",
          }}
        >
          {props.selectedData?.map((element, i) =>
            props.selectedTab.key === "blog" ? (
              <BlogCard element={element} key={i} />
            ) : props.selectedTab.key === "podcast" ? (
              <PodcastCard element={element} key={i} />
            ) : props.selectedTab.key === "videos" ? (
              <VideoCard element={element} key={i} />
            ) : props.selectedTab.key === "pages" ? (
              <AcademicPaperCard
                title="MSKU Bodrum Güzel Sanatlar Fakültesi Uluslararası Sanat Sempozyumu - MSKU Bodrum Faculty of Fine Arts International Art Symposium"
                imageWidth="90px"
                width="508px"
                key={i}
              />
            ) : null
          )}
        </div>

        {/* <CategoriesView selectedTab={props.selectedTab} /> */}
      </div>

      <ButtonContainer
        selectedTab={props.selectedTab}
        hideDropdown={props.hideDropdown}
        navigate={props.navigate}
      />
    </div>
  );
};

//* Buttons
const ButtonContainer = (props) => {
  return (
    <Row
      style={{
        justifyContent: "center",
        padding: "13px 32px",
        borderTop: "1px solid #C2BECC",
        gap: 24,
      }}
    >
      <LeftButton
        title={props.selectedTab.leftButtonTitle}
        icon={props.selectedTab.leftButtonIcon}
        href={props.selectedTab.leftButtonHref}
        hideDropdown={props.hideDropdown}
      />

      <DGButton
        variant="dg-primary"
        size="sm"
        style={{ marginTop: "10px", width: "min-content" }}
        onClick={() => {
          props.hideDropdown();
          props.navigate(props.selectedTab.rightButtonHref);
        }}
      >
        {props.selectedTab.rightButtonTitle}
      </DGButton>
    </Row>
  );
};

const LeftButton = (props) => {
  const navigate = useNavigate();
  const [mustSignInModalShow, setMustSignInModalShow] = useState(false);

  return (props.title || null) === null ? null : (
    <DGButton
      variant="dg-secondary"
      size="sm"
      style={{ marginTop: "10px", width: "min-content" }}
      onClick={() => {
        props.hideDropdown();
        if (localStorage.getItem("signed-in") === "true" || false) {
          navigate(props.href);
        } else {
          setMustSignInModalShow(true);
        }
      }}
    >
      <Image src={props.icon} />
      {props.title}

      <DGModal
        title="Üye girişi gerekiyor"
        desc="Akademik Yazı yükleyebilmen ve yazı sahiplerini eksiksiz referans verebilmemiz için üye girişi yapman gerekiyor."
        textButtonText="Vazgeç"
        buttonText="Giriş Yap"
        textButtonOnClick={() => setMustSignInModalShow(false)}
        buttonOnClick={() => setMustSignInModalShow(false)}
        show={mustSignInModalShow}
        onHide={() => setMustSignInModalShow(false)}
      />
    </DGButton>
  );
};

export default ExploreDropdown;
