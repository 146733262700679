import { Col, Image, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";

import { ReactComponent as Award } from "../../../public/icons/Award.svg";
import { ReactComponent as Project } from "../../../public/icons/Project.svg";
import { ReactComponent as SrUsta } from "../../../public/images/Sr-Usta.svg";
import { ReactComponent as CaretRight } from "../../../public/images/caret-right.svg";

const MobileMentorCard = (props) => {
  return (
    <Link
      to={`/ustalar/${props.username}`}
      style={{
        color: "var(--black)",
        textDecoration: "none",
        padding: "12px 0",
      }}
    >
      <Row style={{ gap: 12 }}>
        <Col xs="auto">
          <Image
            roundedCircle={true}
            src={props?.image}
            style={{ width: "48px", height: "48px" }}
          />
        </Col>
        <Col
          xs
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div className="f-label" style={{ fontWeight: 700 }}>
            {props.name}
          </div>
          <div className="f-label-s" style={{ color: "var(--neutral500)" }}>
            {props.desc}
          </div>
          <div className="f-label-s" style={{ color: "var(--neutral300)" }}>
            {props.company}
          </div>
        </Col>

        <Col xs="auto" style={{ display: "flex", gap: 12 }}>
          <Award
            width={16}
            height={16}
            stroke="var(--purplePrimary)"
            fill="var(--purplePrimary)"
          />

          <OverlayTrigger overlay={<Tooltip id={"project"}>Proje</Tooltip>}>
            <Project width={16} height={16} stroke="var(--greenPrimary)" />
          </OverlayTrigger>

          <SrUsta width={16} height={16} stroke="var(--bluePrimary)" />
          <CaretRight width={10} height={10} stroke="var(--neutral200)" />
        </Col>
      </Row>
    </Link>
  );
};

export default MobileMentorCard;
