import { useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useIsMobile from "../../public/is-Mobile";
import DGButton from "../Buttons/DGButton";
import SliderButton from "../Buttons/SliderButton";
import MentorCard from "../Cards/Mentor/MentorCard";

import "./home.css";

const HomeMentors = (props) => {
  const ref = useRef(null);
  const isMobile = useIsMobile();
  const { t } = useTranslation("home");

  const scroll = (isNext) => {
    if (isNext === true) {
      ref.current.scrollLeft += 540;
    } else {
      ref.current.scrollLeft -= 540;
    }
  };

  return (
    <Row style={{ justifyContent: "center" }}>
      <Container style={{ margin: isMobile ? "45px 0 50px" : "95px 0px 70px" }}>
        {isMobile ? (
          <Row className="f-h3" style={{ padding: "0px 20px" }}>
            {t("populer_mentors_title")}
          </Row>
        ) : (
          <Row style={{ gap: "30px" }}>
            <Col className="f-h3" style={{ flexGrown: "1" }}>
              {t("populer_mentors_title")}
            </Col>
            <Col
              xs="auto"
              style={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <Link to="/ustalar">
                <DGButton variant="tertiary" size="sm">
                  {t("populer_mentors_button_title")}
                </DGButton>
              </Link>
            </Col>
            <Col xs="auto" style={{ display: "flex", alignContent: "center" }}>
              <Row style={{ gap: "12px", alignContent: "center" }}>
                <SliderButton
                  next={true}
                  onClick={() => {
                    scroll(false);
                  }}
                />

                <SliderButton
                  next={false}
                  onClick={() => {
                    scroll(true);
                  }}
                />
              </Row>
            </Col>
          </Row>
        )}

        <div
          className="mentors-scroll"
          style={{
            margin: isMobile ? "0px" : "0px -20px",
            scrollBehavior: "smooth",
            overflowX: isMobile ? "scroll" : "hidden",
          }}
          ref={ref}
        >
          {props?.mentorData?.map((x, i) => (
            <MentorCard
              image={x.img}
              username={x.displayname}
              name={x.username}
              desc={x.worktitle}
              company={x.company}
              projectCount={x.project_count}
              key={i}
              style={{ width: 240 }}
            />
          ))}
        </div>

        {isMobile ? (
          <div style={{ padding: "0px 20px" }}>
            <DGButton variant="dg-primary" size="medium">
              {t("populer_mentors_button_title")}
            </DGButton>
          </div>
        ) : null}
      </Container>
    </Row>
  );
};

export default HomeMentors;
