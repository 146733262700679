import { Col, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import { ReactComponent as Notifications } from "../../../public/icons/Notifications.svg";
import { ReactComponent as Email } from "../../../public/icons/Email.svg";
import { ReactComponent as Icon } from "../../../public/images/DesignGost-Icon.svg";
import { ReactComponent as Hamburger } from "../../../public/icons/Hamburger.svg";
import { ReactComponent as Close } from "../../../public/icons/X.svg";

import useIsMobile from "../../../public/is-Mobile";
import HeaderLeading from "./HeaderLeading";

const MobileHeader = (props) => {
  const isMobile = useIsMobile();
  const variant = props?.variant;
  const isSignedIn = localStorage.getItem("signed-in") === "true" || false;

  return (
    <Row
      style={{
        width: "100%",
        flexWrap: isMobile ? "wrap" : "nowrap",
        padding: "12px 20px",
      }}
    >
      <HeaderLeading variant={variant} />
      <Col
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: 16,
        }}
      >
        {isSignedIn ? (
          <Link to={"/profile"}>
            <Notifications
              width={20}
              height={20}
              stroke={variant === "dark" ? "white" : "black"}
            />
          </Link>
        ) : null}

        {isSignedIn ? (
          <Link to={"/messages"}>
            <Email
              width={20}
              height={20}
              stroke={variant === "dark" ? "white" : "black"}
            />
          </Link>
        ) : null}

        {isSignedIn ? (
          <Image
            src={Icon}
            style={{
              height: "20px",
              width: "20px",
              border: "1px solid #ffffff70",
            }}
            roundedCircle={true}
            onClick={() => {
              props?.setShow(false);
              props?.setShowProfileMenu(!props?.showProfileMenu);
            }}
          />
        ) : null}

        <div
          onClick={() => {
            props?.setShow(!props?.show);
            props?.setShowProfileMenu(false);
          }}
        >
          {props?.show ? (
            <Close
              width={20}
              height={20}
              stroke={variant === "dark" ? "white" : "black"}
            />
          ) : (
            <Hamburger
              width={20}
              height={20}
              stroke={variant === "dark" ? "white" : "black"}
            />
          )}
        </div>
      </Col>
    </Row>
  );
};

export default MobileHeader;
