import { Button, Col, Container, Image, Row } from "react-bootstrap";
import useIsMobile from "../../public/is-Mobile";
import DGButton from "../Buttons/DGButton";

import { ReactComponent as CaretRight } from "../../public/images/caret-right.svg";
import { ReactComponent as GelistirmekGerekiyor } from "../../public/icons/Proje-Gelistirmek-Gerekiyor.svg";
import { ReactComponent as Chat } from "../../public/icons/Chat.svg";
import { ReactComponent as Onaylandı } from "../../public/icons/Onaylandı.svg";
import { ReactComponent as Email } from "../../public/icons/Email.svg";
import { ReactComponent as CloseX } from "../../public/icons/X.svg";

const NotificationsTab = () => {
  const isMobile = useIsMobile();

  let data = [
    {
      image: "",
      title: "DesignGost yeni bir blog yükledi",
      description:
        "Dünyanın en ilham verici online tasarım ve sanat platformları",
      date: "Today",
    },
    {
      image: "",
      title: "Projeye yorum yaptın",
      projectStatus: "NEEDS_IMPROVE",
      date: "25 Haziran 2020, Salı",
    },
    {
      image: "",
      title: "Hakan Ertan projenize yorum yaptı",
      projectStatus: "NEEDS_COMMENT",
      date: "25 Haziran 2020, Salı",
    },
    {
      image: "",
      title: "Emre Ertan ile yaptığınız projeyi onayladın",
      projectStatus: "APPROVED",
      date: "25 Haziran 2020, Salı",
    },
    {
      image: "",
      title: "Hakan Ertan projeni onaylandı",
      projectStatus: "APPROVED",
      date: "25 Haziran 2020, Salı",
    },
    {
      image: "",
      title: "Tebrikler! Hakan Ertan çıraklık talebi yolladı",
      user: {
        image:
          "https://www.designgost.com/img/4e312348-8be8-4a45-ab83-6a5b4fb99a35/hakan-ertan.jpg?fm=webp&fit=max&crop=1350%2C1350%2C0%2C0&w=200&s=ee609c601fab0ec9585be45177fa5a4b",
        name: "Hakan Ertan",
      },
      date: "25 Haziran 2020, Salı",
    },
    {
      image: "",
      title: "Tebrikler! Emre Ertan çıraklık talebini onayladı",
      user: {
        image:
          "https://www.designgost.com/img/4e312348-8be8-4a45-ab83-6a5b4fb99a35/hakan-ertan.jpg?fm=webp&fit=max&crop=1350%2C1350%2C0%2C0&w=200&s=ee609c601fab0ec9585be45177fa5a4b",
        name: "Hakan Ertan",
      },
      date: "25 Haziran 2020, Salı",
    },
    {
      image: null,
      title: "Tebrikler! Mesut Uğurlu çıraklık talebi yolladı",

      user: {
        image:
          "https://www.designgost.com/img/4e312348-8be8-4a45-ab83-6a5b4fb99a35/hakan-ertan.jpg?fm=webp&fit=max&crop=1350%2C1350%2C0%2C0&w=200&s=ee609c601fab0ec9585be45177fa5a4b",
        name: "Hakan Ertan",
      },
      date: "24 Haziran 2020, Pazartesi",
      isMessage: true,
    },
    {
      image: null,
      title: "Tebrikler! Hakan Ertan çıraklık talebini kabul etti",
      user: {
        image:
          "https://www.designgost.com/img/4e312348-8be8-4a45-ab83-6a5b4fb99a35/hakan-ertan.jpg?fm=webp&fit=max&crop=1350%2C1350%2C0%2C0&w=200&s=ee609c601fab0ec9585be45177fa5a4b",
        name: "Hakan Ertan",
      },
      date: "24 Haziran 2020, Pazartesi",
      isMessage: true,
    },
    {
      image: null,
      title: "Yeni bir mesajın var",
      user: {
        image:
          "https://www.designgost.com/img/4e312348-8be8-4a45-ab83-6a5b4fb99a35/hakan-ertan.jpg?fm=webp&fit=max&crop=1350%2C1350%2C0%2C0&w=200&s=ee609c601fab0ec9585be45177fa5a4b",
        name: "Hakan Ertan",
      },
      date: "24 Haziran 2020, Pazartesi",
      isMessage: true,
    },
  ];

  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "column",
        gap: isMobile ? 8 : 12,
        padding: isMobile ? "20px 20px 30px" : "50px 0px 100px",
      }}
    >
      <Row
        className="f-paragraph text-center"
        style={{
          backgroundColor: "var(--blueLight)",
          gap: 20,
          padding: "20px 30px",
          position: "relative",
          borderRadius: 12,
          marginBottom: "20px",
        }}
      >
        <div style={{ fontWeight: 700 }}>
          Hoşgeldin Hakan!
          <div style={{ fontWeight: 500 }}>
            E-postanı onaylarak Designgost üyeliğini oluşturdun.
          </div>
        </div>
        <Row>
          <div
            style={{
              display: "flex",
              gap: 20,
              justifyContent: "center",
              flexDirection: isMobile ? "column" : "row",
            }}
          >
            <div>
              <DGButton variant="dg-primary" size="medium">
                Profilini Tamamla
              </DGButton>
            </div>
            <div>
              <DGButton variant="dg-secondary" size="medium">
                Projeleri İncele
              </DGButton>
            </div>
          </div>
        </Row>
        <Button
          variant="slider-button-light"
          style={{
            background: "white",
            border: "0px",
            position: "absolute",
            top: "20px",
            right: "15px",
          }}
        >
          <CloseX width={7} height={7} />
        </Button>
      </Row>
      {data.map((x, i) => (
        <NotificationListElement
          image={x.image}
          title={x.title}
          description={x.description}
          projectStatus={x.projectStatus}
          user={x.user}
          isMessage={x.isMessage}
          date={
            i > 0 && x.date !== data[i - 1].date
              ? x.date
              : i === 0
              ? x.date
              : null
          }
        />
      ))}
    </Container>
  );
};

export default NotificationsTab;

const NotificationListElement = (props) => {
  const isMobile = useIsMobile();

  return (
    <Row style={{ gap: 30 }}>
      {isMobile ? null : (
        <Col xs="auto" className="f-label-s" style={{ width: "70px" }}>
          {props.date ? props.date : null}
        </Col>
      )}

      <Col xs className="dashboard-notification-card boxed-card">
        {props.image !== null ? (
          <Col xs="auto">
            <Image
              style={{
                width: "72px",
                height: "100%",
                background: "#504B5F",
                borderRadius: "4px",
              }}
            />
          </Col>
        ) : null}

        <Col
          xs
          style={{
            display: "flex",
            gap: 8,
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Row
            className="f-label-l"
            style={{ fontWeight: 700, alignItems: "center" }}
          >
            <span>
              {props.isMessage === true ? (
                <div
                  style={{
                    width: "min-content",
                    display: "initial",
                    marginRight: 8,
                  }}
                >
                  <Email width={24} height={24} />
                </div>
              ) : null}
              {props.title}
            </span>
          </Row>
          {props.description ?? "" !== "" ? (
            <Row className="f-label" style={{ color: "var(--neutral500)" }}>
              {props.description}
            </Row>
          ) : null}

          {props.projectStatus ? (
            <Row className="f-label" style={{ gap: 8, alignItems: "center" }}>
              {isMobile ? null : (
                <Col
                  xs="auto"
                  style={{ height: "min-content", color: "var(--black)" }}
                >
                  Durum:{" "}
                </Col>
              )}

              {props.projectStatus === "NEEDS_IMPROVE" ? (
                <Col
                  xs="auto"
                  style={{
                    display: "flex",
                    gap: 4,
                    alignItems: "center",
                    color: "var(--redPrimary)",
                    fontWeight: 700,
                  }}
                >
                  <GelistirmekGerekiyor
                    width={20}
                    height={20}
                    stroke="var(--redPrimary)"
                  />
                  Gelistirmek gerekiyor
                </Col>
              ) : props.projectStatus === "NEEDS_COMMENT" ? (
                <Col
                  xs="auto"
                  style={{
                    display: "flex",
                    gap: 4,
                    alignItems: "center",
                    color: "var(--purplePrimary)",
                    fontWeight: 700,
                  }}
                >
                  <Chat width={20} height={20} stroke="var(--purplePrimary)" />
                  Yorum gerekiyor
                </Col>
              ) : props.projectStatus === "APPROVED" ? (
                <Col
                  xs="auto"
                  style={{
                    display: "flex",
                    gap: 4,
                    alignItems: "center",
                    color: "var(--greenPrimary)",
                    fontWeight: 700,
                  }}
                >
                  <Onaylandı
                    width={20}
                    height={20}
                    stroke="var(--greenPrimary)"
                  />
                  Proje onaylandı
                </Col>
              ) : null}
            </Row>
          ) : null}

          {props.user ? (
            <Row style={{ alignItems: "center", gap: 8 }}>
              <Col xs="auto">
                <Image
                  roundedCircle={true}
                  src={props.user.image}
                  style={{ height: "20px" }}
                />
              </Col>
              <Col
                xs
                className="f-label"
                style={{ height: "min-content", color: "var(--black)" }}
              >
                {props.user.name}
              </Col>
            </Row>
          ) : null}
        </Col>
        <Col
          xs={isMobile ? 12 : "auto"}
          className="f-label dashboard-notification-card-button"
        >
          <CaretRight width={11} />
        </Col>
      </Col>
    </Row>
  );
};
