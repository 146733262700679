import { useState } from "react";
import {
  Col,
  Container,
  Dropdown,
  Form,
  InputGroup,
  Modal,
  Row,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Search } from "../../public/images/Search.svg";
import { ReactComponent as XIcon } from "../../public/icons/X.svg";
import { ReactComponent as Filter } from "../../public/icons/Filter.svg";
import useIsMobile from "../../public/is-Mobile";
import DGButton from "../Buttons/DGButton";
import DividerToggle from "../DividerToggle";
import Input from "../Inputs/Input";
import "./homeSearchBar.css";
import CloseButton from "../Buttons/CloseButton";
import Switch from "../Switch/Switch";

const SearchBar = (props) => {
  return (
    <Container>
      {props?.variant === "mentor" ? (
        <MentorSearch text={props?.text} />
      ) : (
        <HomeSearch categories={props?.categories} />
      )}
    </Container>
  );
};

const HomeSearch = (props) => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const [query, setQuery] = useState("");
  const [category, setCategory] = useState(null);

  function handleKeyPress(event) {
    if (event.key === "Enter") {
      if (query === null || query.length === 0) {
        navigate(`/ustalar`);
      } else {
        navigate(`/ustalar?q=${query}`);
      }
    }
  }

  function handleChange(event) {
    setQuery(event.target.value);
  }

  return (
    <Row className="home-search-bar">
      <Col
        xs
        style={{
          maxWidth: isMobile ? "unset" : "840px",
          padding: isMobile ? "0px 20px" : "0px",
        }}
      >
        <Container
          style={
            isMobile ? { top: "-110px", borderRadius: 8 } : { top: "-45px" }
          }
        >
          <Row
            style={{
              alignItems: "center",
              padding: isMobile ? "30px 20px" : "6px 16px 6px 16px",
              gap: 20,
            }}
          >
            <Col xs={12} lg={7}>
              <Input
                icon={<Search width={24} />}
                placeholder={
                  isMobile
                    ? "İsim, şirket ya da ünvan yaz"
                    : "İsim, şirket ya da ünvan yazarak arama yapabilirsin"
                }
                withoutBorder={!isMobile}
                onKeyDown={handleKeyPress}
                onChange={handleChange}
              />
            </Col>
            {!isMobile ? <Divider /> : null}
            <Col>
              <Dropdown
                style={{
                  padding: "13px 15px",
                  border: isMobile ? "1px solid var(--neutral50)" : "0px",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                <Dropdown.Toggle as={DividerToggle}>
                  <Row style={{ gap: 4 }}>
                    <Col
                      xs="auto"
                      lg={12}
                      className="f-label"
                      style={{ display: "flex", alignItems: "flex-end" }}
                    >
                      Uzmanlık:
                    </Col>
                    <Col xs lg={12} className="f-h6">
                      {category ?? "Tüm Ustalar"}
                    </Col>
                  </Row>
                </Dropdown.Toggle>

                <Dropdown.Menu
                  style={{ maxHeight: 220, overflow: "scroll", marginTop: 4 }}
                >
                  <Dropdown.Item onClick={() => setCategory(null)}>
                    Tüm Ustalar
                  </Dropdown.Item>
                  {props?.categories.map((category, i) => (
                    <Dropdown.Item
                      onClick={() => setCategory(category.title)}
                      key={i}
                    >
                      {category.title}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            <Col xs={12} lg="auto">
              <Link
                to={`/ustalar${category != "all" ? "?job=" + category : null}`}
              >
                <DGButton
                  variant="dg-primary"
                  size="lg"
                  style={isMobile ? { width: "100%" } : {}}
                >
                  Ara
                </DGButton>
              </Link>
            </Col>
          </Row>
        </Container>
      </Col>
    </Row>
  );
};

const MentorSearch = ({ text }) => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const [query, setQuery] = useState("");

  const [filterModalShow, setFilterModalShow] = useState(false);

  function handleKeyPress(event) {
    if (event.key === "Enter") {
      if (query === null || query.length === 0) {
        navigate(`/ustalar`);
      } else {
        navigate(`/ustalar?q=${query}`);
      }
    }
  }

  function handleChange(event) {
    setQuery(event.target.value);
  }

  return (
    <Container
      style={{
        position: "relative",
      }}
    >
      {isMobile ? (
        <Row style={{ alignItems: "center", gap: 8 }}>
          <Col
            xs
            style={{
              padding: "12px 16px",
              border: "1px solid var(--neutral100)",
              borderRadius: "8px",
            }}
          >
            <InputGroup>
              <InputGroup.Text
                style={{
                  border: "0px",
                  backgroundColor: "transparent",
                  padding: "0px",
                }}
              >
                <Search width={20} />
              </InputGroup.Text>
              <Form.Control
                className="f-label"
                style={{
                  border: "0px",
                  backgroundColor: "transparent",
                  padding: "0px 0px 0px 12px",
                }}
                placeholder="İsim, şirket veya ünvan"
                defaultValue={text}
                onKeyDown={handleKeyPress}
                onChange={handleChange}
              />
            </InputGroup>
          </Col>

          <Col
            xs="auto"
            style={{
              padding: "10px",
              border: "1px solid var(--neutral100)",
              borderRadius: "8px",
              cursor: "pointer",
            }}
            onClick={() => setFilterModalShow(true)}
          >
            <Filter width={24} height={24} />
          </Col>
        </Row>
      ) : (
        <Row>
          <Row style={{ gap: "20px" }}>
            <Col
              xs
              style={{
                padding: "20px 30px",
                backgroundColor: "white",
                border: "1px solid var(--neutral100)",
                borderRadius: "8px",
              }}
            >
              <InputGroup>
                <InputGroup.Text
                  style={{ border: "0px", backgroundColor: "transparent" }}
                >
                  <Search width={24} />
                </InputGroup.Text>
                <Form.Control
                  style={{ border: "0px", backgroundColor: "transparent" }}
                  placeholder="İsim, şirket ya da ünvan yazarak arama yapabilirsin"
                  defaultValue={text}
                  onKeyDown={handleKeyPress}
                  onChange={handleChange}
                />
              </InputGroup>
            </Col>

            <Col xs="auto" style={{ gap: "16px", display: "flex" }}>
              <Col
                className="mentor-search-dropdown"
                style={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => setFilterModalShow(true)}
              >
                <div style={{ display: "flex", flexDirection: "row", gap: 12 }}>
                  <Filter width={24} height={24} />
                  Düzenle
                </div>
              </Col>

              <Col
                className="mentor-search-dropdown"
                style={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 12,
                    width: "max-content",
                  }}
                >
                  Müsait Ustaları sergile
                  <Switch />
                </div>
              </Col>
            </Col>
          </Row>
          <Row
            style={{
              marginTop: "40px",
              display: text === null || text.length === 0 ? "none" : "block",
            }}
          >
            <div>
              <DGButton
                variant="tertiary"
                size="sm"
                onClick={() => {
                  setQuery(null);
                  navigate(`/ustalar`);
                }}
              >
                <XIcon width={14} />
                Filtreyi Temizle
              </DGButton>
            </div>
          </Row>
        </Row>
      )}

      <FilterModal
        show={filterModalShow}
        onHide={() => setFilterModalShow(false)}
        sort="recommended"
      />
    </Container>
  );
};

const Divider = () => {
  return (
    <div
      style={{
        border: "0.5px solid var(--neutral50)",
        width: "1px",
        height: "40px",
        margin: "-20px 0px",
      }}
    />
  );
};

const FilterModal = (props) => {
  const sortData = [
    { key: "recommended", value: "Tavsiye Edilen Ustalar" },
    { key: "topRated", value: "Popüler Ustalar" },
    { key: "newAdded", value: "Yeni Eklenen Ustalar" },
  ];

  const moreOptions = [
    { key: "profession", value: "Uzmanlık" },
    { key: "country", value: "Ülke" },
    { key: "language", value: "Dil" },
  ];

  return (
    <Modal
      {...props}
      centered
      style={{ backgroundColor: "rgba(18, 14, 22, 0.85)" }}
    >
      <Modal.Body
        style={{
          padding: 24,
          backgroundColor: "var(--neutral50)",
          borderRadius: 8,
          display: "flex",
          flexDirection: "column",
          gap: 24,
        }}
      >
        <div style={{ display: "flex", flexDirection: "row", gap: 12 }}>
          <Filter width={24} height={24} />
          <h5 className="f-h5">Düzenle</h5>
        </div>

        <Row
          style={{
            flexDirection: "row",
            padding: "20px 24px",
            background: "white",
            borderRadius: 8,
            alignItems: "center",
          }}
        >
          <Col xs className="f-label-l">
            Müsait Ustaları sergile
          </Col>
          <Col xs="auto">
            <Switch />
          </Col>
        </Row>

        <Row>
          <h6 className="f-h6">Sırala</h6>
          {sortData.map((element, i) => (
            <Row
              style={{
                flexDirection: "row",
                padding: "20px 24px",
                background: "white",
                borderRadius:
                  i === 0
                    ? "8px 8px 0px 0px"
                    : i === sortData.length - 1
                    ? "0px 0px 8px 8px"
                    : "0px",
                borderBottom:
                  i === sortData.length - 1
                    ? "none"
                    : "1px solid var(--neutral100)",
              }}
            >
              <Col
                xs
                className="f-label-l"
                style={{
                  color:
                    element.key !== props.sort
                      ? "black"
                      : "var(--purplePrimary)",
                  fontWeight: element.key !== props.sort ? 400 : 700,
                }}
              >
                {element.value}
              </Col>
              {/* {element.key !== props.sort ? null : <Col xs="auto">a</Col>} */}
            </Row>
          ))}
        </Row>

        <Row>
          <h6 className="f-h6">Daha fazla seçenek</h6>
          {moreOptions.map((element, i) => (
            <Row
              style={{
                flexDirection: "row",
                padding: "20px 24px",
                background: "white",
                borderRadius:
                  i === 0
                    ? "8px 8px 0px 0px"
                    : i === moreOptions.length - 1
                    ? "0px 0px 8px 8px"
                    : "0px",
                borderBottom:
                  i === moreOptions.length - 1
                    ? "none"
                    : "1px solid var(--neutral100)",
              }}
            >
              <Col
                xs
                className="f-label-l"
                style={{
                  color: "black",
                  fontWeight: 400,
                }}
              >
                {element.value}
              </Col>
              {/* {element.key !== props.sort ? null : <Col xs="auto">a</Col>} */}
            </Row>
          ))}
        </Row>

        <DGButton variant="dg-primary" size="medium">
          Sonuçları Göster
        </DGButton>

        <CloseButton
          style={{
            backgroundColor: "white",
            position: "absolute",
            top: 12,
            right: 12,
          }}
          onClick={() => props.onHide()}
        />
      </Modal.Body>
    </Modal>
  );
};

export default SearchBar;
