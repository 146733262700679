import { useEffect, useRef, useState } from "react";
import { Col, Container, Dropdown, Row, Tab } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import DGButton from "../../components/Buttons/DGButton";
import SliderButton from "../../components/Buttons/SliderButton";
import AcademicPaperCard from "../../components/Cards/AcademicPaperCard";
import DGModal from "../../components/Modals/DGModal";
import SideMap from "../../components/SideMap";
import DGTabs from "../../components/Tabs/DGTabs";
import useIsMobile from "../../public/is-Mobile";
import useRouterQuery from "../../public/use-query";
import { ReactComponent as Filter } from "../../public/icons/Filter.svg";

const AcademicPapers = () => {
  let query = useRouterQuery();

  const data = [
    {
      title:
        "MSKU Bodrum Güzel Sanatlar Fakültesi Uluslararası Sanat Sempozyumu - MSKU Bodrum Faculty of Fine Arts International Art Symposium",
      text: "Hız, iletişim, teknoloji ve tüketim çağında yaşamaktayız. Hayatın her alanında tasarım çok önemli bir hale gelmiş durumda. Bir zamanlar sadece ayrıcalıklı zümreler özel tasarım ürünlerine sahip olabiliyorken, artık her - Hız, iletişim, teknoloji ve tüketim çağında yaşamaktayız. Hayatın her alanında tasarım çok önemli bir hale gelmiş durumda. Bir zamanlar sadece ayrıcalıklı zümreler özel tasarım ürünlerine sahip olabiliyorken, artık her",
    },
    {
      title: "Feminist Sanatın Kadın Sanatçılara Etkisi",
      text: "Hız, iletişim, teknoloji ve tüketim çağında yaşamaktayız. Hayatın her alanında tasarım çok önemli bir hale gelmiş durumda.",
    },
    {
      title: "Feminist Sanatın Kadın Sanatçılara Etkisi",
      text: "Hız, iletişim, teknoloji ve tüketim çağında yaşamaktayız. Hayatın her alanında tasarım çok önemli bir hale gelmiş durumda. Bir zamanlar sadece ayrıcalıklı zümreler özel tasarım ürünlerine sahip olabiliyorken, artık her - Hız, iletişim, teknoloji ve tüketim çağında yaşamaktayız. Hayatın her alanında tasarım çok önemli bir hale gelmiş durumda. Bir zamanlar sadece ayrıcalıklı zümreler özel tasarım ürünlerine sahip olabiliyorken, artık her",
    },
    {
      title:
        "MSKU Bodrum Güzel Sanatlar Fakültesi Uluslararası Sanat Sempozyumu - MSKU Bodrum Faculty of Fine Arts International Art Symposium",
      text: "Hız, iletişim, teknoloji ve tüketim çağında yaşamaktayız. Hayatın her alanında tasarım çok önemli bir hale gelmiş durumda. Bir zamanlar sadece ayrıcalıklı zümreler özel tasarım ürünlerine sahip olabiliyorken, artık her - Hız, iletişim, teknoloji ve tüketim çağında yaşamaktayız. Hayatın her alanında tasarım çok önemli bir hale gelmiş durumda. Bir zamanlar sadece ayrıcalıklı zümreler özel tasarım ürünlerine sahip olabiliyorken, artık her",
    },
    {
      title: "Feminist Sanatın Kadın Sanatçılara Etkisi",
      text: "Hız, iletişim, teknoloji ve tüketim çağında yaşamaktayız. Hayatın her alanında tasarım çok önemli bir hale gelmiş durumda. Bir zamanlar sadece ayrıcalıklı zümreler özel tasarım ürünlerine sahip olabiliyorken, artık her - Hız, iletişim, teknoloji ve tüketim çağında yaşamaktayız. Hayatın her alanında tasarım çok önemli bir hale gelmiş durumda. Bir zamanlar sadece ayrıcalıklı zümreler özel tasarım ürünlerine sahip olabiliyorken, artık her",
    },
  ];

  const categories = [
    {
      name: "Tutorials & İpuçları",
      path: "/akademik-yazilar?category=Tutorials & İpuçları",
    },
    {
      name: "Tasarım İlkeleri",
      path: "/akademik-yazilar?category=Tasarım İlkeleri",
    },
    { name: "Photoshop", path: "/akademik-yazilar?category=Tasarım İlkeleri" },
    {
      name: "UI Design System",
      path: "/akademik-yazilar?category=UI Design System",
    },
    {
      name: "Programlar & Araçlar",
      path: "/akademik-yazilar?category=Programlar & Araçlar",
    },
  ];

  const exploreCategories = [
    { name: "Blog", path: "/blog" },
    { name: "Podcast", path: "/podcast" },
    { name: "Eğitim Videoları", path: "/egitim-videolari" },
    { name: "Akademik Yazılar", path: "/akademik-yazilar" },
  ];

  const filters = {
    recommended: "Tavsiye Edilenler",
    topRated: "En Beğenilenler",
    newAdded: "Yeni Eklenenler",
    agencies: "Ajanslar",
  };

  const [selectedFilter, setSelectedFilter] = useState("recommended");

  const ref = useRef(null);

  const isMobile = useIsMobile();

  const sliderData = [
    {
      title:
        "MSKU Bodrum Güzel Sanatlar Fakültesi Uluslararası Sanat Sempozyumu - MSKU Bodrum Faculty of Fine Arts International Art Symposium",
      text: "Hız, iletişim, teknoloji ve tüketim çağında yaşamaktayız. Hayatın her alanında tasarım çok önemli bir hale gelmiş durumda. Bir zamanlar sadece ayrıcalıklı zümreler özel tasarım ürünlerine sahip olabiliyorken, artık her - Hız, iletişim, teknoloji ve tüketim çağında yaşamaktayız. Hayatın her alanında tasarım çok önemli bir hale gelmiş durumda. Bir zamanlar sadece ayrıcalıklı zümreler özel tasarım ürünlerine sahip olabiliyorken, artık her",
    },
    {
      title: "Feminist Sanatın Kadın Sanatçılara Etkisi",
      text: "Hız, iletişim, teknoloji ve tüketim çağında yaşamaktayız. Hayatın her alanında tasarım çok önemli bir hale gelmiş durumda.",
    },
    {
      title: "Feminist Sanatın Kadın Sanatçılara Etkisi",
      text: "Hız, iletişim, teknoloji ve tüketim çağında yaşamaktayız. Hayatın her alanında tasarım çok önemli bir hale gelmiş durumda. Bir zamanlar sadece ayrıcalıklı zümreler özel tasarım ürünlerine sahip olabiliyorken, artık her - Hız, iletişim, teknoloji ve tüketim çağında yaşamaktayız. Hayatın her alanında tasarım çok önemli bir hale gelmiş durumda. Bir zamanlar sadece ayrıcalıklı zümreler özel tasarım ürünlerine sahip olabiliyorken, artık her",
    },
    {
      title:
        "MSKU Bodrum Güzel Sanatlar Fakültesi Uluslararası Sanat Sempozyumu - MSKU Bodrum Faculty of Fine Arts International Art Symposium",
      text: "Hız, iletişim, teknoloji ve tüketim çağında yaşamaktayız. Hayatın her alanında tasarım çok önemli bir hale gelmiş durumda. Bir zamanlar sadece ayrıcalıklı zümreler özel tasarım ürünlerine sahip olabiliyorken, artık her - Hız, iletişim, teknoloji ve tüketim çağında yaşamaktayız. Hayatın her alanında tasarım çok önemli bir hale gelmiş durumda. Bir zamanlar sadece ayrıcalıklı zümreler özel tasarım ürünlerine sahip olabiliyorken, artık her",
    },
    {
      title: "Feminist Sanatın Kadın Sanatçılara Etkisi",
      text: "Hız, iletişim, teknoloji ve tüketim çağında yaşamaktayız. Hayatın her alanında tasarım çok önemli bir hale gelmiş durumda. Bir zamanlar sadece ayrıcalıklı zümreler özel tasarım ürünlerine sahip olabiliyorken, artık her - Hız, iletişim, teknoloji ve tüketim çağında yaşamaktayız. Hayatın her alanında tasarım çok önemli bir hale gelmiş durumda. Bir zamanlar sadece ayrıcalıklı zümreler özel tasarım ürünlerine sahip olabiliyorken, artık her",
    },
  ];

  const scroll = (isNext) => {
    if (isNext === true) {
      if (
        ref.current.scrollLeft + ref.current.clientWidth >=
        ref.current.scrollWidth
      ) {
        ref.current.scrollLeft = 0;
      } else {
        ref.current.scrollLeft += ref.current.clientWidth;
      }
    } else {
      if (ref.current.scrollLeft === 0) {
        ref.current.scrollLeft = ref.current.scrollWidth;
      } else {
        ref.current.scrollLeft -= ref.current.clientWidth;
      }
    }
  };

  const [categoryName, setCategoryName] = useState(
    query.get("category") || null
  );

  useEffect(() => {
    setCategoryName(query.get("category") || null);
  }, [query]);

  const navigate = useNavigate();
  const [mustSignInModalShow, setMustSignInModalShow] = useState(false);

  return isMobile ? (
    <AcademicPapersMobile
      sliderData={sliderData}
      data={data}
      categories={exploreCategories}
    />
  ) : (
    <div>
      {(categoryName || null) === null ? (
        <div>
          <Container style={{ paddingTop: "50px" }}>
            <Col
              xs="auto"
              style={{ display: "flex", alignContent: "center" }}
            ></Col>
            <Row style={{ gap: "30px" }}>
              <Col className="f-h4" style={{ flexGrown: "1" }}>
                Editörün seçtiği akademik yazılar
              </Col>
              <Col
                xs="auto"
                style={{ display: "flex", alignContent: "center" }}
              >
                <div>
                  <DGButton
                    variant="dg-secondary"
                    size="medium"
                    onClick={() => {
                      if (
                        localStorage.getItem("signed-in") === "true" ||
                        false
                      ) {
                        navigate("/");
                      } else {
                        setMustSignInModalShow(true);
                      }
                    }}
                  >
                    Akademik Yazı Yükle
                  </DGButton>
                  <DGModal
                    title="Üye girişi gerekiyor"
                    desc="Akademik Yazı yükleyebilmen ve yazı sahiplerini eksiksiz referans verebilmemiz için üye girişi yapman gerekiyor."
                    textButtonText="Vazgeç"
                    buttonText="Giriş Yap"
                    textButtonOnClick={() => setMustSignInModalShow(false)}
                    buttonOnClick={() => setMustSignInModalShow(false)}
                    show={mustSignInModalShow}
                    onHide={() => setMustSignInModalShow(false)}
                  />
                </div>
              </Col>
            </Row>
          </Container>
          <Row
            style={{
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <SliderButton
              next={true}
              onClick={() => {
                scroll(false);
              }}
            />
            <Container style={{ margin: "0px 30px" }}>
              <div
                style={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  padding: "30px 20px",
                  display: "grid",
                  gridAutoFlow: "column",
                  gridColumnGap: "30px",
                  margin: "0px -20px",
                  scrollBehavior: "smooth",
                }}
                scroll="no"
                ref={ref}
              >
                {sliderData.map((item, i) => (
                  <AcademicPaperCard
                    title={item.title}
                    key={i}
                    width="558px"
                    backgroundColor="var(--neutral50)"
                  />
                ))}
              </div>
            </Container>
            <SliderButton
              next={false}
              onClick={() => {
                scroll(true);
              }}
            />
          </Row>
        </div>
      ) : null}
      <Container>
        <Row
          style={{
            marginTop: "40px",
            paddingBottom: "100px",
          }}
        >
          <Col xs={8}>
            <Row style={{ marginBottom: "30px" }}>
              <Col className="f-h4">
                {(categoryName?.length || 0) > 0
                  ? `${categoryName} akademik yazıları`
                  : "Tüm akademik yazılar"}
              </Col>
              {(categoryName || null) === null ? (
                <Col xs="auto">
                  <Dropdown
                    onSelect={(key) => {
                      setSelectedFilter(key);
                    }}
                  >
                    <Dropdown.Toggle className="dg-dropdown f-paragraph">
                      {filters[selectedFilter]}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {Object.keys(filters).map((innerAttr) => {
                        return (
                          <Dropdown.Item eventKey={innerAttr}>
                            {filters[innerAttr]}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              ) : null}
            </Row>
            <Col>
              <Row style={{ gap: "30px" }}>
                {data.map((item, i) => (
                  <AcademicPaperCard
                    title={item.title}
                    text={item.text}
                    key={i}
                  />
                ))}
              </Row>
              <Col className="text-center" style={{ marginTop: "80px" }}>
                <DGButton variant="tertiary" size="medium">
                  Daha Fazla Yükle
                </DGButton>
              </Col>
            </Col>
          </Col>
          <SideMap
            categories={categories}
            exploreCategories={exploreCategories}
            site="/akademik-yazilar"
          />
        </Row>
      </Container>
    </div>
  );
};

const AcademicPapersMobile = (props) => {
  const navigate = useNavigate();

  return (
    <div>
      <DGTabs
        activeKey={"/akademik-yazilar"}
        onSelect={(key) => {
          navigate(key);
        }}
      >
        {props.categories?.map((x, i) => (
          <Tab
            eventKey={x.path}
            title={x.name}
            style={{ width: "max-content" }}
          />
        ))}
      </DGTabs>
      <Container>
        <Row style={{ gap: "10px", padding: "20px", alignItems: "center" }}>
          <Col className="f-h6">Editörün seçtikleri</Col>
          <Col xs="auto">
            <div>
              <DGButton variant="dg-secondary" size="xs">
                Akademik Yazı Yükle
              </DGButton>
            </div>
          </Col>
        </Row>
      </Container>
      <Row
        style={{
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Container>
          <div
            className="scroll-view-hidden"
            style={{
              overflow: "scroll",
              padding: "0px 20px",
              display: "grid",
              gridAutoFlow: "column",
              gridColumnGap: "10px",
              scrollBehavior: "smooth",
            }}
          >
            {props.sliderData.map((item, i) => (
              <AcademicPaperCard
                title={item.title}
                key={i}
                width="315px"
                backgroundColor="var(--neutral50)"
              />
            ))}
          </div>
        </Container>
      </Row>
      <Container>
        <Row
          style={{
            padding: "30px 20px",
          }}
        >
          <Row>
            <Col className="f-h6">En Yeniler</Col>
            <Col xs="auto">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 8,
                }}
              >
                Düzenle <Filter stroke="var(--black)" width={24} height={24} />
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: "12px", gap: "12px" }}>
            {props.data.map((item, i) => (
              <AcademicPaperCard title={item.title} key={i} />
            ))}
          </Row>
          <Col className="text-center" style={{ marginTop: "20px" }}>
            <DGButton variant="tertiary" size="medium">
              Daha Fazla Yükle
            </DGButton>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AcademicPapers;
