import { useEffect, useState } from "react";

import SearchBar from "../components/SearchBars/SearchBar";
import HomeMentors from "../components/Home/HomeMentors";
import Testimonial from "../components/Home/Testimonial";
import Hero from "../components/Home/Hero";
import Agencies from "../components/Home/Agencies";
import HowItHelps from "../components/Home/HowItHelps";
import Cards from "../components/Home/Cards";
import SendProject from "../components/Home/SendProject";
import RegisterNow from "../components/Home/RegisterNow";
import HomeCategories from "../components/Home/HomeCategories";

import { getUsers } from "../Service";

const Home = () => {
  const [mentorData, setMentorData] = useState([]);
  const [mentorCategories, setMentorCategories] = useState([]);

  const fetchUsers = async () => {
    try {
      const request = await getUsers("page=0&count=12");
      setMentorData(mentorData.concat(request.mentors.data));
      setMentorCategories(mentorCategories.concat(request.jobs));
    } catch (error) {
      console.error("Error fetching assets:", error.message);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <div>
      <Hero />
      <SearchBar categories={mentorCategories} />
      {/* <HowItWorks /> */}
      <Cards />
      <HowItHelps />
      <HomeCategories />
      <Agencies />
      <SendProject />
      <HomeMentors mentorData={mentorData} />
      <Testimonial />
      <RegisterNow />
    </div>
  );
};

export default Home;
