import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import TranslationTR from "./public/locales/tr/translation.json";
import HomeTR from "./public/locales/tr/home.json";
import HowItWorksTR from "./public/locales/tr/how_it_works.json";
import AssetNamesTR from "./public/locales/tr/asset_names.json";

const resources = {
  tr: {
    translation: TranslationTR,
    home: HomeTR,
    how_it_works: HowItWorksTR,
    asset_names: AssetNamesTR,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "tr",

  keySeparator: false,

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
