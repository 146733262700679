import { useCallback, useEffect, useState } from "react";
import { Col, Container, Dropdown, Form, Image, Row } from "react-bootstrap";
import FormFileInput from "react-bootstrap/esm/FormFileInput";
import DGButton from "../Buttons/DGButton";
import ProjectCard from "../Cards/ProjectCard";
import DGInput from "../Inputs/DGInput";

import { ReactComponent as ImageAdd } from "../../public/images/Image-Add.svg";
import FileInput from "../FileInput/FileInput";
import { Link, useNavigate } from "react-router-dom";
import DGModal from "../Modals/DGModal";
import DividerToggle from "../DividerToggle";

import { ReactComponent as CaretDown } from "../../public/icons/Caret-Down.svg";
import useIsMobile from "../../public/is-Mobile";

const ProjectsTab = () => {
  const isMobile = useIsMobile();
  const isMentor = Math.floor(Math.random() * (1 - 0 + 1)) + 0;

  const [showNewProject, setShowNewProject] = useState(false);
  return (
    <Container
      style={{
        padding: isMobile ? "20px 20px 30px" : "50px 0px 100px",
      }}
    >
      {showNewProject ? (
        <NewProject setShowNewProject={setShowNewProject} />
      ) : isMentor ? (
        <MentorProjects />
      ) : (
        <MenteeProjects setShowNewProject={setShowNewProject} />
      )}
    </Container>
  );
};

export default ProjectsTab;

const MentorProjects = () => {
  const isMobile = useIsMobile();

  return (
    <Row>
      <Col className="f-h5">Çırak projeleri</Col>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: isMobile ? "auto auto" : "auto auto auto",
          gap: isMobile ? "20px 10px" : "50px 30px",
          marginTop: isMobile ? "20px" : "30px",
        }}
      >
        {[...Array(16)].map((x, i) => (
          <ProjectCard image="https://trtrades.com/wp-content/uploads/2021/09/Gray-Box.jpeg" />
        ))}
      </div>
      <Col
        className="text-center"
        style={{ marginTop: isMobile ? "30px" : "80px" }}
      >
        <DGButton variant="tertiary" size="medium">
          Daha Fazla Yükle
        </DGButton>
      </Col>
    </Row>
  );
};

const MenteeProjects = (props) => {
  const isMobile = useIsMobile();
  return (
    <Row style={{ gap: isMobile ? 30 : 50 }}>
      <Row style={{ gap: isMobile ? "8px" : "30px" }}>
        <div className="f-h5">Onay bekleyen projelerim</div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: isMobile ? "auto auto" : "auto auto auto",
            gap: isMobile ? "20px 10px" : "50px 30px",
          }}
        >
          {[...Array(5)].map((x, i) => (
            <ProjectCard image="https://trtrades.com/wp-content/uploads/2021/09/Gray-Box.jpeg" />
          ))}
        </div>
      </Row>
      <Row style={{ gap: isMobile ? "8px" : "30px" }}>
        <div className="f-h5">Projelerim</div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: isMobile ? "auto auto" : "auto auto auto",
            gap: isMobile ? "20px 10px" : "50px 30px",
          }}
        >
          {[...Array(4)].map((x, i) => (
            <ProjectCard image="https://trtrades.com/wp-content/uploads/2021/09/Gray-Box.jpeg" />
          ))}
        </div>
      </Row>
    </Row>
  );
};

const NewProject = (props) => {
  const filters = {
    recommended: "Tavsiye Edilenler",
    topRated: "En Beğenilenler",
    newAdded: "Yeni Eklenenler",
    agencies: "Ajanslar",
  };

  const [selectedFilter, setSelectedFilter] = useState("recommended");
  const [selectedType, setSelectedType] = useState("görsel");

  const navigate = useNavigate();

  const onChangeValue = (event) => {
    if (event.target.id !== "textArea") {
      setSelectedType(event.target.id);
    }
  };

  const [projectSentModalShow, setProjectSentModalShow] = useState(false);

  const [images, setImages] = useState([]);
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.map((file) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        setImages((prevState) => [
          ...prevState,
          { id: "42", src: e.target.result },
        ]);
      };
      reader.readAsDataURL(file);
      return file;
    });
  }, []);

  const [thumbnail, setThumbnail] = useState(null);
  const [thumbnailLink, setThumbnailLink] = useState(null);

  useEffect(() => {
    setThumbnailLink(get_youtube_thumbnail(thumbnail, "medium"));
  }, [thumbnail]);

  return (
    <Row style={{ gap: 40, maxWidth: 500 }}>
      <DGInput title="Proje adı" placeholder="Örnek: DesignGost Logo" />

      <DGInput title="Proje hakkında" isLong={true} placeholder="Mesaj" />

      <DGInput title="Destek Talebi" isLong={true} placeholder="Mesaj" />

      <div>
        <div className="dg-input-header">Projeniz için kategori seçiniz</div>
        <Dropdown style={{ marginTop: 10 }}>
          <Dropdown.Toggle className="dg-dropdown">
            <div style={{ display: "flex" }}>
              <div style={{ flexGrow: 1 }}>
                <div
                  className="f-paragraph"
                  style={{ marginRight: 24, width: "max-content" }}
                >
                  Kategori seç:
                </div>
              </div>
              <div>
                <CaretDown stroke="var(--black)" />
              </div>
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item href="#">Action</Dropdown.Item>
            <Dropdown.Item href="#">Another action</Dropdown.Item>
            <Dropdown.Item href="#">Something else here</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>

      <div>
        <div className="dg-input-header">Proje türü</div>
        <div
          key="radio"
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 40,
            marginTop: 10,
            marginBottom: 24,
          }}
          onChange={onChangeValue}
        >
          <Form.Check
            label="Görsel"
            name="group1"
            type="radio"
            id="görsel"
            className="dg-radio-button"
            defaultChecked={true}
            style={{ paddingLeft: 0, width: "min-content" }}
          />
          <Form.Check
            label="Video"
            name="group1"
            type="radio"
            id="video"
            className="dg-radio-button"
            style={{ paddingLeft: 0, width: "min-content" }}
          />
        </div>
        {selectedType === "görsel" ? (
          <FileInput
            onDrop={onDrop}
            accept={{ "image/*": [".jpg", ".png"] }}
            child={
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 20,
                }}
              >
                <ImageAdd width={30} height={30} stroke="none" />
                <div className="f-label">
                  Dosyayı buraya sürükleyip bırakabilirsin ya da
                  <div className="f-label-s" style={{ marginTop: 10 }}>
                    Proje min 1480px genişliğinde ve max 5mb JPG ya da PNG
                    olmalıdır.
                  </div>
                </div>

                <DGButton variant="dg-primary" size="medium">
                  Dosya Yükle
                </DGButton>
              </div>
            }
          />
        ) : (
          <div>
            <DGInput
              placeholder="https://"
              onChange={(event) => {
                setThumbnail(event.target.value);
              }}
            />
            <div
              className="f-label"
              style={{ marginTop: 10, marginBottom: 10 }}
            >
              Lütfen sadece Youtube ve Vimeo linkleri kullanınız.
            </div>
            <Image src={thumbnailLink} />
          </div>
        )}
      </div>

      <div>
        <div className="dg-input-header">Proje yollanacak Usta</div>
        <div
          key="radio"
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 40,
            marginTop: 10,
          }}
        >
          <Form.Check
            label="Hakan Ertan"
            name="group2"
            type="radio"
            id="seyyahil"
            className="dg-radio-button"
            defaultChecked={true}
            style={{ paddingLeft: 0, width: "max-content" }}
          />
          <Form.Check
            label="Emre Ertan"
            name="group2"
            type="radio"
            id="temeyhan"
            className="dg-radio-button"
            style={{ paddingLeft: 0, width: "max-content" }}
          />
        </div>
      </div>

      <Form.Check
        label={
          <div style={{ maxWidth: "345px" }}>
            Proje oluşturarak{" "}
            <Link to="/" style={{ color: "var(--purplePrimary)" }}>
              Kullanım Şartlarımız
            </Link>{" "}
            ve{" "}
            <Link to="/" style={{ color: "var(--purplePrimary)" }}>
              Gizlilik Politikamızı
            </Link>{" "}
            kabul etmiş sayılırsınız.
          </div>
        }
        type="checkbox"
        className="dg-radio-button"
        style={{ paddingLeft: 0, alignItems: "flex-start" }}
        defaultChecked={true}
      />

      <Row
        style={{
          flexDirection: "row",
          alignItems: "center",
          gap: 24,
        }}
      >
        <div
          className="f-h6"
          style={{
            width: "auto",
            color: "var(--purplePrimary)",
            cursor: "pointer",
            height: "min-content",
          }}
          onClick={() => {
            window.scrollTo(0, 0);
            props.setShowNewProject(false);
          }}
        >
          Vazgeç
        </div>

        <DGButton
          variant="dg-primary"
          size="medium"
          style={{ width: "auto" }}
          onClick={() => {
            setProjectSentModalShow(true);
          }}
        >
          Gönder
        </DGButton>
      </Row>

      <DGModal
        title="Tebrikler Hakan! Projen başarıyla yollandı. "
        buttonText="Tamam"
        buttonOnClick={() => {
          setProjectSentModalShow(false);
          navigate(`/projeler/101010100`);
        }}
        show={projectSentModalShow}
        onHide={() => setProjectSentModalShow(false)}
      />
    </Row>
  );
};

function get_youtube_thumbnail(url, quality) {
  if (url) {
    var video_id, thumbnail, result;
    if ((result = url.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/))) {
      video_id = result.pop();
    } else if ((result = url.match(/youtu.be\/(.{11})/))) {
      video_id = result.pop();
    }

    if (video_id) {
      if (typeof quality == "undefined") {
        quality = "high";
      }

      var quality_key = "maxresdefault"; // Max quality
      if (quality == "low") {
        quality_key = "sddefault";
      } else if (quality == "medium") {
        quality_key = "mqdefault";
      } else if (quality == "high") {
        quality_key = "hqdefault";
      }

      var thumbnail =
        "http://img.youtube.com/vi/" + video_id + "/" + quality_key + ".jpg";
      return thumbnail;
    }
  }
  return false;
}
