import React, { useEffect, useRef, useState } from "react";

// Utils
import RegisterLayout from "../layout/RegisterLayout";
import { Row } from "react-bootstrap";
import DGButton from "../components/Buttons/DGButton";

import { ReactComponent as Lock } from "../public/icons/Lock.svg";
import { ReactComponent as Unlocked } from "../public/icons/Lock-Open.svg";
import { useNavigate } from "react-router-dom";
import { register } from "../Service";

const LoginOtp = () => {
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [isValid, setIsValid] = useState(false);

  const navigate = useNavigate();

  const signUp = async () => {
    try {
      const request = await register(
        "Emre Ertan",
        "temeyhan1",
        "a.emre.ertan+1@gmail.com",
        "123456789",
        "123456789"
      );

      navigate("/profile#profilAyarlari");
    } catch (error) {
      console.error("Error fetching assets:", error.message);
    }
  };

  useEffect(() => {
    if (otp?.join("") === "") {
      setIsValid(false);
    } else if (otp?.join("") !== "" && otp?.join("") !== "123456") {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  }, [otp]);

  return (
    <RegisterLayout>
      <Row
        style={{
          justifyContent: "center",
          maxWidth: 840,
          gap: 70,
        }}
      >
        <Row
          className="text-center"
          style={{ gap: 24, maxWidth: 600, justifyContent: "center" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: 64,
              width: 64,
              background: "var(--neutral50)",
              borderRadius: 100,
            }}
          >
            {isValid ? (
              <Unlocked width={32} stroke="var(--purplePrimary)" />
            ) : (
              <Lock width={32} stroke="var(--purplePrimary)" />
            )}
          </div>
          <h4 className="f-h5">E-posta adresi onayı</h4>
          <p className="f-paragraph">
            Onay kodu için lütfen e-posta adresinizi kontrol edin. <br />
            Eğer e-posta almadıysan aşağıdaki linke tıklayarak yeni bir onay
            kodu talep edebilirsin.
          </p>
        </Row>

        <Row className="text-center" style={{ gap: 24, maxWidth: 600 }}>
          <p className="f-label">
            6 haneli onay kodunu aşağıdaki kutulara giriniz
          </p>

          <OtpInputWithValidation
            numberOfDigits={6}
            otp={otp}
            setOtp={setOtp}
          />

          <div style={{ display: "flex", justifyContent: "center" }}>
            <DGButton
              variant="dg-tertiary"
              type="submit"
              style={{
                color: "var(--purplePrimary)",
                textDecorationLine: "underline",
                fontSize: "15px",
                fontWeight: "500",
              }}
            >
              Yeni bir onay kodu yolla
            </DGButton>
          </div>
        </Row>

        <Row>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <DGButton
              variant="dg-primary"
              size="medium"
              onClick={() => {
                signUp();
              }}
            >
              E-posta Adresini Onayla
            </DGButton>
          </div>
        </Row>
      </Row>
    </RegisterLayout>
  );
};

function OtpInputWithValidation({ numberOfDigits, otp, setOtp }) {
  const otpBoxReference = useRef([]);

  function handleChange(value, index) {
    let newArr = [...otp];
    newArr[index] = value;
    setOtp(newArr);

    if (value && index < numberOfDigits - 1) {
      otpBoxReference.current[index + 1].focus();
    }
  }

  function handleBackspaceAndEnter(e, index) {
    if (e.key === "Backspace" && !e.target.value && index > 0) {
      otpBoxReference.current[index - 1].focus();
    }
    if (e.key === "Enter" && e.target.value && index < numberOfDigits - 1) {
      otpBoxReference.current[index + 1].focus();
    }
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        gap: 16,
      }}
    >
      {otp.map((digit, index) => (
        <input
          className="f-h4"
          key={index}
          value={digit}
          maxLength={1}
          onChange={(e) => handleChange(e.target.value, index)}
          onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
          ref={(reference) => (otpBoxReference.current[index] = reference)}
          style={{
            width: 42,
            height: 54,
            borderRadius: 6,
            border: "1px solid var(--neutral200)",
            textAlign: "center",
          }}
        />
      ))}
    </div>
  );
}

export default LoginOtp;
