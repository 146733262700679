import {
  Col,
  Container,
  Image,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import useIsMobile from "../../public/is-Mobile";
import DGButton from "../Buttons/DGButton";

import { format } from "date-fns";
import trLocale from "date-fns/locale/tr";

import { ReactComponent as Award } from "../../public/icons/Award.svg";
import { ReactComponent as Project } from "../../public/icons/Project.svg";
import { ReactComponent as Email } from "../../public/icons/Email.svg";
import { ReactComponent as SrUsta } from "../../public/images/Sr-Usta.svg";

import { ReactComponent as Unavailable } from "../../public/images/Unavailable.svg";
import { ReactComponent as CaretRight } from "../../public/images/caret-right.svg";

import { ReactComponent as Behance } from "../../public/icons/socialMedias/behance.svg";
import { ReactComponent as Codepen } from "../../public/icons/socialMedias/codepen.svg";
import { ReactComponent as Dribble } from "../../public/icons/socialMedias/dribbble.svg";
import { ReactComponent as Github } from "../../public/icons/socialMedias/github.svg";
import { ReactComponent as Instagram } from "../../public/icons/socialMedias/instagram.svg";
import { ReactComponent as Linkedin } from "../../public/icons/socialMedias/linkedin.svg";
import { ReactComponent as Medium } from "../../public/icons/socialMedias/medium.svg";
import { ReactComponent as Twitter } from "../../public/icons/socialMedias/twitter.svg";
import { ReactComponent as Vimeo } from "../../public/icons/socialMedias/vimeo.svg";
import { ReactComponent as Youtube } from "../../public/icons/socialMedias/youtube.svg";
import { ReactComponent as WebLink } from "../../public/icons/Web-Link.svg";

import Switch from "../Switch/Switch";

const ProfileHeader = (props) => {
  const isMobile = useIsMobile();
  // const available = Math.floor(Math.random() * (1 - 0 + 1)) + 0;
  const available = 1;
  const isSettings = props.isSettings ?? false;

  const constants = {
    paddings: isMobile ? "12px 20px 30px" : "40px 0px",
  };

  return (
    <Container>
      {isMobile ? (
        <MobileLayout
          constants={constants}
          available={available}
          isMentor={props.user?.membertype}
          user={props?.user}
        />
      ) : (
        <DesktopLayout
          constants={constants}
          available={available}
          user={props?.user}
          isCurrentUser={props?.isCurrentUser}
          isMentor={props.user?.membertype}
          isSettings={isSettings}
          setSelectedTab={props.setSelectedTab}
        />
      )}
    </Container>
  );
};

const DesktopLayout = (props) => {
  const isSettings = props.isSettings ?? true;

  const currentUserComponents = [
    <Divider />,
    props?.isMentor ? <BreakContainer /> : null,
    props?.isMentor ? <Divider /> : null,
    props.isMentor === true ? (
      <MenteesContainer user={props?.user} />
    ) : (
      <MentorsContainer
        user={props?.user}
        isCurrentUser={props?.isCurrentUser}
      />
    ),
    <Divider />,
    props.isMentor === true ? (
      <MenteeApplicationsContainer user={props?.user} />
    ) : (
      <ProjectsContainer user={props?.user} />
    ),
  ];

  const userComponents = [
    <ButtonsContainer isMentor={props.isMentor} />,
    <Divider />,
    <AboutContainer user={props?.user} />,
    <Divider />,
    props.isMentor ? (
      <ProfessionsContainer data={props?.user?.expertises} />
    ) : null,
    props.isMentor ? <Divider /> : null,
    props.isMentor === true ? (
      <MenteesContainer user={props?.user} />
    ) : (
      <MentorsContainer
        user={props?.user}
        isCurrentUser={props?.isCurrentUser}
      />
    ),
  ];

  return isSettings ? (
    <SettingsLayout setSelectedTab={props.setSelectedTab} />
  ) : (
    <Container
      style={{
        background: "white",
        display: "flex",
        gap: "24px",
        flexDirection: "column",
        alignItems: "center",
        border: "1px solid var(--neutral100)",
        borderRadius: 8,
        padding: "32px 24px",
      }}
    >
      <Row style={{ gap: 16, alignItems: "center", justifyContent: "center" }}>
        <Image
          src={props?.user?.img}
          style={{
            height: 96,
            width: 96,
            borderRadius: 8,
            objectFit: "cover",
          }}
        />
        <Row className="text-center" style={{ gap: 13 }}>
          <div className="f-h3">{props.user?.username}</div>
          <div className="f-paragraph">
            <div>{props?.user?.worktitle}</div>
            {props?.user?.city != "null" ? (
              <div>{props?.user?.city}</div>
            ) : null}
          </div>
        </Row>
      </Row>

      <Row
        className="f-paragraph text-center"
        style={{ width: "100%", gap: 16 }}
      >
        {props?.user?.counts?.projects !== null ? (
          <NumberCard number={props?.user?.counts?.projects} text="Proje" />
        ) : null}

        {props?.user?.allMentees?.length !== null ? (
          <NumberCard number={props?.user?.allMentees?.length} text="Çırak" />
        ) : null}

        {/* <NumberCard number={props?.user?.numbers?.content} text="İçerik" /> */}
      </Row>

      {props?.isCurrentUser
        ? currentUserComponents.map((element, i) => element)
        : userComponents.map((element, i) => element)}

      <Divider />
      <WebLinksContainer data={props.user?.links} />
      <Divider />
      <RegisterContainer date={props.user?.created_at} />
    </Container>
  );
};

const MobileLayout = (props) => {
  return (
    <Container
      style={{
        display: "flex",
        gap: "28px",
        flexDirection: "column",
        alignItems: "center",
        padding: "0 20px",
      }}
    >
      <Row style={{ gap: 8, alignItems: "center", justifyContent: "center" }}>
        <Image
          src={props?.user?.img}
          style={{
            height: 96,
            width: 96,
          }}
          roundedCircle={true}
        />
        <Row style={{ alignItems: "center", justifyContent: "center" }}>
          <Col className="f-h4" xs="auto">
            {props.user?.username}
          </Col>
        </Row>
        <Row className="f-label" style={{ justifyContent: "center" }}>
          {props?.user?.worktitle}
        </Row>
        {props?.user?.city != "null" ? (
          <Row className="f-label" style={{ justifyContent: "center" }}>
            {props?.user?.city}
          </Row>
        ) : null}
      </Row>
      <Row
        className="f-paragraph text-center"
        style={{ width: "100%", gap: 16 }}
      >
        {props?.user?.counts?.projects !== null ? (
          <NumberCard number={props?.user?.counts?.projects} text="Proje" />
        ) : null}

        {props?.user?.allMentees?.length !== null ? (
          <NumberCard number={props?.user?.allMentees?.length} text="Çırak" />
        ) : null}
      </Row>
      <ButtonsContainer isMentor={true} />
      <Divider />
      {props.isMentor ? (
        <ProfessionsContainer data={props?.user?.expertises} />
      ) : null}
      <Divider />
      <MenteesContainer user={props?.user} />
    </Container>
  );
};

const SettingsLayout = (props) => {
  return (
    <Container
      style={{
        background: "white",
        display: "flex",
        gap: "24px",
        flexDirection: "column",
        border: "1px solid var(--neutral100)",
        borderRadius: 8,
        padding: "32px 24px",
      }}
    >
      <Row
        style={{
          width: "100%",
          gap: 16,
          alignItems: "center",
          justifyContent: "flex-start",
          flexDirection: "row",
        }}
      >
        <Col xs="auto">
          <Image
            src="https://www.designgost.com/img/4e312348-8be8-4a45-ab83-6a5b4fb99a35/hakan-ertan.jpg?fm=webp&fit=max&crop=1350%2C1350%2C0%2C0&w=200&s=ee609c601fab0ec9585be45177fa5a4b"
            style={{
              height: 96,
              width: 96,
              borderRadius: 8,
            }}
          />
        </Col>
        <Col>
          <div className="f-h3">{props.user?.displayName}</div>
          <div className="f-paragraph">
            <div>Product Designer</div>
            <div>Product Designer</div>
            <div>New York</div>
          </div>
        </Col>

        {/* <Row className="text-center" style={{ gap: 13 }}> */}
      </Row>
      <Divider />
      <Row
        style={{
          gap: 20,
        }}
      >
        <TabLink path="settings" title="Profil Ayarları" />
        <TabLink path="parola" title="Parola" />
        <TabLink path="bildirimAyarlari" title="Bildirim Ayarları" />
      </Row>
      <Divider />
      <Row>
        <TabLink path="deleteAccount" title="Hesabı Sil" />
      </Row>
    </Container>
  );
};

//*  Sections

const ButtonsContainer = (props) => {
  return (
    <Row style={{ width: "100%", gap: 20 }}>
      <Col xs>
        <DGButton
          variant="tertiary"
          size="sm"
          style={{ width: "100%", height: "100%" }}
        >
          Mesaj Yolla
        </DGButton>
      </Col>

      {props.isMentor === true ? (
        <Col xs>
          <DGButton
            variant="dg-primary"
            size="sm"
            style={{ width: "100%", height: "100%" }}
          >
            Proje Yolla
          </DGButton>
        </Col>
      ) : null}
    </Row>
  );
};

const AboutContainer = (props) => {
  return (
    <Row style={{ width: "100%", gap: 24 }}>
      <div className="f-h6">Hakkımda</div>
      {props.user?.about ?? "" !== "" ? (
        <div className="f-label">{props.user?.aboutshort}</div>
      ) : (
        <div className="f-label" style={{ color: "var(--neutral300)" }}>
          Hakkımda yazısı bulunmamakta.
        </div>
      )}
    </Row>
  );
};

const BreakContainer = (props) => {
  return (
    <Row style={{ width: "100%", gap: 16 }}>
      <Row style={{ alignItems: "center" }}>
        <Col xs className="f-label-l" style={{ height: "fit-content" }}>
          Ara vermek istiyorum
        </Col>
        <Col xs="auto">
          <Switch />
        </Col>
      </Row>
      <div className="f-label" style={{ color: "var(--neutral300)" }}>
        Ara verdiğinizde hesabınız arama sonuçlarında gözükmeyecek, mesaj ve
        proje taleplerine kapanacak.
      </div>
    </Row>
  );
};

const ProfessionsContainer = (props) => {
  function getIcon(title) {
    switch (title) {
      case "UI/UX":
        return "https://designgost.com/UI-UX Designer.svg";
      case "Industrial Designer":
        return "https://designgost.com/Índustrial Designer.svg";
      case "CGI":
        return "https://designgost.com/cgi.svg";
      case "Digital Project Manager":
        return "https://designgost.com/Digital Project-Manager.svg";
      case "Retouching":
        return "https://designgost.com/Retoucher.svg";
      default:
        return "https://designgost.com/" + title + ".svg";
    }
  }

  return (
    <Row style={{ width: "100%", gap: 24 }}>
      <div className="f-h6">Ustalık kategorileri</div>
      <Row style={{ gap: 12 }}>
        {props.data?.map((element, i) => (
          <Link to={"/ustalar?job=" + element.title}>
            <Image
              src={getIcon(element.title)}
              style={{
                width: 20,
                height: 20,
                marginRight: 8,
              }}
            />
            {element.title}
          </Link>
        ))}
      </Row>
    </Row>
  );
};

const MenteesContainer = (props) => {
  return (
    <Row style={{ width: "100%" }}>
      <div className="f-h6" style={{ marginBottom: "24px" }}>
        Güncel Çıraklarım
      </div>
      {props.user?.mentees?.length ?? 0 > 0 ? (
        [...Array(3)].map((x, i) => (
          <Link
            to="/ciraklar/seyyahil"
            style={{
              color: "var(--black)",
              textDecoration: "none",
              padding: "12px 0",
              borderBottom: i === 2 ? "0px" : "1px solid var(--neutral100)",
            }}
          >
            <Row style={{ gap: 12 }}>
              <Col xs="auto">
                <Image
                  roundedCircle={true}
                  src="https://www.designgost.com/img/4e312348-8be8-4a45-ab83-6a5b4fb99a35/hakan-ertan.jpg?fm=webp&fit=max&crop=1350%2C1350%2C0%2C0&w=200&s=ee609c601fab0ec9585be45177fa5a4b"
                  style={{ width: "48px", height: "48px" }}
                />
              </Col>
              <Col
                xs
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <div className="f-label" style={{ fontWeight: 700 }}>
                  Hakan Ertan
                </div>
                <div
                  className="f-label-s"
                  style={{ color: "var(--neutral500)" }}
                >
                  Öğrenci
                </div>
                <div
                  className="f-label-s"
                  style={{ color: "var(--neutral300)" }}
                >
                  Marmara Üniv.
                </div>
              </Col>

              <Col xs="auto">
                <CaretRight width={10} height={10} stroke="var(--neutral200)" />
              </Col>
            </Row>
          </Link>
        ))
      ) : (
        <div className="f-label" style={{ color: "var(--neutral300)" }}>
          Güncel çırak bulunmamakta.
        </div>
      )}
    </Row>
  );
};

const MentorsContainer = (props) => {
  return (
    <Row style={{ width: "100%" }}>
      <div className="f-h6" style={{ marginBottom: "24px" }}>
        Güncel Ustalarım
      </div>
      {props.user?.mentors?.length ?? 0 > 0 ? (
        [...Array(3)].map((x, i) => (
          <Link
            to="/ustalar/seyyahil"
            style={{
              color: "var(--black)",
              textDecoration: "none",
              padding: "12px 0",
              borderBottom: i === 2 ? "0px" : "1px solid var(--neutral100)",
            }}
          >
            <Row style={{ gap: 12 }}>
              <Col xs="auto">
                <Image
                  roundedCircle={true}
                  src="https://www.designgost.com/img/4e312348-8be8-4a45-ab83-6a5b4fb99a35/hakan-ertan.jpg?fm=webp&fit=max&crop=1350%2C1350%2C0%2C0&w=200&s=ee609c601fab0ec9585be45177fa5a4b"
                  style={{ width: "48px", height: "48px" }}
                />
              </Col>
              <Col
                xs
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <div className="f-label" style={{ fontWeight: 700 }}>
                  Hakan Ertan
                </div>
                <div
                  className="f-label-s"
                  style={{ color: "var(--neutral500)" }}
                >
                  Öğrenci
                </div>
                <div
                  className="f-label-s"
                  style={{ color: "var(--neutral300)" }}
                >
                  Marmara Üniv.
                </div>
              </Col>

              <Col xs="auto" style={{ display: "flex", gap: 12 }}>
                <Award
                  width={16}
                  height={16}
                  stroke="var(--purplePrimary)"
                  fill="var(--purplePrimary)"
                />

                <OverlayTrigger
                  overlay={<Tooltip id={"project"}>Proje</Tooltip>}
                >
                  <Project
                    width={16}
                    height={16}
                    stroke="var(--greenPrimary)"
                  />
                </OverlayTrigger>

                <SrUsta width={16} height={16} stroke="var(--bluePrimary)" />
                <CaretRight width={10} height={10} stroke="var(--neutral200)" />
              </Col>
            </Row>

            {/* <div
            role="tooltip"
            x-placement="right"
            class="fade show tooltip bs-tooltip-end"
            id="button-tooltip"
            style="position: absolute; inset: 0px auto auto 0px; transform: translate(478px, 1507px);"
            data-popper-reference-hidden="false"
            data-popper-escaped="false"
            data-popper-placement="right"
          >
            <div
              class="tooltip-arrow"
              style="position: absolute; top: 0px; transform: translate(0px, 8px);"
            ></div>
            <div class="tooltip-inner">Simple tooltip</div>
          </div> */}
          </Link>
        ))
      ) : (
        <Row
          className="f-label"
          style={{
            color: "var(--neutral300)",
            flexDirection: "column",
            gap: 24,
          }}
        >
          Henüz bir Ustanız bulunmamakta.
          {props?.isCurrentUser ? (
            <div>
              <DGButton variant="dg-primary" size="sm">
                Usta Bul
              </DGButton>
            </div>
          ) : null}
        </Row>
      )}
    </Row>
  );
};

const MenteeApplicationsContainer = (props) => {
  return (
    <Row style={{ width: "100%" }}>
      <div className="f-h6" style={{ marginBottom: "24px" }}>
        Çıraklık talepleri
      </div>

      {props.user?.applications?.length ?? 0 > 0 ? (
        [...Array(3)].map((x, i) => (
          <Link
            to="/"
            style={{
              color: "var(--black)",
              textDecoration: "none",
              padding: "12px 0",
              borderBottom: i === 2 ? "0px" : "1px solid var(--neutral50)",
            }}
          >
            <Row style={{ gap: 12 }}>
              <Col xs="auto">
                <div
                  style={{
                    width: "48px",
                    height: "48px",
                    borderRadius: 6,
                    background: "var(--bluePrimary)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Email width={24} height={24} stroke="white" />
                </div>
              </Col>
              <Col
                xs
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <div
                  className="f-label-s"
                  style={{ color: "var(--neutral500)" }}
                >
                  24 Ocak 2023
                </div>
                <div className="f-label">
                  <b>Tebrikler!</b> Hakan Ertan yeni bir <b>mesaj</b> yolladı
                </div>
              </Col>

              <Col xs="auto">
                <CaretRight width={10} height={10} stroke="var(--neutral200)" />
              </Col>
            </Row>
          </Link>
        ))
      ) : (
        <div className="f-label" style={{ color: "var(--neutral300)" }}>
          Güncel Çıraklık talebi bulunmamakta.
        </div>
      )}
    </Row>
  );
};

const ProjectsContainer = (props) => {
  return (
    <Row style={{ width: "100%" }}>
      <div className="f-h6" style={{ marginBottom: "24px" }}>
        Güncel projelerim
      </div>
      {props.user?.projects?.length ?? 0 > 0 ? (
        [...Array(3)].map((x, i) => (
          <Link
            to="/"
            style={{
              color: "var(--black)",
              textDecoration: "none",
              padding: "12px 0",
              borderBottom: i === 2 ? "0px" : "1px solid var(--neutral50)",
            }}
          >
            <Row style={{ gap: 12 }}>
              <Col xs="auto">
                <div
                  style={{
                    width: "48px",
                    height: "48px",
                    borderRadius: 6,
                    background: "var(--bluePrimary)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Project width={24} height={24} stroke="white" />
                </div>
              </Col>
              <Col
                xs
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <div
                  className="f-label-s"
                  style={{ color: "var(--neutral500)" }}
                >
                  24 Ocak 2023
                </div>
                <div className="f-label">Food Recipes Mobile App</div>
              </Col>

              <Col xs="auto">
                <CaretRight width={10} height={10} stroke="var(--neutral200)" />
              </Col>
            </Row>
          </Link>
        ))
      ) : (
        <Row
          className="f-label"
          style={{
            color: "var(--neutral300)",
            flexDirection: "column",
            gap: 24,
          }}
        >
          Henüz projeniz bulunmamakta. Bir Usta bulup beraber proje geliştirmek
          ister misin?
          <div>
            <DGButton variant="dg-primary" size="sm">
              Proje Gönder
            </DGButton>
          </div>
        </Row>
      )}
    </Row>
  );
};

const WebLinksContainer = (props) => {
  function getLogo(platform) {
    switch (platform) {
      case "behance":
        return <Behance width={12} height={12} />;
      case "codepen":
        return <Codepen width={12} height={12} />;
      case "dribbble":
        return <Dribble width={12} height={12} />;
      case "github":
        return <Github width={12} height={12} />;
      case "instagram":
        return <Instagram width={12} height={12} />;
      case "linkedin":
        return <Linkedin width={12} height={12} />;
      case "medium":
        return <Medium width={12} height={12} />;
      case "twitter":
        return <Twitter width={12} height={12} />;
      case "vimeo":
        return <Vimeo width={12} height={12} />;
      case "youtube":
        return <Youtube width={12} height={12} />;
      default:
        return <WebLink width={12} height={12} />;
    }
  }

  return (
    <Row style={{ width: "100%" }}>
      <div className="f-h6" style={{ marginBottom: "24px" }}>
        Web sayfalarım
      </div>
      <Row style={{ gap: "16px" }}>
        {props.data?.map((element, i) => (
          <Link
            to={element.url}
            target="_blank"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              stroke: "transparent",
              border: "1px solid",
              borderRadius: 100,
              width: "32px",
              height: "32px",
            }}
          >
            {getLogo(element.platform)}
          </Link>
        ))}
      </Row>
    </Row>
  );
};

const RegisterContainer = (props) => {
  try {
    var date = new Date(props?.date);
    var formattedDate = format(date, "dd MMMM yyyy", {
      locale: trLocale,
    });

    return (
      <Row
        style={{ width: "100%", color: "var(--neutral500)" }}
        className="f-label-s"
      >
        Üye olduğu tarih: {formattedDate}
      </Row>
    );
  } catch (err) {
    return null;
  }
};

//*  Views

const NumberCard = (props) => {
  return (
    <Col
      style={{
        border: "1px solid var(--neutral100)",
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: "12px 0",
      }}
    >
      <div className="f-h6">{props.number}</div>
      <div className="f-label">{props.text}</div>
    </Col>
  );
};

const Divider = (props) => {
  return (
    <div style={{ border: "1px solid var(--neutral50)", width: "100%" }} />
  );
};

const TabLink = (props) => {
  return <Link to={`/profile#${props.path}`}>{props.title}</Link>;
};

export default ProfileHeader;
