import { Col, Nav, Navbar } from "react-bootstrap";

import Logo from "../../../public/images/Logo-White.png";
import LogoPurple from "../../../public/images/Logo.svg";

const HeaderLeading = (props) => {
  return (
    <Col xs="auto" style={{ display: "flex", justifyContent: "center" }}>
      <Navbar.Brand className="align-self-center">
        <Nav.Link className="f-h6 p-0" href="/">
          <img
            alt=""
            src={props.variant === "light" ? LogoPurple : Logo}
            width={150}
          />
        </Nav.Link>
      </Navbar.Brand>
    </Col>
  );
};

export default HeaderLeading;