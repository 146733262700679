import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const SideMap = (props) => {
  return (
    <Col xs={3} style={{ paddingLeft: "30px" }}>
      <div
        style={{
          border: "1px solid var(--neutral200)",
          borderRadius: "8px",
          padding: "26px 30px",
          position: "sticky",
          width: "362px",
          top: "150px",
        }}
      >
        {props.categories != null ? (
          <div style={{ marginBottom: "30px" }}>
            <div className="f-h4">Kategoriler</div>
            <div
              style={{
                border: "0.5px solid var(--neutral200)",
                marginTop: "16px",
                marginBottom: "16px",
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "15px",
                marginTop: "14px",
              }}
            >
              {props.categories.map((x, i) => (
                <Link to={props.site+"?category="+x.name} key={i}>
                  {x.name}
                </Link>
              ))}
            </div>
          </div>
        ) : null}
        <div className="f-h4">Keşfet</div>
        <div
          style={{
            border: "0.5px solid var(--neutral200)",
            marginTop: "16px",
            marginBottom: "16px",
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "14px",
            gap: "15px",
          }}
        >
          {props.exploreCategories.map((x, i) => (
            <Link
              to={x.path}
              className={x.path === props.site ? "active" : ""}
              key={i}
            >
              {x.name}
            </Link>
          ))}
        </div>
      </div>
    </Col>
  );
};

export default SideMap;
