import { Row, Col, Container } from "react-bootstrap";
import Logo from "../../public/images/Logo.svg";
import Twitter from "../../public/images/Twitter.svg";
import Instagram from "../../public/images/Instagram.svg";
import Youtube from "../../public/images/Youtube.svg";
import AppStore from "../../public/images/AppStore.svg";
import GooglePlay from "../../public/images/GooglePlay.svg";
import useIsMobile from "../../public/is-Mobile";

import "./footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  const isMobile = useIsMobile();

  const links = [
    { title: "Usta Bul", link: "/ustalar" },
    { title: "Projeler", link: "/projeler" },
    { title: "Blog", link: "/blog" },
    { title: "Eğitim Videoları", link: "/egitim-videolari" },
    { title: "Podcast", link: "/podcast" },
    { title: "Akademik Yazılar", link: "/akademik-yazilar" },
  ];

  return (
    <footer style={{ borderTop: "solid 1px var(--neutral50)" }}>
      {isMobile ? (
        <MobileFooter links={links} />
      ) : (
        <Container
          style={{
            paddingTop: "50px",
            paddingBottom: "30px",
            display: "flex",
            gap: isMobile ? "30px" : "45px",
            flexDirection: "column",
          }}
        >
          <Row style={{ flexWrap: "wrap", gap: isMobile ? "30px" : "0px" }}>
            <Col xs="12" lg="auto">
              <img src={Logo} alt="DesignGost" />
            </Col>
            <Col
              style={{
                display: "flex",
                justifyContent: "center",
                gap: isMobile ? "30px" : "40px",
                flexWrap: "wrap",
              }}
            >
              <Col xs="12" lg="auto">
                <Link to="/ustalar" className="f-h6 footer-link">
                  Usta Bul
                </Link>
              </Col>
              <Col xs="12" lg="auto">
                <Link to="/projeler" className="f-h6 footer-link">
                  Projeler
                </Link>
              </Col>
              <Col xs="12" lg="auto">
                <Link to="/blog" className="f-h6 footer-link">
                  Blog
                </Link>
              </Col>
              <Col xs="12" lg="auto">
                <Link to="/podcast" className="f-h6 footer-link">
                  Podcast
                </Link>
              </Col>
              <Col xs="12" lg="auto">
                <Link to="/podcast" className="f-h6 footer-link">
                  Eğitim Videoları
                </Link>
              </Col>
              <Col xs="12" lg="auto">
                <Link to="/akademik-yazilar" className="f-h6 footer-link">
                  Akademik Yazılar
                </Link>
              </Col>
            </Col>
            <Col
              xs="12"
              lg="auto"
              style={{
                display: "flex",
                gap: isMobile ? "30px" : "40px",
                flexWrap: "wrap",
              }}
            >
              <Col xs="12" lg="auto">
                <a href="https://twitter.com/DesignGost">
                  <img src={Twitter} alt="https://twitter.com/DesignGost" />
                </a>
              </Col>
              <Col xs="12" lg="auto">
                <a href="https://instagram.com/designgostcom">
                  <img
                    src={Instagram}
                    alt="https://instagram.com/designgostcom"
                  />
                </a>
              </Col>
              <Col xs="12" lg="auto">
                <a href="https://youtube.com/DesignGost">
                  <img src={Youtube} alt="https://youtube.com/DesignGost" />
                </a>
              </Col>
            </Col>
          </Row>
          {isMobile ? null : (
            <div style={{ border: "1px solid var(--neutral100)" }} />
          )}

          <Row
            style={{
              flexDirection: isMobile ? "column-reverse" : "row",
              flexWrap: "wrap",
              gap: isMobile ? "30px" : "0px",
            }}
          >
            <Col xs="12" lg="auto">
              Tüm hakları saklıdır ©2024 DesignGost
            </Col>
            <Col
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "30px",
                flexWrap: "wrap",
              }}
            >
              <Col xs="12" lg="auto">
                <a
                  className="f-label footer-link"
                  href="mailto:info@designgost.com"
                >
                  İletişim
                </a>
              </Col>
              <Col xs="12" lg="auto">
                <Link className="f-label footer-link" to="/kullanim-kosullari">
                  Kullanım Koşulları
                </Link>
              </Col>
              <Col xs="12" lg="auto">
                <Link className="f-label footer-link" to="/gizlilik-politikasi">
                  Gizlilik Politikası
                </Link>
              </Col>
            </Col>
          </Row>
          <div style={{ border: "1px solid var(--neutral100)" }} />
          <Row style={{ gap: "30px", justifyContent: "flex-end" }}>
            <Col
              xs="12"
              lg="auto"
              className="f-paragraph"
              style={{ textAlign: isMobile ? "start" : "end" }}
            >
              DesignGost mobil uygulamasını indir:
            </Col>
            <Col xs="auto" style={{ display: "flex", gap: "30px" }}>
              {/* <img src={GooglePlay} /> */}
              <a
                href="https://apps.apple.com/tr/app/designgost/id1567805744"
                target="_blank"
              >
                <img src={AppStore} />
              </a>
            </Col>
          </Row>
        </Container>
      )}
    </footer>
  );
};

export default Footer;

const MobileFooter = (props) => {
  return (
    <footer style={{ borderTop: "solid 1px var(--neutral50)" }}>
      <Container
        style={{
          padding: "40px 20px 30px",
          display: "flex",
          gap: 20,
          flexDirection: "column",
        }}
      >
        <Col xs="12">
          <img src={Logo} alt="DesignGost" />
        </Col>

        <Row
          style={{
            flexWrap: "wrap",
            gap: "18px 12px",
          }}
        >
          {props.links?.map((element, i) => (
            <Col xs="auto" key={i}>
              <a className="footer-link" href={element.link}>
                {element.title}
              </a>
            </Col>
          ))}
          <Col xs="auto">
            <a className="footer-link" href="mailto:info@designgost.com">
              İletişim
            </a>
          </Col>
          <Col xs="auto">
            <a className="footer-link" href="/gizlilik-politikasi">
              Gizlilik Politikası
            </a>
          </Col>
          <Col xs="auto">
            <a className="footer-link" href="/kullanim-kosullari">
              Kullanım Koşulları
            </a>
          </Col>
        </Row>
        <div style={{ border: "1px solid var(--neutral100)" }} />
        <Row style={{ gap: 30, justifyContent: "center" }}>
          <Col xs="auto">
            <a href="https://twitter.com/DesignGost">
              <img src={Twitter} alt="https://twitter.com/DesignGost" />
            </a>
          </Col>
          <Col xs="auto">
            <a href="https://instagram.com/designgostcom">
              <img src={Instagram} alt="https://instagram.com/designgostcom" />
            </a>
          </Col>
          <Col xs="auto">
            <a href="https://youtube.com/DesignGost">
              <img src={Youtube} alt="https://youtube.com/DesignGost" />
            </a>
          </Col>
        </Row>
        <div style={{ border: "1px solid var(--neutral100)" }} />
        <Row style={{ gap: "20px" }}>
          <Col xs="12" className="f-paragraph" style={{ textAlign: "center" }}>
            DesignGost mobil uygulamasını indir:
          </Col>
          <Col
            xs="12"
            style={{ display: "flex", gap: "30px", justifyContent: "center" }}
          >
            <img src={AppStore} height={45} />
          </Col>
        </Row>
        <div style={{ border: "1px solid var(--neutral100)" }} />
        <Row style={{ justifyContent: "center" }}>
          Tüm hakları saklıdır ©2024 DesignGost
        </Row>
      </Container>
    </footer>
  );
};
