import { Col, Container, Nav, NavDropdown, Row } from "react-bootstrap";
import useIsMobile from "../public/is-Mobile";

import ProfileHeader from "../components/Profile/ProfileHeader";

import { ReactComponent as CaretDown } from "../public/icons/Caret-Down.svg";

const ProfileLayout = (props) => {
  const isMobile = useIsMobile();

  const isSettings =
    props.selectedTab === "settings" ||
    props.selectedTab === "parola" ||
    props.selectedTab === "bildirimAyarlari" ||
    props.selectedTab === "deleteAccount";

  return (
    <div
      style={{
        background: isMobile
          ? "transparent"
          : "linear-gradient(180deg, var(--purplePrimary) 110px, transparent 110px)",
        paddingTop: 22,
      }}
    >
      <Container>
        <Row style={{ gap: isMobile ? 13 : 48, flexWrap: "wrap" }}>
          <Col xs={12} md={4}>
            <ProfileHeader
              user={props?.data}
              isCurrentUser={props?.isCurrentUser}
              isSettings={isSettings}
              setSelectedTab={props.setSelectedTab}
            />
          </Col>
          <Col xs>
            <Container style={{ paddingTop: isMobile ? "15px" : "45px" }}>
              <Nav
                className="profile-nav"
                activeKey={props.selectedTab}
                onSelect={(key) => {
                  props.setSelectedTab(key);
                }}
                style={{
                  gap: props?.isCurrentUser ? 24 : 40,
                  background: isMobile ? "var(--neutral50)" : "transparent",
                  padding: isMobile ? "8px 16px 0px" : "0",
                  flexWrap: isMobile ? "nowrap" : "wrap",
                  overflow: isMobile ? "scroll" : "auto",
                }}
              >
                {props.tabs.map((element) =>
                  (element.subPages ?? null) !== null ? (
                    <DropdownElement
                      element={element}
                      selectedTab={props.selectedTab}
                    />
                  ) : element.key === "divider" ? (
                    isMobile ? null : (
                      <Nav.Item
                        style={{
                          width: 1,
                          background: "white",
                          margin: "10px 0 14px",
                        }}
                      />
                    )
                  ) : (
                    <NavElement element={element} />
                  )
                )}
              </Nav>
              {props.activePage}
            </Container>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const DropdownElement = (props) => {
  const element = props.element;
  const isMobile = useIsMobile();

  return (
    <NavDropdown
      title={
        <div>
          {element.title}

          <CaretDown
            width={10}
            height={10}
            stroke={
              element.subPages.some((x) => x["key"] === props.selectedTab)
                ? isMobile
                  ? "var(--purplePrimary)"
                  : "var(--yellowPrimary)"
                : isMobile
                ? "black"
                : "white"
            }
            style={{ marginTop: -4, marginLeft: 6 }}
          />
        </div>
      }
      id="nav-dropdown"
      active={element.subPages.some((x) => x["key"] === props.selectedTab)}
    >
      {element.subPages.map((subElement) => (
        <NavDropdown.Item eventKey={subElement.key}>
          {subElement.title}
        </NavDropdown.Item>
      ))}
    </NavDropdown>
  );
};

const NavElement = (props) => {
  const element = props.element;

  return (
    <Nav.Item>
      <Nav.Link eventKey={element.key}>
        {element.icon !== null ? element.icon : null}
        {element.title}
      </Nav.Link>
    </Nav.Item>
  );
};

export default ProfileLayout;
