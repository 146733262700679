import { useCallback, useEffect, useState } from "react";
import { Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import DGButton from "../components/Buttons/DGButton";
import FileInput from "../components/FileInput/FileInput";
import DGInput from "../components/Inputs/DGInput";
import { ReactComponent as ImageAdd } from "../public/images/Image-Add.svg";
import { ReactComponent as Edit } from "../public/icons/Edit.svg";
import { ReactComponent as Remove } from "../public/icons/Remove.svg";
import { ReactComponent as PlusCircle } from "../public/icons/plus-circle.svg";
import useIsMobile from "../public/is-Mobile";

const AcademicPaperUpload = () => {

  const isMobile = useIsMobile();

  const [images, setImages] = useState([]);
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.map((file) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        setImages((prevState) => [
          ...prevState,
          { id: "42", src: e.target.result },
        ]);
      };
      reader.readAsDataURL(file);
      return file;
    });
  }, []);

  return (
    <Container
      style={{
        padding: isMobile ? "20px 20px 30px" : "70px 0px 100px",
        display: "flex",
        flexDirection: "column",
        gap: 40,
        maxWidth: "500px",
      }}
    >
      <div className="f-h5">Yeni Akademik Yazı yükle</div>
      <Row>
        <div className="f-h6" style={{ marginBottom: 20 }}>
          Akademik yazı
        </div>
        <FileInput
          onDrop={onDrop}
          accept={{ "text/html": [".pdf"] }}
          child={
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 28,
              }}
            >
              <ImageAdd width={30} height={30} stroke="none" />
              <div className="f-label">
                Dosyayı buraya sürükleyip bırakabilirsin ya da
              </div>
              <DGButton variant="dg-primary" size="medium">
                Dosya Yükle
              </DGButton>
            </div>
          }
        />
      </Row>

      <AcademicPaperAuthors />

      <DGInput title="Akademik yazı başlığı" />

      <Row>
        <DGInput title="Akademik yazı özeti" isLong={true} maxLength={500} />
        <div className="f-label-s" style={{ color: "var(--neutral500)" }}>
          Yazı özeti için maksimum 500 karakter kullanabilirsin
        </div>
      </Row>

      <Row style={{ gap: "13px 20px" }}>
        <Col xs="auto">
          <DGInput title="Yıl" />
        </Col>

        <Col xs="auto">
          <DGInput title="Ay" />
        </Col>
        <Row className="f-label-s" style={{ color: "var(--neutral500)" }}>
          Tezin yayınlanma tarihi
        </Row>
      </Row>

      <Form.Check
        label={
          <div className="f-label" style={{ maxWidth: "345px" }}>
            Akademik Yazı yükleyerek{" "}
            <Link to="/" style={{ color: "var(--purplePrimary)" }}>
              Kullanım Şartlarımız
            </Link>{" "}
            ve{" "}
            <Link to="/" style={{ color: "var(--purplePrimary)" }}>
              Gizlilik Politikamızı
            </Link>{" "}
            kabul etmiş sayılırsınız.
          </div>
        }
        type="checkbox"
        className="dg-radio-button"
        style={{ paddingLeft: 0 }}
      />

      <div style={{ display: "flex", gap: 30 }}>
        <DGButton variant="tertiary" size="medium">
          Vazgeç
        </DGButton>

        <DGButton variant="dg-primary" size="medium" onClick={() => {
          
        }}>
          Gönder
        </DGButton>
      </div>
    </Container>
  );
};

export default AcademicPaperUpload;

const AcademicPaperAuthors = () => {
  const [editingIndex, setEditingIndex] = useState(null);
  const [data, setData] = useState([]);

  const [isAddingNew, setIsAddingNew] = useState(true);

  useEffect(() => {
    if (data.length === 0) {
      setIsAddingNew(true);
    }
  }, [data]);

  return (
    <Row style={{ gap: 10 }}>
      <div
        style={{
          fontSize: 17,
          fontWeight: 600,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div>Yazarlar</div>
        {!isAddingNew && editingIndex === null ? (
          <div
            className="f-label"
            style={{
              width: "auto",
              color: "var(--purplePrimary)",
              cursor: "pointer",
              height: "min-content",
              fontWeight: 700,
              display: "flex",
              alignItems: "center",
              gap: 10,
            }}
            onClick={() => setIsAddingNew(true)}
          >
            <PlusCircle /> <div style={{ paddingTop: 3 }}>Yeni Yazar Ekle</div>
          </div>
        ) : null}
      </div>
      {data.map((element, i) => (
        <AcademicPaperAuthorListElement
          isButtonsActive={editingIndex === null && !isAddingNew}
          isEditing={editingIndex === i}
          setEditingIndex={setEditingIndex}
          data={data}
          setData={setData}
          index={i}
          element={element}
        />
      ))}
      {isAddingNew ? (
        <NewAuthor
          data={data}
          setData={setData}
          setIsAddingNew={setIsAddingNew}
        />
      ) : null}
    </Row>
  );
};

const AcademicPaperAuthorListElement = (props) => {
  const handleSubmit = (event) => {
    let elements = event.target.elements;
    const temp = props.data;
    temp[props.index] = {
      name: elements["author-name"].value,
      mail: elements["e-mail"].value,
    };
    props.setData(temp);
    props.setEditingIndex(null);
    event.preventDefault();
  };

  return (
    <div>
      {props.isEditing ? (
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "20px",
              background: "var(--purplePrimary)",
              color: "white",
              borderRadius: "4px 4px 0 0",
            }}
          >
            {props.element.name}
          </div>
          <div
            style={{
              border: "1px solid #C2BECC",
              borderRadius: "0 0 4px 4px",
              borderTop: "none",
              padding: "40px 30px 30px 30px",
            }}
          >
            <Form onSubmit={handleSubmit}>
              <Row style={{ gap: 20, paddingTop: 25 }}>
                <DGInput
                  title="Ad - Soyad"
                  name="author-name"
                  defaultValue={props.element.name}
                />
                <DGInput
                  title="E-posta Adresi"
                  name="e-mail"
                  defaultValue={props.element.mail}
                />
                <div style={{ display: "flex", gap: 24, alignItems: "center" }}>
                  <div
                    className="f-h6"
                    style={{
                      width: "auto",
                      color: "var(--purplePrimary)",
                      cursor: "pointer",
                      height: "min-content",
                    }}
                    onClick={() => props.setEditingIndex(null)}
                  >
                    Vazgeç
                  </div>

                  <DGButton variant="dg-primary" size="medium" type="submit">
                    Kaydet
                  </DGButton>
                </div>
              </Row>
            </Form>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 15,
            padding: "20px",
            background: "var(--blueLight)",
            borderRadius: 4,
          }}
        >
          <div style={{ flexGrow: 1, textAlign: "start" }}>
            {props.element.name}
          </div>
          {props.isButtonsActive === true ? (
            <div style={{ display: "flex", gap: 15 }}>
              <Edit
                width={20}
                height={20}
                onClick={() => props.setEditingIndex(props.index)}
              />
              <Remove
                stroke="black"
                width={20}
                height={20}
                onClick={() => {
                  props.setData([
                    ...props.data.slice(0, props.index),
                    ...props.data.slice(props.index + 1),
                  ]);
                }}
              />
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

const NewAuthor = (props) => {
  const handleSubmit = (event) => {
    let elements = event.target.elements;
    props.setData(
      props.data.concat({
        name: elements["author-name"].value,
        mail: elements["e-mail"].value,
      })
    );
    event.preventDefault();
    props.setIsAddingNew(false);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row style={{ gap: 20, paddingTop: 25 }}>
        <DGInput title="Ad - Soyad" name="author-name" />
        <DGInput title="E-posta Adresi" name="e-mail" />
        <div style={{ display: "flex", gap: 24, alignItems: "center" }}>
          <div
            className="f-h6"
            style={{
              width: "auto",
              color: "var(--purplePrimary)",
              cursor: "pointer",
              height: "min-content",
            }}
            onClick={() => {
              if (props.data.length !== 0) {
                props.setIsAddingNew(false);
              }
            }}
          >
            Vazgeç
          </div>

          <DGButton variant="dg-primary" size="medium" type="submit">
            Kaydet
          </DGButton>
        </div>
      </Row>
    </Form>
  );
};
