import { useEffect, useState } from "react";
import { Button, Col, Image, Modal, Row } from "react-bootstrap";
import { ReactComponent as CloseButton } from "../../public/icons/X.svg";

function ProjectImageDetailModal(props) {
  const [selectedVersion, setSelectedVersion] = useState(0);
  const [isZoomIn, setIsZoomIn] = useState(false);

  useEffect(() => {
    setSelectedVersion(props.data?.version?.length - 1);
  }, [props]);

  return (
    <Modal
      fullScreen={true}
      className="fullscreen-dialog"
      show={props.show}
      onHide={props.onHide}
    >
      <Row
        className="versions-container"
        style={{ gap: "10px", justifyContent: "center", padding: "24px 0px" }}
      >
        {props?.data?.version?.map((_, i) => (
          <Col
            xs="auto"
            style={{
              borderRadius: "6px",
              height: "28px",
              width: "28px",
              display: "flex",
              alignItems: "center",
              alignContent: "center",
              textAlign: "center",
              justifyContent: "center",
              backgroundColor:
                selectedVersion == i ? "var(--bluePrimary)" : "transparent",
              color: selectedVersion == i ? "white" : "var(--bluePrimary)",
              border: "2px solid var(--bluePrimary)",
              paddingTop: "4px",
              cursor: "pointer",
            }}
            className="f-h6"
            onClick={() => setSelectedVersion(i)}
          >
            <span>V{i + 1}</span>
          </Col>
        ))}
      </Row>
      <Button
        variant="slider-button-light"
        style={{
          position: "absolute",
          top: "24px",
          right: "50px",
          zIndex: 1,
          background: "white",
        }}
        onClick={props.onHide}
      >
        <CloseButton width={16} height={16}/>
      </Button>
      <div
        style={{
          overflow: "scroll",
          display: isZoomIn ? "unset" : "flex",
          justifyContent: "center",
        }}
      >
        <Image
          src={props?.data?.version?.[selectedVersion]?.image}
          style={{
            objectFit: "contain",
            maxHeight: isZoomIn ? "none" : "100%",
            width: "100%",
            cursor: isZoomIn ? "zoom-out" : "zoom-ins",
          }}
          onClick={() => setIsZoomIn(!isZoomIn)}
        />
      </div>
      <div
        style={{
          background:
            "linear-gradient(180deg, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0.55) 100%)",
          position: "absolute",
          bottom: 0,
          height: "120px",
          width: "100%",
        }}
      >
        <Row
          style={{
            alignItems: "center",
            position: "absolute",
            bottom: "16px",
            left: "230px",
          }}
        >
          <Image
            roundedCircle={true}
            src={props.data?.messages?.[0]?.image}
            style={{
              width: "42px",
              height: "42px",
              marginRight: "8px",
              objectFit: "cover",
            }}
          />
          <Col xs style={{ color: "white" }}>
            <div className="f-label-s">{props.data?.project?.[0]?.title}</div>
            <div className="f-label" style={{ fontWeight: 700 }}>
              {props.data?.messages?.[0]?.name}
            </div>
          </Col>
        </Row>
      </div>
    </Modal>
  );
}

export default ProjectImageDetailModal;
