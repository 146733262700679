import { createContext, useState } from "react";
import DGToast from "./components/Toast/DGToast";

export const ToastContext = createContext();

export const ToastContextProvider = ({ children }) => {
  const [active, setActive] = useState(false);
  const [title, setTitle] = useState(null);
  const [variant, setVariant] = useState(null);

  return (
    <ToastContext.Provider
      value={{ active, setActive, title, setTitle, variant, setVariant }}
    >
      <DGToast show={active} title={title} variant={variant} />
      {children}
    </ToastContext.Provider>
  );
};
