import {
  Button,
  Col,
  Form,
  Image,
  Overlay,
  Row,
  Tooltip,
} from "react-bootstrap";

import "./DGTooltip.css";
import FileInput from "../FileInput/FileInput";
import { useCallback, useState } from "react";
import { ReactComponent as ImageAdd } from "../../public/images/Image-Add.svg";
import DGInput from "../Inputs/DGInput";

const CreatePinMessageTooltip = (props) => {
  const [images, setImages] = useState([]);
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.map((file) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        setImages((prevState) => [
          ...prevState,
          { id: "42", src: e.target.result },
        ]);
      };
      reader.readAsDataURL(file);
      return file;
    });
  }, []);

  return (
    <Overlay
      target={props.tooltipRef}
      show={props.show}
      placement={props.placement || "right"}
      onHide={() => props.setShow(!props.show)}
      rootClose={true}
      rootCloseEvent="click"
    >
      <Tooltip className="dg-create-pin-tooltip">
        {props.isMentor ? (
          <Row style={{ display: "flex", gap: 20 }}>
            <Col xs="auto">
              <Image
                roundedCircle={true}
                src={props.sender.image}
                style={{ width: "50px", height: "50px" }}
              />
            </Col>
            <Col xs>
              <Form>
                <DGInput
                  placeholder="Mesaj yaz"
                  isLong={true}
                  style={{ marginBottom: 20 }}
                />

                <div key="radio">
                  <Form.Check
                    label="Geliştirmek gerekiyor"
                    name="group1"
                    type="radio"
                    id="needs-approve"
                    className="dg-radio-button"
                    style={{ paddingLeft: 0 }}
                  />
                  <Form.Check
                    label="Proje onaylandı"
                    name="group1"
                    type="radio"
                    id="approved"
                    className="dg-radio-button"
                    style={{ paddingLeft: 0 }}
                  />
                </div>

                <Button
                  variant="dg-primary"
                  size="sm"
                  type="submit"
                  style={{ marginTop: "30px" }}
                >
                  Gönder
                </Button>
              </Form>
            </Col>
          </Row>
        ) : (
          <Row style={{ display: "flex", gap: 20 }}>
            <Col xs="auto">
              <Image
                roundedCircle={true}
                src={props.sender.image}
                style={{ width: "50px", height: "50px" }}
              />
            </Col>
            <Col xs>
              <Form>
                <DGInput
                  placeholder="Mesaj yaz"
                  isLong={true}
                  style={{ marginBottom: 20 }}
                />

                <FileInput
                  onDrop={onDrop}
                  accept={"image/*"}
                  child={
                    <div>
                      <ImageAdd width={30} height={30} stroke="none" />
                      <div
                        className="text-center f-label-s"
                        style={{
                          color: "var(--purplePrimary)",
                          fontWeight: 700,
                          marginTop: "8px",
                          marginBottom: "10px",
                        }}
                      >
                        Versiyon yükle
                      </div>
                      <div
                        className="text-center f-label-s"
                        style={{ color: "var(--neutral300)" }}
                      >
                        Min 1480px genişliğinde ve max 5mb JPG ya da PNG
                        olmalıdır.
                      </div>
                    </div>
                  }
                />

                <Button
                  variant="dg-primary"
                  size="sm"
                  type="submit"
                  style={{ marginTop: "30px" }}
                >
                  Gönder
                </Button>
              </Form>
            </Col>
          </Row>
        )}
      </Tooltip>
    </Overlay>
  );
};

export default CreatePinMessageTooltip;
