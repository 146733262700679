// Utils
import { Col, Image, Row } from "react-bootstrap";

import { Link } from "react-router-dom";

// Images
import { ReactComponent as ArrowRight } from "../../public/images/onboarding/Arrow-Right.svg";
import { ReactComponent as Tick } from "../../public/icons/Tick.svg";
import Register1 from "../../public/images/onboarding/Register-1.png";
import Register2 from "../../public/images/onboarding/Register-2.png";
import RegisterLayout from "../../layout/RegisterLayout";
import useIsMobile from "../../public/is-Mobile";

const MemberType = () => {
  const isMobile = useIsMobile();

  return (
    <RegisterLayout>
      <Row
        style={{
          justifyContent: "center",
          maxWidth: 840,
          gap: isMobile ? 40 : 84,
          paddingBottom: isMobile ? 24 : 0,
        }}
      >
        <Row
          className={isMobile ? null : "text-center"}
          style={{ gap: 16, maxWidth: 600 }}
        >
          <h4 className="f-h4">Üyelik tipini seçin</h4>
          <p className="f-paragraph">
            Aşağıdan kendinize uygun olan üyelik tipini seçin ve üyelik formunu
            eksiksiz tamamlayarak bize katılın.
          </p>
        </Row>

        <Row style={{ gap: isMobile ? 62 : 24 }}>
          <Col xs={isMobile ? 12 : 0}>
            <RegisterCard
              background="blue"
              text={
                isMobile ? "Usta olarak<br/>kayıt ol" : "Usta olarak kayıt ol"
              }
              href="/signup?type=mentor"
              img={Register2}
              elements={[
                "Yeni yeteneklerle tanışma fırsatı",
                "Meslektaşlarınla bilgi alışverişi",
                "Yaratıcı içerikler üretme fırsatı",
              ]}
            />
          </Col>
          <Col xs={isMobile ? 12 : 0}>
            <RegisterCard
              background="yellow"
              text="Platform üyesi olarak kayıt ol"
              href="/signup?type=member"
              img={Register1}
              elements={[
                "Network imkânı",
                "Projelerine ustalardan katkılar",
                "Sorularına usta cevaplar",
              ]}
            />
          </Col>
        </Row>
      </Row>
    </RegisterLayout>
  );
};

const RegisterCard = (props) => {
  return (
    <Row
      style={{
        flexDirection: "column",
        background: `var(--${props.background}Light)`,
        borderRadius: 12,
        gap: 32,
        height: "100%",
      }}
    >
      <Row
        style={{
          padding: "0 16px",
          alignItems: "center",
          borderBottom: "1px solid var(--neutral200)",
        }}
      >
        <Col style={{ marginTop: "-20px" }}>
          <Image src={props.img} style={{ height: 130 }} />
        </Col>
        <Col
          className="f-h5"
          style={{ fontWeight: "700" }}
          dangerouslySetInnerHTML={{ __html: props.text }}
        />
      </Row>

      <Row style={{ padding: "0 16px", gap: 16, flexGrow: 1 }}>
        {props.elements.map((x) => (
          <Row className="f-label" style={{ gap: 8 }}>
            <Col xs="auto">
              <Tick />
            </Col>
            <Col>{x}</Col>
          </Row>
        ))}
      </Row>

      <Link
        to={props.href}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "24px 0",
          background: `var(--${props.background}Medium)`,
          borderRadius: "0px 0px 12px 12px",
        }}
      >
        <Col xs="auto" className="f-label" style={{ fontWeight: "700" }}>
          Devam Et
        </Col>
        <Col xs="auto">
          <ArrowRight width={12} style={{ marginLeft: 8 }} />
        </Col>
      </Link>
    </Row>
  );
};

export default MemberType;
