import { useEffect, useRef, useState } from "react";
import { Col, Dropdown, Form, InputGroup, Row } from "react-bootstrap";
import "./dgInput.css";

const DGInput = (props) => {
  const [isFocused, setIsFocused] = useState(false);
  const containerRef = useRef();

  const [count, setCount] = useState(0);

  const [showSuggestion, setShowSuggestion] = useState(false);

  useEffect(() => {
    containerRef.current.focus();
  }, [isFocused]);

  return (
    <InputGroup className="dg-input-group">
      {(props.title || null) !== null ? (
        <Form.Label className="dg-input-header">{props.title}</Form.Label>
      ) : null}
      {(props.isLong || null) === true ? (
        <Row ref={containerRef}>
          <Form.Control
            className="f-label dg-input-area"
            as="textarea"
            rows={3}
            placeholder={props.placeholder}
            value={props.value}
            maxLength={props.maxLength}
            onChange={(e) => setCount(e.target.value.length)}
            name={props.name}
            defaultValue={props.defaultValue}
          />
          {(props.maxLength || null) !== null ? (
            <Row
              className="f-label-s"
              style={{
                color: "var(--neutral200)",
                justifyContent: "end",
                padding: "7px 4px 0 0",
              }}
            >
              {props.maxLength - count}
            </Row>
          ) : null}
        </Row>
      ) : (
        <Row
          className={"f-label dg-input-control" + (isFocused ? "-focus" : "")}
          ref={containerRef}
          style={{
            padding: (props.icon || null) !== null ? "8px 15px" : "10px 20px",
          }}
        >
          {(props.icon || null) !== null ? (
            <Col xs="auto">{props.icon}</Col>
          ) : null}

          <Col xs>
            <Dropdown
              show={props.withSuggestion === true && showSuggestion}
              onSelect={props.onSuggestionSelect}
              style={{ flexGrow: 1 }}
            >
              <Dropdown.Toggle className="dg-dropdown-input">
                <Form.Control
                  autoComplete="off"
                  className="f-label"
                  onFocus={() => {
                    setIsFocused(true);
                    setShowSuggestion(true);
                  }}
                  onBlur={() => {
                    setIsFocused(false);
                    setShowSuggestion(false);
                  }}
                  style={{
                    border: "0px",
                    backgroundColor: "transparent",
                    padding: "0px",
                    borderRadius: "0px",
                    overflow: "visible",
                  }}
                  placeholder={props.placeholder}
                  type={props.type}
                  pattern={props.pattern}
                  onChange={props.onChange}
                  defaultValue={props.defaultValue}
                  name={props.name}
                />
              </Dropdown.Toggle>

              <Dropdown.Menu className="dg-dropdown-input-menu">
                {props.suggestionData?.map((element) => (
                  <Dropdown.Item eventKey={element.id}>
                    {element.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      )}
    </InputGroup>
  );
};

export default DGInput;
