import React from "react";

// CSS
import "../public/style/submission-field.css";

// mobile
import useIsMobile from "../public/is-Mobile";

export const SubmissionField = ({
  children,
  title,
  required,
  setEditable,
  editable,
  desc,
  isEmpty,
  keyWord,
}) => {
  const isMobile = useIsMobile();
  return (
    <div style={{ padding: 30 }}>
      <div className="submission-field__header">
        <h4 className="submission-field__header__title f-h4">
          {title}
          {required && (
            <span className="submission-field__header__required f-label">
              * {isMobile ? "" : "Mecburi"}
            </span>
          )}
        </h4>
        {isEmpty ? (
          <button className="submission-field__header-btn submission-field__header-btn--add f-label">
            Yeni {keyWord} ekle
          </button>
        ) : (
          <button
            className="submission-field__header-btn submission-field__header-btn--edit f-label"
            hidden={
              keyWord === "eğitim bilgisi" ||
              keyWord === "deneyim" ||
              keyWord === "dil" ||
              keyWord === "ödüller" ||
              keyWord === "Atölye & Sergiler"
            }
            onClick={() => {
              setEditable(!editable);
            }}
          >
            {isMobile ? "" : "Düzenle"}
          </button>
        )}
      </div>
      {desc && <p>{desc}</p>}
      {children}
    </div>
  );
};
