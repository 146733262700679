import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Parallax } from "react-scroll-parallax";
import { TypeAnimation } from "react-type-animation";
import Home1 from "../../public/images/hero/home_header_1.png";
import Home2 from "../../public/images/hero/home_header_2.png";
import Home3 from "../../public/images/hero/home_header_3.png";
import useIsMobile from "../../public/is-Mobile";

const Hero = () => {
  const { t } = useTranslation("home");
  const isMobile = useIsMobile();

  const values = {
    paddings: isMobile ? "40px 20px 140px" : "98px 0px 115px",
  };

  return (
    <Container
      style={{
        backgroundColor: "var(--purplePrimary)",
        maxWidth: "100%",
        padding: values.paddings,
        color: "white",
      }}
    >
      <Container>
        <Row>
          <Col xs={12} lg={7}>
            <div className="f-h1" style={{ marginBottom: 32 }}>
              Sektörün
              <div>starlarından öğren,</div>
              <div>
                <TypeAnimation
                  sequence={[
                    "kariyerini",
                    2500,
                    "başarını",
                    2500,
                    "özgüvenini",
                    2500,
                  ]}
                  wrapper="span"
                  cursor={false}
                  repeat={Infinity}
                  style={{ background: "var(--redPrimary)", padding: "12px" }}
                />{" "}
                {isMobile ? <br /> : null}
                zirveye taşı
              </div>
            </div>
            <div className="f-paragraph">{t("hero_description")}</div>
          </Col>
          <Col
            lg={5}
            style={{
              display: !isMobile ? "block" : "none",
              position: "relative",
            }}
          >
            <Parallax speed={-10} style={{ position: "absolute", top: -10 }}>
              <img
                src={Home1}
                alt=""
                style={{
                  width: 422,
                  borderRadius: 8,
                }}
              />
            </Parallax>
            <Parallax
              speed={-5}
              style={{
                position: "absolute",
                left: "62%",
                top: "90%",
              }}
            >
              <img src={Home2} alt="" style={{ width: 220 }} />
            </Parallax>
            <Parallax
              speed={-3}
              style={{ position: "absolute", left: "80%", top: "-60px" }}
            >
              <img src={Home3} alt="" style={{ height: 160 }} />
            </Parallax>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Hero;
