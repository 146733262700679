import { Link } from "react-router-dom";
import DGButton from "../Buttons/DGButton";

import useIsMobile from "../../public/is-Mobile";
import BuildCareer from "../../public/images/build-career.png";
import { useTranslation } from "react-i18next";

const RegisterNow = () => {
  const isMobile = useIsMobile();
  const { t } = useTranslation("home");
  return (
    <div
      className="f-h2"
      style={{
        background: isMobile ? "var(--purplePrimary)" : `url(${BuildCareer})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundColor: "var(--purplePrimary)",
        padding: isMobile ? "40px 20px" : "252px 0 225px",
        textAlign: isMobile ? "start" : "center",
      }}
    >
      <div
        dangerouslySetInnerHTML={{ __html: t("register_now_title") }}
        style={{ color: "white" }}
      />
      <div
        className="f-paragraph"
        style={{ marginTop: "20px", marginBottom: "38px", color: "white" }}
        dangerouslySetInnerHTML={
          isMobile
            ? { __html: t("register_now_desc_mobile") }
            : { __html: t("register_now_desc") }
        }
      />
      <Link to="/choose-signup">
        <DGButton variant="dg-primary" size="medium">
          {t("register_now_button_title")}
        </DGButton>
      </Link>
    </div>
  );
};

export default RegisterNow;
