import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";

const MenteeCard = ({ image, name, surname }) => {
  return (
    <Link to="/ciraklar/seyyahil" className="text-center" style={{width: "120px", color: "var(--black)", textDecoration: "none"}}>
        <Image
          roundedCircle={true}
          src={image}
          style={{ width: "120px", height: "120px"}}
        />
        <div className="f-h6" style={{ marginTop: "20px" }}>
          {name} {surname.substring(0, 1)}.
        </div>
      </Link>
  );
};

export default MenteeCard;
