import { Button } from "react-bootstrap";
import "./button.css";

const DGButton = (props) => {
  const style = {
    display: "flex",
    flexDirection: "row",
    gap: "8px",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <Button {...props}>
      <div className="text-center" style={style}>
        {props.children}
      </div>
    </Button>
  );
};

export default DGButton;
