import { Col, Row } from "react-bootstrap";
import BaseAboutSectionView from "./BaseAboutSectionView";
import { ReactComponent as Languages } from "../../../public/icons/Languages.svg";
import languageList from "../../../data/languages.json";
import langLevels from "../../../data/languageLevels.json";

const LanguagesSection = (props) => {
  return (
    <BaseAboutSectionView title="Konuşabildiğim Diller">
      {props.data?.map((element, i) =>
        element.language != "" && element.languageLevel != "" ? (
          <LanguageView
            key={i}
            language={element.language}
            languageLevel={element.level}
          />
        ) : null
      )}
    </BaseAboutSectionView>
  );
};

const LanguageView = ({ language, languageLevel }) => {
  return (
    <Row className="f-label" style={{ gap: 8 }}>
      <Col xs="auto">
        <Languages width={20} stroke={"var(--neutral300)"} />
      </Col>
      <Col xs style={{ display: "flex", gap: 8, flexDirection: "column" }}>
        <div style={{ fontWeight: 700 }}>{languageList[language]}</div>
        <div>{langLevels.filter((x) => x.id == languageLevel)[0].title}</div>
      </Col>
    </Row>
  );
};

export default LanguagesSection;
