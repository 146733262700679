import { useRef, useState } from "react";

import { Button, Col, Image, Overlay, Row, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import DGButton from "../../Buttons/DGButton";

import Icon from "../../../public/images/DesignGost-Icon.svg";
import { ReactComponent as Notifications } from "../../../public/icons/Notifications.svg";
import { ReactComponent as CloseX } from "../../../public/icons/X.svg";

const NotificationsDropdown = (props) => {
  const [showNotifications, setShowNotifications] = useState(false);
  const notificationsRef = useRef(null);

  return (
    <div>
      <Notifications
        width={24}
        ref={notificationsRef}
        onClick={() => setShowNotifications(!showNotifications)}
        className={
          showNotifications
            ? "header-icon-active"
            : props.variant === "light"
            ? "header-icon-light"
            : "header-icon-dark"
        }
      />
      <Overlay
        target={notificationsRef.current}
        show={showNotifications}
        placement="bottom"
        onHide={() => setShowNotifications(!showNotifications)}
        rootClose={true}
        rootCloseEvent="click"
      >
        <Tooltip className="header-tooltip-notifications">
          <Row
            style={{
              padding: "30px 10px 40px",
            }}
          >
            <Row>
              <Col xs className="f-h6">
                Bildirimler
              </Col>
              <Col xs="auto" className="f-label">
                <Link
                  to="/profile#bildirimler"
                  onClick={() => setShowNotifications(!showNotifications)}
                >
                  Tümüne Bak
                </Link>
              </Col>
            </Row>
            <Row
              style={{
                border: "0.5px solid var(--neutral200)",
                margin: "20px 0px 20px 0px",
              }}
            />
            <Row
              className="f-paragraph text-center"
              style={{
                backgroundColor: "var(--blueLight)",
                gap: 20,
                padding: "20px 25px",
                position: "relative",
                borderRadius: 12,
                marginBottom: "20px",
              }}
            >
              <div style={{ fontWeight: 700 }}>
                Hoşgeldin Hakan!
                <div style={{ fontWeight: 500 }}>
                  E-postanı onaylarak Designgost üyeliğini oluşturdun.
                </div>
              </div>
              <Row>
                <div
                  style={{
                    display: "flex",
                    gap: 20,
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <div>
                    <DGButton variant="dg-primary" size="medium">
                      Profilini Tamamla
                    </DGButton>
                  </div>
                  <div>
                    <DGButton variant="dg-secondary" size="medium">
                      Projeleri İncele
                    </DGButton>
                  </div>
                </div>
              </Row>
              <Button
                variant="slider-button-light"
                style={{
                  background: "white",
                  border: "0px",
                  position: "absolute",
                  top: "8px",
                  right: "8px",
                }}
              >
                <CloseX width={7} height={7} />
              </Button>
            </Row>

            <Row
              style={{
                maxHeight: "475px",
                overflow: "auto",
                paddingBottom: "10px",
                gap: "20px",
              }}
            >
              <Row style={{ gap: 8 }}>
                <Col xs="auto">
                  <Image
                    src={Icon}
                    style={{ height: "24px", width: "24px" }}
                    roundedCircle={true}
                  />
                </Col>
                <Col xs>Hakan Ertan projene yorum yaptı</Col>
                <Col xs="auto">
                  <div
                    style={{
                      width: "8px",
                      height: "8px",
                      background: "var(--redPrimary)",
                      borderRadius: "4px",
                    }}
                  />
                </Col>
              </Row>
              <Row style={{ gap: 8 }}>
                <Col xs="auto">
                  <Image
                    src={Icon}
                    style={{ height: "24px", width: "24px" }}
                    roundedCircle={true}
                  />
                </Col>
                <Col xs>Hakan Ertan bir chat mesajında senden bahsetti</Col>
                <Col xs="auto">
                  <div
                    style={{
                      width: "8px",
                      height: "8px",
                      background: "var(--neutral200)",
                      borderRadius: "4px",
                    }}
                  />
                </Col>
              </Row>
            </Row>
          </Row>
        </Tooltip>
      </Overlay>
    </div>
  );
};

export default NotificationsDropdown;
