import { Row } from "react-bootstrap";
import NotFoundImage from "../public/images/404.png";
import DGButton from "../components/Buttons/DGButton";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div
    className="not-found-page"
      style={{
        padding: "80px 0",
        background: "var(--purplePrimary)",
        color: "white",
        textAlign: "center",
      }}
    >
      <Row style={{ justifyContent: "center" }}>
        <img
          src={NotFoundImage}
          style={{ height: 300, objectFit: "contain" }}
        />

        <div className="f-h4" style={{ marginTop: 64, marginBottom: 16 }}>
          Hayalet Avcıları sayfasına ulaştınız!
        </div>
        <div className="f-paragraph" style={{ marginBottom: 40 }}>
          Lütfen adresi kontrol edin veya anasayfaya gitmek için aşağıdaki
          butonu tıklayın.
        </div>

        <DGButton
          variant="quaternary"
          size="medium"
          style={{ width: "auto" }}
          onClick={() => navigate("/")}
        >
          Ana Sayfa
        </DGButton>
      </Row>
    </div>
  );
};

export default NotFound;
