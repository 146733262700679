import { Col, Navbar, Row } from "react-bootstrap";

import HeaderLeading from "./HeaderLeading";
import HeaderCenter from "./HeaderCenter";
import HeaderTrailing from "./HeaderTrailing";

const DesktopHeader = (props) => {
  const variant = props?.variant;

  return (
    <Row
      style={{
        gap: "80px",
        width: "100%",
        flexWrap: "nowrap",
      }}
    >
      <HeaderLeading variant={variant} />

      <Col xs style={{ display: "flex", justifyContent: "flex-end" }}>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <HeaderCenter variant={variant} />
          <HeaderTrailing variant={variant} />
        </Navbar.Collapse>
      </Col>
    </Row>
  );
};

export default DesktopHeader;
