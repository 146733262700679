const countries = [
  {
    name: "Türkiye",
    code: "TR",
  },
  {
    name: "ABD Virgin Adaları",
    code: "VI",
  },
  {
    name: "Aland Adaları",
    code: "AX",
  },
  {
    name: "Almanya",
    code: "DE",
  },
  {
    name: "Amerika Birleşik Devletleri",
    code: "US",
  },
  {
    name: "Andora",
    code: "AD",
  },
  {
    name: "Angola",
    code: "AO",
  },
  {
    name: "Anguilla",
    code: "AI",
  },
  {
    name: "Antarktika",
    code: "AQ",
  },
  {
    name: "Antigua ve Barbuda",
    code: "AG",
  },
  {
    name: "Arjantin",
    code: "AR",
  },
  {
    name: "Arnavutluk",
    code: "AL",
  },
  {
    name: "Aruba",
    code: "AW",
  },
  {
    name: "Avustralya",
    code: "AU",
  },
  {
    name: "Avusturya",
    code: "AT",
  },
  {
    name: "Azerbaycan",
    code: "AZ",
  },
  {
    name: "Bahamalar",
    code: "BS",
  },
  {
    name: "Bahreyn",
    code: "BH",
  },
  {
    name: "Bangladeş",
    code: "BD",
  },
  {
    name: "Barbados",
    code: "BB",
  },
  {
    name: "Belize",
    code: "BZ",
  },
  {
    name: "Belçika",
    code: "BE",
  },
  {
    name: "Benin",
    code: "BJ",
  },
  {
    name: "Bermuda",
    code: "BM",
  },
  {
    name: "Beyaz Rusya",
    code: "BY",
  },
  {
    name: "Bhutan",
    code: "BT",
  },
  {
    name: "Birleşik Arap Emirlikleri",
    code: "AE",
  },
  {
    name: "Birleşik Krallık",
    code: "GB",
  },
  {
    name: "Bolivya",
    code: "BO",
  },
  {
    name: "Bosna Hersek",
    code: "BA",
  },
  {
    name: "Botsvana",
    code: "BW",
  },
  {
    name: "Brezilya",
    code: "BR",
  },
  {
    name: "Brunei",
    code: "BN",
  },
  {
    name: "Bulgaristan",
    code: "BG",
  },
  {
    name: "Burkina Faso",
    code: "BF",
  },
  {
    name: "Burundi",
    code: "BI",
  },
  {
    name: "Cape Verde",
    code: "CV",
  },
  {
    name: "Cebelitarık",
    code: "GI",
  },
  {
    name: "Cezayir",
    code: "DZ",
  },
  {
    name: "Christmas Adası",
    code: "CX",
  },
  {
    name: "Cibuti",
    code: "DJ",
  },
  {
    name: "Cocos Adaları",
    code: "CC",
  },
  {
    name: "Cook Adaları",
    code: "CK",
  },
  {
    name: "Çad",
    code: "TD",
  },
  {
    name: "Çek Cumhuriyeti",
    code: "CZ",
  },
  {
    name: "Çin",
    code: "CN",
  },
  {
    name: "Danimarka",
    code: "DK",
  },
  {
    name: "Dominik",
    code: "DM",
  },
  {
    name: "Dominik Cumhuriyeti",
    code: "DO",
  },
  {
    name: "Doğu Timor",
    code: "TL",
  },
  {
    name: "Ekvator",
    code: "EC",
  },
  {
    name: "Ekvator Ginesi",
    code: "GQ",
  },
  {
    name: "El Salvador",
    code: "SV",
  },
  {
    name: "Endonezya",
    code: "ID",
  },
  {
    name: "Eritre",
    code: "ER",
  },
  {
    name: "Ermenistan",
    code: "AM",
  },
  {
    name: "Estonya",
    code: "EE",
  },
  {
    name: "Etiyopya",
    code: "ET",
  },
  {
    name: "Falkland Adaları (Malvinalar)",
    code: "FK",
  },
  {
    name: "Faroe Adaları",
    code: "FO",
  },
  {
    name: "Fas",
    code: "MA",
  },
  {
    name: "Fiji",
    code: "FJ",
  },
  {
    name: "Fildişi Sahilleri",
    code: "CI",
  },
  {
    name: "Filipinler",
    code: "PH",
  },
  {
    name: "Filistin Bölgesi",
    code: "PS",
  },
  {
    name: "Finlandiya",
    code: "FI",
  },
  {
    name: "Fransa",
    code: "FR",
  },
  {
    name: "Fransız Guyanası",
    code: "GF",
  },
  {
    name: "Fransız Polinezyası",
    code: "PF",
  },
  {
    name: "Gabon",
    code: "GA",
  },
  {
    name: "Gambia",
    code: "GM",
  },
  {
    name: "Gana",
    code: "GH",
  },
  {
    name: "Gine",
    code: "GN",
  },
  {
    name: "Gine-Bissau",
    code: "GW",
  },
  {
    name: "Granada",
    code: "GD",
  },
  {
    name: "Grönland",
    code: "GL",
  },
  {
    name: "Guadeloupe",
    code: "GP",
  },
  {
    name: "Guam",
    code: "GU",
  },
  {
    name: "Guatemala",
    code: "GT",
  },
  {
    name: "Guernsey",
    code: "GG",
  },
  {
    name: "Guyana",
    code: "GY",
  },
  {
    name: "Güney Afrika",
    code: "ZA",
  },
  {
    name: "Güney Georgia ve Güney Sandwich Adaları",
    code: "GS",
  },
  {
    name: "Güney Kore",
    code: "KR",
  },
  {
    name: "Güney Kıbrıs Rum Kesimi",
    code: "CY",
  },
  {
    name: "Gürcistan",
    code: "GE",
  },
  {
    name: "Haiti",
    code: "HT",
  },
  {
    name: "Hindistan",
    code: "IN",
  },
  {
    name: "Hint Okyanusu İngiliz Bölgesi",
    code: "IO",
  },
  {
    name: "Hollanda",
    code: "NL",
  },
  {
    name: "Hollanda Antilleri",
    code: "AN",
  },
  {
    name: "Honduras",
    code: "HN",
  },
  {
    name: "Hong Kong SAR - Çin",
    code: "HK",
  },
  {
    name: "Hırvatistan",
    code: "HR",
  },
  {
    name: "Irak",
    code: "IQ",
  },
  {
    name: "İngiliz Virgin Adaları",
    code: "VG",
  },
  {
    name: "İran",
    code: "IR",
  },
  {
    name: "İrlanda",
    code: "IE",
  },
  {
    name: "İspanya",
    code: "ES",
  },
  {
    name: "İsrail",
    code: "IL",
  },
  {
    name: "İsveç",
    code: "SE",
  },
  {
    name: "İsviçre",
    code: "CH",
  },
  {
    name: "İtalya",
    code: "IT",
  },
  {
    name: "İzlanda",
    code: "IS",
  },
  {
    name: "Jamaika",
    code: "JM",
  },
  {
    name: "Japonya",
    code: "JP",
  },
  {
    name: "Jersey",
    code: "JE",
  },
  {
    name: "Kamboçya",
    code: "KH",
  },
  {
    name: "Kamerun",
    code: "CM",
  },
  {
    name: "Kanada",
    code: "CA",
  },
  {
    name: "Karadağ",
    code: "ME",
  },
  {
    name: "Katar",
    code: "QA",
  },
  {
    name: "Kayman Adaları",
    code: "KY",
  },
  {
    name: "Kazakistan",
    code: "KZ",
  },
  {
    name: "Kenya",
    code: "KE",
  },
  {
    name: "Kiribati",
    code: "KI",
  },
  {
    name: "Kolombiya",
    code: "CO",
  },
  {
    name: "Komorlar",
    code: "KM",
  },
  {
    name: "Kongo",
    code: "CG",
  },
  {
    name: "Kongo Demokratik Cumhuriyeti",
    code: "CD",
  },
  {
    name: "Kosta Rika",
    code: "CR",
  },
  {
    name: "Kuveyt",
    code: "KW",
  },
  {
    name: "Kuzey Kore",
    code: "KP",
  },
  {
    name: "Kuzey Mariana Adaları",
    code: "MP",
  },
  {
    name: "Küba",
    code: "CU",
  },
  {
    name: "Kırgızistan",
    code: "KG",
  },
  {
    name: "Laos",
    code: "LA",
  },
  {
    name: "Lesotho",
    code: "LS",
  },
  {
    name: "Letonya",
    code: "LV",
  },
  {
    name: "Liberya",
    code: "LR",
  },
  {
    name: "Libya",
    code: "LY",
  },
  {
    name: "Liechtenstein",
    code: "LI",
  },
  {
    name: "Litvanya",
    code: "LT",
  },
  {
    name: "Lübnan",
    code: "LB",
  },
  {
    name: "Lüksemburg",
    code: "LU",
  },
  {
    name: "Macaristan",
    code: "HU",
  },
  {
    name: "Madagaskar",
    code: "MG",
  },
  {
    name: "Makao S.A.R. Çin",
    code: "MO",
  },
  {
    name: "Makedonya",
    code: "MK",
  },
  {
    name: "Malavi",
    code: "MW",
  },
  {
    name: "Maldivler",
    code: "MV",
  },
  {
    name: "Malezya",
    code: "MY",
  },
  {
    name: "Mali",
    code: "ML",
  },
  {
    name: "Malta",
    code: "MT",
  },
  {
    name: "Man Adası",
    code: "IM",
  },
  {
    name: "Marshall Adaları",
    code: "MH",
  },
  {
    name: "Martinik",
    code: "MQ",
  },
  {
    name: "Mauritius",
    code: "MU",
  },
  {
    name: "Mayotte",
    code: "YT",
  },
  {
    name: "Meksika",
    code: "MX",
  },
  {
    name: "Mikronezya Federal Eyaletleri",
    code: "FM",
  },
  {
    name: "Moldovya Cumhuriyeti",
    code: "MD",
  },
  {
    name: "Monako",
    code: "MC",
  },
  {
    name: "Montserrat",
    code: "MS",
  },
  {
    name: "Moritanya",
    code: "MR",
  },
  {
    name: "Mozambik",
    code: "MZ",
  },
  {
    name: "Moğolistan",
    code: "MN",
  },
  {
    name: "Myanmar",
    code: "MM",
  },
  {
    name: "Mısır",
    code: "EG",
  },
  {
    name: "Namibya",
    code: "NA",
  },
  {
    name: "Nauru",
    code: "NR",
  },
  {
    name: "Nepal",
    code: "NP",
  },
  {
    name: "Nijer",
    code: "NE",
  },
  {
    name: "Nijerya",
    code: "NG",
  },
  {
    name: "Nikaragua",
    code: "NI",
  },
  {
    name: "Niue",
    code: "NU",
  },
  {
    name: "Norfolk Adası",
    code: "NF",
  },
  {
    name: "Norveç",
    code: "NO",
  },
  {
    name: "Orta Afrika Cumhuriyeti",
    code: "CF",
  },
  {
    name: "Özbekistan",
    code: "UZ",
  },
  {
    name: "Pakistan",
    code: "PK",
  },
  {
    name: "Palau",
    code: "PW",
  },
  {
    name: "Panama",
    code: "PA",
  },
  {
    name: "Papua Yeni Gine",
    code: "PG",
  },
  {
    name: "Paraguay",
    code: "PY",
  },
  {
    name: "Peru",
    code: "PE",
  },
  {
    name: "Pitcairn",
    code: "PN",
  },
  {
    name: "Polonya",
    code: "PL",
  },
  {
    name: "Portekiz",
    code: "PT",
  },
  {
    name: "Porto Riko",
    code: "PR",
  },
  {
    name: "Reunion",
    code: "RE",
  },
  {
    name: "Romanya",
    code: "RO",
  },
  {
    name: "Ruanda",
    code: "RW",
  },
  {
    name: "Rusya Federasyonu",
    code: "RU",
  },
  {
    name: "Samoa",
    code: "WS",
  },
  {
    name: "San Marino",
    code: "SM",
  },
  {
    name: "Sao Tome ve Principe",
    code: "ST",
  },
  {
    name: "Senegal",
    code: "SN",
  },
  {
    name: "Seyşeller",
    code: "SC",
  },
  {
    name: "Sierra Leone",
    code: "SL",
  },
  {
    name: "Singapur",
    code: "SG",
  },
  {
    name: "Slovakya",
    code: "SK",
  },
  {
    name: "Slovenya",
    code: "SI",
  },
  {
    name: "Solomon Adaları",
    code: "SB",
  },
  {
    name: "Somali",
    code: "SO",
  },
  {
    name: "Sri Lanka",
    code: "LK",
  },
  {
    name: "Sudan",
    code: "SD",
  },
  {
    name: "Surinam",
    code: "SR",
  },
  {
    name: "Suriye",
    code: "SY",
  },
  {
    name: "Suudi Arabistan",
    code: "SA",
  },
  {
    name: "Svalbard ve Jan Mayen",
    code: "SJ",
  },
  {
    name: "Svaziland",
    code: "SZ",
  },
  {
    name: "Sırbistan",
    code: "RS",
  },
  {
    name: "Şili",
    code: "CL",
  },
  {
    name: "Tacikistan",
    code: "TJ",
  },
  {
    name: "Tanzanya",
    code: "TZ",
  },
  {
    name: "Tayland",
    code: "TH",
  },
  {
    name: "Tayvan",
    code: "TW",
  },
  {
    name: "Togo",
    code: "TG",
  },
  {
    name: "Tokelau",
    code: "TK",
  },
  {
    name: "Tonga",
    code: "TO",
  },
  {
    name: "Trinidad ve Tobago",
    code: "TT",
  },
  {
    name: "Tunus",
    code: "TN",
  },
  {
    name: "Turks ve Caicos Adaları",
    code: "TC",
  },
  {
    name: "Tuvalu",
    code: "TV",
  },
  {
    name: "Türkmenistan",
    code: "TM",
  },
  {
    name: "Uganda",
    code: "UG",
  },
  {
    name: "Ukrayna",
    code: "UA",
  },
  {
    name: "Umman",
    code: "OM",
  },
  {
    name: "Uruguay",
    code: "UY",
  },
  {
    name: "Ürdün",
    code: "JO",
  },
  {
    name: "Vanuatu",
    code: "VU",
  },
  {
    name: "Vatikan",
    code: "VA",
  },
  {
    name: "Venezuela",
    code: "VE",
  },
  {
    name: "Vietnam",
    code: "VN",
  },
  {
    name: "Wallis ve Futuna",
    code: "WF",
  },
  {
    name: "Yemen",
    code: "YE",
  },
  {
    name: "Yeni Kaledonya",
    code: "NC",
  },
  {
    name: "Yeni Zelanda",
    code: "NZ",
  },
  {
    name: "Yunanistan",
    code: "GR",
  },
  {
    name: "Zambiya",
    code: "ZM",
  },
  {
    name: "Zimbabve",
    code: "ZW",
  },
];

export const countryList = countries.map((c) => {
  return {
    label: c.name,
    value: c.code,
  };
});
