import { useDropzone } from "react-dropzone";
import { ReactComponent as File } from "../../public/icons/File.svg";
import { ReactComponent as Edit } from "../../public/icons/Edit.svg";
import { ReactComponent as Remove } from "../../public/icons/Remove.svg";
import { useState } from "react";

function FileInput({ onDrop, accept, open, child, defaultFile }) {
  const [initFile, setInitFile] = useState(defaultFile || null);

  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({
      accept,
      onDrop,
      maxFiles: 1,
      maxSize: 5000000,
    });

  return (
    <div>
      {acceptedFiles.length !== 0 || initFile !== null ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 8,
            padding: "26px 20px 26px 32px",
            background: "var(--blueLight)",
            borderRadius: 4,
          }}
        >
          <File stroke="black" width={32} height={32} />
          <div style={{ flexGrow: 1, textAlign: "start" }}>
            {initFile !== null ? initFile : acceptedFiles.map((file) => file.path)}
          </div>
          <div style={{ display: "flex", gap: 15 }}>
            <Edit width={20} height={20} />
            <Remove stroke="black" width={20} height={20} />
          </div>
        </div>
      ) : (
        <div onClick={open}>
          <div {...getRootProps({ className: "dropzone" })}>
            <input className="input-zone" {...getInputProps()} />
            {child}
          </div>
        </div>
      )}
    </div>
  );
}

export default FileInput;
