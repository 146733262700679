import { useEffect, useState } from "react";
import { Col, Container, Dropdown, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import DGButton from "../components/Buttons/DGButton";
import ProjectCard from "../components/Cards/ProjectCard";
import useIsMobile from "../public/is-Mobile";
import useRouterQuery from "../public/use-query";
import { ReactComponent as Filter } from "../public/icons/Filter.svg";
import { getProjects } from "../Service";

const Projects = () => {
  let query = useRouterQuery();

  const isMobile = useIsMobile();

  const constants = {
    padding: isMobile ? "20px 20px 30px" : "50px 0 100px",
  };

  const categories = {
    all: "Tüm Projeler",
    graphicDesign: "Graphic Design",
    uiUx: "UI/UX Design",
    illustration: "Illustration",
    photoshop: "Photoshop",
    uIDesignSystem: "UI Design System",
    logoTasarımı: "Logo Tasarımı",
  };

  const filters = {
    recommended: "Tavsiye Edilenler",
    topRated: "En Beğenilenler",
    newAdded: "Yeni Eklenenler",
    agencies: "Ajanslar",
  };

  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadMoreClicked, setIsLoadMoreClicked] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("recommended");
  const [projectData, setProjectData] = useState([]);

  const [categoryFilter, setSelectedCategory] = useState(
    query.get("category") === null ? "all" : query.get("category")
  );

  const fetchProjects = async () => {
    setIsLoading(true);
    try {
      const request = await getProjects("?page=" + currentPage + "&count=24");
      setProjectData(projectData.concat(request.project.data));
      setCurrentPage(currentPage + 1);
    } catch (error) {
      console.error("Error fetching assets:", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop <
        document.documentElement.offsetHeight - 350 ||
      isLoading ||
      isLoadMoreClicked == false
    ) {
      return;
    }
    fetchProjects();
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isLoading]);

  useEffect(() => {
    fetchProjects();
  }, []);

  return (
    <Container>
      <Row
        style={{
          padding: constants.padding,
        }}
      >
        <Col>
          <Row>
            <Col className="f-h4">{categories[categoryFilter]}</Col>
            <Col xs="auto">
              {isMobile ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    gap: 8,
                  }}
                >
                  Düzenle{" "}
                  <Filter stroke="var(--black)" width={24} height={24} />
                </div>
              ) : (
                <Dropdown
                  onSelect={(key) => {
                    setSelectedFilter(key);
                  }}
                >
                  <Dropdown.Toggle className="dg-dropdown f-paragraph">
                    {filters[selectedFilter]}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {Object.keys(filters).map((innerAttr) => {
                      return (
                        <Dropdown.Item eventKey={innerAttr}>
                          {filters[innerAttr]}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </Col>
          </Row>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: isMobile
                ? "repeat(2, 1fr)"
                : "repeat(3, 1fr)",
              gap: isMobile ? "24px 12px" : 33,
              marginTop: isMobile ? "20px" : "30px",
            }}
          >
            {projectData.map((x, i) => (
              <ProjectCard data={x} key={x.project_id} />
            ))}
          </div>
          {isLoadMoreClicked === false ? (
            <Col className="text-center" style={{ marginTop: "80px" }}>
              <DGButton
                variant="tertiary"
                size="medium"
                onClick={() => {
                  setIsLoadMoreClicked(true);
                  fetchProjects();
                }}
              >
                Daha Fazla Yükle
              </DGButton>
            </Col>
          ) : null}
        </Col>

        {isMobile ? null : (
          <Col xs={3} style={{ paddingLeft: "30px" }}>
            <div
              style={{
                border: "1px solid var(--neutral200)",
                borderRadius: "8px",
                padding: "26px 30px",
                position: "sticky",
                width: "260px",
                top: "150px",
              }}
            >
              <div className="f-h4">Kategoriler</div>
              <div
                style={{
                  border: "0.5px solid var(--neutral200)",
                  marginTop: "16px",
                  marginBottom: "16px",
                }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                }}
              >
                {Object.keys(categories).map((innerAttr) => {
                  return (
                    <Link
                      to={`/projeler?categories=${innerAttr}`}
                      className={categoryFilter === innerAttr ? "active" : ""}
                      onClick={() => {
                        setSelectedCategory(innerAttr);
                      }}
                    >
                      {categories[innerAttr]}
                    </Link>
                  );
                })}
              </div>
            </div>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default Projects;
