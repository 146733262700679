import { useEffect, useState } from "react";

import DGButton from "../components/Buttons/DGButton";
import { Col, Container, Image, Modal, Row } from "react-bootstrap";

import HowItWorksFirst from "../public/images/how_it_works_first.png";
import HowItWorksFooter from "../public/images/how_it_works_footer.png";

import HowItWorks1 from "../public/images/how_it_works_1.png";
import HowItWorks2 from "../public/images/how_it_works_2.png";
import HowItWorks3 from "../public/images/how_it_works_3.png";
import HowItWorks4 from "../public/images/how_it_works_4.png";
import HowItWorks5 from "../public/images/how_it_works_5.png";

import AppStore from "../public/images/AppStore.svg";
import useIsMobile from "../public/is-Mobile";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const HowItWorks = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const [opacity1, setOpacity1] = useState(1);
  const [opacity2, setOpacity2] = useState(0);
  const [opacity3, setOpacity3] = useState(0);
  const [opacity4, setOpacity4] = useState(0);
  const [opacity5, setOpacity5] = useState(0);

  const { t } = useTranslation("how_it_works");

  const setScroll = () => {
    const element = document.getElementsByClassName(`text-container`)[0];
    const y = element.getBoundingClientRect().y;
    const height = element.getBoundingClientRect().height;
    const ratio = y / height;

    if (ratio > 0) {
      setOpacity1(1);
      setOpacity2(0);
      setOpacity3(0);
      setOpacity4(0);
      setOpacity5(0);
    } else if (ratio <= -0.63) {
      let opacity = (-ratio - 0.63) * 10;

      setOpacity1(0);
      setOpacity2(0);
      setOpacity3(0);
      setOpacity4(1 - opacity);
      setOpacity5(opacity);
    } else if (ratio <= -0.53 && ratio > -0.63) {
      setOpacity1(0);
      setOpacity2(0);
      setOpacity3(0);
      setOpacity4(1);
      setOpacity5(0);
    } else if (ratio <= -0.43 && ratio > -0.53) {
      let opacity = (-ratio - 0.43) * 10;

      setOpacity1(0);
      setOpacity2(0);
      setOpacity3(1 - opacity);
      setOpacity4(opacity);
      setOpacity5(0);
    } else if (ratio <= -0.32 && ratio > -0.43) {
      setOpacity1(0);
      setOpacity2(0);
      setOpacity3(1);
      setOpacity4(0);
      setOpacity5(0);
    } else if (ratio <= -0.22 && ratio > -0.32) {
      let opacity = (-ratio - 0.22) * 10;

      setOpacity1(0);
      setOpacity2(1 - opacity);
      setOpacity3(opacity);
      setOpacity4(0);
      setOpacity5(0);
    } else if (ratio <= -0.12 && ratio > -0.22) {
      setOpacity1(0);
      setOpacity2(1);
      setOpacity3(0);
      setOpacity4(0);
      setOpacity5(0);
    } else if (ratio <= -0.02 && ratio > -0.12) {
      let opacity = (-ratio - 0.02) * 10;

      setOpacity1(1 - opacity);
      setOpacity2(opacity);
      setOpacity3(0);
      setOpacity4(0);
      setOpacity5(0);
    }
  };

  const [filterModalShow, setFilterModalShow] = useState(false);

  useEffect(() => {
    if (!isMobile) {
      window.addEventListener("scroll", setScroll);
    }
    return () => {
      window.removeEventListener("scroll", setScroll);
    };
  }, []);

  return isMobile ? (
    <HowItWorksMobileLayout />
  ) : (
    <div>
      <div
        style={{
          padding: "100px 0px",
        }}
      >
        <Container>
          <Row>
            <Col xs={7}>
              <Image src={HowItWorksFirst} style={{ width: "90%" }} />
            </Col>
            <Col
              xs={5}
              className="f-h1"
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                color: "var(--purpleDark)",
              }}
            >
              {t("first_title")}
              <div
                className="f-paragraph"
                style={{ margin: "20px 0px 35px", color: "var(--black)" }}
              >
                {t("first_desc")}
              </div>
              <div style={{ width: "auto" }}>
                <DGButton
                  variant="dg-primary"
                  size="medium"
                  onClick={() => setFilterModalShow(true)}
                >
                  {t("first_button_title")}
                </DGButton>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Row>
        <Col
          xs={7}
          className="f-h1 text-container"
          style={{
            color: "white",
            background: "#F0EDF6",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "start",
          }}
        >
          <Row
            style={{
              height: "calc(100vh - 100px)",
              alignItems: "center",
              justifyContent: "center",
              alignContent: "center",
              maxWidth: "550px",
              alignSelf: "center",
            }}
          >
            <div className="f-h2" style={{ color: "var(--purpleDark)" }}>
              {t("second_title")}
              <div className="f-paragraph" style={{ margin: "20px 0px 35px" }}>
                {t("second_desc")}
              </div>
              <DGButton
                variant="dg-primary"
                size="medium"
                onClick={() => {
                  navigate(`/ustalar`);
                }}
              >
                {t("second_button_title")}
              </DGButton>
            </div>
          </Row>

          <Row
            style={{
              height: "calc(100vh - 100px)",
              alignItems: "center",
              justifyContent: "center",
              alignContent: "center",
              maxWidth: "550px",
              alignSelf: "center",
            }}
          >
            <div className="f-h2" style={{ color: "var(--purpleDark)" }}>
              {t("third_title")}
              <div
                className="f-paragraph"
                style={{ margin: "20px 0px 35px" }}
                dangerouslySetInnerHTML={{ __html: t("third_desc") }}
              />
              <DGButton
                variant="dg-primary"
                size="medium"
                onClick={() => {
                  navigate(`/ustalar`);
                }}
              >
                {t("third_button_title")}
              </DGButton>
            </div>
          </Row>

          <Row
            style={{
              height: "calc(100vh - 100px)",
              alignItems: "center",
              justifyContent: "center",
              alignContent: "center",
              maxWidth: "550px",
              alignSelf: "center",
            }}
          >
            <div className="f-h2" style={{ color: "var(--purpleDark)" }}>
              {t("fourth_title")}
              <div
                className="f-paragraph"
                style={{ margin: "20px 0px 35px" }}
                dangerouslySetInnerHTML={{ __html: t("fourth_desc") }}
              />
              <DGButton
                variant="dg-primary"
                size="medium"
                onClick={() => {
                  navigate(`/ustalar`);
                }}
              >
                {t("fourth_button_title")}
              </DGButton>
            </div>
          </Row>

          <Row
            style={{
              height: "calc(100vh - 100px)",
              alignItems: "center",
              justifyContent: "center",
              alignContent: "center",
              maxWidth: "550px",
              alignSelf: "center",
            }}
          >
            <div className="f-h2" style={{ color: "var(--purpleDark)" }}>
              {t("fifth_title")}
              <div
                className="f-paragraph"
                style={{ margin: "20px 0px 35px" }}
                dangerouslySetInnerHTML={{ __html: t("fifth_desc") }}
              />
              <DGButton
                variant="dg-primary"
                size="medium"
                onClick={() => {
                  navigate(`/ustalar`);
                }}
              >
                {t("fifth_button_title")}
              </DGButton>
            </div>
          </Row>

          <Row
            style={{
              height: "calc(100vh - 100px)",
              alignItems: "center",
              justifyContent: "center",
              alignContent: "center",
              maxWidth: "550px",
              alignSelf: "center",
            }}
          >
            <div className="f-h2" style={{ color: "var(--purpleDark)" }}>
              {t("sixth_title")}
              <div className="f-paragraph" style={{ margin: "20px 0px 35px" }}>
                {t("sixth_desc")}
              </div>
              <DGButton
                variant="dg-primary"
                size="medium"
                onClick={() => {
                  navigate(`/ustalar`);
                }}
              >
                {t("sixth_button_title")}
              </DGButton>
            </div>
          </Row>
        </Col>
        <Col
          xs={5}
          style={{ color: "white", background: "var(--purplePrimary)" }}
        >
          <div
            style={{
              height: "calc(100vh - 100px)",
              position: "sticky",
              top: "100px",
            }}
          >
            <Image
              src={HowItWorks1}
              style={{
                width: "110%",
                opacity: opacity1,
                position: "absolute",
                left: "-16%",
                top: "50%",
                transform: "translate(0, -50%)",
              }}
            />
            <Image
              src={HowItWorks2}
              style={{
                width: "110%",
                opacity: opacity2,
                position: "absolute",
                left: "-16%",
                top: "50%",
                transform: "translate(0, -50%)",
              }}
            />

            <Image
              src={HowItWorks3}
              style={{
                width: "110%",
                opacity: opacity3,
                position: "absolute",
                left: "-16%",
                top: "50%",
                transform: "translate(0, -50%)",
              }}
            />

            <Image
              src={HowItWorks4}
              style={{
                width: "110%",
                opacity: opacity4,
                position: "absolute",
                left: "-16%",
                top: "50%",
                transform: "translate(0, -50%)",
              }}
            />

            <Image
              src={HowItWorks5}
              style={{
                width: "110%",
                opacity: opacity5,
                position: "absolute",
                left: "-16%",
                top: "50%",
                transform: "translate(0, -50%)",
              }}
            />
          </div>
        </Col>
      </Row>

      <div
        style={{
          paddingTop: "115px",
          background: "var(--yellowPrimary)",
        }}
      >
        <Container>
          <Row style={{ alignItems: "center" }}>
            <Col xs={7}>
              <Image src={HowItWorksFooter} />
            </Col>
            <Col xs={5} className="f-h2">
              {t("footer_title")}
              <div
                className="f-paragraph"
                style={{
                  margin: "25px 0px 50px",
                  fontSize: 26,
                  fontWeight: 500,
                }}
              >
                {t("footer_desc")}
              </div>
              <div
                className="f-h5"
                style={{ margin: "25px 0px 50px", fontSize: 21 }}
              >
                {t("download_app")}
              </div>
              <a
                href="https://apps.apple.com/tr/app/designgost/id1567805744"
                target="_blank"
              >
                <img src={AppStore} height={45} />
              </a>
            </Col>
          </Row>
        </Container>
      </div>

      <FilterModal
        show={filterModalShow}
        onHide={() => setFilterModalShow(false)}
      />
    </div>
  );
};

const HowItWorksMobileLayout = () => {
  const { t } = useTranslation("how_it_works");
  const navigate = useNavigate();

  return (
    <div>
      <Row style={{ gap: 50, padding: "30px 20px 50px" }}>
        <Row style={{ gap: 30 }}>
          <Image src={HowItWorksFirst} />

          <Row className="f-h2" style={{ color: "var(--purpleDark)" }}>
            {t("first_title")}
            <div className="f-paragraph" style={{ margin: "20px 0px 35px" }}>
              {t("first_desc")}
            </div>
            <div>
              <DGButton variant="dg-primary" size="medium">
                {t("first_button_title")}
              </DGButton>
            </div>
          </Row>
        </Row>

        <Row style={{ gap: 30 }}>
          <Row className="f-h2" style={{ color: "var(--purpleDark)" }}>
            {t("second_title")}
            <div className="f-paragraph" style={{ margin: "20px 0px 35px" }}>
              {t("second_desc")}
            </div>
            <div>
              <DGButton
                variant="dg-primary"
                size="medium"
                onClick={() => {
                  navigate(`/ustalar`);
                }}
              >
                {t("second_button_title")}
              </DGButton>
            </div>
          </Row>
          <Image src={HowItWorks1} />
        </Row>

        <Row style={{ gap: 30 }}>
          <Row className="f-h2" style={{ color: "var(--purpleDark)" }}>
            {t("third_title")}
            <div
              className="f-paragraph"
              style={{ margin: "20px 0px 35px" }}
              dangerouslySetInnerHTML={{ __html: t("third_desc") }}
            />
            <div>
              <DGButton
                variant="dg-primary"
                size="medium"
                onClick={() => {
                  navigate(`/ustalar`);
                }}
              >
                {t("third_button_title")}
              </DGButton>
            </div>
          </Row>
          <Image src={HowItWorks2} />
        </Row>

        <Row style={{ gap: 30 }}>
          <Row className="f-h2" style={{ color: "var(--purpleDark)" }}>
            {t("fourth_title")}
            <div
              className="f-paragraph"
              style={{ margin: "20px 0px 35px" }}
              dangerouslySetInnerHTML={{ __html: t("fourth_desc") }}
            />
            <div>
              <DGButton
                variant="dg-primary"
                size="medium"
                onClick={() => {
                  navigate(`/ustalar`);
                }}
              >
                {t("fourth_button_title")}
              </DGButton>
            </div>
          </Row>
          <Image src={HowItWorks3} />
        </Row>

        <Row style={{ gap: 30 }}>
          <Row className="f-h2" style={{ color: "var(--purpleDark)" }}>
            {t("fifth_title")}
            <div
              className="f-paragraph"
              style={{ margin: "20px 0px 35px" }}
              dangerouslySetInnerHTML={{ __html: t("fifth_desc") }}
            />
            <div>
              <DGButton
                variant="dg-primary"
                size="medium"
                onClick={() => {
                  navigate(`/ustalar`);
                }}
              >
                {t("fifth_button_title")}
              </DGButton>
            </div>
          </Row>
          <Image src={HowItWorks4} />
        </Row>

        <Row style={{ gap: 30 }}>
          <Row className="f-h2" style={{ color: "var(--purpleDark)" }}>
            {t("sixth_title")}
            <div className="f-paragraph" style={{ margin: "20px 0px 35px" }}>
              {t("sixth_desc")}
            </div>
            <div>
              <DGButton
                variant="dg-primary"
                size="medium"
                onClick={() => {
                  navigate(`/ustalar`);
                }}
              >
                {t("sixth_button_title")}
              </DGButton>
            </div>
          </Row>
          <Image src={HowItWorks5} />
        </Row>
      </Row>

      <div
        style={{
          padding: "40px 20px 0px",
          background: "var(--yellowPrimary)",
        }}
      >
        <Container>
          <Row style={{ gap: 70 }}>
            <Row className="f-h2">
              DesignGost mobil uygulamasıyla Ustan her zaman yanında!
              <div
                className="f-paragraph"
                style={{
                  margin: "25px 0px 0px",
                  fontSize: 26,
                  fontWeight: 500,
                }}
              >
                Nerede olursan ol, DesignGost’a kolaylıkla ulaşabilirsin.
              </div>
              <div
                className="f-h5"
                style={{ margin: "40px 0px 20px", fontSize: 21 }}
              >
                Uygulamayı indir:
              </div>
              <div>
                <a
                  href="https://apps.apple.com/tr/app/designgost/id1567805744"
                  target="_blank"
                >
                  <img src={AppStore} height={45} />
                </a>
              </div>
            </Row>
            <Row>
              <Image src={HowItWorksFooter} />
            </Row>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default HowItWorks;

const FilterModal = (props) => {
  return (
    <Modal
      {...props}
      centered
      style={{ backgroundColor: "rgba(18, 14, 22, 0.85)" }}
    >
      <Modal.Body
        style={{
          padding: 24,
          backgroundColor: "var(--neutral50)",
          borderRadius: 8,
          display: "flex",
          gap: 24,
          width: 560 + 48,
        }}
      >
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/6Qx4bnZXiI4?si=0jAN5ydZbX9V7eqw"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        />
      </Modal.Body>
    </Modal>
  );
};
