import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ReactComponent as Free } from "../../public/images/Free.svg";
import { ReactComponent as GroupPeople } from "../../public/images/Group-People.svg";
import { ReactComponent as UstaCheck } from "../../public/images/Usta-Check.svg";
import useIsMobile from "../../public/is-Mobile";

const Cards = () => {
  const isMobile = useIsMobile();
  const { t } = useTranslation("home");

  return (
    <Row style={{ justifyContent: "center" }}>
      <Container
        style={{
          margin: isMobile ? "-70px 0px 0px" : "0px",
          padding: isMobile ? "0px 20px 45px" : "140px 0px 100px",
        }}
      >
        <Row
          style={{
            gap: isMobile ? "20px" : "30px",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <IconCard
            icon={<Free />}
            title={t("cards_title_first")}
            desc={t("cards_desc_first")}
          />
          <IconCard
            icon={<GroupPeople />}
            title={t("cards_title_second")}
            desc={t("cards_desc_second")}
          />
          <IconCard
            icon={<UstaCheck />}
            title={t("cards_title_third")}
            desc={t("cards_desc_third")}
          />
        </Row>
      </Container>
    </Row>
  );
};

const IconCard = ({ icon, title, desc }) => {
  return (
    <Col
      xs
      style={{
        border: "1px solid var(--neutral100)",
        borderRadius: "8px",
        padding: "40px"
      }}
    >
      <div style={{width: "64px", height: "64px", stroke: "var(--purplePrimary)"}}>
        {icon}
      </div>
      <div className="f-h5" style={{ marginBottom: "16px", marginTop: "40px" }}>
        {title}
      </div>
      <div className="f-paragraph">{desc}</div>
    </Col>
  );
};

export default Cards;
