import { useEffect, useState, useRef } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import DGButton from "../components/Buttons/DGButton";
import SliderButton from "../components/Buttons/SliderButton";
import MentorCard from "../components/Cards/Mentor/MentorCard";
import SearchBar from "../components/SearchBars/SearchBar";
import useRouterQuery from "../public/use-query";
import useIsMobile from "../public/is-Mobile";
import MobileMentorCard from "../components/Cards/Mentor/MobileMentorCard";
import { getUsers } from "../Service";
import Switch from "../components/Switch/Switch";
import { useNavigate } from "react-router-dom";

const Mentors = () => {
  let query = useRouterQuery();
  const navigate = useNavigate();
  const ref = useRef(null);

  const isMobile = useIsMobile();

  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadMoreClicked, setIsLoadMoreClicked] = useState(false);
  const [assets, setAssets] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(
    query.get("job") !== null ? query.get("job") : "all"
  );
  const [mentorData, setMentorData] = useState([]);
  const [searchQuery, setSearchQuery] = useState(
    query.get("q") !== null ? query.get("q") : null
  );

  const fetchUsers = async () => {
    setIsLoading(true);
    try {
      var query = "page=" + currentPage;
      if (selectedCategory != "all") {
        query += "&job=" + selectedCategory;
      }
      const request = await getUsers(query);
      setMentorData(mentorData.concat(request.mentors.data));
      setAssets(request.jobs);
      setCurrentPage(currentPage + 1);
    } catch (error) {
      console.error("Error fetching assets:", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [selectedCategory]);

  useEffect(() => {
    setSearchQuery(query.get("q") !== null ? query.get("q") : null);
  }, [query]);

  const scroll = (isNext) => {
    if (isNext === true) {
      ref.current.scrollLeft += ref.current.clientWidth - 200;
    } else {
      ref.current.scrollLeft -= ref.current.clientWidth + 200;
    }
  };

  function getIcon(title) {
    switch (title) {
      case "UI/UX":
        return "https://designgost.com/UI-UX Designer.svg";
      case "Industrial Designer":
        return "https://designgost.com/Índustrial Designer.svg";
      case "CGI":
        return "https://designgost.com/cgi.svg";
      case "Digital Project Manager":
        return "https://designgost.com/Digital Project-Manager.svg";
      case "Retouching":
        return "https://designgost.com/Retoucher.svg";
      default:
        return "https://designgost.com/" + title + ".svg";
    }
  }

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop <
        document.documentElement.offsetHeight - 350 ||
      isLoading ||
      isLoadMoreClicked == false
    ) {
      return;
    }
    fetchUsers();
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isLoading]);

  return (
    <Container
      style={
        isMobile ? { padding: "16px 0px 0px" } : { padding: "48px 0 100px" }
      }
    >
      <div style={{ padding: isMobile ? "0px 20px" : "0px" }}>
        <SearchBar variant="mentor" text={searchQuery} />
      </div>

      <div style={{ position: "relative" }}>
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: "100px",
            display: "flex",
            alignItems: "center",
            background:
              "linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 50%)",
          }}
        >
          <SliderButton
            next={true}
            onClick={() => {
              scroll(false);
            }}
          />
        </div>

        <Row
          className="mentors-scroll"
          style={{
            gap: 24,
            flexWrap: "nowrap",
            overflow: "scroll",
            flexDirection: "row",
          }}
          ref={ref}
        >
          {assets.map((element, index) => (
            <Row
              key={index}
              className={`f-label mentor-category-item${
                element.title === selectedCategory ? " active" : ""
              }`}
              onClick={() => {
                if (selectedCategory == element.title) {
                  setSelectedCategory("all");
                  navigate("/ustalar");
                } else {
                  setSelectedCategory(element.title);
                  navigate("/ustalar?job=" + element.title);
                }
              }}
            >
              <Image
                src={getIcon(element.title)}
                style={{
                  width: 24,
                  height: 24,
                  fill: "purple",
                }}
              />

              {element.title}
            </Row>
          ))}
        </Row>
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            height: "100%",
            width: "100px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            background:
              "linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 50%)",
          }}
        >
          <SliderButton
            next={false}
            onClick={() => {
              scroll(true);
            }}
          />
        </div>
      </div>

      {isMobile ? (
        <Row
          className="f-label-l"
          style={{
            padding: "12px 16px",
            alignItems: "center",
            backgroundColor: "var(--neutral50)",
          }}
        >
          <Col xs>Müsait Ustaları sergile</Col>
          <Col xs="auto">
            <Switch />
          </Col>
        </Row>
      ) : null}

      <div
        style={{
          display: "grid",
          gridTemplateColumns: isMobile ? "repeat(1, 1fr)" : "repeat(4, 1fr)",
          gap: isMobile ? 0 : 30,
          marginTop: isMobile ? "12px" : "10px",
        }}
      >
        {mentorData.map((x, i) =>
          isMobile ? (
            <div
              style={{
                margin: "0 16px",
                padding: "12px 0",
                borderBottom: "1px solid var(--neutral100)",
              }}
            >
              <MobileMentorCard
                image={x.img}
                username={x.displayname}
                name={x.username}
                desc={x.worktitle}
                company={x.company}
                projectCount={x.project_count}
                key={i}
              />
            </div>
          ) : (
            <MentorCard
              image={x.img}
              username={x.displayname}
              name={x.username}
              desc={x.worktitle}
              company={x.company}
              projectCount={x.project_count}
              key={i}
            />
          )
        )}
      </div>

      {isLoadMoreClicked === false ? (
        <Col
          className="text-center"
          style={{ margin: isMobile ? "20px 0" : "80px 0px 0px" }}
        >
          <DGButton
            variant="tertiary"
            size="medium"
            onClick={() => {
              setIsLoadMoreClicked(true);
              fetchUsers();
            }}
          >
            Daha Fazla Yükle
          </DGButton>
        </Col>
      ) : null}
    </Container>
  );
};

export default Mentors;
