import { useCallback, useEffect, useState } from "react";
import { Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import DGButton from "../components/Buttons/DGButton";
import FileInput from "../components/FileInput/FileInput";
import DGInput from "../components/Inputs/DGInput";
import { ReactComponent as ImageAdd } from "../public/images/Image-Add.svg";
import { ReactComponent as Edit } from "../public/icons/Edit.svg";
import { ReactComponent as Remove } from "../public/icons/Remove.svg";
import { ReactComponent as PlusCircle } from "../public/icons/plus-circle.svg";
import DGModal from "../components/Modals/DGModal";
import useIsMobile from "../public/is-Mobile";

const AcademicPaperEdit = () => {
  const isMobile = useIsMobile();
  const [images, setImages] = useState([]);
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.map((file) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        setImages((prevState) => [
          ...prevState,
          { id: "42", src: e.target.result },
        ]);
      };
      reader.readAsDataURL(file);
      return file;
    });
  }, []);

  const [deleteModalShow, setDeleteModalShow] = useState(false);

  const data = {
    file: "Dosya.pdf",
    title: "MSKU Bodrum Güzel Sanatlar Fakültesi Uluslar",
    desc: "Proin mollis molestie iaculis. Quisque pulvinar nisi tellus, non malesuada orci finibus vitae. Nulla viverra efficitur ex eu rhoncus. Morbi eu lacinia felis. Donec ultrices, metus sit amet vestibulum dignissim, felis velit elementum sem, sagittis aliquam nibh ante non nisl. Quisque ac auctor massa. Duis at quam arcun quis porta.",
    authors: [
      { name: "Hakan Ertan", email: "hakan@designgost.com" },
      { name: "Emre Ertan", email: "emre@designgost.com" },
    ],
    year: "2019",
    month: "Ağustos",
  };

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          background: "#F0EDF6",
          width: "100%",
          zIndex: 100,
          position: "fixed",
          padding: isMobile ? "18px 20px" : "18px 0",
        }}
      >
        <Container>
          <Row>
            <Col xs className="f-label">
              <div style={{ fontWeight: 700, marginBottom: 8 }}>
                Düzenlenen yazı:
              </div>
              <div>
                MSKU Bodrum Güzel Sanatlar Fakültesi Uluslararası Sanat
                Sempozyumu
              </div>
            </Col>
            <Col xs="auto">
              <DGButton variant="dg-primary" size="sm" type="submit">
                Kaydet
              </DGButton>
            </Col>
          </Row>
        </Container>
      </div>
      <Container
        style={{
          padding: isMobile ? "120px 20px 30px" : "140px 0px 100px",
          display: "flex",
          flexDirection: "column",
          gap: 40,
          maxWidth: "500px",
        }}
      >
        <div className="f-h5">Yeni Akademik Yazı yükle</div>
        <Row>
          <div className="f-h6" style={{ marginBottom: 20 }}>
            Akademik yazı
          </div>
          <FileInput
            defaultFile={data.file}
            onDrop={onDrop}
            accept={{ "text/html": [".pdf"] }}
            child={
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 28,
                }}
              >
                <ImageAdd width={30} height={30} stroke="none" />
                <div className="f-label">
                  Dosyayı buraya sürükleyip bırakabilirsin ya da
                </div>
                <DGButton variant="dg-primary" size="medium">
                  Dosya Yükle
                </DGButton>
              </div>
            }
          />
        </Row>

        <AcademicPaperAuthors data={data.authors} />

        <DGInput title="Akademik yazı başlığı" defaultValue={data.title} />

        <Row>
          <DGInput
            title="Akademik yazı özeti"
            isLong={true}
            maxLength={500}
            defaultValue={data.desc}
          />
          <div className="f-label-s" style={{ color: "var(--neutral500)" }}>
            Yazı özeti için maksimum 500 karakter kullanabilirsin
          </div>
        </Row>

        <Row style={{ gap: "13px 20px" }}>
          <Col xs="auto">
            <DGInput title="Yıl" defaultValue={data.year} />
          </Col>

          <Col xs="auto">
            <DGInput title="Ay" defaultValue={data.month} />
          </Col>
          <Row className="f-label-s" style={{ color: "var(--neutral500)" }}>
            Tezin yayınlanma tarihi
          </Row>
        </Row>

        <div
          className="f-label"
          style={{
            display: "flex",
            gap: 18,
            flexDirection: "column",
            fontWeight: 700,
          }}
        >
          Akademik Yazıyi sil
          <div>
            <DGButton
              variant="tertiary"
              size="medium"
              onClick={() => setDeleteModalShow(true)}
            >
              Sil
            </DGButton>
          </div>
        </div>
      </Container>
      <DGModal
        title="Yazıyı silmek istediğinden emin misin?"
        desc="Bu içeriği sildikten sonra tekrar yüklemek istediğinizde Editör onayına yeniden gönderilmesi gerekiyor. Umuyoruz yeni yazılar paylaşmaya devam edersin."
        textButtonText="Vazgeç"
        buttonText="Yazıyı Sil"
        textButtonOnClick={() => {
          setDeleteModalShow(false);
        }}
        buttonOnClick={() => {
          setDeleteModalShow(false);
        }}
        show={deleteModalShow}
        onHide={() => setDeleteModalShow(false)}
      />
    </div>
  );
};

export default AcademicPaperEdit;

const AcademicPaperAuthors = (props) => {
  const [editingIndex, setEditingIndex] = useState(null);
  const [data, setData] = useState(props.data || []);

  const [isAddingNew, setIsAddingNew] = useState(data.length === 0);

  useEffect(() => {
    if (data.length === 0) {
      setIsAddingNew(true);
    }
  }, [data]);

  return (
    <Row style={{ gap: 10 }}>
      <div
        style={{
          fontSize: 17,
          fontWeight: 600,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div>Yazarlar</div>
        {!isAddingNew && editingIndex === null ? (
          <div
            className="f-label"
            style={{
              width: "auto",
              color: "var(--purplePrimary)",
              cursor: "pointer",
              height: "min-content",
              fontWeight: 700,
              display: "flex",
              alignItems: "center",
              gap: 10,
            }}
            onClick={() => setIsAddingNew(true)}
          >
            <PlusCircle /> <div style={{ paddingTop: 3 }}>Yeni Yazar Ekle</div>
          </div>
        ) : null}
      </div>
      {data.map((element, i) => (
        <AcademicPaperAuthorListElement
          isButtonsActive={editingIndex === null && !isAddingNew}
          isEditing={editingIndex === i}
          setEditingIndex={setEditingIndex}
          data={data}
          setData={setData}
          index={i}
          element={element}
        />
      ))}
      {isAddingNew ? (
        <NewAuthor
          data={data}
          setData={setData}
          setIsAddingNew={setIsAddingNew}
        />
      ) : null}
    </Row>
  );
};

const AcademicPaperAuthorListElement = (props) => {
  const handleSubmit = (event) => {
    let elements = event.target.elements;
    const temp = props.data;
    temp[props.index] = {
      name: elements["author-name"].value,
      mail: elements["e-mail"].value,
    };
    props.setData(temp);
    props.setEditingIndex(null);
    event.preventDefault();
  };

  return (
    <div>
      {props.isEditing ? (
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "20px",
              background: "var(--purplePrimary)",
              color: "white",
              borderRadius: "4px 4px 0 0",
            }}
          >
            {props.element.name}
          </div>
          <div
            style={{
              border: "1px solid #C2BECC",
              borderRadius: "0 0 4px 4px",
              borderTop: "none",
              padding: "40px 30px 30px 30px",
            }}
          >
            <Form onSubmit={handleSubmit}>
              <Row style={{ gap: 20, paddingTop: 25 }}>
                <DGInput
                  title="Ad - Soyad"
                  name="author-name"
                  defaultValue={props.element.name}
                />
                <DGInput
                  title="E-posta Adresi"
                  name="e-mail"
                  defaultValue={props.element.mail}
                />
                <div style={{ display: "flex", gap: 24, alignItems: "center" }}>
                  <div
                    className="f-h6"
                    style={{
                      width: "auto",
                      color: "var(--purplePrimary)",
                      cursor: "pointer",
                      height: "min-content",
                    }}
                    onClick={() => props.setEditingIndex(null)}
                  >
                    Vazgeç
                  </div>

                  <DGButton variant="dg-primary" size="medium" type="submit">
                    Kaydet
                  </DGButton>
                </div>
              </Row>
            </Form>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 15,
            padding: "20px",
            background: "var(--blueLight)",
            borderRadius: 4,
          }}
        >
          <div style={{ flexGrow: 1, textAlign: "start" }}>
            {props.element.name}
          </div>
          {props.isButtonsActive === true ? (
            <div style={{ display: "flex", gap: 15 }}>
              <Edit
                width={20}
                height={20}
                onClick={() => props.setEditingIndex(props.index)}
              />
              <Remove
                stroke="black"
                width={20}
                height={20}
                onClick={() => {
                  props.setData([
                    ...props.data.slice(0, props.index),
                    ...props.data.slice(props.index + 1),
                  ]);
                }}
              />
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

const NewAuthor = (props) => {
  const handleSubmit = (event) => {
    let elements = event.target.elements;
    props.setData(
      props.data.concat({
        name: elements["author-name"].value,
        mail: elements["e-mail"].value,
      })
    );
    event.preventDefault();
    props.setIsAddingNew(false);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row style={{ gap: 20, paddingTop: 25 }}>
        <DGInput title="Ad - Soyad" name="author-name" />
        <DGInput title="E-posta Adresi" name="e-mail" />
        <div style={{ display: "flex", gap: 24, alignItems: "center" }}>
          <div
            className="f-h6"
            style={{
              width: "auto",
              color: "var(--purplePrimary)",
              cursor: "pointer",
              height: "min-content",
            }}
            onClick={() => {
              if (props.data.length !== 0) {
                props.setIsAddingNew(false);
              }
            }}
          >
            Vazgeç
          </div>

          <DGButton variant="dg-primary" size="medium" type="submit">
            Kaydet
          </DGButton>
        </div>
      </Row>
    </Form>
  );
};
