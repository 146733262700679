import { Container } from "react-bootstrap";
import useIsMobile from "../../public/is-Mobile";

import Volt from "../../public/images/agencies/1000Volt.svg";
import Acıbadem from "../../public/images/agencies/Acıbadem.svg";
import Borusan from "../../public/images/agencies/Borusan.svg";
import DDB from "../../public/images/agencies/DDB.svg";
import Garanti from "../../public/images/agencies/Garanti.svg";
import Grey from "../../public/images/agencies/Grey.svg";
import Havas from "../../public/images/agencies/Havas.svg";
import IBB from "../../public/images/agencies/IBB.svg";
import McCann from "../../public/images/agencies/McCann.svg";
import Ogilvy from "../../public/images/agencies/Ogilvy.svg";
import SaatchiSaatchi from "../../public/images/agencies/SaatchiSaatchi.svg";
import Sahibinden from "../../public/images/agencies/Sahibinden.svg";
import TBWA from "../../public/images/agencies/TBWA.svg";
import THY from "../../public/images/agencies/THY.svg";
import VML from "../../public/images/agencies/VML.svg";
import WiedenKennedy from "../../public/images/agencies/WiedenKennedy.svg";

import { useTranslation } from "react-i18next";

const Agencies = () => {
  const isMobile = useIsMobile();
  const { t } = useTranslation("home");

  const agenciesList = [
    Volt,
    Acıbadem,
    Borusan,
    DDB,
    Garanti,
    Grey,
    Havas,
    IBB,
    McCann,
    Ogilvy,
    SaatchiSaatchi,
    Sahibinden,
    TBWA,
    THY,
    VML,
    WiedenKennedy,
  ];

  return (
    <div
      className="text-center"
      style={{ padding: isMobile ? "24px 0px" : "64px 0" }}
    >
      <Container className="f-h5" style={{ marginBottom: "45px" }}>
        {t("agencies_title")}
      </Container>

      <div className="inner">
        <div className="wrapper">
          <section style={{ "--speed": `${50}s` }}>
            {agenciesList.map((x, i) => (
              <div className="image" key={i}>
                <img src={x} alt="" />
              </div>
            ))}
          </section>
          <section style={{ "--speed": `${50}s` }}>
            {agenciesList.map((x, i) => (
              <div className="image" key={i}>
                <img src={x} alt="" />
              </div>
            ))}
          </section>
          <section style={{ "--speed": `${50}s` }}>
            {agenciesList.map((x, i) => (
              <div className="image" key={i}>
                <img src={x} alt="" />
              </div>
            ))}
          </section>
        </div>
      </div>
    </div>
  );
};

export default Agencies;