import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";

//CSS
import "./personal-info.css";

// Import Country List
import { countryList } from "../../data/countryList.js";

export const PersonalInfo = (props) => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const [name, setName] = useState(props.personalInfo.name);
  const [email, setEmail] = useState(props.personalInfo.email);
  const [username, setUsername] = useState(props.personalInfo.username);
  const [tel, setTel] = useState(props.personalInfo.tel);
  const [country, setCountry] = useState(props.personalInfo.country);
  const [city, setCity] = useState(props.personalInfo.city);
  const [website, setWebsite] = useState(props.personalInfo.website);

  // Data DB'ye gonderilmeli
  const onSubmit = (data) => {
    props.setEditable(false);
    reset();
  };

  if (!props.editable) {
    return (
      <div className="personal-info">
        <div className="personal-info__image">
          <img
            className="personal-info__image__self"
            src={
              props.personalInfo.image === ""
                ? "/utils/new-user-image.svg"
                : props.personalInfo.image
            }
            alt="user"
          />
        </div>
        <div className="personal-info__text">
          <h4 className="personal-info__text__name f-h4">{name}</h4>
          <p className="personal-info__text__country f-label">{country}</p>
          <p className="personal-info__text_email f-label">{email}</p>
        </div>
      </div>
    );
  } else {
    return (
      <form
        className="personal-info personal-info--alt"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="personal-info__image__card">
          <div>
            <label
              className="personal-info__image personal-info__image--alt"
              htmlFor="image"
            >
              <img
                className="personal-info__image__self"
                src={props.personalInfo.image}
                alt="user"
              />
            </label>
            <input
              type="file"
              id="image"
              accept="image/*"
              hidden
              onChange={(e) => {
                if (e.target.files[0].size > 1 * 1024 * 1024) {
                  alert("Fotoğraf boyutu 1MB'ı geçmemelidir");
                  e.target = null;
                } else {
                }
              }}
            />
          </div>
          <div className="personal-info__image__card__copy">
            <p className="f-label">
              Ustaların bir illüstrasyon yerine kendi fotoğrafını yüklemesini
              tavsiye ederiz.
            </p>
            <p className="f-label-s">
              Profil görseli en fazla 1 mb ve kare ölçüde olmalıdır.
            </p>
          </div>
          <img
            className="personal-info__image__card__icon"
            src="/utils/arrow-red.svg"
            alt="right arrow red coloured"
          />
          <div className="personal-info__image__card__example f-label-s">
            Örnek:
            <img src="/example-user.png" alt="example user image" />
          </div>
        </div>
        <div className="personal-info__input">
          <label>Ad - Soyad</label>
          <input
            value={name}
            {...register("fName")}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="personal-info__input">
          <label>E-Posta adresi</label>
          <input
            value={email}
            {...register("fEmail")}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="personal-info__input">
          <label>Kullanıcı adı</label>
          <input
            value={username}
            {...register("fUsername")}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="personal-info__input">
          <label>Telefon numarası</label>
          <input
            value={tel}
            {...register("fTel")}
            onChange={(e) => setTel(e.target.value)}
          />
        </div>
        <div className="personal-info__input__wrapper">
          <div className="personal-info__input">
            <label>
              Ülke:<sup style={{ color: "var(--redPrimary)" }}>*</sup>
            </label>
            <Controller
              control={control}
              name="fCountry"
              render={({ field: { onChange, value, name, ref } }) => (
                <Select
                  className="personal-info__input__dropdown"
                  classNamePrefix="personal-info__input__dropdown"
                  inputRef={ref}
                  defaultValue={countryList[0]}
                  value={countryList.find((c) => c.value === value)}
                  name={name}
                  options={countryList}
                  required={true}
                  onChange={(selectedOption) => {
                    onChange(selectedOption.label);
                  }}
                />
              )}
            />
          </div>
          <div className="personal-info__input">
            <label>
              Şehir:<sup style={{ color: "var(--redPrimary)" }}>*</sup>
            </label>
            <input
              value={city}
              {...register("fCity", { required: "true" })}
              onChange={(e) => setCity(e.target.value)}
            />
          </div>
        </div>
        <div className="personal-info__input">
          <label>Web Sayfanız</label>
          <input
            placeholder={website}
            {...register("fWebsite")}
            onChange={(e) => setWebsite(e.target.value)}
          />
        </div>

        <div className="personal-info__footer">
          <button
            className="personal-info__footer__btn personal-info__footer__btn--decline"
            onClick={(e) => {
              e.preventDefault();
              reset();
              props.setEditable(false);
            }}
          >
            <span className="f-h6">Vazgeç</span>
          </button>
          <button
            className="personal-info__footer__btn personal-info__footer__btn--submit"
            type="submit"
          >
            <span className="f-h6">Kaydet</span>
          </button>
        </div>
      </form>
    );
  }
};
