import { Col, Container, Image, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import DGButton from "../../components/Buttons/DGButton";
import ProjectCard from "../../components/Cards/ProjectCard";
import MentorCard from "../../components/Cards/Mentor/MentorCard";
import { ReactComponent as EmailIcon } from "../../public/icons/Email.svg";
import useIsMobile from "../../public/is-Mobile";
import MobileMentorCard from "../../components/Cards/Mentor/MobileMentorCard";
import { useState } from "react";
import ProfileLayout from "../../layout/ProfileLayout";
import AboutView from "../../components/Profile/AboutView";

const MenteeProfile = () => {
  let { username } = useParams();
  const isMobile = useIsMobile();

  const [selectedTab, setSelectedTab] = useState("about");

  const data = {};

  const tabs = [
    { key: "about", title: "Hakkımda" },
    { key: "projects", title: "Projeler" },
    { key: "mentors", title: "Ustalarım" },
    {
      key: "contents",
      title: "İçerikler",
      subPages: [
        { key: "blog", title: "Blog" },
        { key: "podcast", title: "Podcast" },
        { key: "videos", title: "Eğitim Videoları" },
        { key: "academic", title: "Akademik Yazılar" },
      ],
    },
  ];

  return (
    <ProfileLayout
      data={data?.user}
      tabs={tabs}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      activePage={
        selectedTab === "mentors" ? (
          <MentorsTab />
        ) : selectedTab === "about" ? (
          <AboutView data={data?.user} />
        ) : (
          <ProjectsTab />
        )
      }
    />
  );
};

const ProjectsTab = () => {
  const isMobile = useIsMobile();
  return (
    <Container
      style={{
        paddingTop: isMobile ? "30px" : "50px",
        paddingBottom: isMobile ? "20px" : "100px",
      }}
    >
      <div
        style={{
          display: "grid",
          gridTemplateColumns: isMobile ? "auto auto" : "auto auto auto",
          gap: isMobile ? 10 : 30,
        }}
      >
        {[...Array(9)].map((x, i) => (
          <ProjectCard image="https://trtrades.com/wp-content/uploads/2021/09/Gray-Box.jpeg" />
        ))}
      </div>
      <Col
        className="text-center"
        style={{ marginTop: isMobile ? "26px" : "60px" }}
      >
        <DGButton variant="tertiary" size="medium">
          Daha Fazla Yükle
        </DGButton>
      </Col>
    </Container>
  );
};

const MentorsTab = () => {
  const isMobile = useIsMobile();
  return (
    <Container
      style={{
        paddingTop: isMobile ? "30px" : "50px",
        paddingBottom: isMobile ? "20px" : "100px",
      }}
    >
      <div
        style={{
          display: "grid",
          gridTemplateColumns: isMobile ? "auto auto" : "auto auto auto",
          gap: isMobile ? 10 : 30,
        }}
      >
        {[...Array(5)].map((x, i) =>
          isMobile ? (
            <MobileMentorCard
              image="https://www.designgost.com/img/4e312348-8be8-4a45-ab83-6a5b4fb99a35/hakan-ertan.jpg?fm=webp&fit=max&crop=1350%2C1350%2C0%2C0&w=200&s=ee609c601fab0ec9585be45177fa5a4b"
              name="Hakan Ertan"
              desc="Art Director / Founder"
              company="DesignGost"
              key={i}
            />
          ) : (
            <MentorCard
              image="https://www.designgost.com/img/4e312348-8be8-4a45-ab83-6a5b4fb99a35/hakan-ertan.jpg?fm=webp&fit=max&crop=1350%2C1350%2C0%2C0&w=200&s=ee609c601fab0ec9585be45177fa5a4b"
              name="Hakan Ertan"
              desc="Art Director / Founder"
              company="DesignGost"
              key={i}
            />
          )
        )}
      </div>
    </Container>
    // <Container
    //   style={{
    //     padding: isMobile ? "30px 0 16px" : "50px 0 100px",
    //   }}
    // >
    //   <Col className="f-h5" style={{ padding: "0px 20px " }}>
    //     Güncel Ustalarım
    //   </Col>
    //   <div
    //     style={{
    //       display: "grid",
    //       gridTemplateColumns: isMobile ? "repeat(1, 1fr)" : "repeat(3, 1fr)",
    //       gap: isMobile ? 0 : 30,
    //       marginTop: isMobile ? "7px" : "36px",
    //     }}
    //   >
    //     {[...Array(2)].map((x, i) =>
    //       isMobile ? (
    //         <MobileMentorCard
    //           image="https://www.designgost.com/img/4e312348-8be8-4a45-ab83-6a5b4fb99a35/hakan-ertan.jpg?fm=webp&fit=max&crop=1350%2C1350%2C0%2C0&w=200&s=ee609c601fab0ec9585be45177fa5a4b"
    //           name="Hakan Ertan"
    //           desc="Art Director / Founder"
    //           company="DesignGost"
    //           key={i}
    //         />
    //       ) : (
    //         <MentorCard
    //           image="https://www.designgost.com/img/4e312348-8be8-4a45-ab83-6a5b4fb99a35/hakan-ertan.jpg?fm=webp&fit=max&crop=1350%2C1350%2C0%2C0&w=200&s=ee609c601fab0ec9585be45177fa5a4b"
    //           name="Hakan Ertan"
    //           desc="Art Director / Founder"
    //           company="DesignGost"
    //           key={i}
    //         />
    //       )
    //     )}
    //   </div>

    //   <Row style={{ marginTop: isMobile ? "26px" : "70px" }}>
    //     <Col className="f-h5" style={{ padding: "0px 20px " }}>
    //       Tüm Ustalarım
    //     </Col>
    //     <div
    //       style={{
    //         display: "grid",
    //         gridTemplateColumns: isMobile ? "repeat(1, 1fr)" : "repeat(3, 1fr)",
    //         gap: isMobile ? 0 : 20,
    //         marginTop: isMobile ? "7px" : "36px",
    //       }}
    //     >
    //       {[...Array(12)].map((x, i) =>
    //         isMobile ? (
    //           <MobileMentorCard
    //             image="https://www.designgost.com/img/4e312348-8be8-4a45-ab83-6a5b4fb99a35/hakan-ertan.jpg?fm=webp&fit=max&crop=1350%2C1350%2C0%2C0&w=200&s=ee609c601fab0ec9585be45177fa5a4b"
    //             name="Hakan Ertan"
    //             desc="Art Director / Founder"
    //             company="DesignGost"
    //             key={i}
    //           />
    //         ) : (
    //           <MentorCard
    //             image="https://www.designgost.com/img/4e312348-8be8-4a45-ab83-6a5b4fb99a35/hakan-ertan.jpg?fm=webp&fit=max&crop=1350%2C1350%2C0%2C0&w=200&s=ee609c601fab0ec9585be45177fa5a4b"
    //             name="Hakan Ertan"
    //             desc="Art Director / Founder"
    //             company="DesignGost"
    //             key={i}
    //           />
    //         )
    //       )}
    //     </div>
    //     <Col
    //       className="text-center"
    //       style={{ marginTop: isMobile ? "16px" : "80px" }}
    //     >
    //       <DGButton variant="tertiary" size="medium">
    //         Daha Fazla Yükle
    //       </DGButton>
    //     </Col>
    //   </Row>
    // </Container>
  );
};

export default MenteeProfile;
