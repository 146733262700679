import { Button } from "react-bootstrap";
import { ReactComponent as CloseIcon } from "../../public/icons/Close-X.svg";
import "./button.css";

const CloseButton = (props) => {
  return (
    <Button variant="dg-close" {...props}>
      <CloseIcon width={8} height={8} style={{marginTop: -2}}/>
    </Button>
  );
};

export default CloseButton;
