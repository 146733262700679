import { Col, Form, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ReactComponent as Onaylandı } from "../../public/images/Onaylandı.svg";
import { ReactComponent as GelistirmekGerekiyor } from "../../public/images/Gelistirmek-Gerekiyor.svg";
import useIsMobile from "../../public/is-Mobile";
import "./projectCard.css";

const ProjectCard = (props) => {
  const isMobile = useIsMobile();
  return (
    <div
      className="card-project boxed-card"
      style={{ width: props.width || "auto" }}
    >
      <Link
        to={`/projeler/${props?.data.project_id}`}
        style={{ position: "relative" }}
      >
        <Image src={props.data?.image} className="project-image" />
        <div className="card-content">
          <Row style={{ paddingTop: 12, color: "var(--black)" }}>
            <Col>
              <Row className="f-label" style={{ flex: "1", fontWeight: 700 }}>
                {props.data?.title}
              </Row>
              <Row
                className="text-center"
                style={{
                  alignItems: "center",

                  marginTop: isMobile ? 8 : 12,
                }}
              >
                <Image
                  roundedCircle={true}
                  src={props.data?.user_image}
                  style={{ width: "20px", height: "20px", marginRight: "8px", objectFit: "cover"}}
                />
                <Col xs className="f-label-s">
                  <Row>{props?.data.user_name}</Row>
                </Col>
              </Row>
            </Col>
            {props.isWaitingApprove === true ? null : isMobile ? (
              <Col
                xs="auto"
                style={{
                  display: "flex",
                  gap: 8,
                  position: "absolute",
                  right: 8,
                  top: 8,
                }}
              >
                {props.data?.status == "improve" ? (
                  <div
                    style={{
                      background: "var(--greenPrimary)",
                      borderRadius: "4px",
                      padding: "0 5px ",
                    }}
                  >
                    <GelistirmekGerekiyor
                      width={14}
                      height={14}
                      stroke="white"
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      background: "var(--greenPrimary)",
                      borderRadius: "4px",
                      padding: "0 5px ",
                    }}
                  >
                    <Onaylandı width={14} height={14} stroke="white" />
                  </div>
                )}

                <Col
                  xs="auto"
                  className="version-view"
                  style={{
                    background: "var(--bluePrimary)",
                    borderColor: "var(--bluePrimary)",
                    color: "white",
                  }}
                >
                  {/* <span>V{props.data?.images.length}</span> */}
                </Col>
              </Col>
            ) : (
              <Col
                xs="auto"
                style={{
                  display: "flex",
                  gap: 8,
                }}
              >
                {props.data?.status == "improve" ? (
                  <GelistirmekGerekiyor
                    width={24}
                    height={24}
                    stroke="var(--redPrimary)"
                  />
                ) : (
                  <Onaylandı
                    width={24}
                    height={24}
                    stroke="var(--greenPrimary)"
                  />
                )}

                <Col xs="auto" className="version-view">
                  <span>V{props.data?.version}</span>
                </Col>
              </Col>
            )}
          </Row>
        </div>
        {props.isWaitingApprove === true ? (
          <Row
            className="f-label-m"
            style={{
              background: "var(--neutral50)",
              padding: "13px 24px",
              fontWeight: 700,
              justifyContent: "space-between",
              flexWrap: "nowrap",
              alignItems: "center",
            }}
          >
            <Col xs>Çıraklık Talebini Onayla</Col>
            <Col xs="auto">
              <Form.Check
                label={null}
                type="check"
                className="dg-radio-button"
                style={{ paddingLeft: 0, width: "min-content" }}
              />
            </Col>
          </Row>
        ) : null}
      </Link>
    </div>
  );
};

export default ProjectCard;
