import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import DGButton from "../Buttons/DGButton";
import DGInput from "../Inputs/DGInput";

import { ReactComponent as Edit } from "../../public/icons/Edit.svg";
import { ReactComponent as Remove } from "../../public/icons/Remove.svg";
import { ReactComponent as PlusCircle } from "../../public/icons/plus-circle.svg";
import { ReactComponent as Academy } from "../../public/icons/Academy.svg";
import { ReactComponent as School } from "../../public/icons/School.svg";

const EducationsView = (props) => {
  const [editingIndex, setEditingIndex] = useState(null);
  const [data, setData] = useState(props.educations || []);
  const [isAddingNew, setIsAddingNew] = useState(false);

  return (
    <Row
      style={{
        gap: 10,
        padding: 30,
      }}
    >
      <div
        style={{
          fontSize: 17,
          fontWeight: 600,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div className="f-h4">
          Eğitim
          <div
            className="f-label-l"
            style={{ color: "var(--black)", marginTop: 10 }}
          >
            Eğitim bilgilerini ekle.
          </div>
        </div>

        {!isAddingNew && editingIndex === null ? (
          <div
            className="f-label"
            style={{
              width: "auto",
              color: "var(--neutral300)",
              cursor: "pointer",
              height: "min-content",
              fontWeight: 700,
              display: "flex",
              alignItems: "center",
              gap: 10,
            }}
            onClick={() => setIsAddingNew(true)}
          >
            <PlusCircle /> <div style={{ paddingTop: 3 }}>Yeni Eğitim Ekle</div>
          </div>
        ) : null}
      </div>
      {data.map((element, i) => (
        <ListElement
          isButtonsActive={editingIndex === null && !isAddingNew}
          isEditing={editingIndex === i}
          setEditingIndex={setEditingIndex}
          data={data}
          setData={setData}
          index={i}
          element={element}
        />
      ))}
      {isAddingNew ? (
        <NewElement
          data={data}
          setData={setData}
          setIsAddingNew={setIsAddingNew}
        />
      ) : null}
    </Row>
  );
};

export default EducationsView;

const ListElement = (props) => {
  const handleSubmit = (event) => {
    let elements = event.target.elements;
    const temp = props.data;
    temp[props.index] = {
      school: elements["school"].value,
      major: elements["major"].value,
      graduation: elements["graduation"].value,
    };

    props.setData(temp);
    props.setEditingIndex(null);
    event.preventDefault();
  };

  return (
    <div>
      {props.isEditing ? (
        <Form onSubmit={handleSubmit}>
          <Row style={{ gap: 20, paddingTop: 25 }}>
            <DGInput
              title="Okul"
              name="school"
              placeholder="Okul"
              defaultValue={props.element.school_id}
              required
              withSuggestion={true}
              icon={<School width={24} />}
            />

            <Row style={{ gap: 20 }}>
              <Col>
                <DGInput
                  title="Bölüm"
                  name="major"
                  placeholder="Örnek: Microsoft"
                  defaultValue={props.element.major}
                  required
                  icon={<Academy width={24} />}
                />
              </Col>
              <Col xs={2}>
                <DGInput
                  title="Mezuniyet Yılı"
                  name="graduation"
                  placeholder="Örnek: 2002"
                  defaultValue={props.element.graduation}
                  required
                />
              </Col>
            </Row>
            <div style={{ display: "flex", gap: 24, alignItems: "center" }}>
              <div
                className="f-h6"
                style={{
                  width: "auto",
                  color: "var(--purplePrimary)",
                  cursor: "pointer",
                  height: "min-content",
                }}
                onClick={() => props.setEditingIndex(null)}
              >
                Vazgeç
              </div>

              <DGButton variant="dg-primary" size="medium" type="submit">
                Kaydet
              </DGButton>
            </div>
          </Row>
        </Form>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 15,
            padding: "20px",
            borderRadius: 4,
          }}
        >
          <Row style={{ flexGrow: 1 }}>
            <div>{props.element.school_id}</div>
            <div>{props.element.major}</div>
            <div>{props.element.graduation}</div>
          </Row>
          {props.isButtonsActive === true ? (
            <div style={{ display: "flex", gap: 15 }}>
              <Edit
                width={20}
                height={20}
                onClick={() => props.setEditingIndex(props.index)}
              />
              <Remove
                stroke="black"
                width={20}
                height={20}
                onClick={() => {
                  props.setData([
                    ...props.data.slice(0, props.index),
                    ...props.data.slice(props.index + 1),
                  ]);
                }}
              />
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

const NewElement = (props) => {
  const [query, setQuery] = useState(null);
  const data = {};

  const handleSubmit = (event) => {
    let elements = event.target.elements;
    props.setData(
      props.data.concat({
        school: elements["school"].value,
        major: elements["major"].value,
        graduation: elements["graduation"].value,
      })
    );
    event.preventDefault();
    props.setIsAddingNew(false);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row style={{ gap: 20, paddingTop: 25 }}>
        <DGInput
          title="Okul"
          name="school"
          placeholder="Okul"
          required
          icon={<School width={24} />}
          onChange={(element) => {
            setQuery(element.target.value);
          }}
          suggestionData={data?.schools}
          withSuggestion={true}
        />

        <Row style={{ gap: 20 }}>
          <Col>
            <DGInput
              title="Bölüm"
              name="major"
              placeholder="Örnek: Microsoft"
              required
              icon={<Academy width={24} />}
            />
          </Col>
          <Col xs={2}>
            <DGInput
              title="Mezuniyet Yılı"
              name="graduation"
              placeholder="Örnek: 2002"
              required
            />
          </Col>
        </Row>

        <div style={{ display: "flex", gap: 24, alignItems: "center" }}>
          <div
            className="f-h6"
            style={{
              width: "auto",
              color: "var(--purplePrimary)",
              cursor: "pointer",
              height: "min-content",
            }}
            onClick={() => {
              props.setIsAddingNew(false);
            }}
          >
            Vazgeç
          </div>

          <DGButton variant="dg-primary" size="medium" type="submit">
            Kaydet
          </DGButton>
        </div>
      </Row>
    </Form>
  );
};
