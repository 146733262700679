import React, { useState } from "react";
import { Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import DGButton from "../../components/Buttons/DGButton";
import DGInput from "../../components/Inputs/DGInput";

// Utils
import RegisterLayout from "../../layout/RegisterLayout";
import { forgotPassword } from "../../Service";

const ForgotPassword = () => {
  const navigate = useNavigate();

  const [hasError, setHasError] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target),
      formDataObj = Object.fromEntries(formData.entries());

    performForgotPassword(formDataObj.email);
  };

  const performForgotPassword = async (email) => {
    try {
      const request = await forgotPassword(email);
      if (request.response.status >= 300) {
        setHasError(true);
      }
    } catch (error) {
      console.error("Error fetching assets:", error.message);
    }
  };

  return (
    <RegisterLayout isLogin={true}>
      <Row style={{ maxWidth: 440, gap: 45 }}>
        <Row style={{ gap: 20 }}>
          <h5 className="f-h5" style={{ fontWeight: 700 }}>
            Şifremi Unuttum
          </h5>
          <div className="f-label">
            Aşağıdaki kutulara bilgilerinizi giriniz
          </div>
        </Row>

        <Form
          onSubmit={onSubmit}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 18,
          }}
        >
          <DGInput name="email" title="E-posta adresi" />

          <Row style={{ justifyContent: "space-between" }}>
            <DGButton
              variant="dg-primary"
              size="medium"
              style={{ width: "fit-content", marginTop: 16 }}
              type="submit"
            >
              E-posta Onayı Gönder
            </DGButton>
          </Row>
        </Form>
      </Row>
    </RegisterLayout>
  );
};

export default ForgotPassword;
