import { Col, Row } from "react-bootstrap";
import BaseAboutSectionView from "./BaseAboutSectionView";

import { ReactComponent as Awards } from "../../../public/icons/Awards.svg";

const AwardsSection = (props) => {
  return (
    <BaseAboutSectionView title="Ödüller">
      {props.data?.map((element, i) => (
        <AwardView
          key={i}
          competitionName={element.competition_name}
          projectName={element.project_name}
          competitionYear={element.competition_year}
          degree={element.degree}
          projectDescription={element.description}
          projectWebsite={element.project_website}
        />
      ))}
    </BaseAboutSectionView>
  );
};

const AwardView = ({
  key,
  competitionName,
  projectName,
  competitionYear,
  degree,
  projectDescription,
  projectWebsite,
}) => {
  return (
    <Row className="f-label" style={{ gap: 8 }} key={key}>
      <Col xs="auto">
        <Awards width={20} stroke={"var(--neutral300)"} />
      </Col>
      <Col xs style={{ display: "flex", gap: 8, flexDirection: "column" }}>
        <div style={{ fontWeight: 700 }}>{competitionName}</div>
        <div>
          {projectName}
          <br />
          Derece: {degree}
        </div>
        <div
          style={{
            color: "var(--neutral300)",
          }}
        >
          {competitionYear}
        </div>
        <div
          style={{
            marginTop: "24px",
            fontWeight: 700,
          }}
        >
          Açıklama:
        </div>
        <div>{projectDescription}</div>
        <a
          target="_blank"
          href={projectWebsite}
          rel="noreferrer"
          style={{
            marginTop: "12px",
            fontWeight: 600,
          }}
        >
          Projeye Gözat
        </a>
      </Col>
    </Row>
  );
};

export default AwardsSection;
