import { useCallback, useEffect, useState } from "react";
import { Col, Container, Form, Image, Row } from "react-bootstrap";

import { ReactComponent as Remove } from "../../public/icons/Remove.svg";
import DGButton from "../Buttons/DGButton";
import DGInput from "../Inputs/DGInput";
import useIsMobile from "../../public/is-Mobile";
import LanguagesView from "../AccountForm/LanguagesView";

import FileInput from "../FileInput/FileInput";
import { SubmissionField } from "../../layout/SubmissionField";
import { PersonalInfo } from "../PersonalInfo/personalInfo";
import EducationsView from "../AccountForm/EducationsView";
import ExperiencesView from "../AccountForm/ExperiencesView";
import AwardsView from "../AccountForm/AwardsView";
import WorkshopsView from "../AccountForm/WorkshopsView";

const ProfileSettingsTab = (props) => {
  const isMobile = useIsMobile();

  return (
    <Container
      style={{
        padding: isMobile ? "20px 20px 30px" : "50px 0 100px",
      }}
    >
      <FormView userType={props.userType} />
    </Container>
  );
};

export default ProfileSettingsTab;

const FormView = (props) => {
  // Edit button states
  const [editableUserInfo, setEditableUserInfo] = useState(true);

  // User data DB'den gelmeli
  /*
            TODO Gelen data'nin icerisinde array olarak barinan alanlarin (expertises, education vb.) 0'dan buyuk veya esit olup olmadigi bilgisi <SubmissionField /> parentina boolean prop olarak gonderilmeli.
            eger gelen deger 0'dan buyuk ise tasarimdaki 'Yeni ... ekle' butonu gorunecektir. (ornek olarak, Education'i kapsayan submission field parentindaki isEmpty'i true yap)
        */

  // User Data States
  const [expertises, setExpertises] = useState([
    {
      name: "Sanat Yönetmeni",
      isSelected: false,
    },
    {
      name: "Yaratıcı Yönetmen",
      isSelected: true,
    },
    {
      name: "Illustrator",
      isSelected: true,
    },
    {
      name: "Motion Designer",
      isSelected: false,
    },
    {
      name: "3D Tasarımcı",
      isSelected: false,
    },
    {
      name: "UI/UX Designer",
      isSelected: false,
    },
    {
      name: "Grafik Tasarımcı",
      isSelected: false,
    },
    {
      name: "Dijital Ürün Tasarımcısı",
      isSelected: false,
    },
  ]);
  const [education, setEducation] = useState([
    {
      schoolName: "Anadolu Üniversitesi",
      department: "Basın ve Yayın",
      gradYear: 2017,
      id: 0,
    },
    {
      schoolName: "Beykent Üniversitesi",
      department: "Bilgisayar Mühendisliği",
      gradYear: 2023,
      id: 1,
    },
  ]);
  const [experience, setExperience] = useState([
    {
      title: "Creative Director",
      company: "Microsoft",
      startDate: "07-2017",
      endDate: "04-2019",
      showOnProfile: false,
      currentlyWorking: false,
      id: 0,
    },
  ]);
  const [languages, setLanguages] = useState([
    {
      name: "Ingilizce",
      proficiency: "Native speaking proficiency",
      id: 0,
    },
  ]);
  const [awards, setAwards] = useState([
    {
      award: "Cannes Lions",
      year: "2015",
      projectName: "DesignGost web sitesi",
      degree: "Gold Lion",
      desc: "Lorem ipsum dolor sit amed",
      url: "https://google.com",
      id: 0,
    },
    {
      award: "Cannes Lions",
      year: "2016",
      projectName: "DesignGost web sitesi",
      degree: "Gold Lion",
      desc: "Lorem ipsum dolor sit amed",
      url: "https://google.com",
      id: 1,
    },
  ]);
  const [workshops, setWorkshops] = useState([
    {
      name: "GMK Tasarımcılar Sergisi",
      year: "2015",
      workshopName: "DesignGost web sitesi",
      desc: "Lorem ipsum dolor sit amed",
      url: "https://google.com",
      id: 0,
    },
  ]);
  const [formValid, setFormValid] = useState(false);
  const user = {
    personalInfo: {
      image: "https://picsum.photos/200",
      name: "Hakan Ertan",
      username: "DesignGost",
      email: "hakanertan@gmail.com",
      tel: "+90 (532) 000 00 00",
      country: "Türkiye",
      city: "Istanbul",
      website: "https://google.com",
    },
    expertises,
    education,
    experience,
    awards,
    workshops,
    languages,
  };

  const [validPersonalInfo, setValidPersonalInfo] = useState(false);

  // const { upload, id } = useFileUpload();

  // const handleFormSubmit = async () => {
  //   await upload({ file: files[0] });
  // };

  // const [mutateUser] = useMutation(
  //   UpdateUserAvatar(
  //     `https://fscxnujhucsyxchhmzvm.storage.eu-central-1.nhost.run/v1/files/${id}`
  //   )
  // );

  // useEffect(() => {
  //   console.log(id)
  //   if (typeof(id) === "string") {
  //     mutateUser();
  //   }
  // }, [id]);

  // const [images, setImages] = useState([]);
  // const [files, seFiles] = useState([]);

  // const onDrop = useCallback((acceptedFiles) => {
  //   acceptedFiles.map((file) => {
  //     seFiles((prevState) => [...prevState, file]);
  //     const reader = new FileReader();
  //     reader.onload = function (e) {
  //       setImages((prevState) => [
  //         ...prevState,
  //         { id: "42", src: e.target.result },
  //       ]);
  //     };
  //     reader.readAsDataURL(file);
  //     return file;
  //   });
  // }, []);

  return (
    <div>
      <SubmissionField
        title={"Kişisel Bilgiler"}
        desc={""}
        required={true}
        setEditable={setEditableUserInfo}
        editable={editableUserInfo}
        isEmpty={false}
        keyWord=""
      >
        <PersonalInfo
          setEditable={setEditableUserInfo}
          editable={editableUserInfo}
          personalInfo={user.personalInfo}
        />
      </SubmissionField>
      {/* <SubmissionField
          title={"Mesleki uzmanlıklar"}
          desc={"Kendini 3 meslek ile tanımla."}
          required={true}
          setEditable={setEditableExpertises}
          editable={editableExpertises}
          isEmpty={false}
          keyWord="uzmanlık"
        >
          <Expertises
            setEditable={setEditableExpertises}
            editable={editableExpertises}
            expertises={user.expertises}
            setExpertises={setExpertises}
          />
        </SubmissionField> */}
      <div
        style={{ background: "var(--neutral200)", height: 1, margin: "0 30px" }}
      />
      <EducationsView educations={user.educations} />
      <div
        style={{ background: "var(--neutral200)", height: 1, margin: "0 30px" }}
      />
      <ExperiencesView />
      <div
        style={{ background: "var(--neutral200)", height: 1, margin: "0 30px" }}
      />
      {props.userType === "mentor" ? <AwardsView /> : null}
      {props.userType === "mentor" ? (
        <div
          style={{
            background: "var(--neutral200)",
            height: 1,
            margin: "0 30px",
          }}
        />
      ) : null}
      {props.userType === "mentor" ? <WorkshopsView /> : null}
      <div
        style={{ background: "var(--neutral200)", height: 1, margin: "0 30px" }}
      />
      <LanguagesView />
    </div>
  );
};
