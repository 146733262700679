import { getBlogCategories } from "../Service";

const Categories = () => {};

export default Categories;

Categories.fetchDatas = async () => {
  try {

    if (Categories.blogCategories === undefined) {
      const blogReq = await getBlogCategories();
      Categories.blogCategories = blogReq.data;
    }
  } catch (error) {
    console.error("Error fetching assets:", error.message);
  }
};
