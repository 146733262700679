import { Col, Container, Row } from "react-bootstrap";
import useIsMobile from "../../public/is-Mobile";

import EducationsSection from "./ProfileAbout/EducationsSection";
import ExperiencesSection from "./ProfileAbout/ExperiencesSection";
import AwardsSection from "./ProfileAbout/AwardsSection";

import { ReactComponent as WebLink } from "../../public/icons/Web-Link.svg";
import ExhibitionsSection from "./ProfileAbout/ExhibitionsSection";
import LanguagesSection from "./ProfileAbout/LanguagesSection";

const AboutView = (props) => {
  const isMobile = useIsMobile();
  const constants = {
    paddings: isMobile ? "30px 20px 40px" : "50px 0px 100px",
  };

  return (
    <Container
      style={{
        padding: constants.paddings,
      }}
    >
      <div
        style={{
          maxWidth: "650px",
          display: "flex",
          gap: isMobile ? 32 : 24,
          flexDirection: "column",
        }}
      >
        {isMobile ? (
          <Row style={{ width: "100%", gap: 24 }}>
            {props.user?.about ?? "" !== "" ? (
              <div className="f-label">{props.user?.about}</div>
            ) : (
              <div className="f-label" style={{ color: "var(--neutral300)" }}>
                Hakkımda yazısı bulunmamakta.
              </div>
            )}
          </Row>
        ) : null}
        {isMobile ? <Divider /> : null}
        <EducationsSection data={props?.data?.educations} />

        <Divider />

        <ExperiencesSection
          data={props?.data?.experiences}
        />

        <Divider />

        <AwardsSection data={props?.data?.awards} />

        <Divider />

        <ExhibitionsSection data={props?.data?.exhibitions} />

        <Divider />

        <LanguagesSection data={props?.data?.languages} />
      </div>
    </Container>
  );
};

export default AboutView;

const Divider = (props) => {
  return (
    <div style={{ border: "1px solid var(--neutral50)", width: "100%" }} />
  );
};
