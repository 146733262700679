import { Col, Image, Row } from "react-bootstrap";
import { ReactComponent as NeedsImprove } from "../../public/images/Gelistirmek-Gerekiyor.svg";

const ExploreTopCard = ({ image, name, desc }) => {
  return (
    <Row
      className="boxed-card"
      style={{
        height: "107px",
        padding: "20px 20px",
        background: "#FFFFFF",
        border: "1px solid #E3DFED",
        borderRadius: "8px",
        flexDirection: "row",
        gap: "16px",
      }}
    >
      <Col xs="auto">
        <Image
          src={image}
          style={{ width: "83px", height: "67px", borderRadius: "8px" }}
        />
      </Col>
      <Col
        xs
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div className="f-h6">{name}</div>
        <div className="f-label-s" style={{ color: "var(--black)" }}>
          {desc}
        </div>
      </Col>
      <Col
        xs="auto"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "flex-end",
          color: "var(--black)",
        }}
      >
        <div className="f-label-s">01.35 Saat</div>
        <span
          style={{
            width: "20px",
            height: "20px",
            borderRadius: "4px",
            background: "var(--purplePrimary)",
            padding: "2px 3px 3px 4px",
            stroke: "white",
          }}
        >
          <NeedsImprove width={14} height={14} />
        </span>
      </Col>
    </Row>
  );
};

export default ExploreTopCard;
