import { Overlay, Row, Tooltip } from "react-bootstrap";
import DGButton from "../Buttons/DGButton";

import "./DGTooltip.css"

const DGTooltip = (props) => {
  return (
    <Overlay
      target={props.tooltipRef}
      show={props.show}
      placement={props.placement || "right"}
      onHide={() => props.setShow(!props.show)}
      rootClose={true}
      rootCloseEvent="click"
    >
      <Tooltip className="dg-tooltip">
        <div
          style={{
            fontWeight: 800,
            fontSize: "18px",
            lineHeight: "260x",
            marginBottom: "7px",
          }}
        >
          {props.title}
        </div>
        <div className="f-paragraph">{props.desc}</div>
        <Row
          style={{
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 22,
            marginTop: 35,
          }}
        >
          {(props.textButtonText || null) !== null ? (
            <div
              className="f-h6"
              style={{
                width: "auto",
                color: "var(--purplePrimary)",
                cursor: "pointer",
                height: "min-content",
              }}
              onClick={props.textButtonOnClick}
            >
              {props.textButtonText}
            </div>
          ) : null}
          {(props.buttonText || null) !== null ? (
            <DGButton
              variant="dg-primary"
              size="medium"
              style={{ width: "auto" }}
              onClick={props.buttonOnClick}
            >
              {props.buttonText}
            </DGButton>
          ) : null}
        </Row>
      </Tooltip>
    </Overlay>
  );
};

export default DGTooltip;
