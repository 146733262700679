import { Container } from "react-bootstrap";
import useIsMobile from "../../public/is-Mobile";
import DGButton from "../Buttons/DGButton";
import FeedbackTool from "../../public/images/FeedbackTool.png";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const SendProject = () => {
  const isMobile = useIsMobile();
  const { t } = useTranslation("home");
  const navigate = useNavigate();

  const values = {
    paddings: isMobile ? "40px 20px 00px" : "90px 0 0",
  };

  return (
    <Container
      style={{
        backgroundColor: "var(--yellowPrimary)",
        maxWidth: "100%",
        padding: values.paddings,
      }}
    >
      <Container style={{ textAlign: isMobile ? "start" : "center" }}>
        <div
          className="f-h2"
          dangerouslySetInnerHTML={
            isMobile
              ? { __html: t("send_project_title_mobile") }
              : { __html: t("send_project_title") }
          }
        />
        <div
          className="f-paragraph "
          style={{ margin: "30px auto", maxWidth: 950 }}
          dangerouslySetInnerHTML={{ __html: t("send_project_desc") }}
        />
        <DGButton
          variant="dg-secondary"
          size="medium"
          onClick={() => {
            // TODO: If user logged-in navigate to ustalar
            navigate("/choose-signup");
          }}
        >
          {t("send_project_button_title")}
        </DGButton>
        <div
          style={{
            maxWidth: "880px",
            margin: "auto",
            marginTop: "48px",
          }}
        >
          <img src={FeedbackTool} alt="" style={{ width: "100%" }} />
        </div>
      </Container>
    </Container>
  );
};

export default SendProject;
