import { useNavigate } from "react-router-dom";
import { Col } from "react-bootstrap";
import DGButton from "../Buttons/DGButton";

const HomeCard = (props) => {
  const navigate = useNavigate();
  return (
    <Col
      xs={12}
      lg
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "22px 25px",
        background: "white",
        border: "0px",
        borderRadius: props.borderRadius || "8px",
      }}
    >
      <div
        className="f-h5"
        style={{ color: "var(--black)", marginBottom: "15px" }}
      >
        {props.title}
      </div>
      <div
        style={{
          color: "var(--black)",
          fontSize: "15px",
          marginBottom: "24px",
        }}
      >
        {props.desc}
      </div>

      <div>
        <DGButton
          variant="dg-primary"
          size="sm"
          onClick={() => {
            navigate("/ustalar");
          }}
        >
          Usta Bul
        </DGButton>
      </div>
    </Col>
  );
};

export default HomeCard;
