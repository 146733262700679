import { Form } from "react-bootstrap";
import "./switch.css";

const Switch = (props) => {
  return (
    <div class="form-switch dg-switch">
      <input type="checkbox" class="form-check-input" />
      <label title="" class="form-check-label"></label>
    </div>
  );
};

export default Switch;
