import { useContext } from "react";
import { Toast } from "react-bootstrap";
import { ToastContext } from "../../ContextProvider";
import "./toast.css";

const DGToast = (props) => {
  const { setActive, setVariant, setTitle } = useContext(ToastContext);

  return (
    <div style={{ position: "fixed", bottom: 32, left: 32, zIndex: 99 }}>
      <Toast
        onClose={() => {
          setActive(false);
          setVariant(null);
          setTitle(null);
        }}
        show={props.show}
        style={{
          border: "none",
          boxShadow: "none",
        }}
        animation={true}
        // autohide
      >
        <Toast.Body className={props.variant}>{props.title}</Toast.Body>
      </Toast>
    </div>
  );
};

export default DGToast;
