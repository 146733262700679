import { Tabs } from "react-bootstrap";
import useIsMobile from "../../public/is-Mobile";
import "./tabs.css";

const DGTabs = (props) => {
  const isMobile = useIsMobile();
  return (
    <Tabs
      className={isMobile ? "f-label mobile" : "f-h6"}
      {...props}
    >
      {props.children}
    </Tabs>
  );
};

export default DGTabs;
