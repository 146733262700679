import { Col, Image, Row } from "react-bootstrap";

import { ReactComponent as AddUser } from "../../public/icons/Add-User.svg";
import { ReactComponent as Replied } from "../../public/icons/Replied.svg";
import { ReactComponent as Tick } from "../../public/icons/Tick.svg";

import Icon from "../../public/images/DesignGost-Icon.svg";

import "./messageListElement.css";

const MessageListElement = (props) => {
  return (
    <Row
      className={
        props.status === "selected"
          ? "message-list-element-selected"
          : "message-list-element"
      }
      {...props}
    >
      {props.authors.length > 1 ? (
        <Col xs="auto" style={{ width: "40px", position: "relative" }}>
          {props.authors.map((x, i) => (
            <Image
              src={x.image || Icon}
              roundedCircle={true}
              style={{
                height: "32px",
                width: "32px",
                position: "absolute",
                left: `${i * 5}px`,
                border: "1px solid #FFFFFF",
              }}
            />
          ))}
        </Col>
      ) : (
        <Image
          src={props.authors[0].image || Icon}
          style={{ height: "40px", width: "40px" }}
          roundedCircle={true}
        />
      )}

      <Col xs>
        <Row
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            className="f-label-m"
            style={{
              fontWeight: props.status === "new" ? 700 : 500,
              width: "auto",
              color:
                props.status === "selected"
                  ? "var(--purplePrimary)"
                  : "var(--black)",
            }}
          >
            {props.authors[props.authors.length - 1].name}
          </div>
          <div
            className="f-label-s"
            style={{ color: "var(--neutral300)", width: "auto" }}
          >
            20/09/2022
          </div>
        </Row>
        <Row
          style={{
            flexDirection: "row",
            justifyContent: "space-between",

            gap: "4px",
          }}
        >
          {props.replied ? (
            <Col xs="auto">
              <Replied
                height={12}
                width={12}
                style={{ verticalAlign: "unset" }}
              />
            </Col>
          ) : (props.applyToMentorship || null) === true ? (
            <Col xs="auto">
              <AddUser
                height={12}
                width={12}
                style={{ verticalAlign: "unset" }}
                stroke="var(--purplePrimary)"
              />
            </Col>
          ) : props.status === "approved" ? (
            <Col xs="auto">
              <Tick
                height={12}
                width={12}
                style={{ verticalAlign: "unset" }}
                stroke="var(--greenPrimary)"
              />
            </Col>
          ) : null}

          <Col xs>
            <div className="message-list-content" style={{ width: "auto" }}>
              Merhaba Hakan, benim Alp, tasarım için baldfsadsgadsfjk fads fasd
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default MessageListElement;
