import { useState } from "react";
import { Form, Modal, Row } from "react-bootstrap";
import DGButton from "../Buttons/DGButton";
import DGInput from "../Inputs/DGInput";

const ComplainModal = (props) => {
  let data = [
    "Şüpheli veya spam",
    "Yetişkin içerik",
    "Yanlış bilgi ve dolandırıcılık",
    "Taciz ediyor veya rahatsız ediyor",
    "Şiddet ve zararlı davranış",
    "Haklarımı ihlal ediyor",
    "Diğer",
  ];

  const [selectedIndex, setSelectedIndex] = useState(null);

  const onChangeValue = (event) => {
    if (event.target.id !== "textArea") {
      setSelectedIndex(event.target.id);
    }
  };

  return (
    <Modal
      {...props}
      centered
      style={{ backgroundColor: "rgba(18, 14, 22, 0.85)" }}
    >
      <Modal.Body style={{ padding: "35px 40px 40px" }}>
        <div style={{ fontWeight: 800, fontSize: "18px" }}>Şikayeti bildir</div>
        <div style={{ margin: "15px 0px 20px" }}>
          DesignGost Topluluk Kurallarımızı ihlal eden içerikleri şikayet etmek
          için lütfen bu formu kullanın.
        </div>

        <Row key="radio" onChange={onChangeValue} style={{ gap: 16 }}>
          {data.map((x, i) => (
            <Row style={{ gap: 12 }}>
              <Form.Check
                label={x}
                name="group1"
                type="radio"
                id={`${i}`}
                className="dg-radio-button"
                style={{ paddingLeft: 0 }}
              />
              {selectedIndex === `${i}` ? (
                <DGInput
                  placeholder="Konu hakkında daha detaylı bilgi yazınız"
                  isLong={true}
                />
              ) : null}
            </Row>
          ))}
        </Row>

        <Row
          style={{
            flexDirection: "row",
            alignItems: "center",
            gap: 30,
            marginTop: 40,
          }}
        >
          <DGButton
            variant="dg-primary"
            size="medium"
            style={{ width: "auto" }}
          >
            Gönder
          </DGButton>
          <div
            className="f-h6"
            style={{
              width: "auto",
              color: "var(--purplePrimary)",
              cursor: "pointer",
              height: "min-content",
            }}
            onClick={props.textButtonOnClick}
          >
            Vazgeç
          </div>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ComplainModal;
