import { Link, useNavigate } from "react-router-dom";
import { Col, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useIsMobile from "../../public/is-Mobile";
import DGButton from "../Buttons/DGButton";

import { ReactComponent as GraphicDesigner } from "../../public/icons/mentorCategories/Graphic-Designer.svg";
import { ReactComponent as Illustrator } from "../../public/icons/mentorCategories/Illustrator.svg";
import { ReactComponent as MotionDesigner } from "../../public/icons/mentorCategories/Motion-Designer.svg";
import { ReactComponent as Photographer } from "../../public/icons/mentorCategories/Photographer.svg";
import { ReactComponent as ProductDesigner } from "../../public/icons/mentorCategories/Product-Designer.svg";
import { ReactComponent as ThreeDArtist } from "../../public/icons/mentorCategories/ThreeD-Artist.svg";
import { ReactComponent as UIUXDesigner } from "../../public/icons/mentorCategories/UI-UX-Designer.svg";
import { ReactComponent as VisualDesigner } from "../../public/icons/mentorCategories/Visual-Designer.svg";

const HomeCategories = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { t } = useTranslation("home");

  const data = [
    {
      name: "Graphic Designer",
      count: "93",
      icon: <GraphicDesigner width={20} stroke="white" />,
    },
    {
      name: "Photographer",
      count: "14",
      icon: <Illustrator width={20} stroke="white" />,
    },
    {
      name: "Lead Product Designer",
      count: "16",
      icon: <MotionDesigner width={20} stroke="white" />,
    },
    {
      name: "Illustrator",
      count: "31",
      icon: <Photographer width={20} stroke="white" />,
    },
    {
      name: "UI/UX Designer",
      count: "41",
      icon: <ProductDesigner width={20} stroke="white" />,
    },
    {
      name: "Motion Designer",
      count: "20",
      icon: <ThreeDArtist width={20} stroke="white" />,
    },
    {
      name: "Industrial Designer",
      count: "4",
      icon: <UIUXDesigner width={20} stroke="white" />,
    },
    {
      name: "3D Artist",
      count: "9",
      icon: <VisualDesigner width={20} stroke="white" />,
    },
  ];

  const colors = [
    "var(--bluePrimary)",
    "var(--purplePrimary)",
    "var(--redPrimary)",
    "var(--greenPrimary)",
    "var(--purplePrimary)",
    "var(--redPrimary)",
    "var(--greenPrimary)",
    "var(--bluePrimary)",
  ];

  return (
    <div style={{ background: "var(--neutral50)" }}>
      <Container
        style={{
          padding: isMobile ? "0 0 40px" : "0 0 80px",
          textAlign: isMobile ? "start" : "center",
        }}
      >
        <div
          className="f-h2"
          style={{ padding: isMobile ? "0 20px" : "0" }}
          dangerouslySetInnerHTML={{ __html: t(isMobile ? "mentor_categories_title_mobile" : "mentor_categories_title") }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: isMobile ? "flex-start" : "center",
            flexWrap: "wrap",
            gap: isMobile ? 16 : 24,
            padding: isMobile ? "24px 20px 50px" : "48px 0 64px",
          }}
        >
          {data.map((x, i) => (
            <Link to={"/ustalar?job=" + x.name} key={i}>
              <div className="homepage-chip">
                <Col xs="auto">
                  <div
                    style={{
                      display: "flex",
                      width: 48,
                      height: 48,
                      background: colors[i],
                      borderRadius: 100,
                      justifyContent: "center",
                    }}
                  >
                    {x.icon}
                  </div>
                </Col>
                <Col
                  xs
                  style={{
                    display: "flex",
                    gap: 4,
                    flexDirection: "column",
                    textAlign: "start",
                    justifyContent: "center",
                  }}
                >
                  <div className="f-h6">{x.name}</div>
                  <div className="f-label">
                    {x.count} {t("mentor")}
                  </div>
                </Col>
              </div>
            </Link>
          ))}
        </div>
        <div style={{ padding: isMobile ? "0 20px" : "0" }}>
          <DGButton
            variant="dg-primary"
            size="medium"
            onClick={() => {
              navigate("/ustalar");
            }}
          >
            {t("mentor_categories_button_title")}
          </DGButton>
        </div>
      </Container>
    </div>
  );
};

export default HomeCategories;
