import { Col, Container, Form, Image, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import DGButton from "../components/Buttons/DGButton";
import MenteeCard from "../components/Cards/MenteeCard";
import ProjectCard from "../components/Cards/ProjectCard";

import { useEffect, useState } from "react";

import DGInput from "../components/Inputs/DGInput";
import NotificationsTab from "../components/Profile/NotificationsTab";
import ProfileSettingsTab from "../components/Profile/ProfileSettingsTab";
import ProjectsTab from "../components/Profile/ProjectsTab";
import useIsMobile from "../public/is-Mobile";
import ProfileLayout from "../layout/ProfileLayout";

import { ReactComponent as Setting } from "../public/icons/Setting.svg";
import DGModal from "../components/Modals/DGModal";

const Profile = () => {
  const { hash, key } = useLocation();

  const [selectedTab, setSelectedTab] = useState("activities");

  const isMobile = useIsMobile();
  const user = {};

  const tabs = [
    { key: "activities", title: "Aktiviteler" },
    { key: "projeler", title: "Projeler" },
    {
      key: "contents",
      title: "İçerikler",
      subPages: [
        { key: "blog", title: "Blog" },
        { key: "podcast", title: "Podcast" },
        { key: "videos", title: "Eğitim Videoları" },
        { key: "academic", title: "Akademik Yazılar" },
      ],
    },
    { key: "divider", title: "" },
    {
      key: "settings",
      title: "Profil Ayarları",
      icon: <Setting width={20} height={20} style={{ marginRight: 8 }} />,
    },
  ];

  useEffect(() => {
    if (hash) {
      const section = hash.substring(1);

      if (
        section === "activities" ||
        section === "projeler" ||
        section === "settings" ||
        section === "parola" ||
        section === "bildirimAyarlari" ||
        section === "ciraklikBasvurulari" ||
        section === "deleteAccount"
      ) {
        setSelectedTab(section);
      }
    }
  }, [key, hash]);

  return (
    <ProfileLayout
      isCurrentUser={true}
      data={user}
      tabs={tabs}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      activePage={
        selectedTab === "activities" ? (
          <NotificationsTab />
        ) : selectedTab === "projeler" ? (
          <ProjectsTab />
        ) : selectedTab === "settings" ? (
          <ProfileSettingsTab userType={"mentor"} />
        ) : selectedTab === "parola" ? (
          <PasswordTab />
        ) : selectedTab === "bildirimAyarlari" ? (
          <NotificationSettingsTab />
        ) : selectedTab === "ciraklikBasvurulari" ? (
          <ApplicationsTab />
        ) : selectedTab === "deleteAccount" ? (
          <DeleteAccountTab />
        ) : null
      }
    />
  );
};

const PasswordTab = () => {
  const isMobile = useIsMobile();
  return (
    <Container
      style={{
        padding: isMobile ? "20px 20px 30px" : "50px 0 100px",
      }}
    >
      <Row style={{ maxWidth: 460, gap: isMobile ? 20 : 30 }}>
        <Col className="f-h5">
          Parola
          <div className="f-label-l" style={{ marginTop: isMobile ? 8 : 10 }}>
            Şifreni değiştirebilir veya sıfırlayabilirsin.
          </div>
        </Col>
        <DGInput title="Eski şifre" placeholder="Eski şifreni gir" />
        <DGInput title="Yeni şifre" placeholder="Yeni şifre" />
        <DGInput
          title="Yeni şifre (Tekrar)"
          placeholder="Yeni şifre (Tekrar)"
        />
        <Col style={{ marginTop: isMobile ? "8px" : "20px" }}>
          <DGButton variant="dg-primary" size="medium">
            Şifreni Değiştir
          </DGButton>
        </Col>
      </Row>
    </Container>
  );
};

const ApplicationsTab = () => {
  const isMobile = useIsMobile();
  return (
    <Container
      style={{
        padding: isMobile ? "20px 20px 30px" : "50px 0 100px",
      }}
    >
      <div>
        <Row style={{ gap: isMobile ? 16 : 30 }}>
          <Col className="f-h5">Güncel Çıraklar</Col>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: isMobile
                ? "repeat(3, 1fr)"
                : "repeat(7, 1fr)",

              gap: isMobile ? "20px 10px" : "50px 30px",
            }}
          >
            {[...Array(7)].map((x, i) => (
              <MenteeCard
                image="https://www.designgost.com/img/4e312348-8be8-4a45-ab83-6a5b4fb99a35/hakan-ertan.jpg?fm=webp&fit=max&crop=1350%2C1350%2C0%2C0&w=200&s=ee609c601fab0ec9585be45177fa5a4b"
                name="Hakan"
                surname="Ertan"
              />
            ))}
          </div>
        </Row>

        <Row
          style={{
            gap: isMobile ? 16 : 30,
            marginTop: isMobile ? "36px" : "70px",
          }}
        >
          <Col className="f-h5">Çıraklık talepleri</Col>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: isMobile
                ? "repeat(2, 1fr) "
                : "repeat(3, 1fr)",
              gap: isMobile ? "16px" : "30px",
            }}
          >
            {[...Array(4)].map((x, i) => (
              <ProjectCard />
            ))}
          </div>
        </Row>
      </div>
    </Container>
  );
};

const NotificationSettingsTab = () => {
  const isMobile = useIsMobile();
  return (
    <Container
      style={{
        padding: isMobile ? "20px 20px 30px" : "50px 0 100px",
      }}
    >
      <Row style={{ gap: isMobile ? 20 : 40 }}>
        <Row style={{ gap: isMobile ? 8 : 10 }}>
          <div className="f-h5">E-Posta ve bildirim ayarları</div>
          <div className="f-label-l">
            Aşağıdaki seçenekler üzerinden hangi bildirimleri alıp hangilerini
            alamayacağınızı seçin.
          </div>
        </Row>
        <Row style={{ gap: isMobile ? 16 : 30 }}>
          <div className="f-h5">Bildirimler & Uyarılar</div>
          <Row style={{ gap: isMobile ? 8 : 10 }}>
            <Form.Check
              label={<div className="f-h6">Yeni içerik bildirimleri</div>}
              type="checkbox"
              className="dg-radio-button"
            />
            <div className="f-label">
              DesignGost blog, podcast, eğitim videoları ve canlı yayınlar
            </div>
          </Row>
          <Row style={{ gap: isMobile ? 8 : 10 }}>
            <Form.Check
              label={<div className="f-h6">Çıraklardan haberler</div>}
              type="checkbox"
              className="dg-radio-button"
            />
            <div className="f-label">Her ay sisteme yeni katılan ustalar</div>
          </Row>
          <Row style={{ gap: isMobile ? 8 : 10 }}>
            <Form.Check
              label={<div className="f-h6">Haber bülteni</div>}
              type="checkbox"
              className="dg-radio-button"
            />
          </Row>
          <Row style={{ gap: isMobile ? 8 : 10 }}>
            <Form.Check
              label={<div className="f-h6">Kariyer fırsatları</div>}
              type="checkbox"
              className="dg-radio-button"
            />
            <div className="f-label">
              Güncel iş ilanları hakkında haberdar olun
            </div>
          </Row>
        </Row>
        <div style={{ border: "1px solid #E3DFED" }} />
        <Row style={{ gap: isMobile ? 16 : 30 }}>
          <div className="f-h5">Hesap aktiviteleri</div>
          <Form.Check
            label={<div className="f-label-l">Çıraklık başvuruları</div>}
            type="checkbox"
            className="dg-radio-button"
          />
          <Form.Check
            label={<div className="f-label-l">Çıraktan mesaj geldiğinde</div>}
            type="checkbox"
            className="dg-radio-button"
          />
          <Form.Check
            label={<div className="f-label-l">Çıraktan proje geldiğinde</div>}
            type="checkbox"
            className="dg-radio-button"
          />
          <Form.Check
            label={<div className="f-label-l">Ustalardan mesaj geldiğinde</div>}
            type="checkbox"
            className="dg-radio-button"
          />
        </Row>
      </Row>
    </Container>
  );
};

const DeleteAccountTab = () => {
  const isMobile = useIsMobile();

  let data = [
    "Yeni bir hesabım var",
    "Çok fazla mesaj alıyorum",
    "Üyeliğimden herhangi bir verim alamıyorum",
    "Artık gizlilik endişem var",
    "İstenmeyen iletişim alıyorum",
    "Diğer",
  ];

  const [selectedIndex, setSelectedIndex] = useState(null);
  const [firstModalShow, setFirstModalShow] = useState(false);

  const onChangeValue = (event) => {
    if (event.target.id !== "textArea") {
      setSelectedIndex(event.target.id);
    }
  };

  return (
    <Container
      style={{
        padding: isMobile ? "20px 20px 30px" : "50px 0 100px",
      }}
    >
      <Row style={{ gap: isMobile ? 20 : 40 }}>
        <Row style={{ gap: isMobile ? 8 : 10 }}>
          <div className="f-h5">Hakan, gidiyor olduğunu görmek üzücü!</div>
          <div className="f-label-l">
            Tüm bağlantılarını, mesajlarını ve projelerini kaybetmek üzeresin.
            Hesabını silmek istediğinden emin misin?
          </div>
        </Row>
        <Row style={{ gap: isMobile ? 16 : 30 }}>
          <div className="f-h5">
            Hesabını neden silmek istediğini aşağıda belirtir misin?
          </div>

          <Row
            key="radio"
            onChange={onChangeValue}
            style={{ gap: isMobile ? 10 : 17 }}
          >
            {data.map((x, i) => (
              <Form.Check
                label={x}
                name="group1"
                type="radio"
                id={`${i}`}
                className="dg-radio-button"
                style={{ paddingLeft: 0 }}
              />
            ))}
          </Row>

          <div style={{ fontSize: 15 }}>
            Geri bildiriminizi önemsiyoruz, ancak bu formda gönderilen
            hesabınızla ilgili yorumlara yanıt veremeyeceğimizi lütfen
            unutmayın. Hesabınızla ilgili bir sorunuz veya talebiniz varsa,
            lütfen bize buradan ulaşın ya da Kullanım Koşullarımızı okuyunuz.
          </div>

          <DGInput
            isLong={true}
            placeholder="Konu hakkında daha detaylı bilgi yazınız"
          />

          <div style={{ display: "flex", gap: isMobile ? 16 : 40 }}>
            <DGButton
              variant="dg-primary"
              size="medium"
              onClick={(e) => {
                // props.setIsOnDeleteAccount(false);
              }}
            >
              Ayarlara Geri Dön
            </DGButton>

            <DGButton
              variant="tertiary"
              size="medium"
              onClick={(e) => {
                setFirstModalShow(true);
              }}
            >
              Hesabı Sil
            </DGButton>
          </div>
        </Row>
      </Row>

      <DGModal
        desc="Hesabını silmek istediğine emin misin?"
        textButtonText="Vazgeç"
        buttonText="Hesabı Sil"
        textButtonOnClick={() => setFirstModalShow(false)}
        buttonOnClick={() => setFirstModalShow(false)}
        show={firstModalShow}
        onHide={() => setFirstModalShow(false)}
      />
    </Container>
  );
};

export default Profile;
