import { Button } from "react-bootstrap";
import { ReactComponent as CaretRight } from "../../public/images/caret-right.svg";
import { ReactComponent as CaretLeft } from "../../public/images/caret-left.svg";
import "./button.css";

const SliderButton = (props) => {
  return (
    <Button
      variant={"slider-button-" + (props.variant || "light")}
      onClick={props.onClick}
    >
      {props.next === true ? (
        <CaretLeft width={11} />
      ) : (
        <CaretRight width={11} />
      )}
    </Button>
  );
};

export default SliderButton;
