import { useEffect, useState } from "react";
import {
  Col,
  Container,
  Dropdown,
  Image,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import DGButton from "../../components/Buttons/DGButton";
import ExploreTopCard from "../../components/Cards/ExploreTopCard";
import VideoCard from "../../components/Cards/VideoCard";
import SideMap from "../../components/SideMap";
import useRouterQuery from "../../public/use-query";
import { ReactComponent as Filter } from "../../public/icons/Filter.svg";
import useIsMobile from "../../public/is-Mobile";
import DGTabs from "../../components/Tabs/DGTabs";
import { useNavigate } from "react-router-dom";
import { getVideos } from "../../Service";

const Video = () => {
  let query = useRouterQuery();

  const categories = [
    {
      name: "Tutorials & İpuçları",
      path: "/egitim-videolari?category=Tutorials & İpuçları",
    },
    {
      name: "Tasarım İlkeleri",
      path: "/egitim-videolari?category=Tasarım İlkeleri",
    },
    { name: "Photoshop", path: "/egitim-videolari?category=Tasarım İlkeleri" },
    {
      name: "UI Design System",
      path: "/egitim-videolari?category=UI Design System",
    },
    {
      name: "Programlar & Araçlar",
      path: "/egitim-videolari?category=Programlar & Araçlar",
    },
  ];

  const exploreCategories = [
    { name: "Blog", path: "/blog" },
    { name: "Podcast", path: "/podcast" },
    { name: "Eğitim Videoları", path: "/egitim-videolari" },
    { name: "Akademik Yazılar", path: "/akademik-yazilar" },
  ];

  const fetchData = async () => {
    try {
      const request = await getVideos();
      setVideosData(request);
    } catch (error) {
      console.error("Error fetching assets:", error.message);
    }
  };

  const filters = {
    recommended: "Tavsiye Edilenler",
    topRated: "En Beğenilenler",
    newAdded: "Yeni Eklenenler",
    agencies: "Ajanslar",
  };

  const [videosData, setVideosData] = useState([]);

  const [selectedFilter, setSelectedFilter] = useState("recommended");
  const [selectedTab, setSelectedTab] = useState("featured");
  const [categoryName, setCategoryName] = useState(
    query.get("category") || null
  );

  const isMobile = useIsMobile();

  useEffect(() => {
    setCategoryName(query.get("category") || null);
  }, [query]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      {isMobile ? (
        <VideoMobile categories={exploreCategories} />
      ) : (
        <Container style={{ paddingTop: "50px" }}>
          {(categoryName || null) === null ? (
            <Row>
              <Row>
                <Col xs={7} className="f-h3" style={{ paddingRight: "15px" }}>
                  Podcast yayınları
                </Col>
                <Col style={{ marginTop: "auto", paddingLeft: "15px" }}>
                  <Tabs
                    className="f-h6"
                    activeKey={selectedTab}
                    onSelect={(key) => {
                      setSelectedTab(key);
                    }}
                  >
                    <Tab eventKey="featured" title="Editörün Seçtikleri" />
                    <Tab eventKey="newest" title="En Yeniler" />
                  </Tabs>
                </Col>
              </Row>
              <Row style={{ marginTop: "35px" }}>
                <Col xs={7} style={{ paddingRight: "15px" }}>
                  <Image
                    src="https://www.designgost.com/img/7840f240-0f02-4f43-9de3-c5a6f914af6d/colors-thumb.jpg?fm=webp&fit=crop&crop=695%2C463%2C0%2C0&w=440&s=a9e38d55c504726086d9e92c1c03ea65"
                    style={{
                      width: "655px",
                      height: "369px",
                      borderRadius: "4px",
                    }}
                  />
                </Col>
                <Col
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "26px",
                    paddingLeft: "15px",
                  }}
                >
                  <ExploreTopCard
                    image="https://manage.designgost.com/img/0b78221c-3c7d-4948-9cc4-c5a7ed692a1e/tasarimda-calma.jpg?fm=webp&fit=crop&crop=440%2C293%2C0%2C0&w=440&s=589782045ed4ab476ec7cfc8c27ab590"
                    name="Tasarımda Denge (Balans)"
                    desc="Tasarım İlkeleri Videoları"
                  />
                  <ExploreTopCard
                    image="https://manage.designgost.com/img/0b78221c-3c7d-4948-9cc4-c5a7ed692a1e/tasarimda-calma.jpg?fm=webp&fit=crop&crop=440%2C293%2C0%2C0&w=440&s=589782045ed4ab476ec7cfc8c27ab590"
                    name="Geçmişten Günümüze Tüm Grafik Tasarım Stilleri"
                    desc="Tasarım Tarihi   •   14 Temmuz 2022"
                  />
                  <ExploreTopCard
                    image="https://manage.designgost.com/img/0b78221c-3c7d-4948-9cc4-c5a7ed692a1e/tasarimda-calma.jpg?fm=webp&fit=crop&crop=440%2C293%2C0%2C0&w=440&s=589782045ed4ab476ec7cfc8c27ab590"
                    name="Dünyanın En İlham Verici Online Tasarım ve Sanat Platformları"
                    desc="İlham Verici  •   14 Temmuz 2022"
                  />
                </Col>
              </Row>
              <div
                style={{ border: "0.5px solid #E3DFED", margin: "60px 0px" }}
              />
            </Row>
          ) : null}
          <Row
            style={{
              paddingBottom: "100px",
            }}
          >
            <Col xs={8}>
              <Row style={{ marginBottom: "30px" }}>
                <Col className="f-h4">
                  {(categoryName?.length || 0) > 0
                    ? `${categoryName} eğitim videoları`
                    : "Tüm eğitim videoları"}
                </Col>
                {(categoryName || null) === null ? (
                  <Col xs="auto">
                    <Dropdown
                      onSelect={(key) => {
                        setSelectedFilter(key);
                      }}
                    >
                      <Dropdown.Toggle className="dg-dropdown f-paragraph">
                        {filters[selectedFilter]}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {Object.keys(filters).map((innerAttr) => {
                          return (
                            <Dropdown.Item eventKey={innerAttr}>
                              {filters[innerAttr]}
                            </Dropdown.Item>
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                ) : null}
              </Row>
              <Col>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: "48px 30px",
                  }}
                >
                  {videosData.map((element, i) => (
                    <VideoCard element={element} key={i} />
                  ))}
                </div>

                <Col className="text-center" style={{ marginTop: "80px" }}>
                  <DGButton variant="tertiary" size="medium">
                    Daha Fazla Yükle
                  </DGButton>
                </Col>
              </Col>
            </Col>
            <SideMap
              categories={categories}
              exploreCategories={exploreCategories}
              site="/egitim-videolari"
            />
          </Row>
        </Container>
      )}
    </div>
  );
};

const VideoMobile = (props) => {
  const navigate = useNavigate();
  return (
    <div>
      <DGTabs
        activeKey={"/egitim-videolari"}
        onSelect={(key) => {
          navigate(key);
        }}
      >
        {props.categories?.map((x, i) => (
          <Tab
            eventKey={x.path}
            title={x.name}
            style={{ width: "max-content" }}
          />
        ))}
      </DGTabs>
      <Container
        style={{
          padding: "20px",
        }}
      >
        <div className="f-h6">
          Editörün Seçtikleri
          <Row
            className="scroll-view-hidden"
            style={{
              flexDirection: "row",
              flexWrap: "nowrap",
              gap: 10,
              overflowX: "scroll",
              padding: "15px 0px 30px",
            }}
          >
            {[...Array(10)].map((x, i) => (
              <VideoCard
                image="https://trtrades.com/wp-content/uploads/2021/09/Gray-Box.jpeg"
                width="160px"
                key={i}
              />
            ))}
          </Row>
        </div>

        <Row>
          <Col className="f-h6">Tüm blog yazıları</Col>
          <Col xs="auto">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 8,
              }}
            >
              Düzenle <Filter stroke="var(--black)" width={24} height={24} />
            </div>
          </Col>
        </Row>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "auto",
            gap: 10,
            marginTop: "12px",
          }}
        >
          {[...Array(20)].map((x, i) => (
            <VideoCard
              image="https://trtrades.com/wp-content/uploads/2021/09/Gray-Box.jpeg"
              key={i}
            />
          ))}
        </div>
        <Col className="text-center" style={{ marginTop: "40px" }}>
          <DGButton variant="tertiary" size="medium">
            Daha Fazla Yükle
          </DGButton>
        </Col>
      </Container>
    </div>
  );
};

export default Video;
