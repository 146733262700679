import { useEffect, useState } from "react";
import {
  Col,
  Container,
  Dropdown,
  Image,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import DGButton from "../../components/Buttons/DGButton";
import ExploreTopCard from "../../components/Cards/ExploreTopCard";
import PodcastCard from "../../components/Cards/PodcastCard";
import SideMap from "../../components/SideMap";
import useIsMobile from "../../public/is-Mobile";
import { ReactComponent as Filter } from "../../public/icons/Filter.svg";
import { ReactComponent as CaretDown } from "../../public/icons/Caret-Down.svg";
import DGTabs from "../../components/Tabs/DGTabs";
import { useNavigate } from "react-router-dom";
import { getPodcasts } from "../../Service";

const Podcast = () => {
  const exploreCategories = [
    { name: "Blog", path: "/blog" },
    { name: "Podcast", path: "/podcast" },
    { name: "Eğitim Videoları", path: "/egitim-videolari" },
    { name: "Akademik Yazılar", path: "/akademik-yazilar" },
  ];
  const [selectedTab, setSelectedTab] = useState("featured");

  const fetchData = async () => {
    try {
      const request = await getPodcasts();
      setPodcastData(request);
    } catch (error) {
      console.error("Error fetching assets:", error.message);
    }
  };

  const filters = {
    recommended: "Tavsiye Edilenler",
    topRated: "En Beğenilenler",
    newAdded: "Yeni Eklenenler",
    agencies: "Ajanslar",
  };

  const [podcastData, setPodcastData] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("recommended");
  const isMobile = useIsMobile();

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      {isMobile ? (
        <PodcastMobile categories={exploreCategories} />
      ) : (
        <Container style={{ paddingTop: "50px" }}>
          <Row>
            <Col className="f-h3">Podcast yayınları</Col>
            <Col style={{ marginTop: "auto" }}>
              <Tabs
                className="f-h6"
                activeKey={selectedTab}
                onSelect={(key) => {
                  setSelectedTab(key);
                }}
              >
                <Tab eventKey="featured" title="Editörün Seçtikleri" />
                <Tab eventKey="newest" title="En Yeniler" />
              </Tabs>
            </Col>
          </Row>
          <Row style={{ marginTop: "35px" }}>
            <Col>
              <Image
                src="https://www.designgost.com/img/7840f240-0f02-4f43-9de3-c5a6f914af6d/colors-thumb.jpg?fm=webp&fit=crop&crop=695%2C463%2C0%2C0&w=440&s=a9e38d55c504726086d9e92c1c03ea65"
                style={{
                  width: "560px",
                  height: "372px",
                  borderRadius: "4px",
                }}
              />
            </Col>
            <Col
              style={{ display: "flex", flexDirection: "column", gap: "26px" }}
            >
              <ExploreTopCard
                image="https://manage.designgost.com/img/0b78221c-3c7d-4948-9cc4-c5a7ed692a1e/tasarimda-calma.jpg?fm=webp&fit=crop&crop=440%2C293%2C0%2C0&w=440&s=589782045ed4ab476ec7cfc8c27ab590"
                name="Merak duygusu olmayan insan yaratıcı olamaz"
                desc="Mesut Uğurlu - Creative Director"
              />
              <ExploreTopCard
                image="https://manage.designgost.com/img/0b78221c-3c7d-4948-9cc4-c5a7ed692a1e/tasarimda-calma.jpg?fm=webp&fit=crop&crop=440%2C293%2C0%2C0&w=440&s=589782045ed4ab476ec7cfc8c27ab590"
                name="Geçmişten Günümüze Tüm Grafik Tasarım Stilleri"
                desc="Tasarım Tarihi   •   14 Temmuz 2022"
              />
              <ExploreTopCard
                image="https://manage.designgost.com/img/0b78221c-3c7d-4948-9cc4-c5a7ed692a1e/tasarimda-calma.jpg?fm=webp&fit=crop&crop=440%2C293%2C0%2C0&w=440&s=589782045ed4ab476ec7cfc8c27ab590"
                name="Dünyanın En İlham Verici Online Tasarım ve Sanat Platformları"
                desc={
                  <div>
                    Cihangir Öziş - Multidisciplinary Designer
                    <br />
                    Hakan Ertan - Lead Product Designer
                  </div>
                }
              />
            </Col>
          </Row>
          <div style={{ border: "0.5px solid #E3DFED", margin: "60px 0px" }} />
          <Row
            style={{
              paddingBottom: "100px",
            }}
          >
            <Col xs={8}>
              <Row style={{ marginBottom: "30px" }}>
                <Col className="f-h4">Tüm podcast yayınları</Col>
                <Col xs="auto">
                  <Dropdown
                    onSelect={(key) => {
                      setSelectedFilter(key);
                    }}
                  >
                    <Dropdown.Toggle
                      className="dg-dropdown f-paragraph"
                      style={{ display: "flex", alignItems: "center", gap: 16 }}
                    >
                      {filters[selectedFilter]}

                      <CaretDown
                        width={12}
                        height={12}
                        style={{ marginTop: -4 }}
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {Object.keys(filters).map((innerAttr) => {
                        return (
                          <Dropdown.Item
                            eventKey={innerAttr}
                            style={{ width: 270 }}
                          >
                            {filters[innerAttr]}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </Row>
              <Col>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: "48px 30px",
                  }}
                >
                  {podcastData?.map((element, i) => (
                    <PodcastCard element={element} key={i} />
                  ))}
                </div>
                <Col className="text-center" style={{ marginTop: "80px" }}>
                  <DGButton variant="tertiary" size="medium">
                    Daha Fazla Yükle
                  </DGButton>
                </Col>
              </Col>
            </Col>
            <SideMap exploreCategories={exploreCategories} site="/podcast" />
          </Row>
        </Container>
      )}
    </div>
  );
};

const PodcastMobile = (props) => {
  const navigate = useNavigate();

  return (
    <div>
      <DGTabs
        activeKey={"/podcast"}
        onSelect={(key) => {
          navigate(key);
        }}
      >
        {props.categories?.map((x, i) => (
          <Tab
            eventKey={x.path}
            title={x.name}
            style={{ width: "max-content" }}
          />
        ))}
      </DGTabs>

      <Container
        style={{
          padding: "20px",
        }}
      >
        <div className="f-h6">
          Editörün Seçtikleri
          <Row
            className="scroll-view-hidden"
            style={{
              flexDirection: "row",
              flexWrap: "nowrap",
              gap: 10,
              overflowX: "scroll",
              margin: "15px 0px 30px",
            }}
          >
            {[...Array(10)].map((x, i) => (
              <PodcastCard
                image="https://trtrades.com/wp-content/uploads/2021/09/Gray-Box.jpeg"
                width="160px"
                key={i}
              />
            ))}
          </Row>
        </div>

        <Row>
          <Col className="f-h6">En Yeniler</Col>
          <Col xs="auto">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 8,
              }}
            >
              Düzenle <Filter stroke="var(--black)" width={24} height={24} />
            </div>
          </Col>
        </Row>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "auto",
            gap: 12,
            marginTop: "12px",
          }}
        >
          {[...Array(20)].map((x, i) => (
            <PodcastCard
              image="https://trtrades.com/wp-content/uploads/2021/09/Gray-Box.jpeg"
              key={i}
            />
          ))}
        </div>
        <Col className="text-center" style={{ marginTop: "40px" }}>
          <DGButton variant="tertiary" size="medium">
            Daha Fazla Yükle
          </DGButton>
        </Col>
      </Container>
    </div>
  );
};

export default Podcast;
