import { useEffect, useState } from "react";

import { Col, Container, Row } from "react-bootstrap";
import { Link, useLocation, useParams } from "react-router-dom";
import DGButton from "../../components/Buttons/DGButton";
import ProjectCard from "../../components/Cards/ProjectCard";

import AcademicPaperCard from "../../components/Cards/AcademicPaperCard";
import BlogCard from "../../components/Cards/BlogCard";
import VideoCard from "../../components/Cards/VideoCard";
import PodcastCard from "../../components/Cards/PodcastCard";

import useIsMobile from "../../public/is-Mobile";

import { ReactComponent as CaretLeft } from "../../public/images/caret-left.svg";

import ProfileLayout from "../../layout/ProfileLayout";
import AboutView from "../../components/Profile/AboutView";
import { getUserDetail, getProjects } from "../../Service";

const MentorProfile = () => {
  let { username } = useParams();

  const { hash, key } = useLocation();
  const isMobile = useIsMobile();

  const [selectedTab, setSelectedTab] = useState("about");
  const [allProjectsModalShow, setAllProjectsModalShow] = useState(false);

  const recommendations = {
    academic: {
      title: "En çok ilgi gören araştırma kategorileri",
      description:
        "Lorem ipsum hız, iletişim, teknoloji ve tüketim çağında yaşamaktayız. Hayatın her alanında tasarım çok önemli bir hale gelmiş durumda.",
      buttonTitle: "Tüm Akademik Yazılar",
      buttonLink: "",
      categories: [
        "Grafik Tasarım",
        "Sanat Tarihi",
        "Sokak Kültürü",
        "Animasyon",
        "Sinema",
        "Tasarım İlkeleri",
        "Sokak Kültürü",
        "Grafik Tasarım",
        "Animasyon",
        "Sanat Tarihi",
        "Sokak Kültürü",
        "Tasarım İlkeleri",
      ],
    },
    blog: {
      title: "En çok ilgi gören blog kategorileri",
      description:
        "Lorem ipsum hız, iletişim, teknoloji ve tüketim çağında yaşamaktayız. Hayatın her alanında tasarım çok önemli bir hale gelmiş durumda.",
      buttonTitle: "Tüm Blog Yazıları",
      buttonLink: "",
      categories: [
        "Grafik Tasarım",
        "Sanat Tarihi",
        "Sokak Kültürü",
        "Animasyon",
        "Sinema",
        "Tasarım İlkeleri",
        "Sokak Kültürü",
        "Grafik Tasarım",
        "Animasyon",
        "Sanat Tarihi",
        "Sokak Kültürü",
        "Tasarım İlkeleri",
      ],
    },
    videos: {
      title: "En çok ilgi gören video kategorileri",
      description:
        "Lorem ipsum hız, iletişim, teknoloji ve tüketim çağında yaşamaktayız. Hayatın her alanında tasarım çok önemli bir hale gelmiş durumda.",
      buttonTitle: "Tüm Eğitim Videoları",
      buttonLink: "",
      categories: [
        "Grafik Tasarım",
        "Sanat Tarihi",
        "Sokak Kültürü",
        "Animasyon",
        "Sinema",
        "Tasarım İlkeleri",
        "Sokak Kültürü",
        "Grafik Tasarım",
        "Animasyon",
        "Sanat Tarihi",
        "Sokak Kültürü",
        "Tasarım İlkeleri",
      ],
    },
    podcast: {
      title: "En çok ilgi gören podcast yayınları",
      description:
        "Lorem ipsum hız, iletişim, teknoloji ve tüketim çağında yaşamaktayız. Hayatın her alanında tasarım çok önemli bir hale gelmiş durumda.",
      buttonTitle: "Tüm Podcast Yayınları",
      buttonLink: "",
      categories: [
        "Grafik Tasarım",
        "Sanat Tarihi",
        "Sokak Kültürü",
        "Animasyon",
        "Sinema",
        "Tasarım İlkeleri",
        "Sokak Kültürü",
        "Grafik Tasarım",
        "Animasyon",
        "Sanat Tarihi",
        "Sokak Kültürü",
        "Tasarım İlkeleri",
      ],
    },
    menteesProjects: {
      title: "En çok ilgi gören proje kategorileri",
      description:
        "Lorem ipsum hız, iletişim, teknoloji ve tüketim çağında yaşamaktayız. Hayatın her alanında tasarım çok önemli bir hale gelmiş durumda.",
      buttonTitle: "Tüm Projeler",
      buttonLink: "",
      categories: [
        "Grafik Tasarım",
        "Sanat Tarihi",
        "Sokak Kültürü",
        "Animasyon",
        "Sinema",
        "Tasarım İlkeleri",
        "Sokak Kültürü",
        "Grafik Tasarım",
        "Animasyon",
        "Sanat Tarihi",
        "Sokak Kültürü",
        "Tasarım İlkeleri",
      ],
    },
  };

  const tabs = [
    { key: "about", title: "Hakkımda", subPages: null },
    { key: "menteesProjects", title: "Projeler", subPages: null },
    // {
    //   key: "contents",
    //   title: "İçerikler",
    //   subPages: [
    //     { key: "blog", title: "Blog" },
    //     { key: "podcast", title: "Podcast" },
    //     { key: "videos", title: "Eğitim Videoları" },
    //     { key: "academic", title: "Akademik Yazılar" },
    //   ],
    // },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    if (data === null) {
      fetchUserDetail();
    }
  });

  useEffect(() => {
    if (hash) {
      const section = hash.substring(1);
      if (
        section === "about" ||
        section === "menteesProjects" ||
        section === "academic" ||
        section === "blog" ||
        section === "videos" ||
        section === "podcast"
      ) {
        setSelectedTab(section);
      }
    }
  }, [key, hash]);

  const [data, setData] = useState(null);

  const fetchUserDetail = async () => {
    try {
      const response = await getUserDetail(username);
      setData(response);
    } catch (error) {
      console.error("Error fetching assets:", error.message);
    }
  };

  return isMobile && allProjectsModalShow ? (
    <AllProjectsModal setAllProjectsModalShow={setAllProjectsModalShow} />
  ) : (
    <ProfileLayout
      data={data}
      tabs={tabs}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      activePage={
        selectedTab === "menteesProjects" ? (
          <MenteesProjectsTab
            data={data?.projects}
            setAllProjectsModalShow={setAllProjectsModalShow}
          />
        ) : selectedTab === "about" ? (
          <AboutView data={data} />
        ) : (
          <ExploreLayout type={selectedTab} />
        )
      }
    />
  );
};

const RecommendationsBlock = (props) => {
  const isMobile = useIsMobile();

  return (
    <div
      style={{
        backgroundColor: "#F8F8FB",
        padding: isMobile ? "30px 20px" : "60px 0px",
      }}
    >
      <Container>
        <Row
          style={{ justifyContent: "space-between", gap: isMobile ? 40 : 0 }}
        >
          <Col xs={13} lg={5}>
            <div className={isMobile ? "f-h4" : "f-h5"}>
              {props.data?.title}
            </div>
            <div
              className="f-label"
              style={{ marginTop: "16px", marginBottom: "40px" }}
            >
              {props.data?.description}
            </div>
            <Link to={props.data?.buttonLink}>
              <DGButton variant="dg-primary" size="medium">
                {props.data?.buttonTitle}
              </DGButton>
            </Link>
          </Col>
          <Col xs={13} lg={5}>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: isMobile ? "auto" : "auto auto auto",
                gap: "20px 30px",
              }}
            >
              {props.data?.categories.map((item) => (
                <Link
                  to=""
                  className="f-label"
                  style={{
                    paddingBottom: "20px",
                    borderBottom: "2px solid var(--neutral50)",
                    color: "var(--purplePrimary)",
                    fontWeight: 700,
                  }}
                >
                  {item}
                </Link>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const ExploreLayout = (props) => {
  const isMobile = useIsMobile();

  return (
    <Container
      style={{
        padding: isMobile ? "30px 20px 40px" : "50px 0px 100px",
      }}
    >
      {props.type === "academic" ? (
        <Row style={{ gap: "30px", maxWidth: "750px" }}>
          <AcademicPaperCard
            title="MSKU Bodrum Güzel Sanatlar Fakültesi Uluslararası Sanat Sempozyumu - MSKU Bodrum Faculty of Fine Arts International Art Symposium MSKU Bodrum Güzel Sanatlar Fakültesi Uluslararası Sanat Sempozyumu - MSKU Bodrum Faculty of Fine Arts International Art Symposium"
            text={
              isMobile
                ? null
                : "Hız, iletişim, teknoloji ve tüketim çağında yaşamaktayız. Hayatın her alanında tasarım çok önemli bir hale gelmiş durumda. Bir zamanlar sadece ayrıcalıklı zümreler özel tasarım ürünlerine sahip olabiliyorken, artık her - Hız, iletişim, teknoloji ve tüketim çağında yaşamaktayız. Hayatın her alanında tasarım çok önemli bir hale gelmiş durumda. Bir zamanlar sadece ayrıcalıklı zümreler özel tasarım ürünlerine sahip olabiliyorken, artık her"
            }
            isEditable={true}
          />
        </Row>
      ) : (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: isMobile ? "auto auto" : "auto auto auto",
            gap: "25px 15px",
          }}
        >
          {props.type === "blog"
            ? [...Array(8)].map((x, i) => (
                <BlogCard
                  title="Dünyanın En İlham Verici Online Tasarım ve Sanat Platformları"
                  image="https://trtrades.com/wp-content/uploads/2021/09/Gray-Box.jpeg"
                  width="auto"
                  height="auto"
                />
              ))
            : props.type === "videos"
            ? [...Array(8)].map((x, i) => (
                <VideoCard image="https://trtrades.com/wp-content/uploads/2021/09/Gray-Box.jpeg" />
              ))
            : [...Array(8)].map((x, i) => (
                <PodcastCard
                  image="https://trtrades.com/wp-content/uploads/2021/09/Gray-Box.jpeg"
                  width="auto"
                  height="auto"
                />
              ))}
        </div>
      )}
    </Container>
  );
};

const MenteesProjectsTab = (props) => {
  const isMobile = useIsMobile();
  return (
    <Container
      style={{
        padding: isMobile ? "30px 20px 20px" : "50px 0 100px",
      }}
    >
      <Row className="f-h5" style={{ gap: isMobile ? "24px" : "70px" }}>
        <Row>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "auto auto",
              gap: isMobile ? "16px" : "45px 30px",
            }}
          >
            {props?.data?.map((x, i) => (
              <ProjectCard data={x} key={i} />
            ))}
          </div>

          {/* {isMobile ? null : (
            <Col className="text-center" style={{ marginTop: "80px" }}>
              <DGButton variant="tertiary" size="medium">
                Daha Fazla Yükle
              </DGButton>
            </Col>
          )} */}
        </Row>
      </Row>
    </Container>
  );
};

const AllProjectsModal = (props) => {
  return (
    <div
      style={{
        width: "100%",
        overflow: "auto",
        background: "white",
        position: "relative",
      }}
    >
      <Row
        style={{
          position: "fixed",
          right: 0,
          left: 0,
          zIndex: 3,
          padding: "16px",
          background: "var(--purplePrimary)",
          gap: 20,
          color: "white",
          stroke: "white",
          alignItems: "center",
        }}
      >
        <Col xs="auto">
          <CaretLeft
            width={16}
            height={16}
            onClick={() => {
              props.setAllProjectsModalShow(false);
              window.scrollTo(0, 0);
            }}
          />
        </Col>
        <Col xs className="f-label text-center">
          Destek Verdiğim Projeler
        </Col>
        <Col xs="auto" style={{ width: 16 }} />
      </Row>
      <div
        style={{
          padding: "76px 20px 30px",
          display: "grid",
          gridTemplateColumns: "auto auto",
          gap: 10,
        }}
      >
        {[...Array(16)].map((x, i) => (
          <ProjectCard image="https://trtrades.com/wp-content/uploads/2021/09/Gray-Box.jpeg" />
        ))}
      </div>
    </div>
  );
};

export default MentorProfile;
