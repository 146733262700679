import {
  Row,
  Col,
  Image,
  OverlayTrigger,
  Tooltip,
  Overlay,
} from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import useIsMobile from "../public/is-Mobile";

import { ReactComponent as YorumGerekiyor } from "../public/icons/Proje-Gelistirmek-Gerekiyor.svg";
import { ReactComponent as Onaylandı } from "../public/icons/Onaylandı.svg";
import { ReactComponent as Usta } from "../public/images/Usta.svg";
import { ReactComponent as CaretLeft } from "../public/images/caret-left.svg";
import { ReactComponent as Chat } from "../public/icons/Chat.svg";

import Pin from "../public/icons/Pin.svg";
import DGButton from "../components/Buttons/DGButton";
import MenteeAnswer from "../components/Project/MenteeAnswer";
import MentorAnswer from "../components/Project/MentorAnswer";
import ProjectImageDetailModal from "../components/Project/ProjectImageDetailModal";
import MustLoginOverlay from "../components/Project/MustLoginOverlay";
import useBreakpoint from "../public/use-breakpoint";
import Draggable from "react-draggable";
import { getProjectDetail } from "../Service";
import { useParams } from "react-router-dom";

function linkify(text) {
  // Regex to find URLs
  const urlPattern =
    /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;

  // Replace URLs with anchor tags
  return text.replace(
    urlPattern,
    (url) =>
      `<a href="${url}" target="_blank" rel="noopener noreferrer" style="color: var(--purplePrimary)">${url}</a>`
  );
}

const ProjectDetail = () => {
  let { id } = useParams();
  const isMobile = useIsMobile();

  const [projectData, setProjectData] = useState([]);

  useEffect(() => {
    let isMounted = true;

    const fetchProject = async () => {
      try {
        const request = await getProjectDetail(id);
        if (isMounted) {
          setProjectData(request);
        }
      } catch (error) {
        if (isMounted) {
          console.error("Error fetching assets:", error.message);
        }
      }
    };

    fetchProject();

    return () => {
      isMounted = false;
    };
  }, []);

  return isMobile ? (
    <MobileLayout projectData={projectData} />
  ) : (
    <DesktopLayout projectData={projectData} />
  );
};

export default ProjectDetail;

const DesktopLayout = (props) => {
  const size = useBreakpoint();

  const [modalShow, setModalShow] = useState(false);
  const [heightOfHeader, setHeightOfHeader] = useState(0);
  const [heightOfFirstMessage, setHeightOfFirstMessage] = useState(0);
  const [selectedVersion, setSelectedVersion] = useState(0);

  useEffect(() => {
    const height = document.getElementsByClassName("navbar")[0]?.clientHeight;
    const heightSecond =
      document.getElementsByClassName("messages-first")[0]?.clientHeight;
    setHeightOfFirstMessage(heightSecond);
    setHeightOfHeader(height);
  }, [size]);

  useEffect(() => {
    setSelectedVersion(props?.projectData?.version?.length - 1);
  }, [props]);

  return (
    <div style={{ height: `calc(100vh - ${heightOfHeader}px)` }}>
      <Row style={{ height: "100%" }}>
        <Col
          xs
          style={{ height: "100%", display: "flex", flexDirection: "column" }}
        >
          <Row
            style={{
              background: "#F0EDF6",
              padding: "14px 40px",
              alignItems: "center",
            }}
          >
            <Col
              style={{ display: "flex", flexDirection: "column", gap: "6px" }}
            >
              <div className="f-label">Proje Adı</div>
              <div className="f-h6">
                {props?.projectData?.project?.[0]?.title}
              </div>
            </Col>
            <Col
              style={{ display: "flex", flexDirection: "column", gap: "6px" }}
            >
              <div className="f-label">Son Durum</div>
              {props?.projectData?.project?.[0]?.status == "approve" ? (
                <div
                  className="f-h6"
                  style={{
                    color: "var(--greenPrimary)",
                    stroke: "var(--greenPrimary)",
                  }}
                >
                  <span style={{ marginRight: "8px" }}>
                    <Onaylandı width={16} height={16} />
                  </span>
                  Onaylandı
                </div>
              ) : (
                <div className="f-h6" style={{ color: "var(--purplePrimary)" }}>
                  <span style={{ marginRight: "8px" }}>
                    <YorumGerekiyor width={16} height={16} />
                  </span>
                  Yorum Gerekiyor
                </div>
              )}
            </Col>
            <VersionComponent
              data={props?.projectData?.version}
              selectedVersion={selectedVersion}
              setSelectedVersion={setSelectedVersion}
            />
          </Row>
          <div
            style={{
              background: "#1F1826",
              flexGrow: "1",
              padding: "50px",
              overflow: "auto",
              position: "relative",
            }}
          >
            <Image
              fluid
              src={props?.projectData?.version?.[selectedVersion]?.image}
              style={{
                maxWidth: "60%",
                width: "auto",
                marginLeft: "20%",
                height: "auto",
                userSelect: "none",
              }}
              onClick={() => setModalShow(true)}
            />

            {/* <Draggable
              axis="both"
              defaultPosition={{ x: 0, y: 0 }}
              position={{ x: 0, y: 0 }}
              onStop={(e) => {
                let a = { top: e.y, left: e.x };
                setComments([...comments, a]);
              }}
            >
              <div
                style={{
                  color: "white",
                  background: "var(--purplePrimary)",
                  display: "inline-block",
                  position: "absolute",
                  padding: "7px 10px",
                }}
                ref={target}
              >
                <div className="handle">
                  <Image src={Pin} style={{ width: "100%" }} />
                </div>
              </div>
            </Draggable> */}

            {/* {comments.map((item) => {
              return (
                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    border: "3px solid #FFFFFF",
                    background: "var(--bluePrimary)",
                    position: "absolute",
                    borderRadius: "15px",
                    top: `${item.top}px`,
                    left: `${item.left}px`,
                  }}
                ></div>
              );
            })} */}

            {/* <Overlay target={target.current} show={show} placement="right">
              {(props) => (
                <Tooltip id="overlay-example" {...props}>
                  My Tooltip
                </Tooltip>
              )}
            </Overlay> */}
          </div>
        </Col>
        <Col
          xs="auto"
          style={{
            height: "100%",
            // overflow: isLogin ? "scroll" : "hidden",
            overflow: "scroll",
            position: "relative",
            width: "370px",
          }}
        >
          {props?.projectData?.messages?.map((element, i) =>
            i == 0 ? (
              <ProjectFirstMessage
                sender={{
                  image: element.image,
                  fullName: element.name,
                }}
                sentDate="16 Haziran 2022"
                aboutProject={element.about}
                message={element.message}
              />
            ) : element.membertype == "mentor" ? (
              <ProjectMentorMessage
                sender={{
                  image: element.image,
                  fullName: element.name,
                }}
                sentDate="16 Haziran 2022"
                message={element.message}
                isLogin={true}
              />
            ) : (
              <ProjectMenteeMessage
                sender={{
                  image: element.image,
                  fullName: element.name,
                }}
                sentDate="16 Haziran 2022"
                message={element.message}
              />
            )
          )}

          <div
            style={{
              height: "1px",
              backgroundColor: "var(--neutral100)",
              margin: "0px 16px",
            }}
          />

          {/* {type === "mentee" ? (
            <MenteeAnswer
              sender={{
                image:
                  "https://www.designgost.com/img/4e312348-8be8-4a45-ab83-6a5b4fb99a35/hakan-ertan.jpg?fm=webp&fit=max&crop=1350%2C1350%2C0%2C0&w=200&s=ee609c601fab0ec9585be45177fa5a4b",
              }}
            />
          ) : type === "mentor" ? (
            <MentorAnswer
              sender={{
                image:
                  "https://www.designgost.com/img/4e312348-8be8-4a45-ab83-6a5b4fb99a35/hakan-ertan.jpg?fm=webp&fit=max&crop=1350%2C1350%2C0%2C0&w=200&s=ee609c601fab0ec9585be45177fa5a4b",
              }}
            />
          ) : type === "waiting" ? (
            <ApproveApplication />
          ) : // <MustLoginOverlay heightOfFirstMessage={heightOfFirstMessage} />
          null} */}
        </Col>
      </Row>
      <ProjectImageDetailModal
        data={props?.projectData}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </div>
  );
};

const MobileLayout = (props) => {
  const [selectedVersion, setSelectedVersion] = useState(0);

  useEffect(() => {
    setSelectedVersion(props?.projectData?.version?.length - 1);
  }, [props]);

  return (
    <Row>
      <Row
        style={{
          padding: "14px 40px 14px 16px",
          gap: 8,
          backgroundColor: "var(--purplePrimary)",
          stroke: "white",
          color: "white",
        }}
      >
        <Col xs="auto">
          <CaretLeft width={16} height={16} />
        </Col>
        <Col className="f-label" style={{ marginTop: 4, textAlign: "center" }}>
          {props?.projectData?.project?.[0]?.title}
        </Col>
      </Row>

      <Image
        fluid
        src={props?.projectData?.version?.[selectedVersion]?.image}
        style={{
          objectFit: "contain",
          aspectRatio: "1.35",
          width: "100%",
          background: "#1F1826",
        }}
        // onClick={() => setModalShow(true)}
      />

      <Row
        style={{
          padding: "12px 20px",
          gap: 8,
          backgroundColor: "var(--neutral50)",
          alignItems: "center",
        }}
      >
        <Col xs="auto" style={{ display: "flex", gap: "4px" }}>
          <div className="f-label-s" style={{ marginTop: 1 }}>
            Durum:
          </div>
          {props?.projectData?.project?.[0]?.status == "approve" ? (
            <div
              className="f-label"
              style={{
                color: "var(--greenPrimary)",
                stroke: "var(--greenPrimary)",
                fontWeight: 600,
              }}
            >
              <span style={{ marginRight: "4px" }}>
                <Onaylandı width={16} height={16} />
              </span>
              Onaylandı
            </div>
          ) : (
            <div
              className="f-label"
              style={{
                color: "var(--purplePrimary)",
                stroke: "var(--purplePrimary)",
                fontWeight: 600,
              }}
            >
              <span style={{ marginRight: "4px" }}>
                <YorumGerekiyor width={16} height={16} />
              </span>
              Yorum Gerekiyor
            </div>
          )}
        </Col>

        <VersionComponent
          data={props?.projectData?.version}
          selectedVersion={selectedVersion}
          setSelectedVersion={setSelectedVersion}
        />
      </Row>

      <div style={{ overflow: "scroll" }}>
        {props?.projectData?.messages?.map((element, i) =>
          i == 0 ? (
            <ProjectFirstMessageMobile
              sender={{
                image: element.image,
                fullName: element.name,
              }}
              sentDate="16 Haziran 2022"
              aboutProject={element.about}
              message={element.message}
              key={i}
            />
          ) : element.membertype == "mentor" ? (
            <ProjectMentorMessageMobile
              sender={{
                image: element.image,
                fullName: element.name,
              }}
              sentDate="16 Haziran 2022"
              message={element.message}
              key={i}
            />
          ) : (
            <ProjectMenteeMessageMobile
              sender={{
                image: element.image,
                fullName: element.name,
              }}
              sentDate="16 Haziran 2022"
              message={element.message}
              key={i}
            />
          )
        )}
      </div>
    </Row>
  );
};

const ProjectFirstMessage = ({ sender, sentDate, aboutProject, message }) => {
  return (
    <Row style={{ padding: "30px" }} className="messages-first">
      <Row className="text-center" style={{ alignItems: "center" }}>
        <Image
          roundedCircle={true}
          src={sender.image}
          style={{ width: "48px", height: "48px", marginRight: "13px" }}
        />
        <Col xs>
          <Row className="f-h6">{sender.fullName}</Row>
          <Row className="f-label" style={{ color: "var(--neutral300)" }}>
            {sentDate}
          </Row>
        </Col>
      </Row>
      <Row style={{ marginTop: "27px" }}>
        <div className="f-h6">Proje Hakkında</div>

        <div
          style={{ marginTop: "8px" }}
          className="f-label-l"
          dangerouslySetInnerHTML={{ __html: linkify(aboutProject) }}
        />
      </Row>
      <Row style={{ marginTop: "23px" }}>
        <div className="f-h6">Destek</div>
        <div
          style={{ marginTop: "8px" }}
          className="f-label-l"
          dangerouslySetInnerHTML={{ __html: linkify(message) }}
        />
      </Row>
    </Row>
  );
};

const ProjectMentorMessage = ({ sender, sentDate, message, isLogin }) => {
  return (
    <Row style={{ padding: "30px", filter: isLogin ? "none" : "blur(4.5px)" }}>
      <Row
        className="text-center"
        style={{ alignItems: "center", gap: "13px" }}
      >
        <Image
          roundedCircle={true}
          src={sender.image}
          style={{ width: "48px", height: "48px" }}
        />
        <Col xs>
          <Row className="f-h6">{sender.fullName}</Row>
          <Row className="f-label" style={{ color: "var(--neutral300)" }}>
            {sentDate}
          </Row>
        </Col>

        <div style={{ width: "fit-content" }}>
          <OverlayTrigger overlay={<Tooltip>Usta</Tooltip>}>
            <Usta
              width={32}
              height={32}
              fill="var(--bluePrimary)"
              stroke="var(--bluePrimary)"
            />
          </OverlayTrigger>
        </div>
      </Row>
      <Row
        style={{
          padding: "21px 24px 18px 24px",
          backgroundColor: "var(--redLight)",
          borderRadius: "8px",
          margin: "30px 0px",
          display: "flex",
          gap: "4px",
        }}
      >
        <div className="f-h6" style={{ color: "var(--redPrimary)" }}>
          <span style={{ marginRight: "8px", stroke: "var(--redPrimary)" }}>
            <YorumGerekiyor height={24} width={24} />
          </span>
          Geliştirmek gerekiyor
        </div>
      </Row>
      <Row>
        <div className="f-h6">Ustanın yorumu</div>
        <div
          style={{ marginTop: "8px" }}
          className="f-label-l"
          dangerouslySetInnerHTML={{ __html: linkify(message) }}
        />
      </Row>
    </Row>
  );
};

const ProjectMenteeMessage = ({ sender, sentDate, message }) => {
  return (
    <Row style={{ padding: "30px" }}>
      <Row className="text-center" style={{ alignItems: "center" }}>
        <Image
          roundedCircle={true}
          src={sender.image}
          style={{ width: "48px", height: "48px", marginRight: "13px" }}
        />
        <Col xs>
          <Row className="f-h6">{sender.fullName}</Row>
          <Row className="f-label" style={{ color: "var(--neutral300)" }}>
            {sentDate}
          </Row>
        </Col>
      </Row>
      <Row style={{ marginTop: "27px" }}>
        <div className="f-h6">Mesaj</div>
        <div
          style={{ marginTop: "8px" }}
          className="f-label-l"
          dangerouslySetInnerHTML={{ __html: linkify(message) }}
        />
      </Row>
    </Row>
  );
};

const ApproveApplication = () => {
  return (
    <div
      style={{
        position: "absolute",
        bottom: `0px`,
        width: "100%",
        alignItems: "center",
        padding: "40px 30px 70px 30px",
        backgroundColor: "var(--neutral50)",
        alignContent: "center",
        justifyContent: "center",
      }}
    >
      <div className="f-h6">
        Tebrikler Hakan!
        <br />
        Özgür Çırağın olmak istiyor.
      </div>
      <div
        className="f-label"
        style={{ marginTop: "9px", marginBottom: "60px" }}
      >
        Projeye cevap yazabilmek için Çıraklık talebini onaylaman gerekir.
      </div>
      <DGButton variant="dg-primary" size="medium">
        Çıraklık Talebini Onayla
      </DGButton>
    </div>
  );
};

const VersionComponent = (props) => {
  return (
    <Col
      style={{
        display: "flex",
        gap: "6px",
        justifyContent: "flex-end",
      }}
    >
      {props?.data?.map((_, i) => (
        <Col
          xs="auto"
          style={{
            borderRadius: "6px",
            height: "28px",
            width: "28px",
            display: "flex",
            alignItems: "center",
            alignContent: "center",
            textAlign: "center",
            justifyContent: "center",
            backgroundColor:
              props?.selectedVersion == i
                ? "var(--bluePrimary)"
                : "transparent",
            color: props?.selectedVersion == i ? "white" : "var(--bluePrimary)",
            border: "2px solid var(--bluePrimary)",
            paddingTop: "4px",
            cursor: "pointer",
          }}
          className="f-h6"
          onClick={() => props?.setSelectedVersion(i)}
          key={i}
        >
          <span>V{i + 1}</span>
        </Col>
      ))}
    </Col>
  );
};

const ProjectFirstMessageMobile = ({
  sender,
  sentDate,
  aboutProject,
  message,
}) => {
  return (
    <Row style={{ padding: "24px 16px", gap: 24 }} className="messages-first">
      <Row style={{ gap: 8 }}>
        <Col xs="auto">
          <Image
            roundedCircle={true}
            src={sender.image}
            width={32}
            height={32}
          />
        </Col>
        <Col xs>
          <Row className="f-label" style={{ fontWeight: 600 }}>
            {sender.fullName}
          </Row>
          <Row className="f-label-s" style={{ color: "var(--neutral300)" }}>
            {sentDate}
          </Row>
        </Col>
      </Row>
      <Row className="f-label" style={{ gap: 12 }}>
        <div style={{ fontWeight: 600 }}>Proje Hakkında</div>
        <div dangerouslySetInnerHTML={{ __html: linkify(aboutProject) }} />
      </Row>
      <Row className="f-label" style={{ gap: 12 }}>
        <div style={{ fontWeight: 600 }}>Destek</div>
        <div dangerouslySetInnerHTML={{ __html: linkify(message) }} />
      </Row>
    </Row>
  );
};

const ProjectMentorMessageMobile = ({ sender, sentDate, message }) => {
  return (
    <Row
      style={{
        padding: "24px 0",
        margin: "0 16px",
        gap: 24,
        borderTop: "1px solid var(--neutral100)",
      }}
    >
      <Row style={{ gap: 8 }}>
        <Col xs="auto">
          <Image
            roundedCircle={true}
            src={sender.image}
            width={32}
            height={32}
          />
        </Col>
        <Col xs>
          <Row className="f-label" style={{ fontWeight: 600 }}>
            {sender.fullName}
          </Row>
          <Row className="f-label-s" style={{ color: "var(--neutral300)" }}>
            {sentDate}
          </Row>
        </Col>
        <Col xs="auto" style={{ width: "fit-content" }}>
          <OverlayTrigger overlay={<Tooltip>Usta</Tooltip>}>
            <Usta
              width={32}
              height={32}
              fill="var(--bluePrimary)"
              stroke="var(--bluePrimary)"
            />
          </OverlayTrigger>
        </Col>
      </Row>

      <Row
        className="f-h6"
        style={{
          padding: "20px 16px",
          borderRadius: "8px",
          display: "flex",
          gap: "6px",
          backgroundColor: "var(--redLight)",
          stroke: "var(--redPrimary)",
          color: "var(--redPrimary)",
        }}
      >
        <YorumGerekiyor style={{ width: 24, height: 24 }} />
        <span style={{ marginTop: 2, width: "auto" }}>
          Geliştirmek gerekiyor
        </span>
      </Row>

      <Row className="f-label" style={{ gap: 12 }}>
        <div style={{ fontWeight: 600 }}>Ustanın yorumu</div>
        <div dangerouslySetInnerHTML={{ __html: linkify(message) }} />
      </Row>
    </Row>
  );
};

const ProjectMenteeMessageMobile = ({ sender, sentDate, message }) => {
  return (
    <Row
      style={{
        padding: "24px 0",
        margin: "0 16px",
        gap: 24,
        borderTop: "1px solid var(--neutral100)",
      }}
    >
      <Row style={{ gap: 8 }}>
        <Col xs="auto">
          <Image
            roundedCircle={true}
            src={sender.image}
            width={32}
            height={32}
          />
        </Col>
        <Col xs>
          <Row className="f-label" style={{ fontWeight: 600 }}>
            {sender.fullName}
          </Row>
          <Row className="f-label-s" style={{ color: "var(--neutral300)" }}>
            {sentDate}
          </Row>
        </Col>
      </Row>

      <Row
        className="f-h6"
        style={{
          padding: "20px 16px",
          borderRadius: "8px",
          display: "flex",
          gap: "6px",
          backgroundColor: "var(--purpleLight)",
          stroke: "var(--purplePrimary)",
          color: "var(--purplePrimary)",
        }}
      >
        <Chat style={{ width: 24, height: 24 }} />
        <span style={{ marginTop: 2, width: "auto" }}>Yorum Gerekiyor</span>
      </Row>

      <Row className="f-label" style={{ gap: 12 }}>
        <div dangerouslySetInnerHTML={{ __html: linkify(message) }} />
      </Row>
    </Row>
  );
};
