import { useEffect } from "react";
import { Container } from "react-bootstrap";
import { Routes, Route, useLocation } from "react-router-dom";

// Layout
import Layout from "./layout/Layout";

// pages
import Home from "./pages/Home";
import Mentors from "./pages/Mentors";
import NotFound from "./pages/NotFound";
import MentorProfile from "./pages/Profile/MentorProfile";
import Projects from "./pages/Projects";
import MenteeProfile from "./pages/Profile/MenteeProfile";
import ProjectDetail from "./pages/ProjectDetail";
import AcademicPaperDetail from "./pages/AcademicPaperDetail";
import Components from "./pages/Components";
import Messages from "./pages/Messages";
import Profile from "./pages/Profile";
import AcademicPaperUpload from "./pages/AcademicPaperUpload";
import HowItWorks from "./pages/HowItWorks";
import MemberType from "./pages/Register/MemberType";
import Signup from "./pages/Register/Signup";
import LoginOtp from "./pages/login-otp";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import { ToastContextProvider } from "./ContextProvider";
import AcademicPaperEdit from "./pages/AcademicPaperEdit";

import { AcademicPapers, Blog, Podcast, Video } from "./pages/Explore";

import BlogDetail from "./pages/Explore/Details/BlogDetail";
import PodcastDetail from "./pages/Explore/Details/PodcastDetail";

import VideoDetail from "./pages/Explore/Details/VideoDetail";
import Login from "./pages/Login/Login";
import ForgotPassword from "./pages/Auth/ForgotPassword";

const App = () => {
  return (
    <ToastContextProvider>
      <Layout>
        <Container style={{ margin: 0, padding: 0, maxWidth: "100%" }}>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} exact />
            <Route path="/ustalar" element={<Mentors />} />
            <Route path="/ustalar/:username" element={<MentorProfile />} />
            <Route path="/ciraklar/:username" element={<MenteeProfile />} />
            <Route path="/projeler" element={<Projects />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/podcast" element={<Podcast />} />
            <Route path="/egitim-videolari" element={<Video />} />

            {/* Explore Details */}
            <Route path="/blog/:id" element={<BlogDetail />} />
            <Route path="/podcast/:id" element={<PodcastDetail />} />
            <Route path="/egitim-videolari/:id" element={<VideoDetail />} />
            <Route
              path="/akademik-yazilar/:id"
              element={<AcademicPaperDetail />}
            />

            <Route path="/projeler/:id" element={<ProjectDetail />} />
            <Route path="/akademik-yazilar" element={<AcademicPapers />} />
            <Route
              path="/akademik-yazilar/upload"
              element={<AcademicPaperUpload />}
            />
            <Route
              path="/akademik-yazilar/:id/edit"
              element={<AcademicPaperEdit />}
            />

            <Route path="/messages" element={<Messages />} />
            <Route path="/profile" element={<Profile />} />

            <Route path="/nasil-calisiyor" element={<HowItWorks />} />
            <Route path="/login-otp" element={<LoginOtp />} />

            <Route path="/gizlilik-politikasi" element={<Privacy />} />
            <Route path="/kullanim-kosullari" element={<Terms />} />

            <Route path="/components" element={<Components />} />
            <Route path="/sifremi-unuttum" element={<ForgotPassword />} />

            <Route path="*" element={<NotFound key={"notFound"} />} />

            {/* Refactoring is Done */}
            <Route path="/choose-signup" element={<MemberType />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/login" element={<Login />} />
          </Routes>
        </Container>
      </Layout>
    </ToastContextProvider>
  );
};

export default App;

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
