import { Col, Row } from "react-bootstrap";
import BaseAboutSectionView from "./BaseAboutSectionView";
import { ReactComponent as Professions } from "../../../public/icons/Professions.svg";

const months = ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"];

const ExperiencesSection = (props) => {
  return (
    <BaseAboutSectionView title="Deneyim">
      {props.data?.map((element, i) => (
        <ExperienceView
          company={element.title}
          title={element.company}
          startMonth={element.start_month}
          startYear={element.start_year}
          endMonth={element.end_month}
          endYear={element.end_year}
          isActive={element.end_year === ""}
        />
      ))}
    </BaseAboutSectionView>
  );
};

const ExperienceView = ({
  company,
  title,
  startMonth,
  startYear,
  endMonth,
  endYear,
  isActive,
}) => {
  return (
    <Row className="f-label" style={{ gap: 8 }}>
      <Col xs="auto">
        <Professions width={20} stroke={"var(--neutral300)"} />
      </Col>
      <Col xs style={{ display: "flex", gap: 8, flexDirection: "column" }}>
        <div style={{ fontWeight: 700 }}>{company}</div>
        <div>{title}</div>
        <div
          style={{
            color: "var(--neutral300)",
          }}
        >
          {months[parseInt(startMonth) - 1]} {startYear} -{" "}
          <span style={{ display: isActive ? "inline" : "none" }}>Şu anda</span>
          <span style={{ display: isActive ? "none" : "inline" }}>
            {months[parseInt(endMonth) - 1]} {endYear}
          </span>
        </div>
      </Col>
    </Row>
  );
};

export default ExperiencesSection;
