import { Col, Image, Row } from "react-bootstrap";
import ShowMoreText from "react-show-more-text";
import { Link } from "react-router-dom";

import { ReactComponent as Label } from "../../public/icons/Label.svg";
import { ReactComponent as Edit } from "../../public/icons/Edit.svg";

import "./academicPaperCard.css";
import useIsMobile from "../../public/is-Mobile";

const AcademicPaperCard = (props) => {
  const isMobile = useIsMobile();

  const defaultProps = {
    backgroundColor: props.backgroundColor || "transparent",
    imageWidth: props.imageWidth || isMobile ? "80px" : "124px",
  };

  return (
    <Link
      to="/akademik-yazilar/111"
      className="card-academic-paper-link"
      style={{ width: props.width || "auto" }}
    >
      <Row
        className="card-academic-paper boxed-card"
        key={props.key}
        style={{
          background: defaultProps.backgroundColor,
          gap: isMobile ? 16 : 30,
          padding: isMobile ? "16px " : "20px",
          borderWidth: props.borderWidth,
        }}
      >
        <Col xs="auto">
          <Image
            className="card-image"
            style={{ width: defaultProps.imageWidth }}
          />
        </Col>
        <Col xs>
          <div
            className={"f-h5 card-header"}
            style={{
              marginBottom: "8px",
              fontWeight: 700,
            }}
          >
            {props.title}
          </div>
          {props.hideDesc || false ? null : (
            <div style={{ color: "var(--black)" }}>
              <ShowMoreText
                lines={2}
                more="tümünü göster"
                less="daha az göster"
                anchorClass="show-more"
                expanded={false}
              >
                {props.text}
              </ShowMoreText>
            </div>
          )}
          <div
            className={"f-label"}
            style={{
              marginTop: "12px",
              marginBottom: "20px",
              color: "var(--black)",
            }}
          >
            <b>Yazarlar: </b>
            Hakan Ertan, Mesut Uğurlu, Emre Ertan
          </div>
          <Row
            style={{
              justifyContent: "space-between",
              flexDirection: "row",
              color: "var(--black)",
            }}
          >
            <Col xs="auto">
              <b>Yıl: </b>
              2022
              <span style={{ margin: "0 8px" }}>|</span>
              <span style={{ marginRight: "8px", stroke: "var(--black)" }}>
                <Label width={20} />
              </span>
              Grafik Tasarım, Sanat
            </Col>

            {props.isEditable || false ? (
              <Col xs="auto" className="edit-button">
                <Link to="/">
                  <span
                    style={{
                      marginRight: "4px",
                    }}
                  >
                    <Edit width={24} />
                  </span>
                  Düzenle
                </Link>
              </Col>
            ) : null}
          </Row>
        </Col>
      </Row>
    </Link>
  );
};

export default AcademicPaperCard;
