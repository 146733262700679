import { useEffect, useState } from "react";
import useBreakpoint from "./use-breakpoint";

/**
 * @returns {Breakpoint}
 */
const useIsMobile = () => {

    const size = useBreakpoint();

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        setIsMobile(size !== "lg" && size !== "xl" && size !== "xxl");
    }, [size]);
	
	return isMobile;
};

export default useIsMobile;