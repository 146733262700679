import { useRef, useState } from "react";

import { Col, Image, Overlay, Row, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import DGButton from "../../Buttons/DGButton";

import Icon from "../../../public/images/DesignGost-Icon.svg";
import { ReactComponent as CaretDown } from "../../../public/icons/Caret-Down.svg";

import { useNavigate } from "react-router-dom";

import { ReactComponent as CaretRight } from "../../../public/images/caret-right.svg";
import { ReactComponent as Email } from "../../../public/icons/Email.svg";
import { ReactComponent as Activities } from "../../../public/icons/Activities.svg";
import { ReactComponent as ProfileSetting } from "../../../public/icons/Setting.svg";
import { ReactComponent as SignOut } from "../../../public/icons/Sign-Out.svg";

const ProfileDropdown = (props) => {
  const [showProfile, setShowProfile] = useState(false);
  const isDark = false;

  const profileRef = useRef(null);
  const navigate = useNavigate();

  return (
    <div>
      <div
        ref={profileRef}
        onClick={() => setShowProfile(!showProfile)}
        className={
          showProfile
            ? "header-icon-active"
            : props.variant === "light"
            ? "header-icon-light f-h6"
            : "header-icon-dark f-h6"
        }
        style={{
          display: "flex",
          gap: "8px",
          alignItems: "center",
        }}
      >
        <Image
          src={Icon}
          style={{
            height: "32px",
            width: "32px",
            border: "1px solid #ffffff70",
          }}
          roundedCircle={true}
        />
        Hakan
        <CaretDown width={12} height={12} />
      </div>
      <Overlay
        target={profileRef.current}
        show={showProfile}
        placement="bottom"
        onHide={() => setShowProfile(!showProfile)}
        rootClose={true}
        rootCloseEvent="click"
      >
        <Tooltip className="header-tooltip-profile">
          <Row
            style={{
              flexDirection: "column",
              paddingBottom: 4
            }}
          >
            <Row
              style={{ padding: "16px 16px 0px", gap: 16, marginBottom: 24 }}
            >
              <Col xs="auto">
                <Image
                  src="https://www.designgost.com/img/4e312348-8be8-4a45-ab83-6a5b4fb99a35/hakan-ertan.jpg?fm=webp&fit=max&crop=1350%2C1350%2C0%2C0&w=200&s=ee609c601fab0ec9585be45177fa5a4b"
                  style={{ height: "80px", width: "80px", borderRadius: 8 }}
                />
              </Col>
              <Col
                xs
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div className="f-h6">Hakan Ertan</div>
                <div>
                  <DGButton
                    variant="dg-primary"
                    size="sm"
                    onClick={() => {
                      navigate(`/profile`);
                      props?.hideOverlays();
                    }}
                  >
                    Profile Git
                  </DGButton>
                </div>
              </Col>
            </Row>

            <MobileMenuElement
              icon={<Activities width={20} height={20} />}
              title="Aktiviteler"
              href="/profile"
              isDark={isDark}
            />
            <MobileMenuElement
              icon={<Email width={20} height={20} />}
              title="Mesajlar"
              href="/messages"
              isDark={isDark}
            />
            <MobileMenuElement
              icon={<ProfileSetting width={20} height={20} />}
              title="Profil Ayarları"
              href="/profile#settings"
              isDark={isDark}
            />

            <Row
              className="f-label-l"
              style={{
                flexDirection: "row",
                padding: "16px",
                gap: 16,
                color: isDark ? "white" : "black",
                stroke: isDark ? "white" : "black",
              }}
              onClick={() => {
                localStorage.setItem("signed-in", false);
              }}
            >
              <Col xs="auto">
                <SignOut width={20} height={20} />
              </Col>
              <Col xs style={{ fontWeight: 700 }}>
                Çıkış Yap
              </Col>
              <Col xs="auto">
                <CaretRight width={12} height={12} stroke="var(--neutral200)" />
              </Col>
            </Row>
          </Row>
        </Tooltip>
      </Overlay>
    </div>
  );
};

const MobileMenuElement = (props) => {
  const isDark = props.isDark ?? false;

  return (
    <Link to={props.href}>
      <Row
        className="f-label-l"
        style={{
          flexDirection: "row",
          padding: "16px",
          gap: 16,
          color: isDark ? "white" : "black",
          stroke: isDark ? "white" : "black",
        }}
      >
        <Col xs="auto">{props.icon}</Col>
        <Col xs style={{ fontWeight: 700, marginTop: 2 }}>
          {props.title}

          {(props.subPages ?? []).length !== 0 ? (
            <Col
              xs={13}
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 20,
                marginTop: 20,
              }}
            >
              {props.subPages?.map((element, i) => (
                <Link
                  to={element.href}
                  style={{
                    color: isDark ? "white" : "black",
                    stroke: isDark ? "white" : "black",
                  }}
                >
                  <Row>
                    <Col xs className="f-label">
                      {element.title}
                    </Col>
                    <Col xs="auto">
                      <CaretRight
                        width={12}
                        height={12}
                        stroke="var(--neutral200)"
                      />
                    </Col>
                  </Row>
                </Link>
              ))}
            </Col>
          ) : null}
        </Col>
        {(props.subPages ?? []).length !== 0 ? null : (
          <Col xs="auto">
            <CaretRight width={12} height={12} stroke="var(--neutral200)" />
          </Col>
        )}
      </Row>
    </Link>
  );
};

export default ProfileDropdown;
