import { Col, Container, Row } from "react-bootstrap";
import HomeCard from "./HomeCard";

import CardBg from "../../public/images/home-cards.png";

import useIsMobile from "../../public/is-Mobile";
import { useTranslation } from "react-i18next";

const HowItHelps = () => {
  const isMobile = useIsMobile();

  const { t } = useTranslation("home");

  return (
    <div>
      <div
        className={isMobile ? "f-h3" : "f-h2 text-center"}
        style={{
          marginBottom: isMobile ? "24px" : "48px",
          padding: isMobile ? "0px 20px" : "0",
        }}
        dangerouslySetInnerHTML={{
          __html: t(
            isMobile ? "how_it_helps_title_mobile" : "how_it_helps_title"
          ),
        }}
      />
      <Row
        style={{
          justifyContent: "center",
          background: isMobile
            ? "linear-gradient(180deg, white 28.3%, #EFEDF5 28.3%)"
            : "linear-gradient(180deg, white 46%, #EFEDF5 46%)",
        }}
      >
        <Container
          style={{
            marginBottom: isMobile ? "45px" : "95px",
            padding: isMobile ? "0px 20px" : "0",
          }}
        >
          {isMobile ? (
            <Row
              style={{
                justifyContent: "flex-end",
                flexWrap: "wrap",
              }}
            >
              <Col
                xs={12}
                lg={5}
                style={{
                  backgroundImage: `url(${CardBg}), linear-gradient(269.81deg, rgba(96, 38, 158, 0.65) 0.14%, rgba(96, 38, 158, 0) 91.09%)`,
                  borderRadius: "12px 12px 0px 0px",
                  backgroundBlendMode: "overlay",
                  backgroundSize: "cover",
                  height: isMobile ? "325px" : "0px",
                }}
              />
              <Col xs={12} lg={7}>
                <Row style={{ gap: 1 }}>
                  <HomeCard
                    title={t("how_it_helps_card_1_title")}
                    desc={t("how_it_helps_card_1_desc")}
                    borderRadius="0px"
                  />
                  <HomeCard
                    title={t("how_it_helps_card_2_title")}
                    desc={t("how_it_helps_card_2_desc")}
                    borderRadius="0px"
                  />
                  <HomeCard
                    title={t("how_it_helps_card_3_title")}
                    desc={t("how_it_helps_card_3_desc")}
                    borderRadius="0px"
                  />
                  <HomeCard
                    title={t("how_it_helps_card_3_title")}
                    desc={t("how_it_helps_card_3_desc")}
                    borderRadius="0px 0px 12px 12px"
                  />
                </Row>
              </Col>
            </Row>
          ) : (
            <Row
              style={{
                backgroundImage: `url(${CardBg}), linear-gradient(269.81deg, rgba(62, 31, 95, 0.5) 0.14%, rgba(96, 38, 158, 0) 91.09%)`,
                backgroundBlendMode: "overlay",
                borderRadius: "32px",
                padding: "40px 45px",
                backgroundSize: "cover",
                justifyContent: "flex-end",
                flexWrap: "wrap",
              }}
            >
              <Col xs={12} lg={5} />
              <Col xs={12} lg={7}>
                <Row style={{ gap: "30px", margin: "0px 0px 30px 0px" }}>
                  <HomeCard
                    title={t("how_it_helps_card_1_title")}
                    desc={t("how_it_helps_card_1_desc")}
                  />
                  <HomeCard
                    title={t("how_it_helps_card_2_title")}
                    desc={t("how_it_helps_card_2_desc")}
                  />
                </Row>
                <Row style={{ gap: "30px", margin: "0px" }}>
                  <HomeCard
                    title={t("how_it_helps_card_3_title")}
                    desc={t("how_it_helps_card_3_desc")}
                  />
                  <HomeCard
                    title={t("how_it_helps_card_4_title")}
                    desc={t("how_it_helps_card_4_desc")}
                  />
                </Row>
              </Col>
            </Row>
          )}
        </Container>
      </Row>
    </div>
  );
};

export default HowItHelps;
