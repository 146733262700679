import { Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const PodcastCard = (props) => {
  const element = props.element ?? {
    cover_image: "https://trtrades.com/wp-content/uploads/2021/09/Gray-Box.jpeg",
    slug: "merak-duygusu-olmayan-insan-yaratici-olamaz",
    name: "Tasarım Hobi Değil Tam Zamanlı Bir Uğraşıdır",
  };

  return (
    <Link to={`/podcast/${element.slug.tr}`} className="boxed-card">
      <Row>
        <Image
          src={element.cover_image}
          style={{
            width: "100%",
            aspectRatio: "1.2",
            borderRadius: "8px",
            marginBottom: 16,
            objectFit: "cover",
          }}
        />

        <Row className="f-label" style={{ display: "inline", marginBottom: 8 }}>
          <span>Podcast •</span>
          <span> </span>
          <span style={{ color: "var(--neutral500)" }}>3 Eylül 2020</span>
        </Row>

        <div className="f-label-l" style={{ fontWeight: 700 }}>
          {element.title.tr}
        </div>
      </Row>
    </Link>
  );
};

export default PodcastCard;
