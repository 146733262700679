import { Container } from "react-bootstrap";
import useIsMobile from "../public/is-Mobile";

const Privacy = () => {
  const isMobile = useIsMobile();
  return (
    <Container
      style={{
        maxWidth: "686px",
        padding: isMobile ? "24px" : "80px 0 74px",
      }}
    >
      <div class="text-content">
        <div className="f-paragraph">
          <p>
            <div className="f-h2">Gizlilik Politikası</div>
          </p>
          <p>
            DesignGost, kişisel verilerinizin güvenliği hususuna azami
            hassasiyet göstermektedir. Kişisel verileriniz 6698 sayılı Kişisel
            Verilerin Korunması Kanunu’na (KVKK) uygun olarak işlenmekte ve
            muhafaza edilmektedir.
          </p>
          <p className="f-h4">
            1. Kişisel Verilerinizin Ne Şekilde İşlenebileceği
          </p>
          <p>
            6698 sayılı KVKK uyarınca, DesignGost ile paylaştığınız kişisel
            verileriniz, tamamen veya kısmen, otomatik olarak veyahut herhangi
            bir veri kayıt sisteminin parçası olmak kaydıyla otomatik olmayan
            yollarla elde edilerek, kaydedilerek, depolanarak, değiştirilerek,
            yeniden düzenlenerek, kısacası veriler üzerinde gerçekleştirilen her
            türlü işleme konu olarak tarafımızdan işlenebilecektir. KVKK
            kapsamında veriler üzerinde gerçekleştirilen her türlü işlem
            “kişisel verilerin işlenmesi” olarak kabul edilmektedir.
          </p>
          <p className="f-h4">
            2. Kişisel Verilerinizin İşlenme Amaçları ve Hukuki Sebepleri
          </p>
          <p>Paylaştığınız kişisel veriler,</p>
          <ul>
            <li>
              Platform üyelerimize verdiğimiz hizmetlerin gereklerini,
              sözleşmenin ve teknolojinin gereklerine uygun şekilde yapabilmek,
              sunulan ürün ve hizmetlerimizi geliştirebilmek,
            </li>
            <li>
              Kamu güvenliğine ilişkin hususlarda ve hukuki uyuşmazlıklarda,
              talep halinde ve mevzuat gereği savcılıklara, mahkemelere ve
              ilgili kamu görevlilerine bilgi verebilmek,
            </li>
            <li>
              Üyelerimize geniş kapsamda çeşitli imkânlar sunabilmek veya bu
              imkânları sunabilecek kişi veya kurumlarla yasal çerçevede
              paylaşabilmek,
            </li>
            <li>Reklam tercihlerini analiz etmek için</li>
          </ul>
          <p>
            6698 sayılı KVKK ve ilgili ikincil düzenlemelere uygun olarak
            işlenecektir.
          </p>
          <p className="f-h4">
            3. Kişisel Verilerinizin Aktarılabileceği Üçüncü Kişi veya
            Kuruluşlar Hakkında Bilgilendirme
          </p>
          <p>
            Yukarıda belirtilen amaçlarla, DesignGost ile paylaştığınız kişisel
            verilerinizin aktarılabileceği kişi / kuruluşlar; ana
            hissedarlarımız, hissedarlarımız, reklam verenler, doğrudan veya
            dolaylı yurt içi / yurt dışı iştiraklerimiz; başta DesignGost.com
            altyapısını kullanan üyeler ve bunlarla sınırlı olmamak üzere
            sunulan hizmet ile ilgili kişi ve kuruluşlar olmak üzere,
            faaliyetlerimizi yürütmek üzere ve/veya Veri İşleyen sıfatı ile
            hizmet aldığımız, iş birliği yaptığımız, program ortağı kuruluşlar,
            yurt içi / yurt dışı kuruluşlar ve diğer 3. kişiler ve
            kuruluşlardır.
          </p>
          <p>
            Reklam amaçlı kullanılacak çerezlere ilişkin düzenlemelerimiz
            DesignGost Çerez Politikası altında olup, şu anda okuduğunuz
            “Gizlilik Politikası”nın bir parçasını oluşturmaktadır. Çerez
            Politikamız hakkında bilgi edinmek için lütfen tıklayın.
          </p>
          <p className="f-h4">4. Kişisel Verilerinizin Toplanma Şekli</p>
          <p>Kişisel verileriniz,</p>
          <ul>
            <li>
              DesignGost internet sitesi ve mobil uygulamalarındaki formlar ile
              ad, soyad, adres, telefon, iş veya özel e-posta adresi gibi
              bilgiler ile kullanıcı adı ve şifresi kullanılarak giriş yapılan
              sayfalardaki tercihleri, gerçekleştirilen işlemlerin IP kayıtları,
              tarayıcı tarafından toplanan çerez verileri ile gezinme süre ve
              detaylarını içeren veriler, konum verileri şeklinde;
            </li>
            <li>
              DesignGost ile ticari ilişki kurmak, iş başvurusu yapmak, teklif
              vermek gibi amaçlarla, kartvizit, özgeçmiş (CV), teklif vermek ve
              sair yollarla kişisel verilerini paylaşan kişilerden alınan,
              fiziki veya sanal bir ortamda, yüz yüze ya da mesafeli, sözlü veya
              yazılı ya da elektronik ortamdan;
            </li>
            <li>
              Ayrıca farklı kanallardan dolaylı yoldan elde edilen; web sitesi,
              blog, yarışma, anket, oyun, kampanya ve benzeri amaçlı kullanılan
              (mikro) web sitelerinden ve sosyal medyadan elde edilen veriler,
              e-bülten okuma veya tıklama hareketleri, kamuya açık veri
              tabanlarının sunduğu veriler, sosyal medya platformları (Facebook,
              Twitter, Instagram, Snapchat, TikTok vb) gibi sosyal paylaşım
              sitelerinden paylaşıma açık profil ve verilerden işlenebilmekte ve
              toplanabilmektedir.
            </li>
          </ul>
          <p className="f-h4">
            5. KVKK Yürürlüğe Girmeden Önce Elde Edilen Kişisel Verileriniz
          </p>
          <p>
            DesignGost, KVKK’nın yürürlük tarihi olan 7 Nisan 2016 tarihinden
            önce yayım hayatına başlamadığı için bu tarihten önce depoladığımız
            bir kişisel veri bulunmamaktadır.
          </p>
          <p className="f-h4">6. Kişisel Verilerinizin Aktarılması</p>
          <p>
            Türkiye’de işlenerek veya Türkiye dışında işlenip muhafaza edilmek
            üzere, yukarıda sayılan yöntemlerden herhangi birisi ile toplanmış
            kişisel verileriniz KVKK kapsamında kalmak kaydıyla ve sözleşme
            amaçlarına uygun olarak yurt dışında bulunan (Kişisel Veriler Kurulu
            tarafından akredite edilen ve kişisel verilerin korunması hususunda
            yeterli korumanın bulunduğu) ülkelerde hizmet aracılarına da
            aktarılabilecektir.
          </p>
          <p>Kişisel Verileriniz;</p>
          <ul>
            <li>
              Ürün ve hizmetlerin sunulması ve tanıtılması için iş birliği
              yapılan ve/veya hizmet alınan iş ortaklarımız,
            </li>
            <li>
              Acil yardım çağrısı halinde konumunuzu tespit edecek olan yetkili
              mercilere,
            </li>
            <li>
              Düzenleyici ve denetleyici kurumlar ile mahkeme ve icra
              müdürlükleri gibi sair resmi kurumlara, kişisel verilerinizi talep
              etmeye yetkili olan diğer kamu kurum veya kuruluşlarına,
            </li>
            <li>
              DesignGost ile ticari ilişki içinde bulunan ve telefon numaranıza
              sahip tüzel kişilere,
            </li>
          </ul>
          <p>Gerekli görüldüğü durumlarda aktarılabilecektir.</p>
        </div>
      </div>
    </Container>
  );
};

export default Privacy;
