import { Col, Image, Overlay, Row, Tooltip } from "react-bootstrap";
import DGButton from "../Buttons/DGButton";

import Usta from "../../public/images/Usta.svg";
import { ReactComponent as YorumGerekiyor } from "../../public/icons/Proje-Gelistirmek-Gerekiyor.svg";

import "./DGTooltip.css";

const PinMessageTooltip = (props) => {
  return (
    <Overlay
      target={props.tooltipRef}
      show={props.show}
      placement={props.placement || "right"}
      onHide={() => props.setShow(!props.show)}
      rootClose={true}
      rootCloseEvent="click"
    >
      <Tooltip className="dg-tooltip" >
        <div style={{ display: "flex", flexDirection: "column", gap: 30 }}>
          <Row
            className="text-center"
            style={{ alignItems: "center", gap: "13px" }}
          >
            <Image
              roundedCircle={true}
              src={props.mentor.image}
              style={{ width: "50px", height: "50px" }}
            />
            <Col xs>
              <Row className="f-h6">{props.mentor.fullName}</Row>
              <Row className="f-label" style={{ color: "var(--neutral300)" }}>
                {props.sentDate}
              </Row>
            </Col>

            <Image
              roundedCircle={true}
              src={Usta}
              style={{ width: "32px", height: "32px" }}
            />
          </Row>

          <Row
            style={{
              padding: "21px 24px 18px 24px",
              border: "1px solid #E3DFED",
              borderRadius: "8px",
              display: "flex",
              gap: "4px",
            }}
          >
            <div className="f-label">Son durum</div>
            <div className="f-h6" style={{ color: "var(--redPrimary)" }}>
              <span style={{ marginRight: "8px", stroke: "var(--redPrimary)" }}>
                <YorumGerekiyor height={24} width={24} />
              </span>
              Geliştirmek gerekiyor
            </div>
          </Row>

          <Row>
            <div className="f-h6">Ustanın yorumu</div>
            <div style={{ marginTop: "8px" }} className="f-paragraph">
              {props.message}
            </div>
          </Row>

          <div>
            <DGButton
              variant="dg-primary"
              size="medium"
              onClick={() => props.setShow(!props.show)}
            >
              Kapat
            </DGButton>
          </div>
        </div>
      </Tooltip>
    </Overlay>
  );
};

export default PinMessageTooltip;