import { Col, Image, Row } from "react-bootstrap";
import { ReactComponent as Usta } from "../../public/images/Usta.svg";

const TestimonialCard = (props) => {
  return (
    <div
      style={{
        padding: "30px 50px 30px 40px",
        background: "white",
        borderRadius: "15px",
        position: "relative",
        textAlign: "start",
      }}
    >
      <Row style={{ gap: "11px" }}>
        <Col xs="auto">
          <Image
            roundedCircle={true}
            src={props.data.mentor.image}
            style={{ width: "48px", height: "48px" }}
          />
        </Col>
        <Col
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div className="f-h6">{props.data.mentor.name}</div>
          <div className="f-label">{props.data.mentor.title}</div>
        </Col>
      </Row>
      <div
        style={{
          opacity: "0.3",
          border: "1px dashed #120E16",
          margin: "30px 0px 30px 0px",
        }}
      />
      <div className="f-label" style={{ whiteSpace: "normal" }}>
        {props.data.text}
      </div>
      <Row style={{ gap: "11px", marginTop: "30px" }}>
        <Col xs="auto">
          <Image
            roundedCircle={true}
            src={props.data.mentee.image}
            style={{ width: "48px", height: "48px" }}
          />
        </Col>
        <Col>
          <div className="f-h6">{props.data.mentee.name}</div>
          <div className="f-label">{props.data.mentee.title}</div>
        </Col>
      </Row>

      <div
        style={{
          padding: "12px 8px 9px 8px",
          background: "var(--yellowPrimary)",
          borderRadius: "0px 0px 28px 28px",
          position: "absolute",
          top: "0px",
          right: "50px",
        }}
      >
        <Usta width={25} height={25} />
      </div>
    </div>
  );
};

export default TestimonialCard;
