import { useState } from "react";
import { Form, Row } from "react-bootstrap";
import DGButton from "../Buttons/DGButton";
import DGInput from "../Inputs/DGInput";

import { ReactComponent as Edit } from "../../public/icons/Edit.svg";
import { ReactComponent as Remove } from "../../public/icons/Remove.svg";
import { ReactComponent as PlusCircle } from "../../public/icons/plus-circle.svg";

const LanguagesView = () => {
  const [editingIndex, setEditingIndex] = useState(null);
  const [data, setData] = useState([]);
  const [isAddingNew, setIsAddingNew] = useState(false);

  return (
    <Row
      style={{
        gap: 10,
        padding: 30,
      }}
    >
      <div
        style={{
          fontSize: 17,
          fontWeight: 600,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div className="f-h4">
          Konuşabildiği diller
          <div
            className="f-label-l"
            style={{ color: "var(--black)", marginTop: 10 }}
          >
            Konuşabildiği diller
          </div>
        </div>

        {!isAddingNew && editingIndex === null ? (
          <div
            className="f-label"
            style={{
              width: "auto",
              color: "var(--purplePrimary)",
              cursor: "pointer",
              height: "min-content",
              fontWeight: 700,
              display: "flex",
              alignItems: "center",
              gap: 10,
            }}
            onClick={() => setIsAddingNew(true)}
          >
            <PlusCircle /> <div style={{ paddingTop: 3 }}>Yeni Dil Ekle</div>
          </div>
        ) : null}
      </div>
      {data.map((element, i) => (
        <ListElement
          isButtonsActive={editingIndex === null && !isAddingNew}
          isEditing={editingIndex === i}
          setEditingIndex={setEditingIndex}
          data={data}
          setData={setData}
          index={i}
          element={element}
        />
      ))}
      {isAddingNew ? (
        <NewElement
          data={data}
          setData={setData}
          setIsAddingNew={setIsAddingNew}
        />
      ) : null}
    </Row>
  );
};

export default LanguagesView;

const ListElement = (props) => {
  const handleSubmit = (event) => {
    let elements = event.target.elements;
    const temp = props.data;
    temp[props.index] = {
      name: elements["author-name"].value,
      mail: elements["e-mail"].value,
    };
    props.setData(temp);
    props.setEditingIndex(null);
    event.preventDefault();
  };

  return (
    <div>
      {props.isEditing ? (
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "20px",
              background: "var(--purplePrimary)",
              color: "white",
              borderRadius: "4px 4px 0 0",
            }}
          >
            {props.element.name}
          </div>
          <div
            style={{
              border: "1px solid #C2BECC",
              borderRadius: "0 0 4px 4px",
              borderTop: "none",
              padding: "40px 30px 30px 30px",
            }}
          >
            <Form onSubmit={handleSubmit}>
              <Row style={{ gap: 20, paddingTop: 25 }}>
                <DGInput
                  title="Dil"
                  name="language"
                  defaultValue={props.element.name}
                />
                <DGInput
                  title="E-posta Adresi"
                  name="e-mail"
                  defaultValue={props.element.mail}
                />
                <div style={{ display: "flex", gap: 24, alignItems: "center" }}>
                  <div
                    className="f-h6"
                    style={{
                      width: "auto",
                      color: "var(--purplePrimary)",
                      cursor: "pointer",
                      height: "min-content",
                    }}
                    onClick={() => props.setEditingIndex(null)}
                  >
                    Vazgeç
                  </div>

                  <DGButton variant="dg-primary" size="medium" type="submit">
                    Kaydet
                  </DGButton>
                </div>
              </Row>
            </Form>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 15,
            padding: "20px",
            borderRadius: 4,
          }}
        >
          <div style={{ flexGrow: 1, textAlign: "start" }}>
            {props.element.name}
          </div>
          {props.isButtonsActive === true ? (
            <div style={{ display: "flex", gap: 15 }}>
              <Edit
                width={20}
                height={20}
                onClick={() => props.setEditingIndex(props.index)}
              />
              <Remove
                stroke="black"
                width={20}
                height={20}
                onClick={() => {
                  props.setData([
                    ...props.data.slice(0, props.index),
                    ...props.data.slice(props.index + 1),
                  ]);
                }}
              />
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

const NewElement = (props) => {
  const handleSubmit = (event) => {
    let elements = event.target.elements;
    props.setData(
      props.data.concat({
        name: elements["author-name"].value,
        mail: elements["e-mail"].value,
      })
    );
    event.preventDefault();
    props.setIsAddingNew(false);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row style={{ gap: 20, paddingTop: 25 }}>
        <DGInput title="Dil" name="author-name" required />
        <DGInput title="Yetkinlik düzeyi" name="e-mail" required />
        <div style={{ display: "flex", gap: 24, alignItems: "center" }}>
          <div
            className="f-h6"
            style={{
              width: "auto",
              color: "var(--purplePrimary)",
              cursor: "pointer",
              height: "min-content",
            }}
            onClick={() => {
              props.setIsAddingNew(false);
            }}
          >
            Vazgeç
          </div>

          <DGButton variant="dg-primary" size="medium" type="submit">
            Kaydet
          </DGButton>
        </div>
      </Row>
    </Form>
  );
};
