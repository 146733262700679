import { Col, Form, Image, Modal, Row } from "react-bootstrap";
import DGButton from "../Buttons/DGButton";

import Icon from "../../public/images/DesignGost-Icon.svg";

const DGModal = (props) => {
  return (
    <Modal
      {...props}
      centered
      style={{ backgroundColor: "rgba(18, 14, 22, 0.85)" }}
    >
      <Modal.Body style={{ padding: "35px 40px 40px" }}>
        <div
          style={{
            fontWeight: 800,
            fontSize: "18px",
            lineHeight: "260x",
            marginBottom: "7px",
          }}
        >
          {props.title}
        </div>
        <div className="f-paragraph">{props.desc}</div>
        {(props.notShowAgain || null) === true ? (
          <Form.Check
            label={<div className="f-label">Bir daha gösterme</div>}
            type="checkbox"
            className="dg-radio-button"
            style={{ marginTop: "30px", paddingLeft: 0 }}
          />
        ) : null}

        <Row
          style={{
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 22,
            marginTop: 25,
          }}
        >
          {(props.textButtonText || null) !== null ? (
            <div
              className="f-h6"
              style={{
                width: "auto",
                color: "var(--purplePrimary)",
                cursor: "pointer",
                height: "min-content",
              }}
              onClick={props.textButtonOnClick}
            >
              {props.textButtonText}
            </div>
          ) : null}
          {(props.buttonText || null) !== null ? (
            <DGButton
              variant="dg-primary"
              size="medium"
              style={{ width: "auto" }}
              onClick={props.buttonOnClick}
            >
              {props.buttonText}
            </DGButton>
          ) : null}
        </Row>
        {(props.mentorTitle || null) !== null ? (
          <Row>
            <div
              style={{ border: ".5px solid #D6D6D6", margin: "40px 0px 35px" }}
            />
            <Row
              style={{
                fontWeight: 800,
                fontSize: "18px",
                marginBottom: "22px",
              }}
            >
              {props.mentorTitle}
            </Row>
            <Row style={{ gap: 16, flexWrap: "nowrap" }}>
              <SuggestedMentor />
              <SuggestedMentor />
              <SuggestedMentor />
            </Row>
          </Row>
        ) : null}
      </Modal.Body>
    </Modal>
  );
};

export default DGModal;

const SuggestedMentor = (props) => {
  return (
    <Col>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Image
          src={Icon}
          style={{ height: "64px", width: "64px" }}
          roundedCircle={true}
        />
        <div
          style={{
            fontWeight: 700,
            fontSize: "15px",
            textAlign: "center",
            margin: "12px 0px 5px",
          }}
        >
          Selin Demirdökenoğlu
        </div>
        <div style={{ fontWeight: 450, fontSize: "12px" }}>Art Director</div>
      </div>
    </Col>
  );
};
