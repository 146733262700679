import { forwardRef } from "react";
import {ReactComponent as CaretDown } from "../public/icons/Caret-Down.svg";

const DividerToggle = forwardRef(({ children, onClick }, ref) => (
  <a
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    style={{ textDecoration: "none", color: "var(--black" }}
  >
    <div style={{ display: "flex" }}>
      <div style={{ flexGrow: 1 }}>{children}</div>
      <div>
        <CaretDown stroke="var(--black)"/>
      </div>
    </div>
  </a>
));

export default DividerToggle;