import { Col, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import useIsMobile from "../../public/is-Mobile";

const VideoCard = (props) => {
  const isMobile = useIsMobile();

  const element = props.element ?? {
    tutorial_id: "0f85eeb9-2a47-4829-b69d-95d1e885ffd7",
    name: "Yeni Başlayanlar İçin Detaylı Anlatım",
  };

  const constants = {
    padding: isMobile ? "16px" : "30px",
    textContentGap: isMobile ? 16 : 18,
  };

  return (
    <Link
      to={`/egitim-videolari/${element.slug}`}
      className="boxed-card"
    >
      <Row
        style={{
          height: "100%",
          gap: 32,
          padding: 24,
          border: "1px solid var(--neutral100)",
          borderRadius: 8,
        }}
      >
        <Image
          src={
            "https://trtrades.com/wp-content/uploads/2021/09/Gray-Box.jpeg"
          }
          style={{
            width: 40,
            height: 40,
            borderRadius: "4px",
          }}
        />
        <Row style={{ gap: 16 }}>
          <Row className="f-label-l" style={{ fontWeight: "700" }}>
            {element.name}
          </Row>
          <Row className="f-label">After Effects Videoları</Row>
        </Row>
        <Row>
          <Col className="f-label-s">
            <span>2.35 Min</span>
            {isMobile ? null : <span> | 3 Eylül 2020</span>}
          </Col>
          <Col xs="auto"></Col>
        </Row>
      </Row>
    </Link>
  );
};

export default VideoCard;
