import { Button, Col, Row } from "react-bootstrap";
import DGButton from "../components/Buttons/DGButton";
import { ReactComponent as New } from "../public/icons/New-Email.svg";
import { ReactComponent as ArrowUp } from "../public/icons/Caret-Up.svg";
import { ReactComponent as ArrowDown } from "../public/icons/Slider-Caret-Down.svg";
import SliderButton from "../components/Buttons/SliderButton";
import DGModal from "../components/Modals/DGModal";
import { useContext, useRef, useState } from "react";
import DGTooltip from "../components/Tooltips/DGTooltip";
import PinMessageTooltip from "../components/Tooltips/PinMessageTooltip";
import CreatePinMessageTooltip from "../components/Tooltips/CreatePinMessageTooltip";
import CloseButton from "../components/Buttons/CloseButton";
import { ToastContext } from "../ContextProvider";

import ProfileHeader from "../components/Profile/ProfileHeader";

const Components = () => {
  const [firstModalShow, setFirstModalShow] = useState(false);
  const [secondModalShow, setSecondModalShow] = useState(false);
  const [thirdModalShow, setThirdModalShow] = useState(false);

  const firstTooltipRef = useRef(null);
  const [showFirstTooltip, setShowFirstTooltip] = useState(false);

  const secondTooltipRef = useRef(null);
  const [showSecondTooltip, setShowSecondTooltip] = useState(false);

  const pinMessageTooltipRef = useRef(null);
  const [showPinMessageTooltip, setShowPinMessageTooltip] = useState(false);

  const pinMenteeTooltipRef = useRef(null);
  const [showPinMenteeTooltip, setShowPinMenteeTooltip] = useState(false);

  const pinMentorTooltipRef = useRef(null);
  const [showPinMentorTooltip, setShowPinMentorTooltip] = useState(false);

  const { setTitle, setActive, setVariant } = useContext(ToastContext);

  return (
    <div>
      <div className="f-h2" style={{ padding: "45px 0px 0px 45px" }}>
        Fonts
      </div>
      <Row style={{ gap: "45px", padding: "45px" }}>
        <Row>
          <Col xs={2}>Button XS </Col>
          <Col className="f-label-s">
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable. If you are going to use a passage of Lorem Ipsum, you
            need to be sure there isn't anything embarrassing hidden in the
            middle of text. All the Lorem Ipsum generators on the Internet tend
            to repeat predefined chunks as necessary, making this the first true
            generator on the Internet. It uses a dictionary of over 200 Latin
            words, combined with a handful of model sentence structures, to
            generate Lorem Ipsum which looks reasonable. The generated Lorem
            Ipsum is therefore always free from repetition, injected humour, or
            non-characteristic words etc.
          </Col>
        </Row>
        <Row>
          <Col xs={2}>Button S </Col>
          <Col className="f-label">
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable. If you are going to use a passage of Lorem Ipsum, you
            need to be sure there isn't anything embarrassing hidden in the
            middle of text. All the Lorem Ipsum generators on the Internet tend
            to repeat predefined chunks as necessary, making this the first true
            generator on the Internet. It uses a dictionary of over 200 Latin
            words, combined with a handful of model sentence structures, to
            generate Lorem Ipsum which looks reasonable. The generated Lorem
            Ipsum is therefore always free from repetition, injected humour, or
            non-characteristic words etc.
          </Col>
        </Row>
        <Row>
          <Col xs={2}>Button Primary </Col>
          <Col className="f-label-l">
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable. If you are going to use a passage of Lorem Ipsum, you
            need to be sure there isn't anything embarrassing hidden in the
            middle of text. All the Lorem Ipsum generators on the Internet tend
            to repeat predefined chunks as necessary, making this the first true
            generator on the Internet. It uses a dictionary of over 200 Latin
            words, combined with a handful of model sentence structures, to
            generate Lorem Ipsum which looks reasonable. The generated Lorem
            Ipsum is therefore always free from repetition, injected humour, or
            non-characteristic words etc.
          </Col>
        </Row>
        <Row>
          <Col xs={2}>Button L </Col>
          <Col className="f-label-xl">
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable. If you are going to use a passage of Lorem Ipsum, you
            need to be sure there isn't anything embarrassing hidden in the
            middle of text. All the Lorem Ipsum generators on the Internet tend
            to repeat predefined chunks as necessary, making this the first true
            generator on the Internet. It uses a dictionary of over 200 Latin
            words, combined with a handful of model sentence structures, to
            generate Lorem Ipsum which looks reasonable. The generated Lorem
            Ipsum is therefore always free from repetition, injected humour, or
            non-characteristic words etc.
          </Col>
        </Row>
        <Row>
          <Col xs={2}>Label</Col>
          <Col className="f-label">
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable. If you are going to use a passage of Lorem Ipsum, you
            need to be sure there isn't anything embarrassing hidden in the
            middle of text. All the Lorem Ipsum generators on the Internet tend
            to repeat predefined chunks as necessary, making this the first true
            generator on the Internet. It uses a dictionary of over 200 Latin
            words, combined with a handful of model sentence structures, to
            generate Lorem Ipsum which looks reasonable. The generated Lorem
            Ipsum is therefore always free from repetition, injected humour, or
            non-characteristic words etc.
          </Col>
        </Row>
        <Row>
          <Col xs={2}>Paragraph</Col>
          <Col className="f-paragraph">
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable. If you are going to use a passage of Lorem Ipsum, you
            need to be sure there isn't anything embarrassing hidden in the
            middle of text. All the Lorem Ipsum generators on the Internet tend
            to repeat predefined chunks as necessary, making this the first true
            generator on the Internet. It uses a dictionary of over 200 Latin
            words, combined with a handful of model sentence structures, to
            generate Lorem Ipsum which looks reasonable. The generated Lorem
            Ipsum is therefore always free from repetition, injected humour, or
            non-characteristic words etc.
          </Col>
        </Row>
        <Row>
          <Col xs={2}>H6</Col>
          <Col className="f-h6">
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable. If you are going to use a passage of Lorem Ipsum, you
            need to be sure there isn't anything embarrassing hidden in the
            middle of text. All the Lorem Ipsum generators on the Internet tend
            to repeat predefined chunks as necessary, making this the first true
            generator on the Internet. It uses a dictionary of over 200 Latin
            words, combined with a handful of model sentence structures, to
            generate Lorem Ipsum which looks reasonable. The generated Lorem
            Ipsum is therefore always free from repetition, injected humour, or
            non-characteristic words etc.
          </Col>
        </Row>
        <Row>
          <Col xs={2}>H5</Col>
          <Col className="f-h5">
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable. If you are going to use a passage of Lorem Ipsum, you
            need to be sure there isn't anything embarrassing hidden in the
            middle of text. All the Lorem Ipsum generators on the Internet tend
            to repeat predefined chunks as necessary, making this the first true
            generator on the Internet. It uses a dictionary of over 200 Latin
            words, combined with a handful of model sentence structures, to
            generate Lorem Ipsum which looks reasonable. The generated Lorem
            Ipsum is therefore always free from repetition, injected humour, or
            non-characteristic words etc.
          </Col>
        </Row>
        <Row>
          <Col xs={2}>H4</Col>
          <Col className="f-h4">
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable. If you are going to use a passage of Lorem Ipsum, you
            need to be sure there isn't anything embarrassing hidden in the
            middle of text. All the Lorem Ipsum generators on the Internet tend
            to repeat predefined chunks as necessary, making this the first true
            generator on the Internet. It uses a dictionary of over 200 Latin
            words, combined with a handful of model sentence structures, to
            generate Lorem Ipsum which looks reasonable. The generated Lorem
            Ipsum is therefore always free from repetition, injected humour, or
            non-characteristic words etc.
          </Col>
        </Row>
        <Row>
          <Col xs={2}>H3</Col>
          <Col className="f-h3">
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable. If you are going to use a passage of Lorem Ipsum, you
            need to be sure there isn't anything embarrassing hidden in the
          </Col>
        </Row>
        <Row>
          <Col xs={2}>H2</Col>
          <Col className="f-h2">
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable. If you are going to use a passage of Lorem Ipsum, you
            need to be sure there isn't anything embarrassing hidden in the
          </Col>
        </Row>
        <Row>
          <Col xs={2}>H1</Col>
          <Col className="f-h1">
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable. If you are going to use a passage of Lorem Ipsum, you
            need to be sure there isn't anything embarrassing hidden in the
          </Col>
        </Row>
      </Row>
      <div className="f-h2" style={{ padding: "45px 0px 0px 45px" }}>
        Buttons
      </div>
      <Row style={{ gap: "45px", padding: "45px" }}>
        <Row>
          <Col xs={2}>Medium / Default </Col>
          <Col>
            <DGButton variant="dg-primary" size="medium">
              Primary Button
            </DGButton>
          </Col>
        </Row>
        <Row>
          <Col xs={2}>Medium / Icon left </Col>
          <Col>
            <DGButton variant="dg-primary" size="medium-ic">
              <New width={20} /> Primary Button
            </DGButton>
          </Col>
        </Row>
        <Row>
          <Col xs={2}>Large / Default </Col>
          <Col>
            <DGButton variant="dg-primary" size="lg">
              Primary Button
            </DGButton>
          </Col>
        </Row>
        <Row>
          <Col xs={2}>Large / Icon left </Col>
          <Col>
            <DGButton variant="dg-primary" size="lg">
              <New width={24} /> Primary Button
            </DGButton>
          </Col>
        </Row>
        <Row>
          <Col xs={2}>Small / Default </Col>
          <Col>
            <DGButton variant="dg-primary" size="sm">
              Primary Button
            </DGButton>
          </Col>
        </Row>
        <Row>
          <Col xs={2}>Small / Icon Left </Col>
          <Col>
            <DGButton variant="dg-primary" size="sm">
              <New width={16} /> Primary Button
            </DGButton>
          </Col>
        </Row>
        <Row>
          <Col xs={2}>Extra Small / Default </Col>
          <Col>
            <DGButton variant="dg-primary" size="xs">
              Primary Button
            </DGButton>
          </Col>
        </Row>
      </Row>
      <Row style={{ gap: "45px", padding: "45px" }}>
        <Row>
          <Col xs={2}>Medium / Default </Col>
          <Col>
            <DGButton variant="dg-secondary" size="medium">
              Primary Button
            </DGButton>
          </Col>
        </Row>
        <Row>
          <Col xs={2}>Medium / Icon left </Col>
          <Col>
            <DGButton variant="dg-secondary" size="medium">
              <New width={20} /> Primary Button
            </DGButton>
          </Col>
        </Row>
        <Row>
          <Col xs={2}>Large / Default </Col>
          <Col>
            <DGButton variant="dg-secondary" size="lg">
              Primary Button
            </DGButton>
          </Col>
        </Row>
        <Row>
          <Col xs={2}>Large / Icon left </Col>
          <Col>
            <DGButton variant="dg-secondary" size="lg">
              <New width={24} /> Primary Button
            </DGButton>
          </Col>
        </Row>
        <Row>
          <Col xs={2}>Small / Default </Col>
          <Col>
            <DGButton variant="dg-secondary" size="sm">
              Primary Button
            </DGButton>
          </Col>
        </Row>
        <Row>
          <Col xs={2}>Small / Icon Left </Col>
          <Col>
            <DGButton variant="dg-secondary" size="sm">
              <New width={16} /> Primary Button
            </DGButton>
          </Col>
        </Row>
        <Row>
          <Col xs={2}>Extra Small / Default </Col>
          <Col>
            <DGButton variant="dg-secondary" size="xs">
              Primary Button
            </DGButton>
          </Col>
        </Row>
      </Row>
      <Row style={{ gap: "45px", padding: "45px" }}>
        <Row>
          <Col xs={2}>Medium / Default </Col>
          <Col>
            <DGButton variant="tertiary" size="medium">
              Primary Button
            </DGButton>
          </Col>
        </Row>
        <Row>
          <Col xs={2}>Medium / Icon left </Col>
          <Col>
            <DGButton variant="tertiary" size="medium-ic">
              <New width={20} /> Primary Button
            </DGButton>
          </Col>
        </Row>
        <Row>
          <Col xs={2}>Large / Default </Col>
          <Col>
            <DGButton variant="tertiary" size="lg">
              Primary Button
            </DGButton>
          </Col>
        </Row>
        <Row>
          <Col xs={2}>Large / Icon left </Col>
          <Col>
            <DGButton variant="tertiary" size="lg">
              <New width={24} /> Primary Button
            </DGButton>
          </Col>
        </Row>
        <Row>
          <Col xs={2}>Small / Default </Col>
          <Col>
            <DGButton variant="tertiary" size="sm">
              Primary Button
            </DGButton>
          </Col>
        </Row>
        <Row>
          <Col xs={2}>Small / Icon Left </Col>
          <Col>
            <DGButton variant="tertiary" size="sm">
              <New width={16} /> Primary Button
            </DGButton>
          </Col>
        </Row>
        <Row>
          <Col xs={2}>Extra Small / Default </Col>
          <Col>
            <DGButton variant="tertiary" size="xs">
              Primary Button
            </DGButton>
          </Col>
        </Row>
      </Row>
      <Row
        style={{
          gap: "45px",
          padding: "45px",
          background: "var(--purplePrimary)",
          color: "white",
        }}
      >
        <Row>
          <Col xs={2}>Medium / Default </Col>
          <Col>
            <DGButton variant="quaternary" size="medium">
              Primary Button
            </DGButton>
          </Col>
        </Row>
        <Row>
          <Col xs={2}>Medium / Icon left </Col>
          <Col>
            <DGButton variant="quaternary" size="medium">
              <New width={20} /> Primary Button
            </DGButton>
          </Col>
        </Row>
        <Row>
          <Col xs={2}>Large / Default </Col>
          <Col>
            <DGButton variant="quaternary" size="lg">
              Primary Button
            </DGButton>
          </Col>
        </Row>
        <Row>
          <Col xs={2}>Large / Icon left </Col>
          <Col>
            <DGButton variant="quaternary" size="lg">
              <New width={24} /> Primary Button
            </DGButton>
          </Col>
        </Row>
        <Row>
          <Col xs={2}>Small / Default </Col>
          <Col>
            <DGButton variant="quaternary" size="sm">
              Primary Button
            </DGButton>
          </Col>
        </Row>
        <Row>
          <Col xs={2}>Small / Icon Left </Col>
          <Col>
            <DGButton variant="quaternary" size="sm">
              <New width={16} /> Primary Button
            </DGButton>
          </Col>
        </Row>
        <Row>
          <Col xs={2}>Extra Small / Default </Col>
          <Col>
            <DGButton variant="quaternary" size="xs">
              Primary Button
            </DGButton>
          </Col>
        </Row>
      </Row>
      <Row style={{ gap: "45px", padding: "45px" }}>
        <Row>
          <Col xs={2} style={{ padding: "15px" }}>
            Slider Buttons
          </Col>
          <Col
            xs="auto"
            style={{ display: "flex", gap: "15px", padding: "15px" }}
          >
            <SliderButton next={true} />
            <SliderButton next={false} />
          </Col>
          <Col
            xs="auto"
            style={{
              display: "flex",
              gap: "15px",
              background: "var(--purplePrimary)",
              padding: "15px",
            }}
          >
            <SliderButton next={true} variant="dark" />
            <SliderButton next={false} variant="dark" />
          </Col>
          <Col
            xs="auto"
            style={{ display: "flex", gap: "15px", padding: "15px" }}
          >
            <Button variant="slider-button-light">
              <ArrowUp height={8} />
            </Button>
            <Button variant="slider-button-light">
              <ArrowDown width={12} />
            </Button>
          </Col>
          <Col
            xs="auto"
            style={{
              display: "flex",
              gap: "15px",
              background: "var(--purplePrimary)",
              padding: "15px",
            }}
          >
            <Button variant="slider-button-dark">
              <ArrowUp height={8} />
            </Button>
            <Button variant="slider-button-dark">
              <ArrowDown width={12} />
            </Button>
          </Col>
        </Row>
      </Row>
      <Row style={{ gap: "45px", padding: "45px" }}>
        <Row>
          <Col xs={2} style={{ padding: "15px" }}>
            Close Button
          </Col>
          <Col
            xs="auto"
            style={{ display: "flex", gap: "15px", padding: "15px" }}
          >
            <CloseButton />
          </Col>
        </Row>
      </Row>
      <Row style={{ gap: "45px", padding: "45px" }}>
        <Row>
          <Col xs={2} style={{ padding: "15px" }}>
            Modals
          </Col>
          <Col
            xs="auto"
            style={{ display: "flex", gap: "15px", padding: "15px" }}
          >
            <div>
              <DGButton
                variant="dg-primary"
                size="medium"
                onClick={() => setFirstModalShow(true)}
              >
                Show First Modal
              </DGButton>
            </div>
            <div>
              <DGButton
                variant="dg-primary"
                size="medium"
                onClick={() => setSecondModalShow(true)}
              >
                Show Second Modal
              </DGButton>
            </div>
            <div>
              <DGButton
                variant="dg-primary"
                size="medium"
                onClick={() => setThirdModalShow(true)}
              >
                Show Third Modal
              </DGButton>
            </div>

            <DGModal
              title="Ustalar projelerinize yorum yapsın"
              desc="Her ay Usta başı sınırsız mesaj ve 1 proje yollayabilirsiniz."
              textButtonText="Daha Fazla Öğren"
              buttonText="Anladım"
              textButtonOnClick={() => setFirstModalShow(false)}
              buttonOnClick={() => setFirstModalShow(false)}
              show={firstModalShow}
              onHide={() => setFirstModalShow(false)}
            />
            <DGModal
              title="Ustalar projelerinize yorum yapsın"
              desc="Her ay Usta başı sınırsız mesaj ve 1 proje yollayabilirsiniz."
              textButtonText="Daha Fazla Öğren"
              buttonText="Anladım"
              textButtonOnClick={() => setSecondModalShow(false)}
              buttonOnClick={() => setSecondModalShow(false)}
              show={secondModalShow}
              onHide={() => setSecondModalShow(false)}
              notShowAgain={true}
            />
            <DGModal
              title="Seçtiğiniz Usta ve proje kategorileri uyuşmuyor."
              desc="UI/UX kategorisine ait bir proje yolluyorsunuz! Kategorinizi değiştirebilir ya da projenizle eşleşen daha uygun bir Usta seçebilirsiniz."
              textButtonText="Yeni Usta Seç"
              buttonText="Aynı Usta ile Devam"
              textButtonOnClick={() => setThirdModalShow(false)}
              buttonOnClick={() => setThirdModalShow(false)}
              show={thirdModalShow}
              onHide={() => setThirdModalShow(false)}
              mentorTitle="UI/UX Usta önerileri"
            />
          </Col>
        </Row>
      </Row>

      <Row style={{ gap: "45px", padding: "45px" }}>
        <Row>
          <Col xs={2} style={{ padding: "15px" }}>
            Tooltips
          </Col>
          <Col
            xs="auto"
            style={{ display: "flex", gap: "35px", padding: "15px" }}
          >
            <div>
              <div
                onClick={() => setShowFirstTooltip(true)}
                ref={firstTooltipRef}
              >
                Show Big tooltip
              </div>
            </div>
            <DGTooltip
              tooltipRef={firstTooltipRef}
              show={showFirstTooltip}
              setShow={setShowFirstTooltip}
              desc="Her ay Usta başı sınırsız mesaj ve 1 proje yollayabilirsiniz."
              textButtonText="Daha Fazla Öğren"
              buttonText="Anladım"
              textButtonOnClick={() => setShowFirstTooltip(false)}
              buttonOnClick={() => setShowFirstTooltip(false)}
            />

            <div>
              <div
                onClick={() => setShowSecondTooltip(true)}
                ref={secondTooltipRef}
              >
                Show Big tooltip with heading
              </div>
            </div>
            <DGTooltip
              tooltipRef={secondTooltipRef}
              show={showSecondTooltip}
              setShow={setShowSecondTooltip}
              title="Ustalar projelerinize yorum yapsın"
              desc="Her ay Usta başı sınırsız mesaj ve 1 proje yollayabilirsiniz."
              textButtonText="Daha Fazla Öğren"
              buttonText="Anladım"
              textButtonOnClick={() => setShowSecondTooltip(false)}
              buttonOnClick={() => setShowSecondTooltip(false)}
            />

            <div>
              <div
                onClick={() => setShowPinMessageTooltip(true)}
                ref={pinMessageTooltipRef}
              >
                Show Pin message
              </div>
            </div>

            <PinMessageTooltip
              tooltipRef={pinMessageTooltipRef}
              show={showPinMessageTooltip}
              setShow={setShowPinMessageTooltip}
              mentor={{
                image:
                  "https://www.designgost.com/img/4e312348-8be8-4a45-ab83-6a5b4fb99a35/hakan-ertan.jpg?fm=webp&fit=max&crop=1350%2C1350%2C0%2C0&w=200&s=ee609c601fab0ec9585be45177fa5a4b",
                fullName: "Hakan Ertan",
              }}
              message="Renkler ile ilgili şöyle yapmalısın lorem ipsum dolar sit belki biraz farklı renkler mesela mor, mavi yada kırmızı deneyebilirsin."
              sentDate="16 Haziran 2022"
            />

            <div>
              <div
                onClick={() => setShowPinMenteeTooltip(true)}
                ref={pinMenteeTooltipRef}
              >
                Show Mentee Pin message
              </div>
            </div>

            <CreatePinMessageTooltip
              tooltipRef={pinMenteeTooltipRef}
              show={showPinMenteeTooltip}
              setShow={setShowPinMenteeTooltip}
              sender={{
                image:
                  "https://www.designgost.com/img/4e312348-8be8-4a45-ab83-6a5b4fb99a35/hakan-ertan.jpg?fm=webp&fit=max&crop=1350%2C1350%2C0%2C0&w=200&s=ee609c601fab0ec9585be45177fa5a4b",
                fullName: "Hakan Ertan",
              }}
            />

            <div>
              <div
                onClick={() => setShowPinMentorTooltip(true)}
                ref={pinMentorTooltipRef}
              >
                Show Mentor Pin message
              </div>
            </div>

            <CreatePinMessageTooltip
              tooltipRef={pinMentorTooltipRef}
              show={showPinMentorTooltip}
              setShow={setShowPinMentorTooltip}
              isMentor={true}
              sender={{
                image:
                  "https://www.designgost.com/img/4e312348-8be8-4a45-ab83-6a5b4fb99a35/hakan-ertan.jpg?fm=webp&fit=max&crop=1350%2C1350%2C0%2C0&w=200&s=ee609c601fab0ec9585be45177fa5a4b",
                fullName: "Hakan Ertan",
              }}
            />
          </Col>
        </Row>
      </Row>

      <Row style={{ gap: "45px", padding: "45px" }}>
        <Row>
          <Col xs={2} style={{ padding: "15px" }}>
            Toast
          </Col>
          <Col
            xs="auto"
            style={{ display: "flex", gap: "35px", padding: "15px" }}
          >
            <div>
              <div
                onClick={() => {
                  setTitle(
                    <div>
                      <Row className="f-h6">
                        Success
                      </Row>
                      <Row>Example Toast Message</Row>
                    </div>
                  );
                  setVariant("success");
                  setActive(true);
                }}
              >
                Show Toast Message
              </div>
            </div>
          </Col>
          <Col
            xs="auto"
            style={{ display: "flex", gap: "35px", padding: "15px" }}
          >
            <div>
              <div
                onClick={() => {
                  setTitle(
                    <div>
                      <Row className="f-h6">
                        Error
                      </Row>
                      <Row>Example Toast Message</Row>
                    </div>
                  );
                  setVariant("error");
                  setActive(true);
                }}
              >
                Show Purple Toast Message
              </div>
            </div>
          </Col>
        </Row>
      </Row>

      <Row style={{ gap: "45px", padding: "45px" }}>
        <Row style={{ gap: "25px" }}>
          <Col xs={2} style={{ padding: "15px" }}>
            Profile Side Bar Component
          </Col>
          <Col xs={2}>
            <ProfileHeader
              isMentor={true}
              user={{
                numbers: { project: 0, mentee: 0, content: 0 },
                mentees: [],
                applications: [],
                links: [],
              }}
              isCurrentUser={true}
            />
          </Col>
          <Col xs={2}>
            <ProfileHeader
              isMentor={true}
              user={{
                numbers: { project: 62, mentee: 41, content: 0 },
                mentees: [{}, {}, {}],
                applications: [{}, {}, {}],
                links: [{}, {}, {}],
              }}
              isCurrentUser={true}
            />
          </Col>
          <Col xs={2}>
            <ProfileHeader
              isMentor={false}
              user={{
                numbers: { project: 0, mentee: 0, content: 0 },
                mentors: [],
                projects: [],
                links: [{}, {}, {}],
              }}
              isCurrentUser={true}
            />
          </Col>
          <Col xs={2}>
            <ProfileHeader
              isMentor={false}
              user={{
                numbers: { project: 62, mentee: 41, content: 0 },
                mentors: [{}, {}, {}],
                projects: [{}, {}, {}],
                links: [{}, {}, {}],
              }}
              isCurrentUser={true}
            />
          </Col>

          <Col xs={2} style={{ padding: "15px" }}></Col>

          <Col xs={2}>
            <ProfileHeader
              isMentor={true}
              user={{
                numbers: { project: 0, mentee: 0, content: 0 },
                mentees: [],
                links: [],
              }}
              isCurrentUser={false}
            />
          </Col>
          <Col xs={2}>
            <ProfileHeader
              isMentor={true}
              user={{
                about:
                  "I use human-centered design methodologies and innovative ideas to address challenges, leading all product development processes from research to execution. I enjoy figuring out how to make things work and bringing my own unique twist to everyday things. I have a demonstrated track record of designing and leading digital products like as websites, mobile applications, and a variety of other touchpoints.",
                numbers: { project: 62, mentee: 41, content: 13 },
                mentees: [{}, {}, {}],
                links: [],
              }}
              isCurrentUser={false}
            />
          </Col>
          <Col xs={2}>
            <ProfileHeader
              isMentor={false}
              user={{
                numbers: { project: 0, mentee: 0, content: 0 },
                mentees: [{}, {}, {}],
                links: [],
              }}
              isCurrentUser={false}
            />
          </Col>
          <Col xs={2}>
            <ProfileHeader
              isMentor={false}
              user={{
                about:
                  "I use human-centered design methodologies and innovative ideas to address challenges, leading all product development processes from research to execution. I enjoy figuring out how to make things work and bringing my own unique twist to everyday things. I have a demonstrated track record of designing and leading digital products like as websites, mobile applications, and a variety of other touchpoints.",
                numbers: { project: 0, mentee: 0, content: 0 },
                mentors: [{}, {}, {}],
                links: [],
              }}
              isCurrentUser={false}
            />
          </Col>
        </Row>
      </Row>
    </div>
  );
};

export default Components;
