import React, { useState, useEffect } from "react";
import { Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import DGButton from "../../components/Buttons/DGButton";
import DGInput from "../../components/Inputs/DGInput";

// Utils
import RegisterLayout from "../../layout/RegisterLayout";

const Signup = () => {
  const [query, setQuery] = useState("");
  const [signupType, setSignupType] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (signupType === "student") {
      setSignupType("Çırak");
    } else {
      setSignupType("Usta");
    }
  }, [signupType, setSignupType]);

  useEffect(() => {
    const currentQuery = window.location.search.replace("?type=", "");

    setQuery(currentQuery);
  }, [setQuery]);

  const mockForm = [
    {
      label: "Ad - Soyad",
      input: "name",
      placeholder: "",
      settings: {
        required: true,
      },
    },
    {
      label: "E-posta adresi",
      input: "email",
      placeholder: "",
      settings: {
        required: true,
      },
    },
    {
      label: "Kullanıcı Adı",
      input: "username",
      placeholder: "",
      settings: {
        required: true,
      },
    },
    {
      label: "Parola",
      input: "password",
      placeholder: "6+ karakter",
      settings: {
        required: true,
        minLength: 6,
      },
    },
    {
      label: "Parola Onayla",
      input: "checkPassword",
      placeholder: "",
      settings: {
        required: true,
      },
    },
  ];

  const onSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target),
      formDataObj = Object.fromEntries(formData.entries());

    navigate("/login-otp");

    // signUpEmailPassword(formDataObj["email"], formDataObj["password"], {
    //   displayName: formDataObj["name"].trim(),
    // });
  };

  return (
    <RegisterLayout>
      <Row style={{ maxWidth: 440, gap: 45 }}>
        <Row style={{ gap: 20 }}>
          <h5 className="f-h5" style={{ fontWeight: 700 }}>
            {signupType} olarak kayıt olmak üzeresin
          </h5>
          <div className="f-label">
            Aşağıdaki kutulara bilgilerinizi giriniz
          </div>
        </Row>

        <Form
          onSubmit={onSubmit}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 18,
          }}
        >
          {mockForm.map((item, index) => (
            <DGInput
              name={item.input}
              title={item.label}
              placeholder={item.placeholder}
            />
          ))}

          <Form.Check
            label={
              <div className="f-label">
                Hesap oluşturarak{" "}
                <Link
                  to="/kullanim-kosullari"
                  style={{ color: "var(--purplePrimary)" }}
                >
                  Kullanım Koşulları
                </Link>
                ,{" "}
                <Link
                  to="/gizlilik-politikasi"
                  style={{ color: "var(--purplePrimary)" }}
                >
                  Gizlilik Politikamızı
                </Link>{" "}
                kabul etmiş sayılırsınız.
              </div>
            }
            type="checkbox"
            className="dg-radio-button"
            style={{ paddingLeft: 0 }}
            required
          />
          <DGButton
            variant="dg-primary"
            size="medium"
            style={{ width: "fit-content", marginTop: 16 }}
            type="submit"
          >
            Hesap Oluştur
          </DGButton>
        </Form>
      </Row>
    </RegisterLayout>
  );
};

export default Signup;
