import {
  Row,
  Col,
  Image,
  Overlay,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import { ReactComponent as Label } from "../public/icons/Label.svg";
import { ReactComponent as More } from "../public/icons/More.svg";
import { ReactComponent as Download } from "../public/icons/Download.svg";
import { ReactComponent as CaretRigth } from "../public/images/caret-right.svg";
import DGButton from "../components/Buttons/DGButton";
import useBreakpoint from "../public/use-breakpoint";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { Link } from "react-router-dom";
import PDF from "../public/Metis247434.pdf";
import AcademicPaperCard from "../components/Cards/AcademicPaperCard";
import SliderButton from "../components/Buttons/SliderButton";
import Icon from "../public/images/DesignGost-Icon.svg";
import useIsMobile from "../public/is-Mobile";
import { ReactComponent as CaretLeft } from "../public/images/caret-left.svg";

const AcademicPaperDetail = () => {
  const target = useRef(null);
  const isMobile = useIsMobile();

  const authors = [
    {
      image:
        "https://www.designgost.com/img/4e312348-8be8-4a45-ab83-6a5b4fb99a35/hakan-ertan.jpg?fm=webp&fit=max&crop=1350%2C1350%2C0%2C0&w=200&s=ee609c601fab0ec9585be45177fa5a4b",
      name: "Hakan Ertan",
      title: "Product Designer",
      username: "seyyahil",
      numbers: { projects: 41, mentees: 62, contents: 3, papers: 12 },
      categories: ["Creative Direction", "Art Direction", "Animation"],
      papers: [],
    },
    { name: "Emre Ertan", title: "Software Developer" },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [heightOfHeader, setHeightOfHeader] = useState(0);
  const size = useBreakpoint();
  const [minimalTop, setMinimalTop] = useState(false);
  const [numPages, setNumPages] = useState(null);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const scroll = () => {
    if (
      document
        .getElementsByClassName("scroll-container")[0]
        ?.getBoundingClientRect().y <=
      heightOfHeader + 50
    ) {
      setMinimalTop(true);
    } else {
      setMinimalTop(false);
    }
  };

  useEffect(() => {
    const height = document.getElementsByClassName("navbar")[0].clientHeight;
    setHeightOfHeader(isMobile ? height : height - 30);
  }, [size]);

  const [show, setShow] = useState(false);
  const [userClicked, setUserClicked] = useState(false);

  return isMobile && userClicked ? (
    <MobileOverlay
      authors={authors}
      currentIndex={currentIndex}
      setCurrentIndex={setCurrentIndex}
      setUserClicked={setUserClicked}
    />
  ) : (
    <div style={{ height: `calc(100vh - ${heightOfHeader}px)` }}>
      <Row style={{ height: "100%" }}>
        <Col
          xs
          style={{ height: "100%", display: "flex", flexDirection: "column" }}
        >
          <Row
            style={{
              background: "#F0EDF6",
              padding: isMobile
                ? "20px"
                : minimalTop === true
                ? "15px 40px"
                : "30px 40px",
              gap: "100px",
              transition: "all 0.5s",
            }}
          >
            <Col
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "6px",
                justifyContent: "center",
              }}
            >
              <div
                className={
                  isMobile
                    ? minimalTop === true
                      ? "f-label"
                      : "f-h6"
                    : minimalTop === true
                    ? "f-label"
                    : "f-h4"
                }
                style={{ fontWeight: 700 }}
              >
                MSKU Bodrum Güzel Sanatlar Fakültesi Uluslararası Sanat
                Sempozyumu - MSKU Bodrum Faculty of Fine Arts International Art
                Symposium
              </div>
              {isMobile ? (
                <Row
                  style={{
                    display: minimalTop === true ? "none" : "flex",
                    marginTop: "12px",
                    alignItems: "center",
                    gap: 12,
                  }}
                >
                  <Row
                    style={{
                      display: minimalTop === true ? "none" : "flex",
                      alignItems: "flex-end",
                      gap: 4,
                    }}
                  >
                    <Col xs="auto" className="f-label">
                      22 Ekim 2022
                    </Col>
                    <Col xs="auto" className="f-label">
                      |
                    </Col>
                    <Col xs="auto" className="f-label">
                      Grafik Tasarım, Sanat
                    </Col>
                  </Row>
                  <div
                    style={{
                      display: "flex",
                      gap: 5,
                      flexDirection: "column",
                    }}
                  >
                    <div className="f-label" style={{ fontWeight: 700 }}>
                      Yazarlar:
                    </div>
                    <Row style={{ gap: 20 }}>
                      {authors.map((x, i) => (
                        <Col
                          xs="auto"
                          onClick={() => {
                            setCurrentIndex(i);
                            setUserClicked(true);
                          }}
                        >
                          <Row style={{ gap: 8, alignContent: "center" }}>
                            <Image
                              src={x.image || Icon}
                              style={{ height: "24px", width: "24px" }}
                              roundedCircle={true}
                            />
                            {x.name}
                          </Row>
                        </Col>
                      ))}
                    </Row>
                  </div>
                </Row>
              ) : (
                <Row
                  style={{
                    display: minimalTop === true ? "none" : "flex",
                    marginTop: "20px",
                    alignItems: "center",
                    gap: 12,
                  }}
                >
                  <Col xs="auto" className="f-label">
                    <b>Yazarlar:</b> Hakan Ertan, Mesut Uğurlu
                  </Col>
                  <Col xs="auto" className="f-label">
                    |
                  </Col>
                  <Col xs="auto" className="f-label">
                    22 Ekim 2022
                  </Col>
                  <Col xs="auto" className="f-label">
                    |
                  </Col>
                  <Col xs="auto" className="f-label">
                    <Label
                      width={20}
                      height={20}
                      style={{
                        marginRight: "8px",
                      }}
                    />
                    Grafik Tasarım, Sanat
                  </Col>
                </Row>
              )}
            </Col>
            {isMobile ? null : (
              <Col xs="auto">
                <DGButton variant="dg-primary" size="sm">
                  <Download width={20} height={20} />
                  PDF İndir
                </DGButton>

                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>Tooltip on</Tooltip>}
                >
                  <DGButton
                    variant="tertiary"
                    style={{
                      background: "white",
                      borderRadius: "100px",
                      borderWidth: "0px",
                      marginLeft: "30px",
                      width: "38px",
                      height: "38px",
                    }}
                    ref={target}
                    onClick={() => setShow(!show)}
                  >
                    <More width={20} height={20} />
                  </DGButton>
                </OverlayTrigger>
              </Col>
            )}
          </Row>
          <div
            style={{
              background: "#1F1826",
              flexGrow: "1",
              padding: "50px",
              overflow: "auto",
              display: "flex",
              justifyContent: "center",
            }}
            onScroll={scroll}
          >
            <Document
              file={PDF}
              onLoadSuccess={onDocumentLoadSuccess}
              className="scroll-container"
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
              ))}
            </Document>

            {isMobile ? (
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  padding: 20,
                  left: 0,
                  right: 0,
                  background: "#1F1826",
                  borderTop: "1px solid rgba(255, 255, 255, 0.3)",
                }}
              >
                <DGButton
                  variant="dg-primary"
                  size="sm"
                  style={{ width: "100%" }}
                >
                  <Download width={20} height={20} />
                  PDF İndir
                </DGButton>
              </div>
            ) : null}
          </div>
        </Col>
        {isMobile ? null : (
          <Col
            xs="auto"
            style={{
              height: "100%",
              overflow: "auto",
              position: "relative",
              width: "370px",
            }}
          >
            {authors.length > 1 ? (
              <Row
                style={{
                  borderBottom: "1px solid var(--neutral100)",
                  padding: "0px 20px 0px 16px",
                  justifyContent: "center",
                }}
              >
                <Col xs style={{ display: "flex", gap: "10px" }}>
                  {authors.map((x, i) => (
                    <div
                      style={{
                        borderBottom:
                          currentIndex === i
                            ? "2px solid var(--purplePrimary)"
                            : "none",
                        padding: "16px 0px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setCurrentIndex(i);
                      }}
                    >
                      <Image
                        src={x.image || Icon}
                        style={{ height: "32px", width: "32px" }}
                        roundedCircle={true}
                      />
                    </div>
                  ))}
                </Col>

                <Col
                  xs="auto"
                  style={{
                    display: "flex",
                    gap: "14px",
                    alignItems: "center",
                  }}
                >
                  <SliderButton
                    next={true}
                    onClick={() => {
                      if (currentIndex + 1 === authors.length) {
                        setCurrentIndex(0);
                      } else {
                        setCurrentIndex(currentIndex + 1);
                      }
                    }}
                  />

                  <SliderButton
                    next={false}
                    onClick={() => {
                      if (currentIndex === 0) {
                        setCurrentIndex(authors.length - 1);
                      } else {
                        setCurrentIndex(currentIndex - 1);
                      }
                    }}
                  />
                </Col>
              </Row>
            ) : null}

            {/* Author */}

            <Author data={authors[currentIndex]} />

            {/* Author's Other Papers */}

            <Row
              style={{
                borderBottom: "1px solid var(--neutral100)",
                display:
                  (authors[currentIndex].papers || null) === null
                    ? "none"
                    : "flex",
              }}
            >
              <Row
                style={{
                  padding: "20px 20px 0 20px",
                  justifyContent: "space-between",
                }}
              >
                <Col
                  xs="auto"
                  className="f-label-l"
                  style={{ fontWeight: 700 }}
                >
                  Diğer yazılar
                  <span className="f-label-s"> (12 yazı)</span>
                </Col>
                <Col xs="auto">
                  <Link
                    to={`/ustalar/${authors[currentIndex].username}#academic`}
                    className="f-label-m"
                  >
                    Tüm yazılar
                    <CaretRigth width={24} style={{ marginLeft: "8px" }} />
                  </Link>
                </Col>
              </Row>
              <AcademicPaperCard
                title="MSKU Bodrum Güzel Sanatlar Fakültesi Uluslararası Sanat Sempozyumu - MSKU Bodrum Faculty of Fine Arts International Art Symposium"
                imageWidth="80px"
              />
            </Row>

            {/* Related Papers */}

            <Row>
              <Row
                className="f-label-l"
                style={{
                  padding: "20px 20px 0 20px",
                  justifyContent: "space-between",
                  fontWeight: 700,
                }}
              >
                <Col xs="auto">İlgili diğer yazılar</Col>
              </Row>

              {[...Array(5)].map((i) => (
                <div style={{ borderBottom: "1px solid var(--neutral100)" }}>
                  <AcademicPaperCard
                    title="MSKU Bodrum Güzel Sanatlar Fakültesi Uluslararası Sanat Sempozyumu - MSKU Bodrum Faculty of Fine Arts International Art Symposium"
                    imageWidth="80px"
                    key={i}
                  />
                </div>
              ))}
            </Row>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default AcademicPaperDetail;

const MobileOverlay = (props) => {
  return (
    <div
      style={{
        width: "100%",
        overflow: "auto",
        background: "white",
      }}
    >
      <Row
        style={{
          padding: "16px",
          background: "var(--purplePrimary)",
          gap: 20,
          color: "white",
          stroke: "white",
        }}
      >
        <Col xs="auto">
          <CaretLeft
            width={16}
            height={16}
            onClick={() => props.setUserClicked(false)}
          />
        </Col>
        <Col xs className="f-label">
          MSKU Bodrum Güzel Sanatlar Fakültesi Uluslararası Sanat Sempozyumu -
          MSKU Bodrum Faculty of Fine Arts International Art Symposium
        </Col>
      </Row>
      {props.authors.length > 1 ? (
        <Row
          style={{
            borderBottom: "1px solid var(--neutral100)",
            padding: "0px 20px 0px 16px",
            justifyContent: "center",
          }}
        >
          <Col xs style={{ display: "flex", gap: "10px" }}>
            {props.authors.map((x, i) => (
              <div
                style={{
                  borderBottom:
                    props.currentIndex === i
                      ? "2px solid var(--purplePrimary)"
                      : "none",
                  padding: "16px 0px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  props.setCurrentIndex(i);
                }}
              >
                <Image
                  src={x.image || Icon}
                  style={{ height: "32px", width: "32px" }}
                  roundedCircle={true}
                />
              </div>
            ))}
          </Col>
        </Row>
      ) : null}

      {/* Author */}

      <Author data={props.authors[props.currentIndex]} />

      {/* Author's Other Papers */}

      <Row
        style={{
          display:
            (props.authors[props.currentIndex].papers || null) === null
              ? "none"
              : "flex",
        }}
      >
        <Row
          style={{
            padding: "20px",
            justifyContent: "space-between",
          }}
        >
          <Col xs="auto" className="f-label-l" style={{ fontWeight: 700 }}>
            Diğer yazılar
            <span className="f-label-s"> (12 yazı)</span>
          </Col>
          <Col xs="auto">
            <Link
              to={`/ustalar/${
                props.authors[props.currentIndex].username
              }#academic`}
              className="f-label-m"
            >
              Tüm yazılar
              <CaretRigth width={24} style={{ marginLeft: "8px" }} />
            </Link>
          </Col>
        </Row>
        <AcademicPaperCard
          title="MSKU Bodrum Güzel Sanatlar Fakültesi Uluslararası Sanat Sempozyumu - MSKU Bodrum Faculty of Fine Arts International Art Symposium"
          imageWidth="80px"
          borderWidth="0px"
        />
      </Row>

      {/* Related Papers */}

      <Row>
        <Row
          className="f-label-l"
          style={{
            padding: "20px",
            justifyContent: "space-between",
            fontWeight: 700,
          }}
        >
          <Col xs="auto">İlgili diğer yazılar</Col>
        </Row>

        {[...Array(5)].map((i) => (
          <div>
            <AcademicPaperCard
              title="MSKU Bodrum Güzel Sanatlar Fakültesi Uluslararası Sanat Sempozyumu - MSKU Bodrum Faculty of Fine Arts International Art Symposium"
              imageWidth="80px"
              key={i}
              borderWidth="0px"
            />
          </div>
        ))}
      </Row>
    </div>
  );
};

const Author = ({ data }) => {
  const isMobile = useIsMobile();

  const Constants = {
    imageSize: isMobile ? "64px" : "90px",
  };

  return (
    <Row
      style={{
        justifyContent: "center",
        padding: "30px 0px",
        borderBottom: "1px solid var(--neutral100)",
        gap: 8,
      }}
    >
      {(data.username || null) !== null ? (
        <Link to={`/ustalar/${data.username}`} style={{ textAlign: "center" }}>
          <Image
            src={data.image || Icon}
            style={{ height: Constants.imageSize, width: Constants.imageSize }}
            roundedCircle={true}
          />
        </Link>
      ) : (
        <Image
          src={data.image || Icon}
          style={{ height: Constants.imageSize, width: Constants.imageSize }}
          roundedCircle={true}
        />
      )}

      {(data.username || null) !== null ? (
        <Link to={`/ustalar/${data.username}`} style={{ textAlign: "center" }}>
          <div className="f-h5 text-center">{data.name}</div>
        </Link>
      ) : (
        <div className="f-h5 text-center">{data.name}</div>
      )}

      <div
        className="f-label text-center"
        style={{ color: "var(--neutral500)" }}
      >
        {data.title}
      </div>
      {(data.numbers || null) !== null ? (
        <Row
          className="f-paragraph text-center"
          style={{
            width: "100%",
            gap: 16,
            padding: "0px 20px",
          }}
        >
          {(data.numbers.projects || 0) !== 0 ? (
            <Col
              style={{
                background: "var(--neutral50)",
                padding: "9px 0px",
                borderRadius: "8px",
              }}
            >
              <span className="f-h6">{data.numbers.projects}</span>
              <br />
              <span className="f-label">Proje</span>
            </Col>
          ) : null}
          {(data.numbers.mentees || 0) !== 0 ? (
            <Col
              style={{
                background: "var(--neutral50)",
                padding: "9px 0px",
                borderRadius: "8px",
              }}
            >
              <span className="f-h6">{data.numbers.mentees}</span>
              <br />
              <span className="f-label">Çırak</span>
            </Col>
          ) : null}

          {(data.numbers.contents || 0) !== 0 ? (
            <Col
              style={{
                background: "var(--neutral50)",
                padding: "9px 0px",
                borderRadius: "8px",
              }}
            >
              <span className="f-h6">{data.numbers.contents}</span>
              <br />
              <span className="f-label">İçerik</span>
            </Col>
          ) : null}
        </Row>
      ) : null}

      {(data.categories || null) !== null ? (
        <Row
          style={{
            marginTop: "19px",
            flexDirection: "column",
            padding: "0px 20px",
            justifyContent: "center",
          }}
        >
          <div className="f-h6 text-center">Ustalık kategorileri</div>
          <Row
            xs="auto"
            style={{
              display: "flex",
              gap: "12px 20px",
              justifyContent: "center",
              marginTop: "12px",
            }}
          >
            {data.categories.map((x, i) => (
              <Link
                to={`/ustalar?job=${x}`}
                style={{
                  textDecoration: "underline",
                  color: "var(--neutral500)",
                  fontSize: "15px",
                }}
              >
                {x}
              </Link>
            ))}
          </Row>
        </Row>
      ) : null}
    </Row>
  );
};
