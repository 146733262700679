import {
  Row,
  Col,
  Image,
  Form,
  Dropdown,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useEffect, useState } from "react";
import DGButton from "../components/Buttons/DGButton";
import useBreakpoint from "../public/use-breakpoint";
import { Link } from "react-router-dom";
import SliderButton from "../components/Buttons/SliderButton";

import MessageListElement from "../components/ListElements/MessageListElement";

import Icon from "../public/images/DesignGost-Icon.svg";
import { ReactComponent as Remove } from "../public/icons/Remove.svg";
import { ReactComponent as Sikayet } from "../public/icons/Sikayet.svg";
import { ReactComponent as NewEmail } from "../public/icons/New-Email.svg";
import { ReactComponent as AddUser } from "../public/icons/Add-User.svg";
import { ReactComponent as Newwork } from "../public/icons/Newwork.svg";
import { ReactComponent as CloseIcon } from "../public/icons/X.svg";
import { ReactComponent as AddIcon } from "../public/icons/Add.svg";

import data from "./data_messages.json";
import DGModal from "../components/Modals/DGModal";
import ComplainModal from "../components/Modals/ComplainModal";
import CloseButton from "../components/Buttons/CloseButton";

import useIsMobile from "../public/is-Mobile";

const Messages = () => {
  const isMobile = useIsMobile();
  const [heightOfHeader, setHeightOfHeader] = useState(0);
  const [selectedMessage, setSelectedMessage] = useState(
    isMobile ? null : data[0]
  );
  const [activeTab, setActiveTab] = useState("messages");
  const [newMessageText, setNewMessageText] = useState(null);

  const size = useBreakpoint();

  const approveMentorship = () => {
    let temp = {};
    Object.assign(temp, selectedMessage);
    temp.applyToMentorship = null;
    temp.status = "done";
    setSelectedMessage(temp);
  };

  useEffect(() => {
    const height = document.getElementsByClassName("navbar")[0].clientHeight;
    setHeightOfHeader(height);
  }, [size]);

  return (
    <div
      style={{
        height: isMobile ? "auto" : `calc(100vh - ${heightOfHeader}px)`,
        padding: isMobile ? "0px" : "0px 20px",
      }}
    >
      {isMobile ? (
        <MobileLayout
          selectedMessage={selectedMessage}
          setSelectedMessage={setSelectedMessage}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          newMessageText={newMessageText}
          setNewMessageText={setNewMessageText}
        />
      ) : (
        <DestopLayout
          selectedMessage={selectedMessage}
          setSelectedMessage={setSelectedMessage}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          newMessageText={newMessageText}
          setNewMessageText={setNewMessageText}
        />
      )}
    </div>
  );
};

const DestopLayout = (props) => {
  return (
    <Row
      style={{
        height: "100%",
        borderLeft: "1px solid var(--neutral100)",
        borderRight: "1px solid var(--neutral100)",
        flexWrap: "nowrap",
      }}
    >
      <MessagesLeftView
        selectedMessage={props.selectedMessage}
        setSelectedMessage={props.setSelectedMessage}
        activeTab={props.activeTab}
        setActiveTab={props.setActiveTab}
        newMessageText={props.newMessageText}
        setNewMessageText={props.setNewMessageText}
      />
      <MessagesMainView
        selectedMessage={props.selectedMessage}
        authors={props.selectedMessage?.authors}
        approveMentorship={props.approveMentorship}
        setActiveTab={props.setActiveTab}
        newMessageText={props.newMessageText}
        setNewMessageText={props.setNewMessageText}
      />

      {/* Right Part */}
      {props.selectedMessage?.authors.length > 0 ? (
        <MessagesRightView selectedMessage={props.selectedMessage} />
      ) : null}
    </Row>
  );
};

const MobileLayout = (props) => {
  return (
    <Row
      style={{
        flexWrap: "nowrap",
      }}
    >
      <MessagesLeftView
        selectedMessage={props.selectedMessage}
        setSelectedMessage={props.setSelectedMessage}
        activeTab={props.activeTab}
        setActiveTab={props.setActiveTab}
        newMessageText={props.newMessageText}
        setNewMessageText={props.setNewMessageText}
      />
    </Row>
  );
};

export default Messages;

const MessagesLeftView = (props) => {
  const isMobile = useIsMobile();

  const [cancelNewMessageModalShow, setCancelNewMessageModalShow] =
    useState(false);
  const [selectedMessageIndex, setSelectedMessageIndex] = useState(null);
  return (
    <Col
      xs="auto"
      className="messages-left-view"
      style={{ width: isMobile ? "100%" : "360px" }}
    >
      <Row className="messages-left-view-tabs">
        <Col xs className="f-label-l" style={{ padding: "0px 20px" }}>
          <Row
            style={{
              width: "100%",
              height: "100%",
              gap: "30px",
              justifyContent: "space-between",
            }}
          >
            <Col xs="auto" className="messages-left-view-tab-element">
              <div>Mesajlar</div>
            </Col>

            <Col xs="auto" className="messages-left-view-tab-element">
              <div>
                <DGButton variant="tertiary" size="xs" type="submit">
                  <AddIcon width={15} height={15} style={{ marginBottom: 2 }} />
                  Yeni Mesaj
                </DGButton>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      {data.map((x, i) => (
        <MessageListElement
          status={x.status}
          authors={x.authors}
          replied={x.replied}
          applyToMentorship={x.applyToMentorship || null}
          onClick={() => {
            if (
              props.activeTab === "new-message" ||
              props.newMessageText !== null
            ) {
              setCancelNewMessageModalShow(true);
              setSelectedMessageIndex(i);
            } else {
              if ((props.selectedMessage?.status || null) !== null) {
                props.selectedMessage.status = "";
              }
              x.status = "selected";
              props.setSelectedMessage(data[i]);
              props.setActiveTab("messages");
            }
          }}
          style={{
            borderBottom:
              isMobile && i !== data.length - 1
                ? "1px solid var(--neutral100)"
                : "none",
          }}
        />
      ))}
      <DGModal
        title="Sohbeti sonlandırmak istediğinizden emin misin?"
        desc="Sohbeti sonlandırdığınızda konuşma akışı kaydedilmeyecektir."
        textButtonText="Vazgeç"
        buttonText="Sonlandır"
        textButtonOnClick={() => setCancelNewMessageModalShow(false)}
        buttonOnClick={() => {
          setCancelNewMessageModalShow(false);
          props.setNewMessageText(null);
          document.getElementById("new-message-input").value = null;
          if ((props.selectedMessage?.status || null) !== null) {
            props.selectedMessage.status = "";
          }
          data[selectedMessageIndex].status = "selected";
          props.setSelectedMessage(data[selectedMessageIndex]);
          props.setActiveTab("messages");
        }}
        show={cancelNewMessageModalShow}
        onHide={() => setCancelNewMessageModalShow(false)}
      />
    </Col>
  );
};

const MessagesMainView = (props) => {
  const [isFocused, setIsFocused] = useState(false);
  const [value, setValue] = useState("");
  const [receivers, setReceivers] = useState([]);

  const [cancelNewMessageModalShow, setCancelNewMessageModalShow] =
    useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [complainModalShow, setComplainModalShow] = useState(false);
  const [approveModalShow, setApproveModalShow] = useState(false);

  const mentorData = [
    "Gökçe Çankaya",
    "Emrah Doğru",
    "Selin Çınar",
    "Murat Kalkavan",
    "Gökhan Altıniğne",
    "Can Dağlı",
    "Mehmet Gözetlik",
    "Alican Kılıçoğlu",
    "Kağan Küçük",
    "Cihan Önder",
    "Cihan Kavaklıpınar",
    "Taner Ardalı",
  ];

  const change = (eventkey) => {
    setReceivers([...receivers, eventkey]);
    setIsFocused(false);
    document.getElementsByClassName("dg-dropdown-input-box")[0].value = "";
  };

  return (
    <Col
      xs
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        borderLeft: "1px solid var(--neutral100)",
        borderRight: "1px solid var(--neutral100)",
        position: "relative",
      }}
    >
      {props.selectedMessage === null ? (
        <Row
          style={{
            minHeight: "65px",
            borderBottom: "1px solid var(--neutral100)",
            padding: "14px 20px",
            flexWrap: "nowrap",
            gap: 20,
          }}
        >
          <Col xs="auto" style={{ paddingTop: 7 }}>
            Kime:
          </Col>
          <Col
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "7px",
              flexGrow: 1,
            }}
          >
            {receivers.map((item) => (
              <div
                style={{
                  display: "flex",
                  width: "max-content",
                  padding: "11px 16px 7px",
                  background: "var(--purpleLight)",
                  alignItems: "center",
                  flexWrap: "nowrap",
                  gap: 16,
                  borderRadius: 4,
                }}
                className="f-label"
              >
                {item}
                <CloseIcon
                  width={9}
                  height={9}
                  stroke="black"
                  style={{ width: "auto", cursor: "pointer" }}
                  onClick={() => {
                    let filteredArray = receivers.filter((re) => re !== item);
                    setReceivers(filteredArray);
                  }}
                />
              </div>
            ))}

            <Dropdown
              show={isFocused}
              onSelect={change}
              style={{ minWidth: "200px", flexGrow: 1 }}
            >
              <Dropdown.Toggle className="dg-dropdown-input">
                <Form.Control
                  className="dg-dropdown-input-box"
                  onBlur={(e) => {
                    if (
                      (e.relatedTarget?.className || null) === "dropdown-item"
                    ) {
                      return;
                    }
                    setIsFocused(false);
                  }}
                  onChange={(e) => {
                    if (e.target.value.length >= 2) {
                      setIsFocused(true);
                    } else {
                      setIsFocused(false);
                    }
                    setValue(e.target.value);
                  }}
                />
              </Dropdown.Toggle>

              <Dropdown.Menu className="dg-dropdown-input-menu">
                {mentorData
                  .filter(
                    (name) =>
                      name.toLowerCase().includes(value) &&
                      !receivers.includes(name)
                  )
                  .map((filteredName) => (
                    <Dropdown.Item eventKey={filteredName}>
                      <Row style={{ alignItems: "center", gap: "8px" }}>
                        <Image
                          src={data.image || Icon}
                          style={{ height: "40px", width: "40px" }}
                          roundedCircle={true}
                        />
                        {filteredName}
                      </Row>
                    </Dropdown.Item>
                  ))}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col xs="auto">
            <CloseButton onClick={() => setCancelNewMessageModalShow(true)} />
            <DGModal
              title="Sohbeti sonlandırmak istediğinizden emin misin?"
              desc="Sohbeti sonlandırdığınızda konuşma akışı kaydedilmeyecektir."
              textButtonText="Vazgeç"
              buttonText="Sonlandır"
              textButtonOnClick={() => setCancelNewMessageModalShow(false)}
              buttonOnClick={() => {
                setCancelNewMessageModalShow(false);
                props.setActiveTab("messages");
              }}
              show={cancelNewMessageModalShow}
              onHide={() => setCancelNewMessageModalShow(false)}
            />
          </Col>
        </Row>
      ) : (
        <Row
          style={{
            height: "65px",
            borderBottom: "1px solid var(--neutral100)",
            alignItems: "center",
            padding: "0px 20px",
            gap: "20px",
          }}
        >
          <Col xs>
            {(props.selectedMessage?.applyToMentorship || null) === true ? (
              props.selectedMessage?.isMentee ? (
                <div>
                  <span>
                    <Link
                      to={`/ustalar/seyyahil`}
                      style={{
                        color: "var(--purplePrimary)",
                        fontWeight: 700,
                      }}
                    >
                      Hakan Ertan
                    </Link>{" "}
                  </span>
                  için çıraklık başvurusu yaptın.
                </div>
              ) : (
                <div>
                  Tebrikler Hakan!{" "}
                  {props.authors.map((x, i) => (
                    <span>
                      <Link
                        to={`/ustalar/${x.username}`}
                        style={{
                          color: "var(--purplePrimary)",
                          fontWeight: 700,
                        }}
                      >
                        {x.name}
                      </Link>{" "}
                    </span>
                  ))}{" "}
                  çırağın olmak istiyor.
                </div>
              )
            ) : props.authors.length > 1 ? (
              <div className="f-label">
                {props.authors.map((x, i) => (
                  <span>
                    <Link
                      to={`/ustalar/${x.username}`}
                      style={{
                        color: "var(--purplePrimary)",
                        fontWeight: 700,
                      }}
                    >
                      {x.name}
                    </Link>
                    {i === props.authors.length - 1 ? "" : ","}{" "}
                  </span>
                ))}
                ve{" "}
                <Link
                  to={`/ustalar/${"seyyahil"}`}
                  style={{ color: "var(--purplePrimary)", fontWeight: 700 }}
                >
                  Hakan Ertan
                </Link>{" "}
                arasındaki mesajlaşma
              </div>
            ) : (
              <div>
                {props.authors.map((x, i) => (
                  <span>
                    <Link
                      to={`/ustalar/${x.username}`}
                      style={{
                        color: "var(--purplePrimary)",
                        fontWeight: 700,
                      }}
                    >
                      {x.name}
                    </Link>{" "}
                  </span>
                ))}
                ve{" "}
                <Link
                  to={`/ustalar/${"seyyahil"}`}
                  style={{ color: "var(--purplePrimary)", fontWeight: 700 }}
                >
                  Hakan Ertan
                </Link>{" "}
                arasındaki mesajlaşma
              </div>
            )}
          </Col>
          <Col
            xs="auto"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "20px",
            }}
          >
            {(props.selectedMessage?.applyToMentorship || null) === true &&
            (props.selectedMessage?.isMentee || null) === true ? (
              <Row
                className="f-label"
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 8,
                }}
              >
                Onay bekliyor{" "}
                <div
                  style={{
                    width: "25px",
                    height: "25px",
                    borderRadius: 25,
                    background: "var(--yellowPrimary",
                  }}
                />
              </Row>
            ) : (
              <div>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip className="dg-tooltip-small">Şikayet et</Tooltip>
                  }
                >
                  <Sikayet
                    width={24}
                    height={24}
                    style={{ cursor: "pointer" }}
                    stroke="var(--redPrimary)"
                    onClick={() => setComplainModalShow(true)}
                  />
                </OverlayTrigger>
                <ComplainModal
                  textButtonOnClick={() => setComplainModalShow(false)}
                  show={complainModalShow}
                  onHide={() => setComplainModalShow(false)}
                />
              </div>
            )}

            {(props.selectedMessage?.applyToMentorship || null) ===
            true ? null : (
              <Remove
                width={24}
                height={24}
                style={{ cursor: "pointer" }}
                onClick={() => setDeleteModalShow(true)}
              />
            )}
            <DGModal
              title="Mesajı silmek istediğinden emin misin?"
              desc="Mesajı Sil butonuna basınca Hakan Ertan ile yaptığın tüm geçmiş yazışmaların mesaj kutundan silinecek. Mesajı silmek istediğinden emin misin?"
              textButtonText="Vazgeç"
              buttonText="Mesajı Sil"
              textButtonOnClick={() => setDeleteModalShow(false)}
              show={deleteModalShow}
              onHide={() => setDeleteModalShow(false)}
            />
          </Col>
        </Row>
      )}
      {(props.selectedMessage?.applyToMentorship || null) === true ? (
        props.selectedMessage?.isMentee ? (
          <Row
            style={{
              padding: "30px 30px",
              gap: "20px",
              flexGrow: 1,
              flexDirection: "column-reverse",
              flexWrap: "nowrap",
              height: "0px",
              overflow: "auto",
            }}
          >
            <Row style={{ justifyContent: "center", padding: "70px 0px" }}>
              <Newwork stroke="var(--purplePrimary)" width={48} height={48} />
              <div
                style={{
                  textAlign: "center",
                  margin: "20px 0px 60px 0px",
                  fontWeight: 500,
                  fontSize: "18px",
                  maxWidth: "450px",
                }}
              >
                Aylık 10 çıraklık başvusu hakkının 7 tanesini kullandın. Bu ay
                için sadece 3 başvuru hakkın kaldı.
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <DGButton
                  variant="dg-secondary"
                  size="medium"
                  // onClick={() => {
                  //   props.approveMentorship();
                  // }}
                >
                  Usta Bul
                </DGButton>
              </div>
            </Row>
            <ReceivedMessage />
          </Row>
        ) : (
          <Row
            style={{
              padding: "30px 30px",
              gap: "20px",
              flexGrow: 1,
              flexDirection: "column-reverse",
              flexWrap: "nowrap",
              height: "0px",
              overflow: "auto",
            }}
          >
            <Row style={{ justifyContent: "center", padding: "70px 0px" }}>
              <AddUser stroke="var(--purplePrimary)" width={48} height={48} />
              <div
                style={{
                  textAlign: "center",
                  margin: "20px 0px 60px 0px",
                  fontWeight: 500,
                  fontSize: "18px",
                  maxWidth: "450px",
                }}
              >
                Mesaja yanıt yazabilmek için Çıraklık başvurusunu onaylaman
                gerekiyor.
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <DGButton
                  variant="dg-primary"
                  size="medium"
                  onClick={() => {
                    setApproveModalShow(true);
                  }}
                >
                  Çıraklık Başvurusunu Onayla
                </DGButton>
              </div>
              <DGModal
                title="Çıraklık başvurusunu onaylamak istediğinden emin misin?"
                desc="Çıraklık başvurusunu onayladıktan sonra yeni Çırağınla konuşmaya başlayabilirsin."
                textButtonText="Vazgeç"
                buttonText="Onayla"
                buttonOnClick={() => {
                  props.approveMentorship();
                }}
                textButtonOnClick={() => setApproveModalShow(false)}
                show={approveModalShow}
                onHide={() => setApproveModalShow(false)}
              />
            </Row>
            <ReceivedMessage />
          </Row>
        )
      ) : (
        <Row
          style={{
            padding: "30px 30px",
            gap: "20px",
            flexGrow: 1,
            flexDirection: "column-reverse",
            flexWrap: "nowrap",
            height: "0px",
            overflow: "auto",
          }}
        >
          {props.selectedMessage?.messages.map((x, i) => (
            <div>
              {x.isReceived ? (
                <ReceivedMessage text={x.text} />
              ) : (
                <SentMessage text={x.text} />
              )}
            </div>
          ))}
        </Row>
      )}

      <Row
        style={{
          padding: "30px 9px 30px 30px",
          borderTop: "1px solid var(--neutral100)",
          width: "100%",
          gap: "20px",
          position: "relative",
        }}
      >
        <Col
          xs
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-end",
            gap: "20px",
          }}
        >
          <Form.Control
            as="textarea"
            id="new-message-input"
            style={{
              border: "1px solid var(--neutral200)",
              borderRadius: "16px",
              minHeight: "77px",
              width: "100%",
              resize: "none",
              padding: "13px 20px",
              lineHeight: "1.3",
              maxWidth: "1000px",
            }}
            placeholder="Mesaj yaz"
            value={props.newMessageText}
            onChange={(e) => props.setNewMessageText(e.target.value)}
          />
          <DGButton variant="dg-primary" size="sm">
            Gönder
          </DGButton>
        </Col>

        {(props.selectedMessage?.applyToMentorship || null) === true ? (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              height: "100%",
              width: "100%",
              background: "white",
              opacity: 0.8,
            }}
          />
        ) : null}
      </Row>
    </Col>
  );
};

const MessagesRightView = (props) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  return (
    <Col
      xs="auto"
      style={{
        height: "100%",
        overflow: "auto",
        position: "relative",
        width: "370px",
      }}
    >
      {props.selectedMessage?.authors.length > 1 ? (
        <Row
          style={{
            borderBottom: "1px solid var(--neutral100)",
            padding: "0px 20px 0px 16px",
            justifyContent: "center",
            height: "65px",
          }}
        >
          <Col xs style={{ display: "flex", gap: "10px" }}>
            {props.selectedMessage?.authors.map((x, i) => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  borderBottom:
                    currentIndex === i
                      ? "2px solid var(--purplePrimary)"
                      : "none",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setCurrentIndex(i);
                }}
              >
                <Image
                  src={x.image || Icon}
                  style={{ height: "32px", width: "32px" }}
                  roundedCircle={true}
                />
              </div>
            ))}
          </Col>

          <Col
            xs="auto"
            style={{ display: "flex", gap: "14px", alignItems: "center" }}
          >
            <SliderButton
              next={true}
              onClick={() => {
                if (
                  currentIndex + 1 ===
                  props.selectedMessage?.authors.length
                ) {
                  setCurrentIndex(0);
                } else {
                  setCurrentIndex(currentIndex + 1);
                }
              }}
            />

            <SliderButton
              next={false}
              onClick={() => {
                if (currentIndex === 0) {
                  setCurrentIndex(props.selectedMessage?.authors.length - 1);
                } else {
                  setCurrentIndex(currentIndex - 1);
                }
              }}
            />
          </Col>
        </Row>
      ) : null}

      {/* Author */}

      <Author data={props.selectedMessage?.authors[currentIndex]} />

      {(props.selectedMessage?.authors[currentIndex].projects || null) ===
      null ? null : (
        <Row style={{ gap: "20px", padding: "20px" }}>
          <div className="f-h6 text-center">Projeler</div>

          {props.selectedMessage?.authors[currentIndex].projects.length > 9 ? (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "auto auto auto",
                gap: "10px",
              }}
            >
              {props.selectedMessage?.authors[currentIndex].projects
                .slice(0, 8)
                .map((i) => (
                  <div
                    style={{
                      background: "var(--neutral500)",
                      aspectRatio: "1.38",
                      borderRadius: "8px",
                    }}
                  ></div>
                ))}
              <Link
                to={`/ustalar/${props.selectedMessage?.authors[currentIndex].username}#menteesProjects`}
              >
                <div
                  style={{
                    background: "var(--neutral50)",
                    aspectRatio: "1.38",
                    borderRadius: "8px",
                    position: "relative",
                  }}
                  className="f-paragraph"
                >
                  <div
                    style={{
                      position: "absolute",
                      left: 0,
                      width: "100%",
                      transform: "translateY(-50%)",
                      top: "50%",
                      textAlign: "center",
                    }}
                  >
                    +
                    {
                      props.selectedMessage?.authors[currentIndex].projects
                        .length
                    }
                  </div>
                </div>
              </Link>
            </div>
          ) : (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "auto auto auto",
                gap: "10px",
              }}
            >
              {props.selectedMessage?.authors[currentIndex].projects.map(
                (i) => (
                  <div
                    style={{
                      background: "var(--neutral500)",
                      aspectRatio: "1.38",
                      borderRadius: "8px",
                    }}
                  ></div>
                )
              )}
            </div>
          )}
        </Row>
      )}
    </Col>
  );
};

const Author = ({ data }) => {
  return (
    <Row
      style={{
        justifyContent: "center",
        padding: "30px 0px",
        borderBottom: "1px solid var(--neutral100)",
      }}
    >
      {(data.username || null) !== null ? (
        <Link to={`/ustalar/${data.username}`} style={{ textAlign: "center" }}>
          <Image
            src={data.image || Icon}
            style={{ height: "90px", width: "90px" }}
            roundedCircle={true}
          />
        </Link>
      ) : (
        <Image
          src={data.image || Icon}
          style={{ height: "90px", width: "90px" }}
          roundedCircle={true}
        />
      )}

      {(data.username || null) !== null ? (
        <Link to={`/ustalar/${data.username}`} style={{ textAlign: "center" }}>
          <div className="f-h5 text-center" style={{ margin: "12px 0 8px 0" }}>
            {data.name}
          </div>
        </Link>
      ) : (
        <div className="f-h5 text-center" style={{ margin: "12px 0 8px 0" }}>
          {data.name}
        </div>
      )}
      <div
        className="f-label text-center"
        style={{ color: "var(--neutral500)" }}
      >
        {data.title}
      </div>
      {(data.numbers || null) !== null ? (
        <Row
          className="f-label"
          style={{ gap: "10px", justifyContent: "center", marginTop: "20px" }}
        >
          {(data.numbers.projects || 0) !== 0 ? (
            <Col xs="auto">
              <span>
                <b>{data.numbers.projects}</b> Proje
              </span>
            </Col>
          ) : null}

          {(data.numbers.mentees || 0) !== 0 ? (
            <Col xs="auto">
              <span>
                <b>{data.numbers.mentees}</b> Çırak
              </span>
            </Col>
          ) : null}

          {(data.numbers.contents || 0) !== 0 ? (
            <Col xs="auto">
              <span>
                <b>{data.numbers.contents}</b> İçerik
              </span>
            </Col>
          ) : null}

          {(data.numbers.papers || 0) !== 0 ? (
            <Col xs="auto">
              <span>
                <b>{data.numbers.papers}</b> Akademik Yazı
              </span>
            </Col>
          ) : null}
        </Row>
      ) : null}

      {(data.categories || null) !== null ? (
        <Row
          style={{
            marginTop: "19px",
            flexDirection: "column",
            padding: "0px 20px",
            justifyContent: "center",
          }}
        >
          <div className="f-h6 text-center">Ustalık kategorileri:</div>
          <Row
            xs="auto"
            style={{
              display: "flex",
              gap: "12px 25px",
              justifyContent: "center",
              marginTop: "12px",
            }}
          >
            {data.categories.map((x, i) => (
              <Link
                to={`/ustalar?job=${x}`}
                style={{
                  textDecoration: "underline",
                  color: "var(--neutral500)",
                }}
              >
                {x}
              </Link>
            ))}
          </Row>
        </Row>
      ) : null}
    </Row>
  );
};

const ReceivedMessage = (props) => {
  return (
    <Row style={{ alignItems: "flex-end", gap: "8px", height: "fit-content" }}>
      <Col xs="auto">
        <Image
          src={props.image || Icon}
          style={{ height: "32px", width: "32px" }}
          roundedCircle={true}
        />
      </Col>
      <Col xs="auto">
        <Row
          style={{
            maxWidth: "700px",
            background: "var(--neutral50)",
            borderRadius: "16px 16px 16px 0px",
            padding: "20px",
          }}
          className="f-label"
          dangerouslySetInnerHTML={{ __html: props.text }}
        />
        <Row
          className="f-label-s"
          style={{ color: "var(--neutral300)", marginTop: "8px" }}
        >
          2 saat önce yollandı
        </Row>
      </Col>
    </Row>
  );
};

const SentMessage = (props) => {
  return (
    <Row
      style={{
        alignItems: "flex-end",
        gap: "8px",
        height: "fit-content",
        flexDirection: "row-reverse",
      }}
    >
      <Col xs="auto">
        <Image
          src={props.image || Icon}
          style={{ height: "32px", width: "32px" }}
          roundedCircle={true}
        />
      </Col>
      <Col xs="auto">
        <Row
          style={{
            maxWidth: "700px",
            background: "var(--purplePrimary)",
            borderRadius: "16px 16px 0px 16px",
            padding: "20px",
            color: "white",
          }}
          className="f-label"
          dangerouslySetInnerHTML={{ __html: props.text }}
        />
        <Row
          className="f-label-s"
          style={{
            color: "var(--neutral300)",
            marginTop: "8px",
            justifyContent: "end",
          }}
        >
          2 saat önce yollandı
        </Row>
      </Col>
    </Row>
  );
};

// message content xxl -> 170px padding
