import { Col, Row } from "react-bootstrap";
import BaseAboutSectionView from "./BaseAboutSectionView";

import { ReactComponent as Academy } from "../../../public/icons/Academy.svg";

const EducationsSection = (props) => {
  return (
    <BaseAboutSectionView title="Eğitim">
      {props.data?.map((element, i) => (
        <EducationView
          school={element.school}
          faculty={element.faculty}
          yearstamp={element.yearstamp}
          key={i}
        />
      ))}
    </BaseAboutSectionView>
  );
};

const EducationView = ({ school, faculty, yearstamp }) => {
  return (
    <Row className="f-label" style={{ gap: 8 }}>
      <Col xs="auto">
        <Academy width={20} stroke={"var(--neutral300)"} />
      </Col>
      <Col xs style={{ display: "flex", gap: 8, flexDirection: "column" }}>
        <div style={{ fontWeight: 700 }}>{school}</div>
        <div>
          {faculty}
          <span
            style={{
              color: "var(--neutral300)",
            }}
          >
            {" "}
            -{"  "}
            {yearstamp}
          </span>
        </div>
      </Col>
    </Row>
  );
};

export default EducationsSection;
