import { Col, Row } from "react-bootstrap";
import BaseAboutSectionView from "./BaseAboutSectionView";

import { ReactComponent as School } from "../../../public/icons/School.svg";
import { Link } from "react-router-dom";

const ExhibitionsSection = (props) => {
  return (
    <BaseAboutSectionView title="Atölye & Sergiler">
      {props.data?.map((element, i) => (
        <ExhibitionView
          key={i}
          exhibitionName={element.exhibition_name}
          projectName={element.project_name}
          exhibitionYear={element.exhibition_year}
          projectDescription={element.description}
          projectWebsite={element.project_website}
        />
      ))}
    </BaseAboutSectionView>
  );
};

const ExhibitionView = (props) => {
  return (
    <Row className="f-label" style={{ gap: 8 }}>
      <Col xs="auto">
        <School width={20} stroke={"var(--neutral300)"} />
      </Col>
      <Col xs style={{ display: "flex", gap: 8, flexDirection: "column" }}>
        <div style={{ fontWeight: 700 }}>{props.exhibitionName}</div>
        <div>{props.projectName}</div>
        <div
          style={{
            color: "var(--neutral300)",
          }}
        >
          {props.exhibitionYear}
        </div>
        <div style={{ marginTop: "24px", fontWeight: 700 }}>Açıklama:</div>
        <div>{props.projectDescription}</div>
        <Link
          to={props.projectWebsite}
          target="_blank"
          style={{
            marginTop: "12px",
            fontWeight: 600,
          }}
        >
          Projeye Gözat
        </Link>
      </Col>
    </Row>
  );
};

export default ExhibitionsSection;
