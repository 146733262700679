import { Col, Image, Row } from "react-bootstrap";
import useIsMobile from "../public/is-Mobile";

import DGLogo from "../public/images/Logo-White.png";
import People from "../public/images/onboarding/Register-Mentors.png";
import DGButton from "../components/Buttons/DGButton";
import { Link } from "react-router-dom";

const RegisterLayout = ({ children, isLogin }) => {
  const isMobile = useIsMobile();

  return (
    <Row style={{ minHeight: "100vh" }}>
      {isMobile ? null : (
        <Col
          xs={4}
          style={{
            backgroundColor: "var(--purplePrimary)",
            padding: "18px",
          }}
        >
          <Row
            style={{
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Link to={"/"} style={{ width: "auto" }}>
              <img
                alt="DesignGost Logo"
                src={DGLogo}
                className="left-col__text__logo"
                style={{ width: "150px" }}
              />
            </Link>

            <p
              className="f-paragraph text-center"
              style={{ margin: "40px 0 90px", color: "white" }}
            >
              <div>Geleceğin yaratıcıları için ücretsiz eğitim</div>
              <div>kaynakları ve sektörün yıldızlarına erişim sağlayan,</div>
              Türkiye'nin ilk ve tek Usta-Çırak platformu.
            </p>

            <Image
              src={People}
              style={{
                maxHeight: 620,
                objectFit: "contain",
                padding: "0 60px",
              }}
            />
          </Row>
        </Col>
      )}
      <Col>
        <Row
          style={{
            height: "100%",
            flexDirection: "column",
          }}
        >
          <Row
            style={{
              justifyContent: "flex-end",
              alignItems: "center",
              gap: 20,
              padding: isMobile ? "30px 30px 30px 0" : "30px 30px 0 0",
            }}
          >
            Hesabınız {isLogin ? "yoksa" : "varsa"}
            <Link
              to={isLogin ? "/choose-signup" : "/login"}
              style={{ width: "auto" }}
            >
              <DGButton variant="tertiary" size="sm" style={{ width: "auto" }}>
                {isLogin ? "Kayıt Ol" : "Giriş Yap"}
              </DGButton>
            </Link>
          </Row>
          <Row
            style={{
              alignItems: "center",
              flexGrow: 1,
              justifyContent: "center",
              padding: "0 24px",
            }}
          >
            {children}
          </Row>
        </Row>
      </Col>
    </Row>
  );
};

export default RegisterLayout;
