import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ReactComponent as Award } from "../../../public/icons/Award.svg";
import { ReactComponent as Star } from "../../../public/icons/Star.svg";
import { ReactComponent as Project } from "../../../public/icons/Project.svg";
import { ReactComponent as SrUsta } from "../../../public/images/Sr-Usta.svg";

const MentorCard = (props) => {
  return (
    <Link to={`/ustalar/${props.username}`} style={props.style}>
      <div
        className="card-mentor boxed-card"
        style={{
          display: "flex",
          flexDirection: "column",
          textDecoration: "none",
          gap: 4,
          height: "100%",
        }}
      >
        <Image
          src={props.image}
          style={{
            objectFit: "cover",
            aspectRatio: "1",
            borderRadius: 8,
            width: "100%",
          }}
        />
        <div className="f-h5" style={{ marginTop: 4 }}>
          {props.name}
        </div>
        <div className="f-label" style={{ color: "var(--neutral500)" }}>
          {props.desc}
        </div>
        <div className="f-label-s" style={{ color: "var(--neutral300)" }}>
          {props.company}
        </div>
        <div
          style={{
            height: 1,
            background: "rgba(240, 237, 246, 1)",
            marginTop: 6,
          }}
        />
        {/* 
        <div className="f-label-s" style={{ color: "var(--neutral500)", marginTop: 6 }}>
          <Award width={16} style={{ marginRight: 4 }} stroke="var(--purplePrimary)"/>
          {props.experience} yıl deneyim
        </div>
         */}
        <div
          className="f-label-s"
          style={{ color: "var(--neutral500)", marginTop: 6 }}
        >
          <SrUsta
            height={16}
            stroke="var(--bluePrimary)"
            style={{ marginRight: 4 }}
          />
          Sr. Usta
        </div>
        <div
          className="f-label-s"
          style={{ color: "var(--neutral500)", marginTop: 6 }}
        >
          <Project
            width={16}
            stroke="var(--greenPrimary)"
            style={{ marginRight: 4 }}
          />
          {props.projectCount} proje
        </div>
      </div>
    </Link>
  );
};

export default MentorCard;
