import { Col, Row } from "react-bootstrap";

const BaseAboutSectionView = (props) => {
  return (
    <Row>
      <Col className="f-h5">{props.title}</Col>
      <Row style={{ paddingTop: "24px", gap: "16px" }}>{props.children}</Row>
    </Row>
  );
};

export default BaseAboutSectionView;
