import { useState } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

import DGButton from "../../Buttons/DGButton";
import MessagesDropdown from "../Subviews/MessagesDropdown";
import NotificationsDropdown from "../Subviews/NotificationsDropdown";
import ProfileDropdown from "../Subviews/ProfileDropdown";

const HeaderTrailing = (props) => {
  const [isSignedIn, setIsSignedIn] = useState(
    localStorage.getItem("signed-in") === "true" || false
  );

  return (
    <Navbar.Brand className="justify-content-end" style={{ margin: 0 }}>
      {isSignedIn ? (
        <Nav className="gap-4 align-items-center">
          <NotificationsDropdown variant={props.variant} />
          <MessagesDropdown variant={props.variant} />
          <ProfileDropdown
            variant={props.variant}
            setIsSignedIn={setIsSignedIn}
          />
        </Nav>
      ) : (
        <Nav className="gap-4 align-items-center">
          <Link
            className="f-h6 p-0"
            to="/login"
            style={{ color: props.variant === "light" ? "black" : "white" }}
          >
            Giriş Yap
          </Link>
          <Link to="/choose-signup">
            <DGButton
              variant={props.variant === "light" ? "tertiary" : "quaternary"}
              size="medium"
            >
              Kayıt Ol
            </DGButton>
          </Link>
        </Nav>
      )}
    </Navbar.Brand>
  );
};

export default HeaderTrailing;
