import { Col, Overlay, Row, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useRef, useState } from "react";

import MessageListElement from "../../ListElements/MessageListElement";
import { ReactComponent as Email } from "../../../public/icons/Email.svg";
import { ReactComponent as ArrowRight } from "../../../public/images/onboarding/Arrow-Right.svg";

import data from "../../../pages/data_messages.json";

const MessagesDropdown = (props) => {
  const [showMessages, setShowMessages] = useState(false);
  const messagesRef = useRef(null);

  return (
    <div>
      <Email
        width={24}
        ref={messagesRef}
        onClick={() => setShowMessages(!showMessages)}
        className={
          showMessages
            ? "header-icon-active"
            : props.variant === "light"
            ? "header-icon-light"
            : "header-icon-dark"
        }
      />
      <Overlay
        target={messagesRef.current}
        show={showMessages}
        placement="bottom"
        onHide={() => setShowMessages(!showMessages)}
        rootClose={true}
        rootCloseEvent="click"
      >
        <Tooltip className="header-tooltip">
          <Row
            style={{
              padding: "32px 24px 24px",
            }}
          >
            <Row className="f-h6">Mesajlar</Row>
            <Row
              style={{
                maxHeight: "450px",
                overflow: "auto",
              }}
            >
              {data.map((x, i) => (
                <MessageListElement
                  status={x.status}
                  authors={x.authors}
                  replied={x.replied}
                  applyToMentorship={x.applyToMentorship || null}
                  onClick={() => {}}
                />
              ))}
            </Row>
            <Row>
              <Link
                to="/messages"
                onClick={() => setShowMessages(!showMessages)}
                style={{
                  textAlign: "center",
                  fontSize: 15,
                  fontWeight: 700,
                  paddingTop: 16,
                }}
              >
                Tümüne Bak
                <ArrowRight width={12} style={{ marginLeft: 8 }} />
              </Link>
            </Row>
          </Row>
        </Tooltip>
      </Overlay>
    </div>
  );
};

export default MessagesDropdown;
