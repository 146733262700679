import { Container, Row } from "react-bootstrap";
import TestimonialCard from "../Cards/TestimonialCard";
import useIsMobile from "../../public/is-Mobile";
import { useTranslation } from "react-i18next";

const Testimonial = () => {
  const isMobile = useIsMobile();
  const { t } = useTranslation("home");

  let data = [
    {
      mentee: {
        name: "Mert Altun",
        image:
          "https://designgost.com/399ece81-9202-4e24-9308-b0943259f9eb-img-20240419-173032-651.avif",
        title: "Öğrenci",
      },
      mentor: {
        name: "Mehmet Gözetlik",
        image:
          "https://designgost.com/88c02b22-14cd-4a6a-b6e9-ecad9898617e-avatar2.avif",
        title: "Independent Brand Designer",
      },
      text: t("testimonial_1"),
    },
    {
      mentee: {
        name: "Hale Kocabaş",
        image:
          "https://designgost.com/halekocabas.jpg",
        title: "Öğrenci",
      },
      mentor: {
        name: "Kağan Küçük",
        image:
          "https://designgost.com/ff2c7396-e4de-4767-85d7-b8f2959b2732-611.avif",
        title: "Executive Creative Director",
      },
      text: t("testimonial_2"),
    },
    {
      mentee: {
        name: "Cesim Yaray",
        image:
          "https://designgost.com/cesimyaray.png",
        title: "Grafik Tasarımcı",
      },
      mentor: {
        name: "Can Dağlı",
        image:
          "https://designgost.com/16e8a204-5426-401a-b541-3e3f2aec3837-screen-shot-2020-12-16-at-125707.avif",
        title: "Creative Director & Founder",
      },
      text: t("testimonial_3"),
    },
  ];

  return (
    <Container
      style={{
        backgroundColor: "var(--neutral50)",
        maxWidth: "100%",
        padding: isMobile ? "40px 0px" : "90px 0px",
      }}
    >
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 40,
          textAlign: isMobile ? "start" : "center",
        }}
      >
        <Row
          style={{
            maxWidth: 830,
            gap: 20,
            padding: isMobile ? "0 20px" : "0px",
          }}
        >
          <div className="f-h2">{t("testimonial_title")}</div>
          <div className="f-paragraph">{t("testimonial_desc")}</div>
        </Row>

        {isMobile ? (
          <div style={{ width: "100%" }}>
            <div
              className="mentors-scroll"
              style={{
                padding: "0px 20px",
                scrollBehavior: "smooth",
                overflowX: isMobile ? "scroll" : "hidden",
                gap: "10px",
              }}
            >
              {data.map((_, i) => (
                <div style={{ minWidth: "335px", maxWidth: "366px" }}>
                  <TestimonialCard data={data[i]} />
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gap: 30,
            }}
          >
            {data.map((_, i) => (
              <TestimonialCard data={data[i]} />
            ))}
          </div>
        )}
      </Container>
    </Container>
  );
};

export default Testimonial;
