import { useEffect, useState } from "react";
import { Navbar, Row, Col, Image } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { ReactComponent as Jobs } from "../../public/icons/Jobs.svg";
import { ReactComponent as Search } from "../../public/icons/Search.svg";
import { ReactComponent as CaretRight } from "../../public/images/caret-right.svg";
import { ReactComponent as Projects } from "../../public/icons/Projects.svg";
import { ReactComponent as HowItWorks } from "../../public/icons/HowItWorks.svg";

import { ReactComponent as Email } from "../../public/icons/Email.svg";
import { ReactComponent as Activities } from "../../public/icons/Activities.svg";
import { ReactComponent as ProfileSetting } from "../../public/icons/Profile-Setting.svg";
import { ReactComponent as SignOut } from "../../public/icons/Sign-Out.svg";

import DGButton from "../Buttons/DGButton";

import "./header.css";
import useIsMobile from "../../public/is-Mobile";

import MobileHeader from "./Layouts/MobileHeader";
import DesktopHeader from "./Layouts/DesktopHeader";

const Header = ({ variant }) => {
  const isMobile = useIsMobile();

  const location = useLocation();

  const [show, setShow] = useState(false);
  const [showProfileMenu, setShowProfileMenu] = useState(false);

  function hideOverlays() {
    setShow(false);
    showProfileMenu(false);
  }

  useEffect(() => {
    setShow(false);
    setShowProfileMenu(false);
  }, [location.pathname]);

  useEffect(() => {
    const html = document.querySelector("html");
    if (html) {
      const isOverlayActive = show || showProfileMenu;
      html.style.overflow = isOverlayActive ? "hidden" : "auto";
    }
  }, [show, showProfileMenu]);

  return (
    <Navbar
      variant={variant}
      sticky="top"
      expand="lg"
      style={{ padding: isMobile ? "0" : "25px 40px", position: "relative" }}
    >
      {isMobile ? (
        <MobileHeader
          variant={variant}
          setShow={setShow}
          show={show}
          setShowProfileMenu={setShowProfileMenu}
          showProfileMenu={showProfileMenu}
        />
      ) : (
        <DesktopHeader variant={variant} />
      )}
      {show ? <MobileMenu variant={variant} /> : null}
      {showProfileMenu ? (
        <MobileProfileMenu variant={variant} hideOverlays={hideOverlays} />
      ) : null}
    </Navbar>
  );
};

export default Header;

const MobileMenu = (props) => {
  const isDark = props.variant === "dark";
  const [isSignedIn, setIsSignedIn] = useState(
    localStorage.getItem("signed-in") === "true" || false
  );

  return (
    <Row
      style={{
        position: "absolute",
        height: "calc(100vh - 64px)",
        background: isDark ? "var(--purplePrimary)" : "white",
        zIndex: 100,
        width: "100%",
        top: "64px",
        flexDirection: "column",
        stroke: isDark ? "white" : "black",
      }}
    >
      <Row
        style={{
          flexDirection: "column",
        }}
      >
        <MobileMenuElement
          icon={<Jobs width={20} height={20} />}
          title="Usta Bul"
          href="/ustalar"
          isDark={isDark}
        />
        <MobileMenuElement
          icon={<Projects width={20} height={20} />}
          title="Projeler"
          href="/projeler"
          isDark={isDark}
        />
        <MobileMenuElement
          icon={<HowItWorks width={20} height={20} />}
          title="Nasıl Çalışıyor"
          href="/nasil-calisiyor"
          isDark={isDark}
        />
        <MobileMenuElement
          icon={<Search width={20} height={20} />}
          title="Keşfet"
          subPages={[
            { title: "Blog", href: "/blog" },
            { title: "Podcast", href: "/podcast" },
            { title: "Eğitim Videoları", href: "/egitim-videolari" },
            { title: "Akademik Yazılar", href: "/akademik-yazilar" },
          ]}
          isDark={isDark}
        />
      </Row>

      {isSignedIn ? null : (
        <div style={{ padding: "32px 16px 0px" }}>
          <DGButton
            variant={isDark ? "quaternary" : "tertiary"}
            size="medium"
            style={{
              marginBottom: "24px",
              width: "100%",
            }}
          >
            Giriş Yap
          </DGButton>
          <DGButton
            variant="dg-primary"
            size="medium"
            style={{ width: "100%" }}
          >
            Kayıt Ol
          </DGButton>
        </div>
      )}
    </Row>
  );
};

const MobileMenuElement = (props) => {
  const isDark = props.isDark ?? false;

  return (
    <Link to={props.href}>
      <Row
        className="f-label-l"
        style={{
          flexDirection: "row",
          padding: "16px",
          gap: 16,
          color: isDark ? "white" : "black",
          stroke: isDark ? "white" : "black",
        }}
      >
        <Col xs="auto">{props.icon}</Col>
        <Col xs style={{ fontWeight: 700 }}>
          {props.title}

          {(props.subPages ?? []).length !== 0 ? (
            <Col
              xs={13}
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 20,
                marginTop: 20,
              }}
            >
              {props.subPages?.map((element, i) => (
                <Link
                  to={element.href}
                  style={{
                    color: isDark ? "white" : "black",
                    stroke: isDark ? "white" : "black",
                  }}
                >
                  <Row>
                    <Col xs className="f-label">
                      {element.title}
                    </Col>
                    <Col xs="auto">
                      <CaretRight
                        width={12}
                        height={12}
                        stroke="var(--neutral200)"
                      />
                    </Col>
                  </Row>
                </Link>
              ))}
            </Col>
          ) : null}
        </Col>
        {(props.subPages ?? []).length !== 0 ? null : (
          <Col xs="auto">
            <CaretRight width={12} height={12} stroke="var(--neutral200)" />
          </Col>
        )}
      </Row>
    </Link>
  );
};

const MobileProfileMenu = (props) => {
  const isDark = props.variant === "dark";
  const navigate = useNavigate();

  return (
    <Row
      style={{
        position: "absolute",
        height: "calc(100vh - 64px)",
        background: isDark ? "var(--purplePrimary)" : "white",
        zIndex: 100,
        width: "100%",
        top: "64px",
        flexDirection: "column",
        stroke: isDark ? "white" : "black",
      }}
    >
      <Row
        style={{
          flexDirection: "column",
        }}
      >
        <Row style={{ padding: "16px 16px 0px", gap: 16, marginBottom: 24 }}>
          <Col xs="auto">
            <Image
              src="https://www.designgost.com/img/4e312348-8be8-4a45-ab83-6a5b4fb99a35/hakan-ertan.jpg?fm=webp&fit=max&crop=1350%2C1350%2C0%2C0&w=200&s=ee609c601fab0ec9585be45177fa5a4b"
              style={{ height: "80px", width: "80px", borderRadius: 8 }}
            />
          </Col>
          <Col
            xs
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div className="f-h6">Hakan Ertan</div>
            <div>
              <DGButton
                variant="dg-primary"
                size="sm"
                onClick={() => {
                  navigate(`/profile`);
                  props?.hideOverlays();
                }}
              >
                Profile Git
              </DGButton>
            </div>
          </Col>
        </Row>

        <MobileMenuElement
          icon={<Activities width={20} height={20} />}
          title="Aktiviteler"
          href="/profile"
          isDark={isDark}
        />
        <MobileMenuElement
          icon={<Email width={20} height={20} />}
          title="Mesajlar"
          href="/messages"
          isDark={isDark}
        />
        <MobileMenuElement
          icon={<ProfileSetting width={20} height={20} />}
          title="Profil Ayarları"
          href="/profile?settings"
          isDark={isDark}
        />

        <Row
          className="f-label-l"
          style={{
            flexDirection: "row",
            padding: "16px",
            gap: 16,
            color: isDark ? "white" : "black",
            stroke: isDark ? "white" : "black",
          }}
          onClick={() => {
            localStorage.setItem("signed-in", false);
          }}
        >
          <Col xs="auto">
            <SignOut width={20} height={20} />
          </Col>
          <Col xs style={{ fontWeight: 700 }}>
            Çıkış Yap
          </Col>
          <Col xs="auto">
            <CaretRight width={12} height={12} stroke="var(--neutral200)" />
          </Col>
        </Row>
      </Row>
    </Row>
  );
};