import { Form, InputGroup } from "react-bootstrap";

const Input = ({ icon, placeholder, withoutBorder = false, onKeyDown, onChange }) => {
  return (
    <InputGroup style={{padding: "8px 15px", border: (withoutBorder === true ? "0px" : "1px solid var(--neutral50)"), borderRadius: "5px"}} >
      {icon != null ? (
        <InputGroup.Text
          style={{ border: "0px", backgroundColor: "transparent", padding: "0px" }}
        >
          {icon}
        </InputGroup.Text>
      ) : null}
      <Form.Control
        style={{ border: "0px", backgroundColor: "transparent" }}
        placeholder={placeholder}
        onKeyDown={onKeyDown}
        onChange={onChange}
      />
    </InputGroup>
  );
};

export default Input;