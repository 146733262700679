import { Nav, Navbar } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import ExploreDropdown from "../Subviews/ExploreDropdown";

const HeaderCenter = (props) => {
  const location = useLocation();

  return (
    <Navbar.Brand
      className="space-between align-items-center"
      style={{ flexGrow: 1 }}
    >
      <Nav
        className="align-items-center"
        style={{ gap: "50px" }}
        activeKey={location.pathname}
      >
        <Nav.Link
          className="f-h6 p-0"
          href="/ustalar"
          active={location.pathname.includes("/ustalar")}
        >
          Usta Bul
        </Nav.Link>
        <Nav.Link
          className="f-h6 p-0"
          href="/projeler"
          active={location.pathname.includes("/projeler")}
        >
          Projeler
        </Nav.Link>
        <Nav.Link
          className="f-h6 p-0"
          href="/nasil-calisiyor"
          active={location.pathname.includes("/nasil-calisiyor")}
        >
          Nasıl Çalışıyor
        </Nav.Link>
        <ExploreDropdown variant={props.variant} />
      </Nav>
    </Navbar.Brand>
  );
};

export default HeaderCenter;
