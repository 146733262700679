import { Container } from "react-bootstrap";
import useIsMobile from "../public/is-Mobile";

const Terms = () => {
  const isMobile = useIsMobile();
  return (
    <Container
      style={{
        maxWidth: "686px",
        padding: isMobile ? "24px" : "80px 0 74px",
      }}
    >
      <div class="text-content">
        <div className="f-paragraph">
          <p>
            <div className="f-h2">Kullanım Koşulları</div>
          </p>
          <p>
            DesignGost, geleceğin tasarımcılarını (“Çırak”) profesyonel
            tasarımcılarla (“Usta”) bir araya getiren, Çırakların işlerini
            yükleyip kendilerine Usta seçebildiği, Ustaların ise Çırakların
            işlerine dair yönlendirmelerde bulunarak bilgi ve deneyimlerini
            paylaştığı, aynı zamanda tasarımla ilgili her konuda blog, podcast
            ve eğitim videolarının yer aldığı bir mentorluk platformudur.
          </p>
          <p>
            Kullanım Koşullarındaki “Kullanıcı” ifadesi Usta ve Çırakları temsil
            eder.
          </p>
          <p>
            DesignGost kullanıcısı olarak haklarınız ve sorumluluklarınız
            hakkında daha fazla bilgi edinmek için aşağıdaki koşulları
            okuyabilirsiniz.
          </p>
          <p>
            Bu Kullanım Koşulları ("Koşullar"), DesignGost'un web sitesini,
            hizmetlerini ve mobil uygulamasını (topluca "Hizmet") kullanımınız
            için geçerli olan hakları ve sorumlulukları açıklar ve her biri
            DesignGost tarafından yönetilir.
          </p>
          <p>
            Lütfen Hizmeti kullanmadan önce Koşulları dikkatlice okuyunuz.
            Koşulları, ayrıca DesignGost'un
          </p>
          <p>
            Gizlilik Politikasını ("Gizlilik Politikası") ve DesignGost'un
            Topluluk Kurallarını ("Topluluk Kuralları") kabul etmiyorsanız
            Hizmeti kullanamazsınız. Bir şirket veya başka bir tüzel kişilik
            adına kayıt oluyorsanız bu tüzel kişiliği Koşullara bağlama
            yetkisine sahip olduğunuzu beyan edersiniz. Böyle bir yetkiniz yoksa
            Koşulları kabul etmemeli veya bu tür bir kuruluş adına Hizmeti
            kullanmamalısınız. Hizmet, yalnızca bağlı olduğunuz yargı alanında
            reşit olma yaşına girdiyseniz ve Koşullara uyma konusunda tam yetkin
            olmanız durumunda kullanılabilir.
          </p>
          <p className="f-h4">1. Mentorluk Sistemi</p>
          <p>
            DesignGost, deneyimli tasarımcılar (“Usta”) ile tasarımcı
            adaylarının (“Çırak”) bir araya gelerek bilgi alışverişinde
            bulundukları bir mentorluk platformudur. Mentorluk sistemi aşağıdaki
            şekilde işlemektedir.
          </p>
          <ul>
            <li>
              Çırak platformdaki Ustalar arasından herhangi birine Ustalık
              talebi gönderebilir.
            </li>
            <li>
              Çırak, bir ay içerisinde, en fazla iki Usta ile çalışabilir ve her
              bir Ustaya bir proje olmak üzere, toplam 2 proje yollayabilir.
            </li>
            <li>
              Usta, gelen talepleri değerlendirerek kendisine istediği sayıda
              Çırak seçebilir.
            </li>
            <li>
              Usta ve Çırak eşleşmesinin ardından Çırak çalışmalarını sisteme
              yükler ve Usta ile paylaşır.
            </li>
            <li>
              Usta, kendisiyle paylaşılan işleri inceleyerek gerekli
              yönlendirmeleri yapar ve Çırak’a bilgi verir. Ardından Çırak
              istenen iyileştirmeleri yaparak çalışmasını tekrar sisteme yükler.
              Bu süreç çalışmanın 3. versiyonu sisteme yüklendiğinde sona erer.
              Ustanın projeyi tam yeterli bulması halinde proje onaylanır.
            </li>
            <li>
              Onaylanan projeler kullanıcıların profillerinde yayımlanır.
              Projenin ilerleyiş sürecindeki mesajlaşmalar bu bölümde
              görülebilir.
            </li>
            <li>
              Usta ve Çırak arasındaki iletişim platform üzerindeki mesajlaşma
              fasilitesi ile gerçekleştirilir. Çıraklar mesajlaşma fasilitesi
              üzerinden en fazla 5 mesaj gönderebilir. Ustalar için herhangi bir
              mesajlaşma sınırı bulunmaz.
            </li>
            <li>
              Usta ve Çırak arasındaki mentorluk süresi 1 (bir) ay olarak
              belirlenmiştir.
            </li>
            <li>
              Usta istediği zaman meşgul butonu aracılığıyla profilini sessize
              alarak Çırak taleplerine kapatabilir.
            </li>
            <li>
              Çırak, Topluluk Kurallarını ihlal ederse Çıraklıktan
              çıkartılabilir.
            </li>
            <li>
              Usta ve Çırakların tekrar eşleşmesi Ustanın inisiyatifindedir.
            </li>
          </ul>
          <p className="f-h4">2. DesignGost Hesabınız</p>
          <p>
            Hizmet üzerinde bir hesap ("Hesabınız") oluşturursanız, Hesabınızın
            ve İçeriğinin güvenliğini sağlamaktan sorumlu olursunuz.
            Hesabınızdan ve diğer herhangi bir şekilde gerçekleşen tüm
            faaliyetlerden tamamen siz sorumlusunuz. Hesabınız üzerinden
            İçeriği, başkalarının adını veya itibarını zedeleyecek, yanıltıcı
            veya yasa dışı bir şekilde platforma yüklememelisiniz. DesignGost,
            uygunsuz veya yasa dışı olduğunu düşündüğü herhangi bir tanım veya
            anahtar kelimeyi değiştirebilir veya kaldırabilir. Hesabınızın
            yetkisiz kullanımlarını veya diğer güvenlik ihlallerini derhal
            DesignGost'a bildirmelisiniz. DesignGost, bu tür eylemler veya
            ihmallerin bir sonucu olarak ortaya çıkan her türlü zarar da dahil
            olmak üzere, herhangi bir eylem veya ihmalden sorumlu olmayacaktır.
          </p>
          <p className="f-h4">3. Sorumluluklarınız</p>
          <p>
            Bir Hesap açtığınızda yorum yaparsanız, materyal gönderirseniz,
            bağlantılar paylaşırsanız veya Hizmet aracılığıyla materyal
            yayınlarsanız (veya herhangi bir üçüncü parti aracılığıyla
            yayınlanmasına izin verirseniz) bu içerikten ve içerikten
            kaynaklanan her türlü zarardan tamamen siz sorumlusunuz. Söz konusu
            İçeriğin metin veya grafik olup olmadığına bakılmaz. İçeriği
            kullanılabilir hale getirerek şunları beyan ve garanti edersiniz:
          </p>
          <p>
            1. İçeriğin indirilmesi, kopyalanması ve kullanılması, herhangi bir
            üçüncü tarafın telif hakkı, patent, ticari marka veya ticari sır
            hakları dahil ancak bunlarla sınırlı olmamak üzere mülkiyet
            haklarını ihlal etmeyecektir.
          </p>
          <p>
            2. İşvereninizin oluşturduğunuz içeriğe dair fikri mülkiyet hakları
            varsa işvereninizden İçeriği yayınlamak veya kullanıma sunmak için
            herhangi bir yazılım dahil ancak bunlarla sınırlı olmamak üzere izin
            almışsınızdır veya feragat etmişsinizdir.
          </p>
        </div>
      </div>
    </Container>
  );
};

export default Terms;
